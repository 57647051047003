import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const DmaNoDataPointsAssignedNotice: FC = () => {
  const { t } = useTranslation("double_materiality_assessment_page");

  return (
    <Box display="flex" flexDirection="column" gap={2} maxWidth={860}>
      <Typography variant="h2">{t("title", { ns: "dma_common" })}</Typography>
      <Typography>{t("no_data_points_assigned_text")}</Typography>
    </Box>
  );
};
