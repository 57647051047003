import * as React from "react";
import { FC } from "react";
import { TablePagination, TablePaginationProps } from "@mui/material";
import { IUsePaginationStateResult } from "../pagination.types";

interface ITablePaginationForStateProps
  extends Omit<
    TablePaginationProps,
    "page" | "rowsPerPage" | "onPageChange" | "onRowsPerPageChange"
  > {
  paginationStateResult: IUsePaginationStateResult;
}

export const TablePaginationForState: FC<ITablePaginationForStateProps> = ({
  paginationStateResult,
  count,
  rowsPerPageOptions,
  ...props
}) => {
  const internalRowsPerPageOptions = (rowsPerPageOptions ?? [10, 25, 50, 100]).filter(
    (option) => (typeof option === "number" ? option : option.value) <= count,
  );

  return (
    <TablePagination
      page={paginationStateResult.state.currentPage}
      rowsPerPage={paginationStateResult.state.rowsPerPage}
      onPageChange={(event, page) => paginationStateResult.setCurrentPage(page)}
      onRowsPerPageChange={(event) =>
        paginationStateResult.setRowsPerPage(parseInt(event.target.value))
      }
      count={count}
      rowsPerPageOptions={internalRowsPerPageOptions}
      {...props}
    />
  );
};
