import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { QueryWrapper } from "../common/components/query-wrapper.component";
import { useRecordingPeriodRecordingStructuresQuery } from "../recording-structures/recording-structures.queries";
import { useHasOrganizationModuleAccess } from "../organization-module-access/has-organization-module-access.hook";
import { AppModule } from "@netcero/netcero-common";
import { NavItemUrlUtilities } from "../common/utilities/nav-item-url.utilities";

interface IRecordingPeriodActionsDashboardSectionProps {
  organizationId: string;
  recordingPeriodId: string;
}

export const RecordingPeriodActionsDashboardSection: FC<
  IRecordingPeriodActionsDashboardSectionProps
> = ({ organizationId, recordingPeriodId }) => {
  const { t } = useTranslation("recording_period_actions_dashboard_section");

  const hasDMAAccess = useHasOrganizationModuleAccess(organizationId, AppModule.DMA);

  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId,
    recordingPeriodId,
  );

  return (
    <QueryWrapper
      query={recordingStructuresQuery}
      loadingOverride={() => false} // Do not show loading indicator
      build={(data) => {
        const esrsStructures = data.recordingStructures.filter((recordingStructure) =>
          recordingStructure.categoryIdentifier.startsWith("esrs"),
        );

        const showDMARecommendation =
          // Only show DMA recommendation if user has access to DMA
          hasDMAAccess &&
          // Check that no esrs recording structures exist yet
          esrsStructures.length <= 0;

        const hasAnyRecommendations = showDMARecommendation;

        return (
          hasAnyRecommendations && (
            <Box display="flex" flexDirection="column" pt="7vh" mb={4}>
              {showDMARecommendation && (
                <ActionCard
                  title={t("title_double_materiality_assessment")}
                  content={t("text_double_materiality_assessment")}
                  button={
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to={NavItemUrlUtilities.getDMAPageUrl(organizationId, recordingPeriodId)}
                      sx={{ mx: "auto" }}
                    >
                      {t("button_double_materiality_assessment")}
                    </Button>
                  }
                />
              )}
            </Box>
          )
        );
      }}
    />
  );
};

interface IActionCardProps {
  title: ReactNode;
  content: ReactNode;
  button: ReactNode;
}

const ActionCard: FC<IActionCardProps> = ({ title, content, button }) => {
  return (
    <Card
      sx={{
        width: 580,
        maxWidth: "100%",
        mx: "auto",
        border: "none",
        borderRadius: 8,
        p: 1,
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 30px 2px",
        backgroundColor: (theme) => `${theme.palette.primary.main}10`,
        ":hover": {
          transition: "box-shadow 300ms",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 30px 5px",
        },
      }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body1" whiteSpace="pre-line">
          {content}
        </Typography>
        {button}
      </CardContent>
    </Card>
  );
};
