import { Box, Collapse, Divider, Typography } from "@mui/material";
import {
  IDataEntryObjectInputParameterContext,
  IDataEntryObjectInputParameterLinkMetadataRecordingStructureTypeEnum,
  IDataEntryObjectInputParameterStatus,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CONTENT_CONTAINER_ID } from "../../common/components/app-shell-wrapper.component";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { ExpansionIconButton } from "../../common/components/expansion-icon-button.component";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { LinkChip } from "../../common/components/link-chip.component";
import { TOP_BAR_HEIGHT } from "../../common/components/top-bar.component";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { useScrollPosition } from "../../common/hooks/use-scroll-position.hook";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import { DataEntryObjectInputParameterStatusIndicatorWithMenu } from "../../data-entry-objects-input-parameters/components/data-entry-object-input-parameter-status-indicator-with-menu.component";
import { IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { SingleOrganizationUserAvatarPicker } from "../../user/components/single-organization-user-avatar-picker.component";
import { ConditionalRecordingInfo } from "../components/conditional-recoding-info.component";
import { useSearchParams } from "react-router-dom";
import { PhaseInExplanationAlertForInputParameter } from "./phase-in-explanation-alert.component";
import { DataEntryObjectInputParameterLinkedSourcesPicker } from "../components/linked-sources-data-entry-object-input-parameter-picker.component";
import { useDeoEsrsInputContext } from "./deo-esrs-input.context";
import { DisclosureRequirementInfoIcon } from "./disclosure-requirement-info-icon.component";
import { DrInputParametersList } from "./dr-input-parameters-list.component";
import { UPDATE_DISCLOSURE_REQUIREMENT_SOURCES_ACTION } from "./esrs-api-actions.constants";
import { DisclosureRequirementCard } from "./esrs-common-components.constants";
import { FillInHelpersComponent } from "./fill-in-helpers/fill-in-helpers.component";
import { DrValueEditor } from "./value-editing/dr-value-editor.component";
import { AuthenticationUtilities } from "@netcero/netcero-common";
import { InputParameterRecordingEsrsStructuresUtilities } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { useUserContext } from "../../user/user.context";
import { useHasObjectAdminDataEntryObjectAccess } from "../../user/hooks/has-object-admin-deo-access.hook";
import { InputParameterMaterialityChip } from "./input-parameter-materiality-chip.component";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "copy-dr-to-clipboard-button";

interface IDisclosureRequirementInputProps {
  disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement;
}

export const DisclosureRequirementInput: FC<IDisclosureRequirementInputProps> = ({
  disclosureRequirement,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_esrs_component");
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();
  // TODO: NC-XYZ Clean up - only use TOP_BAR_HEIGHT + 1 in line 72
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  const titleRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<HTMLDivElement | undefined>(undefined);
  const [showTitleDropShadow, setShowTitleDropShadow] = useState(false);

  // Get the content container element to use as ref for scrolling
  useEffect(() => {
    const element = document.querySelector(`#${CONTENT_CONTAINER_ID}`);
    if (element instanceof HTMLDivElement) {
      setContainer(element);
    }
  }, []);

  useScrollPosition(
    16,
    () => {
      if (titleRef.current) {
        setShowTitleDropShadow(
          titleRef.current.getBoundingClientRect().y <=
            (isFeatureFlagEnabled ? TOP_BAR_HEIGHT + 2 : 0),
        );
      }
    },
    container,
  );

  const {
    expandedDisclosureRequirementIds,
    setExpandedDisclosureRequirementIds,
    organization,
    recordingPeriod,
    organizationStructure,
    dataEntryObject,
    recordingStructureId,
    recordingStructure,
    sectionId,
    conditionalDisplayInputParametersLookup,
    handleDisclosureRequirementContributingUserIdsChange,
    handleDisclosureRequirementResponsibleUsersChange,
    handleCreateDrValue,
    handleUpdateDrValue,
    handleDeleteDrValue,
    handleUpdateTableValue,
    handleResetDisclosureRequirement,
    handleSubmitDisclosureRequirement,
    handleApproveDisclosureRequirement,
    handleRejectDisclosureRequirement,
    createDisclosureRequirementUrl,
    isLoading,
  } = useDeoEsrsInputContext();
  const deoInputParameterContext: IDataEntryObjectInputParameterContext = useMemo(
    () => ({
      recordingStructureId,
      sectionId,
      recordingStructureType:
        IDataEntryObjectInputParameterLinkMetadataRecordingStructureTypeEnum.Esrs,
    }),
    [recordingStructureId, sectionId],
  );

  const { user } = useUserContext();
  const authenticatedUserId = user?.jwtPayload.sub;
  const authenticatedUserOrgRole = AuthenticationUtilities.getOrgRoleOfUser(
    user?.jwtPayload,
    organization.id,
  );
  const hasObjectAdminDataEntryObjectAccess = useHasObjectAdminDataEntryObjectAccess({
    organizationId: organization.id,
    recordingPeriodId: recordingPeriod.id,
    dataEntryObjectId: dataEntryObject.id,
  });

  const [searchParams] = useSearchParams();
  const filters =
    InputParameterRecordingEsrsStructuresUtilities.getFiltersFromSearchParams(searchParams);

  const enableInteractiveDrElements =
    InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
      authenticatedUserId,
      authenticatedUserOrgRole,
      inputParameter: disclosureRequirement.disclosureRequirementInputParameter,
      hasObjectAdminDataEntryObjectAccess,
    }) &&
    InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
      disclosureRequirement.disclosureRequirementInputParameter,
      filters,
    );

  const isExpanded = useMemo(
    () => expandedDisclosureRequirementIds.includes(disclosureRequirement.id),
    [expandedDisclosureRequirementIds, disclosureRequirement.id],
  );
  const handleExpandDisclosureRequirement = useCallback(() => {
    setExpandedDisclosureRequirementIds((prev) =>
      prev.includes(disclosureRequirement.id)
        ? prev.filter((id) => id !== disclosureRequirement.id)
        : [...prev, disclosureRequirement.id],
    );
  }, [disclosureRequirement.id, setExpandedDisclosureRequirementIds]);

  const translatedDisclosureRequirementTitle = useMemo(
    () =>
      translateOptionalContent(
        disclosureRequirement.disclosureRequirementInputParameter?.inputParameter.name,
      ) ?? "Unknown Disclosure Requirement Input Parameter!",
    [
      disclosureRequirement.disclosureRequirementInputParameter?.inputParameter.name,
      translateOptionalContent,
    ],
  );

  const disclosureRequirementInputParameterConditionalRecordingInfo = useMemo(
    () => conditionalDisplayInputParametersLookup[disclosureRequirement.id] ?? null,
    [conditionalDisplayInputParametersLookup, disclosureRequirement.id],
  );
  const notConditionallyHiddenInputParameters = useMemo(
    () =>
      disclosureRequirement.inputParameters.filter((ip) => {
        const displayInformation = conditionalDisplayInputParametersLookup[ip.parameterId];
        return !displayInformation || displayInformation.displayMode !== "hide";
      }),
    [disclosureRequirement.inputParameters, conditionalDisplayInputParametersLookup],
  );

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const copyLinkComponent = useMemo(
    () => (
      <Box display="inline-block" className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
        <Box sx={{ transform: "translateY(-2px)" }}>
          <CopyToClipboardButton
            size="small"
            disableRipple
            value={createDisclosureRequirementUrl(disclosureRequirement)}
            tooltip={t("tooltip_copy_link", { ns: "buttons" })}
            tooltipSuccess={t("tooltip_copy_link_success", { ns: "buttons" })}
            tooltipPlacement="right"
          />
        </Box>
      </Box>
    ),
    [disclosureRequirement, createDisclosureRequirementUrl, t],
  );

  // Submission Handlers

  const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState(false);
  const handleSubmit = useCallback(() => {
    // Skip if no DR IP
    if (!disclosureRequirement.disclosureRequirementInputParameter) {
      console.error(
        "Cannot Submit. Unknown Disclosure Requirement Input Parameter!",
        disclosureRequirement,
      );
      return;
    }

    const areAllChildInputParametersComplete = disclosureRequirement.inputParameters
      .filter((inputParameter) => !!inputParameter.inputParameter)
      .every(
        (inputParameter) =>
          inputParameter.inputParameter!.status === IDataEntryObjectInputParameterStatus.Done,
      );

    if (areAllChildInputParametersComplete) {
      void handleSubmitDisclosureRequirement(
        disclosureRequirement.disclosureRequirementInputParameter.inputParameter,
        deoInputParameterContext,
      );
    } else {
      setShowConfirmSubmitDialog(true);
    }
  }, [disclosureRequirement, handleSubmitDisclosureRequirement, deoInputParameterContext]);
  const handleConfirmIncompleteSubmission = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        void handleSubmitDisclosureRequirement(
          disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
          deoInputParameterContext,
        );
      }
      setShowConfirmSubmitDialog(false);
    },
    [
      disclosureRequirement.disclosureRequirementInputParameter,
      handleSubmitDisclosureRequirement,
      deoInputParameterContext,
    ],
  );

  return (
    <>
      {/* Confirm Submission Dialog */}
      <ConfirmDialogTextBody
        open={showConfirmSubmitDialog}
        onClose={handleConfirmIncompleteSubmission}
        title={t("confirm_submit_incomplete_dr_title")}
        content={t("confirm_submit_incomplete_dr_body")}
      />

      {/* DR Card */}
      <DisclosureRequirementCard id={disclosureRequirement.id} isExpanded={isExpanded}>
        {/* DR Title Bar */}
        <Box
          ref={titleRef}
          display="flex"
          alignItems="center"
          gap={2}
          p={2}
          position="sticky"
          top={isFeatureFlagEnabled ? -40 : 0}
          borderRadius={6}
          zIndex={99}
          boxShadow={
            isExpanded && showTitleDropShadow ? "rgba(0, 0, 0, 0.1) 0px 2px 15px 5px" : "none"
          }
          m={-0.5}
          sx={{
            transition: "box-shadow 100ms",
            backgroundColor: (theme) => theme.palette.common.white,
          }}
        >
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            gap={1}
            onClick={handleExpandDisclosureRequirement}
            sx={CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`)}
          >
            {/* Collapse Button */}
            <ExpansionIconButton
              expanded={isExpanded}
              iconButtonProps={{
                color: "inherit",
                sx: {
                  fontSize: "48px",
                },
              }}
            />
            {/* Title */}
            <LineClampTypographyWithTooltip
              tooltipOverride={translatedDisclosureRequirementTitle}
              onOverflowChange={setIsTextOverflowing}
              variant="h6"
              component="h3"
              maxLines={2}
              style={{ cursor: "pointer", overflowWrap: "break-word", wordBreak: "break-word" }}
            >
              {translatedDisclosureRequirementTitle}
              {/* Copy IP Link - shown here when NOT overflowing (end of text) */}
              {!isTextOverflowing && copyLinkComponent}
            </LineClampTypographyWithTooltip>
            {/* Copy IP Link - shown here when overflowing (would be hidden by lineclamp) */}
            {isTextOverflowing && (
              <Box alignSelf="end" mt={-0.65} ml={-1}>
                {copyLinkComponent}
              </Box>
            )}
          </Box>
          {enableInteractiveDrElements &&
            (disclosureRequirement.disclosureRequirementInputParameter ? (
              <>
                {/* Materiality Status */}
                <InputParameterMaterialityChip
                  identity={{
                    organizationId: organization.id,
                    recordingPeriodId: recordingPeriod.id,
                    dataEntryObjectId: organizationStructure.id,
                    inputParameterId:
                      disclosureRequirement.disclosureRequirementInputParameter.inputParameter.id,
                  }}
                />

                {/* Status */}
                <DataEntryObjectInputParameterStatusIndicatorWithMenu
                  type="disclosure-requirement"
                  organizationId={organization.id}
                  recordingPeriodId={recordingPeriod.id}
                  dataEntryObjectId={dataEntryObject.id}
                  recordingStructureId={recordingStructureId}
                  dataEntryObjectInputParameter={
                    disclosureRequirement.disclosureRequirementInputParameter
                  }
                  onSubmit={handleSubmit}
                  onReset={() =>
                    handleResetDisclosureRequirement(
                      disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                    )
                  }
                  onApprove={() =>
                    handleApproveDisclosureRequirement(
                      disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                    )
                  }
                  onReject={() =>
                    handleRejectDisclosureRequirement(
                      disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                      deoInputParameterContext,
                    )
                  }
                  disabled={isLoading}
                />
                {/* Responsible Person */}
                <SingleOrganizationUserAvatarPicker
                  organizationId={organization.id}
                  value={
                    disclosureRequirement.disclosureRequirementInputParameter.responsibleUserId ??
                    null
                  }
                  tooltipNoUserSelected={t("add_responsible_user_tooltip", {
                    ns: "data_entry_object_values_overview_common",
                  })}
                  noUsersAvailableText={t("no_users_available_for_responsible_user", {
                    ns: "data_entry_object_values_overview_common",
                  })}
                  noUserOption
                  noUserOptionText={t("no_responsible_user_value_text", {
                    ns: "data_entry_object_values_overview_common",
                  })}
                  onChange={(userId: string | null) =>
                    handleDisclosureRequirementResponsibleUsersChange(
                      disclosureRequirement.disclosureRequirementInputParameter!,
                      userId,
                    )
                  }
                  disabled={isLoading}
                />
              </>
            ) : (
              // Error message for unknown DR IP
              <Typography variant="body1" color="error">
                Unknown Disclosure Requirement Input Parameter! (Id: {disclosureRequirement.id})
              </Typography>
            ))}
          {/* Description */}
          <DisclosureRequirementInfoIcon disclosureRequirement={disclosureRequirement} />
        </Box>
        {/* Collapsable DR Content */}
        <Collapse in={isExpanded} unmountOnExit>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            p={2}
            pt={enableInteractiveDrElements ? 2 : 0.5}
          >
            {enableInteractiveDrElements && (
              <>
                {/* Second Row */}
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {/* EU Regulations */}
                  {disclosureRequirement.metaData.linkToStandard && (
                    <LinkChip
                      to={translateContent(disclosureRequirement.metaData.linkToStandard)}
                      label={t("eu_law_label")}
                    />
                  )}
                  {disclosureRequirement.metaData.linkToAR && (
                    <LinkChip
                      to={translateContent(disclosureRequirement.metaData.linkToAR)}
                      label={t("application_requirements_label")}
                    />
                  )}
                  {/* Vertical Grow Spacer */}
                  <Box flex={1} />
                  {/* Wrap the following content into a separate box for line breaks */}
                  <Box display="flex" flexWrap="wrap" gap={1} alignItems="center">
                    {disclosureRequirement.disclosureRequirementInputParameter ? (
                      <>
                        {/* Linked Sources */}
                        <DataEntryObjectInputParameterLinkedSourcesPicker
                          organizationId={organization.id}
                          recordingPeriodId={recordingPeriod.id}
                          dataEntryObjectId={dataEntryObject.id}
                          inputParameterId={
                            disclosureRequirement.disclosureRequirementInputParameter.inputParameter
                              .id
                          }
                          snackbarMessage={UPDATE_DISCLOSURE_REQUIREMENT_SOURCES_ACTION}
                          disabled={isLoading}
                        />
                        {/*Vertical Divider*/}
                        <Divider flexItem orientation="vertical" sx={{ my: 1, mx: 1 }} />
                        {/* Contributors */}
                        <OrganizationUsersComponent
                          values={
                            disclosureRequirement.disclosureRequirementInputParameter
                              .contributingUserIds
                          }
                          organizationId={organization.id}
                          onChange={(newAssignedUserIds) => {
                            handleDisclosureRequirementContributingUserIdsChange(
                              disclosureRequirement.disclosureRequirementInputParameter!,
                              newAssignedUserIds,
                            );
                          }}
                          tooltipAddButton={t("add_contributing_user_tooltip", {
                            ns: "data_entry_object_values_overview_common",
                          })}
                          emptyMessageAddButton={t("no_more_contributing_users_to_assign", {
                            ns: "data_entry_object_values_overview_common",
                          })}
                          emptyMessage={t("no_contributing_users", {
                            ns: "data_entry_object_values_overview_common",
                          })}
                          disabled={isLoading}
                        />
                      </>
                    ) : (
                      // Error message for unknown DR IP
                      <Typography variant="body1" color="error">
                        Unknown Disclosure Requirement Input Parameter! (Id:{" "}
                        {disclosureRequirement.id})
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* DR Phase In Info */}
                <PhaseInExplanationAlertForInputParameter
                  inputParameter={
                    disclosureRequirement.disclosureRequirementInputParameter?.inputParameter
                  }
                />
                {/* Info about conditional recording */}
                <ConditionalRecordingInfo
                  displayInformation={disclosureRequirementInputParameterConditionalRecordingInfo}
                />
                {/* DR Input */}
                {disclosureRequirement.disclosureRequirementInputParameter ? (
                  <DrValueEditor
                    type="disclosure-requirement"
                    deoInputParameter={disclosureRequirement.disclosureRequirementInputParameter}
                    // NC-1945: Disable distribution criteria
                    // availableDistributionCriteria={[]}
                    organizationId={organization.id}
                    recordingPeriod={recordingPeriod}
                    rootDataEntryObjectId={organizationStructure.id}
                    dataEntryObjectId={dataEntryObject.id}
                    disabled={isLoading}
                    onCreate={(data) =>
                      handleCreateDrValue(
                        disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                        data,
                      )
                    }
                    onUpdate={(valueId, data) =>
                      handleUpdateDrValue(
                        disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                        valueId,
                        data,
                      )
                    }
                    onDelete={(valueId) =>
                      handleDeleteDrValue(
                        disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                        valueId,
                      )
                    }
                    onTableUpdate={(created, updated, deleted) =>
                      handleUpdateTableValue(
                        disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                        created,
                        updated,
                        deleted,
                      )
                    }
                  />
                ) : (
                  // Unknown DR IP (normally not shown since these IPs are filtered out)
                  <Typography variant="body1" color="error">
                    Unknown Disclosure Requirement Input Parameter! (Id: {disclosureRequirement.id})
                  </Typography>
                )}
                {/* Fill In Helpers */}
                <FillInHelpersComponent
                  disclosureRequirement={disclosureRequirement}
                  organizationId={organization.id}
                  recordingPeriodId={recordingPeriod.id}
                  rootDataEntryObject={organizationStructure}
                  dataEntryObjectId={dataEntryObject.id}
                  esrsTopicIdentifier={recordingStructure.structure.topicIdentifier}
                />
              </>
            )}
            {/* Input Parameters Section (only shown when there are input parameters */}
            {notConditionallyHiddenInputParameters.length > 0 && (
              <>
                <Divider />
                {/* Input Parameters*/}
                <DrInputParametersList
                  disclosureRequirementInputParameters={notConditionallyHiddenInputParameters}
                  drIsExcluded={
                    !!disclosureRequirement.disclosureRequirementInputParameter?.exclude
                  }
                />
              </>
            )}
          </Box>
        </Collapse>
      </DisclosureRequirementCard>
    </>
  );
};
