import { AlwaysArray, MaybeArray } from "../array.public-types";

export class ArrayUtilities {
  public static alwaysArray<T>(arr: MaybeArray<T>): AlwaysArray<T> {
    return Array.isArray(arr) ? arr : [arr];
  }

  public static chunkArray<T>(arr: T[], chunkSize: number = 1000): T[][] {
    if (chunkSize <= 0) {
      throw "Invalid chunk size";
    }

    const result: T[][] = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }

    return result;
  }

  public static deduplicateArray<T, TI>(arr: T[], getIdentifier: (item: T) => TI) {
    return arr.filter(
      (e, i, a) => a.findIndex((inner) => getIdentifier(inner) === getIdentifier(e)) === i,
    );
  }

  public static deduplicateArrayEquals<T>(
    arr: T[],
    equalsOther: (currentItem: T, otherItem: T) => boolean,
  ) {
    return arr.filter((e, i, a) => a.findIndex((inner) => equalsOther(e, inner)) === i);
  }

  public static deduplicatePrimArray<T>(arr: T[]) {
    return Array.from(new Set(arr));
  }

  public static indexArray<T>(array: T[]): [number, T][] {
    return array.map((item, index) => [index, item]);
  }

  // TODO: Replace all usages once lib ES2023 is added --> switch to .toSorted() method
  public static sorted<T>(array: T[]): T[] {
    const clone = [...array];
    clone.sort();
    return clone;
  }

  // shamelessly copied from https://www.freecodecamp.org/news/javascript-range-create-an-array-of-numbers-with-the-from-method/
  public static generateRange(length: number) {
    return Array.from({ length }, (value, index) => index);
  }

  /**
   * This method removes all values that are considered "not present" (null or undefined) from the array.
   * Note: On some feature branch, there are `MaybePresent` etc. utility types that should be used here in the future.
   * @param values
   */
  public static removeNotPresentValues<T>(
    values: Array<T | null | undefined>,
  ): Array<Exclude<T, null | undefined>> {
    return values.filter(
      (value): value is Exclude<T, null | undefined> => value !== null && value !== undefined,
    );
  }

  /**
   * This method returns true if any of the booleans in the passed array is true
   * @param array The array to inspect
   */
  public static isAnyArrayValue(array: boolean[]) {
    return array.some((i) => i);
  }
}
