/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of AI operation to execute
 * @export
 * @enum {string}
 */

export const IOrganizationalAiOperationEnum = {
  ExpandText: "expand_text",
  SummarizeText: "summarize_text",
} as const;

export type IOrganizationalAiOperationEnum =
  (typeof IOrganizationalAiOperationEnum)[keyof typeof IOrganizationalAiOperationEnum];
