export enum AiErrorType {
  TEXT_TOO_LONG = "TEXT_TOO_LONG",
  BAD_REQUEST = "BAD_REQUEST",
  SERVER_ERROR = "SERVER_ERROR",
  UNKNOWN = "UNKNOWN",
}

export interface IApiError {
  message?: string | string[];
  statusCode?: number;
}

export const getAiErrorType = (error: IApiError): AiErrorType => {
  if (!error || !error.message) {
    return AiErrorType.UNKNOWN;
  }

  if (Array.isArray(error.message)) {
    if (
      error.message.some((msg: string) => msg.includes("text must be shorter than or equal to"))
    ) {
      return AiErrorType.TEXT_TOO_LONG;
    }
  }

  if (error.statusCode === 400) {
    return AiErrorType.BAD_REQUEST;
  }

  if (error.statusCode && error.statusCode >= 500) {
    return AiErrorType.SERVER_ERROR;
  }

  return AiErrorType.UNKNOWN;
};
