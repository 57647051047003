import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getAiErrorType, IApiError } from "@netcero/ai-common";

export const useTranslateAiErrorMessage = (error: IApiError | null) => {
  const { t } = useTranslation("lexical_ai");

  return useMemo(() => {
    if (!error) {
      return null;
    }

    const errorType = getAiErrorType(error);
    return t(`error.${errorType}`);
  }, [t, error]);
};
