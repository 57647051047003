import { useMemo } from "react";
import { useUserJwt } from "../../user/hooks/user-jwt.hook";
import { IBaseApiClientConfiguration } from "@netcero/netcero-common";

type ApiClientConstructor<T, K extends IBaseApiClientConfiguration> = new (config: K) => T;

export const useApiClient = <T, K extends IBaseApiClientConfiguration>(
  ClientClass: ApiClientConstructor<T, K>,
  config: Partial<Omit<ConstructorParameters<ApiClientConstructor<T, K>>[0], "token">>,
): T | null => {
  const token = useUserJwt();

  return useMemo(() => {
    if (!token) {
      return null;
    }

    return new ClientClass({
      ...config,
      token,
    } as ConstructorParameters<ApiClientConstructor<T, K>>[0]); // Explicit cast to satisfy TypeScript
    // Only track primitive values in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, token]);
};
