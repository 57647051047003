import React, { createContext, FC, useContext, useRef } from "react";
import { useObserveSizeAsState } from "../common/hooks/use-observe-size-state.hook";

interface ISizeContextType {
  size: { width: number; height: number };
}

const SizeContext = createContext<ISizeContextType | undefined>(undefined);

export const useSizeContext = () => {
  const context = useContext(SizeContext);
  if (!context) {
    throw new Error("useSize must be used within a SizeProvider");
  }
  return context;
};

export const SizeProviderWithWrapperContainer: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const size = useObserveSizeAsState(containerRef.current);

  return (
    <SizeContext.Provider value={{ size }}>
      <div ref={containerRef}>{children}</div>
    </SizeContext.Provider>
  );
};
