import { SetURLSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import {
  IPaginationCurrentPageHandler,
  IPaginationRowsPerPageHandler,
  IPaginationState,
  IUsePaginationStateResult,
} from "../pagination.types";

const PAGE_INDEX_QUERY_PARAM_KEY = "page";
const ROWS_PER_PAGE_QUERY_PARAM_KEY = "rowsPerPage";

export const useSearchParamsPaginationState = (
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  startWithPage: number = 0,
  startWithRowsPerPage: number = 10,
): IUsePaginationStateResult => {
  const state: IPaginationState = useMemo(
    () => ({
      currentPage: Number(searchParams.get(PAGE_INDEX_QUERY_PARAM_KEY)) || startWithPage,
      rowsPerPage: Number(searchParams.get(ROWS_PER_PAGE_QUERY_PARAM_KEY)) || startWithRowsPerPage,
    }),
    [searchParams, startWithPage, startWithRowsPerPage],
  );

  const setCurrentPage: IPaginationCurrentPageHandler = useCallback(
    (page: number) => {
      setSearchParams((params) => {
        params.set(PAGE_INDEX_QUERY_PARAM_KEY, page.toString());
        return params;
      });
    },
    [setSearchParams],
  );

  const setRowsPerPage: IPaginationRowsPerPageHandler = useCallback(
    (rowsPerPage: number) => {
      setSearchParams((params) => {
        params.set(PAGE_INDEX_QUERY_PARAM_KEY, "0");
        params.set(ROWS_PER_PAGE_QUERY_PARAM_KEY, rowsPerPage.toString());
        return params;
      });
    },
    [setSearchParams],
  );

  return useMemo(
    () => ({
      state,
      setCurrentPage,
      setRowsPerPage,
    }),
    [state, setCurrentPage, setRowsPerPage],
  );
};
