import { FC, useMemo } from "react";
import {
  IDMACategoryWithEffectsAndChildren,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { ESRSTopicContextProvider, useESRSTopicContext } from "./esrs-topic.context";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "./dma.queries";
import { DMACategoryUtilities } from "./dialogs/dma-categories/dma-category.utilities";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import {
  AddDmaCategorySummaryIcon,
  AddDmaSubTopicIcon,
  CommentIcon,
  EditIcon,
} from "../common/constants/tabler-icon.constants";
import { ROLE_ACCESS } from "@netcero/netcero-common";
import { OnlyShowToUsersWithRole } from "../authentication/components/only-show-to-users-with-role.component";
import { DMACategoryValidationButton } from "./dialogs/dma-categories/dma-category-validation-buttons.component";
import { useVerifyDMACategoryStakeholderFeedback } from "./mutations/dma-category.mutations";
import { useAppSnackbar } from "../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../app-snackbar/app-snackbar.interfaces";

interface IDMACategoryActionsProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
  onEdit: VoidFunction;
  canEdit: boolean;
  showEdit: boolean;
  onShowFeedback?: VoidFunction;
  onAddSummary?: VoidFunction;
  onAddChild?: VoidFunction;
  onOptOut?: VoidFunction;
}

export const DMACategoryActions: FC<IDMACategoryActionsProps> = ({
  dmaCategory,
  onEdit,
  canEdit,
  onShowFeedback,
  onAddSummary,
  onAddChild,
  onOptOut,
  showEdit,
}) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component", {
    keyPrefix: "dma_category_actions_component",
  });

  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  // Load stakeholder feedbacks to display the count in the badge
  const stakeholderFeedbacksQuery = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  // Count the feedbacks that are not pending as we only want to display the amount of those
  const feedbackCount = useMemo(() => {
    return stakeholderFeedbacksQuery.data?.stakeholderFeedbacks.filter(
      (feedback) => DMACategoryUtilities.getFeedbackState(feedback) !== "pending",
    ).length;
  }, [stakeholderFeedbacksQuery.data]);

  // Also load manual feedbacks, so they are cached when opening the feedback dialog
  useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const dmaCategoryContextValue = useMemo(() => {
    return {
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId,
    };
  }, [organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId]);

  // Verify Feedback
  const { wrapApiPromise } = useAppSnackbar();

  const verifyStakeholderFeedbackMutation = useVerifyDMACategoryStakeholderFeedback();

  const handleVerifyFeedback = async (payload: IVerifyStakeholderFeedbackRequest) => {
    await wrapApiPromise(
      verifyStakeholderFeedbackMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId: dmaCategory?.id ?? "",
        payload,
      }),
      {
        type: payload.verified
          ? BasicSnackbarApiActionType.VERIFY_DMA_CATEGORY
          : BasicSnackbarApiActionType.UNVERIFY_DMA_CATEGORY,
      },
    );
  };

  return (
    <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}>
      {/* Edit Topic Button */}
      {showEdit && (
        <Tooltip title={t("tooltip_edit")}>
          <span>
            <IconButton size="small" onClick={onEdit} disabled={!canEdit}>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {/* Open Feedback Dialog Button */}
      {onShowFeedback && (
        <Tooltip title={t("tooltip_stakeholder_feedback")}>
          <Badge color="primary" badgeContent={feedbackCount} overlap="circular">
            <IconButton size="small" onClick={onShowFeedback}>
              <CommentIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      )}
      {/* Add DMA Summary Button */}
      {onAddSummary && (
        <Tooltip title={canEdit ? t("tooltip_add_summary") : t("tooltip_add_summary_disabled")}>
          <span>
            <IconButton size="small" onClick={onAddSummary} disabled={!canEdit}>
              <AddDmaCategorySummaryIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {/* Add DMA Subtopic Button */}
      {onAddChild && (
        <Tooltip title={canEdit ? t("tooltip_add_child") : t("tooltip_add_child_disabled")}>
          <span>
            <IconButton size="small" onClick={onAddChild} disabled={!canEdit}>
              <AddDmaSubTopicIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {/* Opt-Out Button */}
      {onOptOut && (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={onOptOut}
          sx={{ minWidth: 124 }}
        >
          {t("button_category_irrelevant")}
        </Button>
      )}
      {/* Validation Button */}
      <ESRSTopicContextProvider value={dmaCategoryContextValue}>
        <DMACategoryValidationButton dmaCategory={dmaCategory} onVerify={handleVerifyFeedback} />
      </ESRSTopicContextProvider>
    </OnlyShowToUsersWithRole>
  );
};
