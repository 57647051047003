export class MappingUtilities {
  public static mapIfNotUndefined<T, R>(
    value: T | undefined,
    mapper: (value: T) => R,
  ): R | undefined {
    return value === undefined ? undefined : mapper(value);
  }

  public static mapIfNotNull<T, R>(value: T | null, mapper: (value: T) => R): R | null {
    return value === null ? null : mapper(value);
  }

  public static undefinedToNull<T>(value: T | undefined): T | null {
    return value === undefined ? null : value;
  }

  public static nullToUndefined<T>(value: T | null): T | undefined {
    return value === null ? undefined : value;
  }
}
