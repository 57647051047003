import { Dispatch, FC } from "react";
import {
  Box,
  Button,
  Collapse,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import { CONTENT_Y_PADDING } from "../common/components/app-shell-wrapper.component";
import { IDMACategoryState, IESRSTopic } from "@netcero/netcero-core-api-client";
import { Done, DoneAll, PriorityHigh } from "@mui/icons-material";
import { DMACategoryStatusIndicator } from "./common/dma-category-status-indicator.component";
import { PlusIcon } from "../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { useRenderESRSTopicName } from "./hooks/render-esrs-topic-name.hook";
import { useRenderDMACategoryName } from "./hooks/render-dma-category-name.hook";
import { useDoesUserHaveRole } from "../authentication/hooks/does-user-have-role.hook";
import { ROLE_ACCESS } from "@netcero/netcero-common";
import { OnlyShowToUsersWithRole } from "../authentication/components/only-show-to-users-with-role.component";

interface IEsrsTopicsStepperTocProps {
  dataEntryObjectId: string;
  viewedESRSTopicId: string | null;
  viewedDMACategoryId: string | null;
  selectedStepperIndex: number;
  shownTopics: IESRSTopic[];
  esrsTopicsStates: IDMACategoryState[];
  dmaCategoriesStates: IDMACategoryState[];
  onChangeStepperIndex: Dispatch<number>;
  onChangeViewSettings: (esrsTopicId: string | null, dmaCategoryId: string | null) => void;
  onCreateDMACategory: Dispatch<IESRSTopic>;
  onClearSettingsForDataEntryObject: Dispatch<string>;
}

export const EsrsTopicsStepperToc: FC<IEsrsTopicsStepperTocProps> = ({
  dataEntryObjectId,
  viewedESRSTopicId,
  viewedDMACategoryId,
  selectedStepperIndex,
  onChangeStepperIndex,
  shownTopics,
  esrsTopicsStates,
  dmaCategoriesStates,
  onChangeViewSettings,
  onCreateDMACategory,
  onClearSettingsForDataEntryObject,
}) => {
  const { t } = useTranslation("double_materiality_assessment_stepper_component");

  const renderName = useRenderDMACategoryName();
  const renderTopicName = useRenderESRSTopicName();

  const canEditDmaConfiguration = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION,
  );
  // When configuration is not accessible - adjust index
  const adjustedSelectedIndex = canEditDmaConfiguration
    ? selectedStepperIndex
    : selectedStepperIndex - 1;

  return (
    <Box display="flex" flexDirection="column" gap={2} width="18vw" maxWidth={340}>
      <Box
        sx={{
          overflowY: "auto",
          overflowX: "clip",
          py: `${CONTENT_Y_PADDING}px`,
          pr: "1vw",
        }}
      >
        <Stepper nonLinear activeStep={adjustedSelectedIndex} orientation="vertical">
          {/* DMA Configuration */}
          {canEditDmaConfiguration && (
            <Step completed={selectedStepperIndex > 0}>
              <StepButton
                onClick={() => {
                  // be sure to clear saved category
                  onClearSettingsForDataEntryObject(dataEntryObjectId);
                  // set correct index
                  onChangeStepperIndex(0);
                }}
                sx={{ flex: 1 }}
              >
                <StepLabel sx={{ flex: 1 }}>
                  <Box display="flex" gap={2}>
                    <Box flex={1}>{t("label_step_dma_configuration")}</Box>
                  </Box>
                </StepLabel>
              </StepButton>
            </Step>
          )}

          {/* Topics Steps */}
          {shownTopics.map((esrsTopic, index) => (
            <Step
              key={esrsTopic.id}
              completed={esrsTopicsStates[index] === IDMACategoryState.Verified}
            >
              <StepButton onClick={() => onChangeStepperIndex(index + 1)} sx={{ flex: 1 }}>
                <StepLabel sx={{ flex: 1 }}>
                  <Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography variant="inherit">{esrsTopic.topic}</Typography>
                      <Box display="flex" justifyContent="center" alignItems="center" ml="auto">
                        {esrsTopicsStates[index] === IDMACategoryState.Open && (
                          <Tooltip title={t("dma_open_topic")}>
                            <PriorityHigh color="warning" />
                          </Tooltip>
                        )}
                        {esrsTopicsStates[index] === IDMACategoryState.Shared && (
                          <Tooltip title={t("dma_shared_topic")}>
                            <Done color="primary" />
                          </Tooltip>
                        )}
                        {esrsTopicsStates[index] === IDMACategoryState.Verified && (
                          <Tooltip title={t("dma_verified_topic")}>
                            <DoneAll color="primary" />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                    <Typography variant="caption">{renderTopicName(esrsTopic)}</Typography>
                  </Box>
                </StepLabel>
              </StepButton>
              {esrsTopic.recordedESRSTopic &&
                esrsTopic.recordedESRSTopic?.dmaCategories.length > 0 && (
                  <Collapse in={viewedESRSTopicId === esrsTopic.id}>
                    <Box
                      ml={1.5}
                      pl={1.5}
                      pt={1}
                      mb={-1}
                      borderLeft="solid 1px"
                      borderColor="#bdbdbd"
                    >
                      {/* List top level categories */}
                      {esrsTopic.recordedESRSTopic?.dmaCategories.map((dmaCategory, catIndex) => (
                        <Box
                          key={dmaCategory.id}
                          onClick={() => onChangeViewSettings(esrsTopic.id, dmaCategory.id)}
                          sx={{ cursor: "pointer" }}
                          display="flex"
                          alignItems="start"
                          py={1}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={dmaCategory.id === viewedDMACategoryId ? "bold" : "light"}
                            flex={1}
                          >
                            {renderName(dmaCategory)}
                          </Typography>
                          <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_ALL_IROS}>
                            <DMACategoryStatusIndicator
                              state={dmaCategoriesStates[catIndex]}
                              fontSize="medium"
                            />
                          </OnlyShowToUsersWithRole>
                        </Box>
                      ))}
                      {/* Add Top Level Category Button */}
                      <OnlyShowToUsersWithRole
                        roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_ESRS_TOPICS}
                      >
                        <Box display="flex" flexDirection="column" mt={1}>
                          <Tooltip title={t("button_create_category")} placement="right">
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => onCreateDMACategory(esrsTopic)}
                            >
                              <PlusIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </OnlyShowToUsersWithRole>
                    </Box>
                  </Collapse>
                )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};
