export type IAppSnackbarEnqueueApiMessageAction = IAppSnackbarEnqueueBasicSuccessMessageData;

interface IAppSnackbarEnqueueSuccessMessageDataBase<T extends object> {
  data: T;
}

export enum BasicSnackbarApiActionType {
  // Disclosure Requirements
  UPDATE_DISCLOSURE_REQUIREMENT_VALUE = "UPDATE_DISCLOSURE_REQUIREMENT_VALUE",
  UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS = "UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS",
  UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER = "UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER",
  UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_AVAILABILITY = "UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_AVAILABILITY",
  UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_DATA_COLLECTION = "UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_DATA_COLLECTION",
  // DR Submission Process
  RESET_DISCLOSURE_REQUIREMENT = "RESET_DISCLOSURE_REQUIREMENT",
  SUBMIT_DISCLOSURE_REQUIREMENT = "SUBMIT_DISCLOSURE_REQUIREMENT",
  APPROVE_DISCLOSURE_REQUIREMENT = "APPROVE_DISCLOSURE_REQUIREMENT",
  REJECT_DISCLOSURE_REQUIREMENT = "REJECT_DISCLOSURE_REQUIREMENT",
  RESET_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "RESET_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  // Input Parameter Values
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE",
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS",
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER",
  // Exclusion
  EXCLUDE_DISCLOSURE_REQUIREMENT = "EXCLUDE_DISCLOSURE_REQUIREMENT",
  INCLUDE_DISCLOSURE_REQUIREMENT = "INCLUDE_DISCLOSURE_REQUIREMENT",
  EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER = "INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER",
  // Phase In Decisions
  UPDATE_PHASE_IN_DECISION = "UPDATE_PHASE_IN_DECISION",
  // DMA
  EXPORT_DMA_IROS = "EXPORT_DMA_IROS",
  EXPORT_DMA_STAKEHOLDER_FEEDBACK = "EXPORT_DMA_STAKEHOLDER_FEEDBACK",
  EXPORT_USERS = "EXPORT_USERS",
  // DR Assessment
  UPDATE_DISCLOSURE_REQUIREMENT_ASSESSMENT = "UPDATE_DISCLOSURE_REQUIREMENT_ASSESSMENT",
  // Sources
  UPDATE_DISCLOSURE_REQUIREMENT_SOURCES = "UPDATE_DISCLOSURE_REQUIREMENT_SOURCES",
  UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_SOURCES = "UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_SOURCES",
  // IRO Move
  MOVE_FINANCIAL_EFFECT_TO_OTHER_TOPIC = "MOVE_FINANCIAL_EFFECT_TO_OTHER_TOPIC",
  MOVE_MATERIAL_IMPACT_TO_OTHER_TOPIC = "MOVE_MATERIAL_IMPACT_TO_OTHER_TOPIC",
  // IRO Duplicate
  DUPLICATE_MATERIAL_IMPACT = "DUPLICATE_MATERIAL_IMPACT",
  DUPLICATE_FINANCIAL_EFFECT = "DUPLICATE_FINANCIAL_EFFECT",
  // Init all ESRS Topics
  INIT_ALL_ESRS_TOPICS = "INIT_ALL_ESRS_TOPICS",
  // Organization Roles
  UPDATE_ORG_ROLE = "UPDATE_ORG_ROLE",
  UPDATE_USER_DATA_ENTRY_OBJECTS_ACCESS = "UPDATE_USER_DATA_ENTRY_OBJECTS_ACCESS",
  // Verification of DMA Categories
  VERIFY_DMA_CATEGORY = "VERIFY_DMA_CATEGORY",
  UNVERIFY_DMA_CATEGORY = "UNVERIFY_DMA_CATEGORY",
}

export type SnackbarSuccessMassageType = keyof typeof BasicSnackbarApiActionType;

export interface IAppSnackbarEnqueueBasicSuccessMessageData
  extends Partial<IAppSnackbarEnqueueSuccessMessageDataBase<never>> {
  type: BasicSnackbarApiActionType;
}
