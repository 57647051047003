import { IBaseApiClientConfiguration } from "./base-api.types";

export interface IFetchOptions {
  searchParams?: URLSearchParams;
  body?: unknown;
}

export class BaseApiClient {
  // Instance Logic
  constructor(protected configuration: IBaseApiClientConfiguration) {}

  /**
   * Generates the URL for the API based on the configuration.
   * @param baseUrl
   * @param realm
   * @param path
   * @param options
   * @protected
   */
  protected static generateApiUrlForConfiguration(
    { baseUrl }: Pick<IBaseApiClientConfiguration, "baseUrl">,
    path?: string,
    options?: {
      searchParams?: URLSearchParams;
    },
  ) {
    const baseUrlObject = new URL(baseUrl);
    const url = new URL(`${baseUrlObject.toString()}${path ? `${path}` : ""}`);
    if (options?.searchParams) {
      url.search = options.searchParams.toString();
    }
    return url;
  }

  /**
   * Generates the headers for the API.
   * @param token
   * @protected
   */
  protected static getHeaders(token: string) {
    return {
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    };
  }

  protected static async fetch(
    configuration: IBaseApiClientConfiguration,
    method: "GET" | "POST" | "PUT" | "DELETE",
    path?: string,
    options?: IFetchOptions,
  ) {
    return fetch(BaseApiClient.generateApiUrlForConfiguration(configuration, path, options), {
      method,
      headers: BaseApiClient.getHeaders(configuration.token),
      body: options?.body ? JSON.stringify(options.body) : undefined,
    }).then((res) => (res.ok ? res : Promise.reject(res)));
  }

  protected static async fetchTextStream(
    configuration: IBaseApiClientConfiguration,
    method: "GET" | "POST" | "PUT" | "DELETE",
    path?: string,
    options?: IFetchOptions,
  ) {
    const response = await fetch(
      BaseApiClient.generateApiUrlForConfiguration(configuration, path, options),
      {
        method,
        headers: BaseApiClient.getHeaders(configuration.token),
        body: options?.body ? JSON.stringify(options.body) : undefined,
      },
    );

    if (!response.ok || !response.body) {
      throw await response.json();
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    // Return a ReadableStream so that the caller can consume it
    return new ReadableStream({
      async start(controller) {
        let isDone = false;
        do {
          const { done, value } = await reader.read();
          isDone = done;
          if (done) {
            controller.close();
          } else {
            const chunk = decoder.decode(value, { stream: true });
            controller.enqueue(chunk); // Push chunk to the stream
          }
        } while (!isDone);
      },
    });
  }

  protected static parseJson<TResult>(res: Response) {
    return res.json() as Promise<TResult>;
  }
}
