import { IPaginationQueryParams, IPaginationState } from "./pagination.types";

export class PaginationUtilities {
  public static getPaginationQueryParams(
    paginationState: IPaginationState,
  ): IPaginationQueryParams {
    return {
      limit: paginationState.rowsPerPage,
      offset: paginationState.currentPage * paginationState.rowsPerPage,
    };
  }
}
