import { ICategoryInputParameter } from "../dma-categories.public-types";
import { IDmaCategoryIdentifierEnum } from "@netcero/netcero-core-api-client";

export const ESRS_DMA_CATEGORY_INPUT_PARAMETERS: ICategoryInputParameter = {
  // Please keep this for testing purposes, just leave it as is
  "1179ad80-2939-4fa0-90e1-7f31f72cbf7a": [
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
  ],
  // -------------------------------------------
  // REAL DATA STARTS HERE
  // -------------------------------------------
  // This is the ID of the IP that will consider this (or the ID of the DR)
  "4a05c0dc-79ef-4267-be31-fa9954bdce1b": [
    IDmaCategoryIdentifierEnum.E1AdaptionToClimateChange,
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "43c8e8fe-b934-45b3-9b17-9c393552c474": [
    IDmaCategoryIdentifierEnum.E1AdaptionToClimateChange,
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
    IDmaCategoryIdentifierEnum.E2GroundPollution,
    IDmaCategoryIdentifierEnum.E2PollutionOfOrganisms,
    IDmaCategoryIdentifierEnum.E2AlarmingSubstances,
    IDmaCategoryIdentifierEnum.E2MicroPlastic,
    IDmaCategoryIdentifierEnum.E3WaterConsumption,
    IDmaCategoryIdentifierEnum.E3WaterWithdrawal,
    IDmaCategoryIdentifierEnum.E3DrainOfWater,
    IDmaCategoryIdentifierEnum.E3DrainOfWaterIntoOceans,
    IDmaCategoryIdentifierEnum.E3UsageOfSeaResources,
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
    IDmaCategoryIdentifierEnum.E5ResourceInfluxes,
    IDmaCategoryIdentifierEnum.E5ResourceEffluxes,
    IDmaCategoryIdentifierEnum.E5Waste,
    IDmaCategoryIdentifierEnum.G1CorporateCulture,
    IDmaCategoryIdentifierEnum.G1WhistleblowerProtection,
    IDmaCategoryIdentifierEnum.G1AnimalProtection,
    IDmaCategoryIdentifierEnum.G1PoliticalCommitment,
    IDmaCategoryIdentifierEnum.G1ManagementOfVendorRelations,
    IDmaCategoryIdentifierEnum.G1CorruptionPrevention,
    IDmaCategoryIdentifierEnum.G1CorruptionOccurrences,
  ],
  "21217bbd-f1ec-44a7-8faf-afdfb7bb1a9a": [
    IDmaCategoryIdentifierEnum.E1AdaptionToClimateChange,
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
  ],
  "9548cbf0-5759-4c21-b43c-ef2fd48b991a": [
    IDmaCategoryIdentifierEnum.E1AdaptionToClimateChange,
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
  ],
  "1271ecd8-5e27-4929-a256-3945f00a6cea": [
    IDmaCategoryIdentifierEnum.E1AdaptionToClimateChange,
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
  ],
  "eb6f6274-3795-4e10-9227-8deee553e5cc": [
    IDmaCategoryIdentifierEnum.E1AdaptionToClimateChange,
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
  ],
  "d8462d86-1085-4049-b347-c30fcfc4ea12": [IDmaCategoryIdentifierEnum.E1ClimateProtection],
  "4d5a93e6-112d-488d-b1b0-34ef8af65dd0": [IDmaCategoryIdentifierEnum.E1ClimateProtection],
  "7c1e1c01-c6eb-424a-a8f6-3df868f8e035": [
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E1Energy,
  ],
  "f2f8484a-8bc7-47dd-954a-0709a8f276b5": [
    IDmaCategoryIdentifierEnum.E1ClimateProtection,
    IDmaCategoryIdentifierEnum.E5Waste,
  ],
  "a3e975d7-8c74-40a8-ba0b-0610d3a26a7c": [IDmaCategoryIdentifierEnum.E1ClimateProtection],
  "4ad58476-80de-4534-b968-74b51400810c": [IDmaCategoryIdentifierEnum.E1ClimateProtection],
  "35a0a464-dd94-423e-a695-355d06c18f1a": [
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
    IDmaCategoryIdentifierEnum.E2GroundPollution,
    IDmaCategoryIdentifierEnum.E2PollutionOfOrganisms,
    IDmaCategoryIdentifierEnum.E2AlarmingSubstances,
    IDmaCategoryIdentifierEnum.E2MicroPlastic,
  ],
  "d3342c7c-95d8-472a-8854-4ae177288bb6": [
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
    IDmaCategoryIdentifierEnum.E2GroundPollution,
    IDmaCategoryIdentifierEnum.E2PollutionOfOrganisms,
    IDmaCategoryIdentifierEnum.E2AlarmingSubstances,
    IDmaCategoryIdentifierEnum.E2MicroPlastic,
  ],
  "888ecc26-6898-4aed-8e19-ddd15a9c813d": [
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
    IDmaCategoryIdentifierEnum.E2GroundPollution,
    IDmaCategoryIdentifierEnum.E2PollutionOfOrganisms,
    IDmaCategoryIdentifierEnum.E2AlarmingSubstances,
    IDmaCategoryIdentifierEnum.E2MicroPlastic,
  ],
  "2f36e39d-5541-4bb9-92ed-c9fcd82ee6bc": [
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
    IDmaCategoryIdentifierEnum.E2GroundPollution,
    IDmaCategoryIdentifierEnum.E2MicroPlastic,
  ],
  "2ce498c7-15ae-4b51-a6d8-c6e0db504790": [
    IDmaCategoryIdentifierEnum.E2AirPollution,
    IDmaCategoryIdentifierEnum.E2WaterPollution,
    IDmaCategoryIdentifierEnum.E2GroundPollution,
    IDmaCategoryIdentifierEnum.E2PollutionOfOrganisms,
    IDmaCategoryIdentifierEnum.E2AlarmingSubstances,
    IDmaCategoryIdentifierEnum.E2MicroPlastic,
  ],
  "819b03aa-7413-4376-ade8-7a5174f05ea1": [IDmaCategoryIdentifierEnum.E2AlarmingSubstances],
  "fa835433-3d9e-4435-9f25-c065238971ce": [
    IDmaCategoryIdentifierEnum.E3WaterConsumption,
    IDmaCategoryIdentifierEnum.E3WaterWithdrawal,
    IDmaCategoryIdentifierEnum.E3DrainOfWater,
    IDmaCategoryIdentifierEnum.E3DrainOfWaterIntoOceans,
    IDmaCategoryIdentifierEnum.E3UsageOfSeaResources,
  ],
  "74d891ac-5113-4aaa-b625-7d057310a54a": [
    IDmaCategoryIdentifierEnum.E3WaterConsumption,
    IDmaCategoryIdentifierEnum.E3WaterWithdrawal,
    IDmaCategoryIdentifierEnum.E3DrainOfWater,
    IDmaCategoryIdentifierEnum.E3DrainOfWaterIntoOceans,
    IDmaCategoryIdentifierEnum.E3UsageOfSeaResources,
  ],
  "fe8d391a-3682-4924-b1af-e488b8fa7fd0": [
    IDmaCategoryIdentifierEnum.E3WaterConsumption,
    IDmaCategoryIdentifierEnum.E3WaterWithdrawal,
    IDmaCategoryIdentifierEnum.E3DrainOfWater,
    IDmaCategoryIdentifierEnum.E3DrainOfWaterIntoOceans,
    IDmaCategoryIdentifierEnum.E3UsageOfSeaResources,
  ],
  "888a860c-b49d-48e7-b192-a123db7fc3f0": [
    IDmaCategoryIdentifierEnum.E3WaterConsumption,
    IDmaCategoryIdentifierEnum.E3WaterWithdrawal,
    IDmaCategoryIdentifierEnum.E3DrainOfWater,
  ],
  "35a7c9dd-7f5a-420c-bc9e-d25855769980": [
    IDmaCategoryIdentifierEnum.E3WaterConsumption,
    IDmaCategoryIdentifierEnum.E3WaterWithdrawal,
    IDmaCategoryIdentifierEnum.E3DrainOfWater,
    IDmaCategoryIdentifierEnum.E3DrainOfWaterIntoOceans,
    IDmaCategoryIdentifierEnum.E3UsageOfSeaResources,
  ],
  "a6a9ffa0-bd4c-417d-8df0-eec85012efc0": [
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
  ],
  "25f4840c-c743-468b-b493-eef8acf2004c": [
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
  ],
  "e65ebc87-0094-4d1e-8bb6-62a05ce67850": [
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
  ],
  "694bc9df-f872-48c3-9b89-d7371ebeef2e": [
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
  ],
  "c99c1ee5-f264-4228-922f-860dfe279d22": [
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
  ],
  "9a4fcb89-dc89-4164-a0a3-6053bd1bb1f6": [
    IDmaCategoryIdentifierEnum.E4ClimateChange,
    IDmaCategoryIdentifierEnum.E4ChangesInUsage,
    IDmaCategoryIdentifierEnum.E4DirectExploitation,
    IDmaCategoryIdentifierEnum.E4InvasiveSpecies,
    IDmaCategoryIdentifierEnum.E4EnvironmentalPollution,
    IDmaCategoryIdentifierEnum.E4Other,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfSpecies,
    IDmaCategoryIdentifierEnum.E4ImpactOnConditionOfEcosystems,
    IDmaCategoryIdentifierEnum.E4ImpactsAndDependenciesOnEcosystemServices,
  ],
  "62167362-882a-4ff7-9976-cd4841745cfd": [
    IDmaCategoryIdentifierEnum.E5ResourceInfluxes,
    IDmaCategoryIdentifierEnum.E5ResourceEffluxes,
    IDmaCategoryIdentifierEnum.E5Waste,
  ],
  "3f06f150-59bd-472e-9010-d4acb5d2a36d": [
    IDmaCategoryIdentifierEnum.E5ResourceInfluxes,
    IDmaCategoryIdentifierEnum.E5ResourceEffluxes,
    IDmaCategoryIdentifierEnum.E5Waste,
  ],
  "a2bf543a-f085-4a96-bf55-b0a3346e32d0": [
    IDmaCategoryIdentifierEnum.E5ResourceInfluxes,
    IDmaCategoryIdentifierEnum.E5ResourceEffluxes,
    IDmaCategoryIdentifierEnum.E5Waste,
  ],
  "3925a914-117d-414e-bbe4-6eff9604a85d": [IDmaCategoryIdentifierEnum.E5ResourceInfluxes],
  "76f1f116-d84d-426d-b74d-7c7b0b8fc8e6": [
    IDmaCategoryIdentifierEnum.E5ResourceInfluxes,
    IDmaCategoryIdentifierEnum.E5ResourceEffluxes,
  ],
  "737fd33a-d5a8-4fe3-a01a-4529af59220b": [
    IDmaCategoryIdentifierEnum.E5ResourceEffluxes,
    IDmaCategoryIdentifierEnum.E5Waste,
  ],
  "74b8b2e5-7bc3-4585-a2bc-ae5c41ac4be9": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "64e7399e-b7d1-4472-a0cc-9e4c090b58a5": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "01a53ec2-320c-455a-bf65-91069f052279": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "8e9e1288-55dc-4acc-8f0c-f485a8be0451": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "5be54bc8-a90e-4810-b1d7-0ce84e15c236": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "ad81ce78-4cb3-4296-a526-2a2109758381": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "1526fd47-4f30-4f2a-b0f5-274d71e03620": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "74626144-342d-4189-b5ae-920f9eb0fc48": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "81e0c3ff-9655-4221-8436-110906e98192": [IDmaCategoryIdentifierEnum.S1SafeEmployment],
  "d58997eb-90db-4274-8c34-c8fa0312b6c6": [
    IDmaCategoryIdentifierEnum.S1SafeEmployment,
    IDmaCategoryIdentifierEnum.S1WorkingHours,
    IDmaCategoryIdentifierEnum.S1AptPay,
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
    IDmaCategoryIdentifierEnum.S1WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S1HealthProtection,
    IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S1TrainingCourses,
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S1ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S1Diversity,
    IDmaCategoryIdentifierEnum.S1ChildLabour,
    IDmaCategoryIdentifierEnum.S1ForceLabour,
    IDmaCategoryIdentifierEnum.S1AptAccommodation,
    IDmaCategoryIdentifierEnum.S1DataPrivacy,
  ],
  "ae64b363-4faa-43d8-92bb-539367aeda14": [IDmaCategoryIdentifierEnum.S1AptPay],
  "99782d97-ab8c-403a-8960-f9dcc233aa8e": [
    IDmaCategoryIdentifierEnum.S1SocialDialogue,
    IDmaCategoryIdentifierEnum.S1FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S1Bargaining,
  ],
  "3c64ed87-e7b0-407d-8727-3b6309a40acd": [IDmaCategoryIdentifierEnum.S1WorkLifeBalance],
  "4ff5f204-1e58-40b2-af1a-01a5e651ee3e": [IDmaCategoryIdentifierEnum.S1HealthProtection],
  "106f0719-5525-4103-b43f-0bec69c24868": [IDmaCategoryIdentifierEnum.S1EqualTreatmentOfGenders],
  "047bd5e9-e1f7-4b57-8e06-87d8a3e7c6b7": [IDmaCategoryIdentifierEnum.S1TrainingCourses],
  "ff145d8a-7240-4513-9167-b5c8e524f872": [
    IDmaCategoryIdentifierEnum.S1InclusionOfPeopleWithDisabilities,
  ],
  "091dac9b-ef54-43d0-8db8-efaf1cfb7c82": [IDmaCategoryIdentifierEnum.S1Diversity],
  "f1d3b76b-0081-4454-9c91-7a911a571820": [
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
  ],
  "856fcab1-4bd4-4703-9f73-c0a33a498329": [
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
  ],
  "80582ed8-7a90-4468-8320-1a8551cd0778": [
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
  ],
  "ab875d5a-b355-4ec8-a3e5-74ad504267a7": [
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
  ],
  "cef6a063-03ee-4864-8440-038d8e6af442": [
    IDmaCategoryIdentifierEnum.S2SafeEmployment,
    IDmaCategoryIdentifierEnum.S2WorkingHours,
    IDmaCategoryIdentifierEnum.S2AptPay,
    IDmaCategoryIdentifierEnum.S2SocialDialogue,
    IDmaCategoryIdentifierEnum.S2FreedomOfAssociation,
    IDmaCategoryIdentifierEnum.S2Bargaining,
    IDmaCategoryIdentifierEnum.S2WorkLifeBalance,
    IDmaCategoryIdentifierEnum.S2HealthProtection,
    IDmaCategoryIdentifierEnum.S2EqualTreatmentOfGenders,
    IDmaCategoryIdentifierEnum.S2TrainingCourses,
    IDmaCategoryIdentifierEnum.S2InclusionOfPeopleWithDisabilities,
    IDmaCategoryIdentifierEnum.S2ActionsAgainstViolence,
    IDmaCategoryIdentifierEnum.S2Diversity,
    IDmaCategoryIdentifierEnum.S2ChildLabour,
    IDmaCategoryIdentifierEnum.S2ForcedLabour,
    IDmaCategoryIdentifierEnum.S2AptAccommodation,
    IDmaCategoryIdentifierEnum.S2Sanitation,
    IDmaCategoryIdentifierEnum.S2DataPrivacy,
  ],
  "88a161d3-e2a4-48e0-b431-b715f3a05f79": [
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
  ],
  "fa890742-71f9-4dc1-969e-723efb9d790a": [
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
  ],
  "93e84d86-0137-4bbb-b738-6284ee9dc1d2": [
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
  ],
  "8f1eb757-7785-479e-bf01-40cd35b6f54c": [
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
  ],
  "6ced035c-10fb-4c1a-a589-a8743a1a1987": [
    IDmaCategoryIdentifierEnum.S3AptAccommodation,
    IDmaCategoryIdentifierEnum.S3AptNutrition,
    IDmaCategoryIdentifierEnum.S3Sanitation,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSoil,
    IDmaCategoryIdentifierEnum.S3ImpactsAffectingSafety,
    IDmaCategoryIdentifierEnum.S3FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S3FreedomOfAssembly,
    IDmaCategoryIdentifierEnum.S3ImpactsOnProtectorsOfHumanRights,
    IDmaCategoryIdentifierEnum.S3Approval,
    IDmaCategoryIdentifierEnum.S3SelfDetermination,
    IDmaCategoryIdentifierEnum.S3CulturalRights,
  ],
  "f73f64fa-e8aa-41a1-85b0-e3e3e66339ad": [
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "f1ad0e4a-953d-4a77-b83b-1d5ac7684f32": [
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "56c40e32-6fbd-4c23-a18c-cba84cf0719b": [
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "b42e8d78-5b1a-4db0-bc83-160827ee6502": [
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "fa2807ef-75e0-4ad9-937f-ca912b48d85c": [
    IDmaCategoryIdentifierEnum.S4DataPrivacy,
    IDmaCategoryIdentifierEnum.S4FreedomOfSpeech,
    IDmaCategoryIdentifierEnum.S4AccessToInformation,
    IDmaCategoryIdentifierEnum.S4HealthProtection,
    IDmaCategoryIdentifierEnum.S4PersonalSafety,
    IDmaCategoryIdentifierEnum.S4ChildProtection,
    IDmaCategoryIdentifierEnum.S4NonDiscrimination,
    IDmaCategoryIdentifierEnum.S4AccessToProducts,
    IDmaCategoryIdentifierEnum.S4ResponsibleMarketingMethods,
  ],
  "2c5cc0f8-3c30-41ba-a98b-0fbce99eae12": [
    IDmaCategoryIdentifierEnum.G1CorporateCulture,
    IDmaCategoryIdentifierEnum.G1WhistleblowerProtection,
    IDmaCategoryIdentifierEnum.G1AnimalProtection,
    IDmaCategoryIdentifierEnum.G1PoliticalCommitment,
    IDmaCategoryIdentifierEnum.G1ManagementOfVendorRelations,
    IDmaCategoryIdentifierEnum.G1CorruptionPrevention,
    IDmaCategoryIdentifierEnum.G1CorruptionOccurrences,
  ],
  "3c17e21a-8a51-4c0e-af18-c81c9c502565": [
    IDmaCategoryIdentifierEnum.G1CorporateCulture,
    IDmaCategoryIdentifierEnum.G1WhistleblowerProtection,
    IDmaCategoryIdentifierEnum.G1AnimalProtection,
    IDmaCategoryIdentifierEnum.G1PoliticalCommitment,
    IDmaCategoryIdentifierEnum.G1ManagementOfVendorRelations,
    IDmaCategoryIdentifierEnum.G1CorruptionPrevention,
    IDmaCategoryIdentifierEnum.G1CorruptionOccurrences,
  ],
  "ad1cb08e-9605-4bf0-98d1-41da5ca54785": [IDmaCategoryIdentifierEnum.G1PoliticalCommitment],
  "b4e33752-56c6-4ee7-9bfe-45dc01f2cfb1": [
    IDmaCategoryIdentifierEnum.G1ManagementOfVendorRelations,
  ],
  "1fa14bc3-c18e-4f0f-aa8c-8278851c72f4": [
    IDmaCategoryIdentifierEnum.G1ManagementOfVendorRelations,
  ],
  "57f1b63f-993d-4069-a503-7d796f3f1436": [IDmaCategoryIdentifierEnum.G1CorruptionPrevention],
  "1e9371ad-73d2-43da-9379-4391c74e411f": [IDmaCategoryIdentifierEnum.G1CorruptionOccurrences],
};
