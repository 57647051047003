import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconSize, PlusIcon } from "../../common/constants/tabler-icon.constants";
import { InputParameterRecordingStructuresUtilities } from "../../input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { useCurrentOrganization } from "../../organizations/use-current-organization.hook";
import { useRecordingModelsQuery } from "../../recording-models/recording-models.queries";
import { useCurrentRecordingPeriod } from "../../recording-periods/use-current-recording-period.hook";
import { AddRecordingStructureToRecordingPeriodDialog } from "../../recording-structures/add-recording-structure-to-recording-period.dialog";
import { RecordingStructureCard } from "../../recording-structures/recording-structure-card.component";
import { useRecordingPeriodRecordingStructuresQuery } from "../../recording-structures/recording-structures.queries";
import { useTranslateOptionalContent } from "../../content-translation/hooks/translate-content.hook";
import { AppModule, OrgRole, ROLE_ACCESS } from "@netcero/netcero-common";
import { useHasOrganizationModuleAccess } from "../../organization-module-access/has-organization-module-access.hook";
import { GenericRecordingStructureCard } from "../../recording-structures/generic-recording-structure-card.component";
import {
  IInputParameterRecordingStructureBase,
  IRecordingModel,
} from "@netcero/netcero-core-api-client";
import { QueriesWrapper } from "../../common/components/queries-wrapper.component";
import { useAuthenticatedUserOrganizationRole } from "../../user/hooks/authenticated-user-org-role.hook";
import { useInitEsrsRecording } from "../hooks/use-init-esrs-recording.hook";
import { InitEsrsConfirmDialog } from "./init-esrs-confirm-dialog.component";
import { RecordingModelTemplateGroupsUtilities } from "../utilities/recording-model-template-groups.utilities";
import { OnlyShowToUsersWithRole } from "../../authentication/components/only-show-to-users-with-role.component";

const ADD_TOPICS_BUTTON_PROPS = {
  variant: "outlined",
  color: "primary",
  sx: {
    display: "flex",
    gap: 1,
    borderRadius: "24px",
    width: "100%",
    minHeight: "134px",
  },
} as const;

interface IAddRecordingStructureDialogState {
  open: boolean;
  recordingModelId: string | null;
}

interface IRecordingPeriodRecordingStructuresCardsListProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingStructureType?: string;
}

export const RecordingPeriodRecordingStructuresCardsList: FC<
  IRecordingPeriodRecordingStructuresCardsListProps
> = ({ organizationId, recordingPeriodId, recordingStructureType }) => {
  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  const hasAccessToEsrs = useHasOrganizationModuleAccess(organizationId, AppModule.ESRS);

  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();
  const isViewMember = authenticatedUserOrgRole === OrgRole.VIEW_MEMBERS;

  const recordingModelsQuery = useRecordingModelsQuery(organizationId);
  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const recordingModelFilter = useMemo(() => {
    switch (recordingStructureType) {
      case "thg":
        return "carbon_accounting";
      case "esrs":
        return "esrs";
      default:
        return undefined;
    }
  }, [recordingStructureType]);

  const recordingStructuresGroupedByRecordingModel = useMemo(() => {
    // Wait for data to become available
    if (!recordingStructuresQuery.data || !recordingModelsQuery.data) {
      return [];
    }

    return InputParameterRecordingStructuresUtilities.getGroupedRecordingStructures(
      recordingModelsQuery.data.recordingModels,
      recordingStructuresQuery.data.recordingStructures,
      hasAccessToEsrs,
      !isViewMember,
    );
  }, [recordingModelsQuery.data, recordingStructuresQuery.data, hasAccessToEsrs, isViewMember]);

  const [showAddInputParameterModelDialog, setShowAddInputParameterModelDialog] =
    useState<IAddRecordingStructureDialogState>({ open: false, recordingModelId: null });

  const categoryIdentifiersInUse = useMemo(
    () =>
      new Set(
        recordingStructuresQuery.data?.recordingStructures.map(
          (recordingStructure) => recordingStructure.categoryIdentifier,
        ) || [],
      ),
    [recordingStructuresQuery.data?.recordingStructures],
  );

  // Disabled for now since only ESRS after DMA is possible for now
  const handleOpenAddInputParameterModelDialog = (recordingModelId: string | null) => {
    setShowAddInputParameterModelDialog({
      open: true,
      recordingModelId,
    });
  };

  const orderNumbersInUse = useMemo(
    () =>
      recordingStructuresQuery.data
        ? InputParameterRecordingStructuresUtilities.extractRecordingModelsOrderValues(
            recordingStructuresQuery.data.recordingStructures,
          )
        : new Map<string | null, Set<number>>(),
    [recordingStructuresQuery],
  );

  const {
    showConfirmInitEsrs,
    initEsrsRecordingMutation,
    handleInitEsrsRecording,
    setShowConfirmInitEsrs,
  } = useInitEsrsRecording(organizationId, recordingPeriodId);

  return (
    <>
      {currentOrganization && currentRecordingPeriod && (
        <AddRecordingStructureToRecordingPeriodDialog
          open={showAddInputParameterModelDialog.open}
          onClose={() =>
            setShowAddInputParameterModelDialog({ open: false, recordingModelId: null })
          }
          organization={currentOrganization}
          recordingPeriod={currentRecordingPeriod}
          categoryIdentifiersInUse={categoryIdentifiersInUse}
          orderNumbersInUse={orderNumbersInUse}
          initiallySelectedRecordingModelId={showAddInputParameterModelDialog.recordingModelId}
        />
      )}
      {/* Confirm Start ESRS Dialog */}
      <InitEsrsConfirmDialog
        open={showConfirmInitEsrs}
        initEsrsRecordingMutation={initEsrsRecordingMutation}
        onClose={handleInitEsrsRecording}
      />
      {/* Render List */}
      <QueriesWrapper
        queries={[recordingModelsQuery, recordingStructuresQuery]}
        loadingOverride={() => (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        )}
        build={([recordingModels, recordingStructures]) => {
          const filteredModels = recordingStructuresGroupedByRecordingModel.filter(
            ([model]) => !recordingModelFilter || model?.identifier === recordingModelFilter,
          );

          return filteredModels.map(([model, structures]) => (
            <RecordingModelList
              key={model?.id ?? null}
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              recordingModel={model ?? null}
              structures={structures}
              onOpenAddInputParameterModelDialog={handleOpenAddInputParameterModelDialog}
              onInitializeEsrsRecording={() => setShowConfirmInitEsrs(true)}
              isViewMember={isViewMember}
            />
          ));
        }}
      />
    </>
  );
};

interface IRecordingModelListProps {
  organizationId: string;
  recordingPeriodId: string;
  recordingModel: IRecordingModel | null;
  structures: IInputParameterRecordingStructureBase[];
  onOpenAddInputParameterModelDialog: (recordingModelId: string | null) => void;
  onInitializeEsrsRecording: VoidFunction;
  isViewMember: boolean;
}

export const RecordingModelList: FC<IRecordingModelListProps> = ({
  organizationId,
  recordingPeriodId,
  recordingModel,
  structures,
  onOpenAddInputParameterModelDialog,
  onInitializeEsrsRecording,
  isViewMember,
}) => {
  const { t } = useTranslation([
    "recording_period_recording_structures_cards_list",
    "recording_period_actions_dashboard_section",
    "gap_analysis",
  ]);
  const translateContent = useTranslateOptionalContent();
  const hasAccessToDMA = useHasOrganizationModuleAccess(organizationId, AppModule.DMA);
  const hasAccessToEsrs = useHasOrganizationModuleAccess(organizationId, AppModule.ESRS);

  const isEsrs = recordingModel?.identifier === "esrs";

  const showStartEsrsRecording =
    isEsrs &&
    // Ensure access to ESRS
    hasAccessToEsrs &&
    // Only available if not all ESRS structures exist yet (ESRS 2, E1-5, S1-4, G1)
    RecordingModelTemplateGroupsUtilities.hasMissingEsrsRecordingStructures(structures) &&
    // VIEW_MEMBERS can't start ESRS recording
    !isViewMember;

  const showAddCategoryButton =
    // VIEW_MEMBERS can't add new category
    !isViewMember;

  return (
    <Box mb={4}>
      <Typography variant="h2">
        {translateContent(recordingModel?.name) ?? t("structures_without_recording_model_header")}
      </Typography>
      <Divider />

      <Grid container spacing={2} columnSpacing={3} mt={2} alignItems="stretch">
        {isEsrs && structures.length > 0 && (
          <>
            {hasAccessToDMA && (
              <RecordingStructureCardGridItem>
                <GenericRecordingStructureCard
                  id="dma"
                  title={t(
                    "recording_period_actions_dashboard_section:double_materiality_assessment_abbreviation",
                  )}
                  subtitle={t(
                    "recording_period_actions_dashboard_section:title_double_materiality_assessment",
                  )}
                  iconName="ph:rows"
                  organizationId={organizationId}
                  recordingPeriodId={recordingPeriodId}
                  url="double-materiality-assessment"
                />
              </RecordingStructureCardGridItem>
            )}
            <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_ACCESS_GAP_ANALYSIS}>
              <RecordingStructureCardGridItem>
                <GenericRecordingStructureCard
                  id="gap-analysis"
                  title={t("gap_analysis:title")}
                  subtitle={t("gap_analysis:subtitle")}
                  iconName="tabler:file-text"
                  organizationId={organizationId}
                  recordingPeriodId={recordingPeriodId}
                  url="gap-analysis"
                />
              </RecordingStructureCardGridItem>
            </OnlyShowToUsersWithRole>
          </>
        )}

        {/* Existing recording structures */}
        {structures.map((recordingStructure) => (
          <RecordingStructureCardGridItem key={recordingStructure.id}>
            <RecordingStructureCard
              organizationId={organizationId ?? ""}
              recordingPeriodId={recordingPeriodId ?? ""}
              recordingStructure={recordingStructure}
              recordingModel={recordingModel?.identifier ?? ""}
            />
          </RecordingStructureCardGridItem>
        ))}
        {/* Button to add all ESRS topics */}
        {showStartEsrsRecording && (
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="stretch">
            <Button {...ADD_TOPICS_BUTTON_PROPS} onClick={() => onInitializeEsrsRecording()}>
              <PlusIcon size={IconSize.Larger} />
              <Typography color="primary">{t("add_all_esrs_topics_button")}</Typography>
            </Button>
          </Grid>
        )}
        {/* Button to add new recording structure */}
        {showAddCategoryButton && (
          <Grid item xs={12} md={6} lg={4} display="flex" justifyContent="stretch">
            <Button
              {...ADD_TOPICS_BUTTON_PROPS}
              onClick={() => onOpenAddInputParameterModelDialog(recordingModel?.id ?? null)}
            >
              <PlusIcon size={IconSize.Larger} />
              <Typography color="primary">{t("add_category_button")}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const RecordingStructureCardGridItem: FC<PropsWithChildren> = ({ children }) => (
  <Grid item xs={12} sm={6} lg={4}>
    {children}
  </Grid>
);
