import React, { FC, useCallback, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, BaseSelection } from "lexical";
import { useListenToSelection } from "../../hooks/listen-to-selection.hook";
import { AiIcon } from "../../../common/constants/custom-icons.constants";
import { IconButton, ListSubheader, Menu, MenuItem, Tooltip } from "@mui/material";
import { AiSuggestionModal } from "../../components/ai-suggestion.modal";
import { createPortal } from "react-dom";
import { IOrganizationalAiOperationEnum } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { LexicalFormattingUtilities } from "../../lexical-formatting.utilities";
import { useCurrentOrganization } from "../../../organizations/use-current-organization.hook";
import { useAiApiClient } from "../../../ai/hooks/ai-api.hook";
import { IApiError } from "@netcero/ai-common";
import { ILexicalEditorVariant } from "../../lexical.types";

interface IAiButtonProps {
  disabled?: boolean;
  variant?: ILexicalEditorVariant;
}

export const AiButton: FC<IAiButtonProps> = ({ disabled, variant }) => {
  const { t } = useTranslation("lexical_ai");

  const organization = useCurrentOrganization();
  const aiClient = useAiApiClient();

  const [editor] = useLexicalComposerContext();

  const [isTextSelected, setIsTextSelected] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [aiModalOpen, setAiModalOpen] = useState<boolean>(false);
  const [generatedText, setGeneratedText] = useState<string | null>(null);
  const [error, setError] = useState<IApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState<BaseSelection | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTextAction = (action: IOrganizationalAiOperationEnum) => {
    editor.update(async () => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) && !selection.isCollapsed()) {
        const content = selection.getTextContent();
        setSelection(selection);
        setAiModalOpen(true);
        void generateText(action, content);
      }
    });
    handleClose();
  };

  const generateText = async (action: IOrganizationalAiOperationEnum, text: string) => {
    if (!organization || !aiClient) {
      return;
    }

    // Set loading state to true
    setIsLoading(true);

    aiClient
      .fetchAiResponseStream(organization.id, {
        text: text,
        operation: action,
      })
      .then(async (stream) => {
        const reader = stream.getReader();
        let isDone = false;

        do {
          const { done, value } = await reader.read();
          if (done) {
            isDone = true;
            break;
          }

          setGeneratedText((currentText) => (currentText ? currentText + value : value));
        } while (!isDone);
      })
      .catch((error) => {
        // Set the error, so the correct text can be displayed by the hook
        setError(error);
      })
      .finally(() => {
        // Set loading state to false
        setIsLoading(false);
      });
  };

  const onClose = useCallback(
    (accept: boolean | null) => {
      if (generatedText && accept) {
        editor.update(() => {
          if ($isRangeSelection(selection) && !selection.isCollapsed()) {
            LexicalFormattingUtilities.formatParagraph(editor);
            selection.insertRawText(generatedText);
          }
        });
      }

      if (isLoading) {
        return;
      }
      setAiModalOpen(false);
      setGeneratedText(null);
      setError(null);
    },
    [editor, generatedText, isLoading, selection],
  );

  // This function exists to disable / enable the ai button based in whether text is selected
  const handleSelectionChange = useCallback((selection: BaseSelection | null) => {
    if ($isRangeSelection(selection) && !selection.isCollapsed()) {
      setIsTextSelected(true);
    } else {
      setIsTextSelected(false);
    }
  }, []);

  useListenToSelection(handleSelectionChange);

  return (
    <>
      <Tooltip
        title={isTextSelected ? t("ai_button_enabled_tooltip") : t("ai_button_disabled_tooltip")}
      >
        <span>
          <IconButton
            className="ai-button"
            size="small"
            value="ai"
            onClick={handleClick}
            disabled={disabled || !isTextSelected || aiModalOpen}
          >
            <AiIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <ListSubheader sx={{ lineHeight: "unset", mb: 1 }}>
          {t("ai_button_menu.title")}
        </ListSubheader>
        <MenuItem onClick={() => handleTextAction(IOrganizationalAiOperationEnum.ExpandText)}>
          {t(`ai_button_menu.${IOrganizationalAiOperationEnum.ExpandText}`)}
        </MenuItem>
        <MenuItem onClick={() => handleTextAction(IOrganizationalAiOperationEnum.SummarizeText)}>
          {t(`ai_button_menu.${IOrganizationalAiOperationEnum.SummarizeText}`)}
        </MenuItem>
      </Menu>
      {aiModalOpen &&
        createPortal(
          <AiSuggestionModal
            onClose={onClose}
            isLoading={isLoading}
            generatedText={generatedText}
            error={error}
            variant={variant}
          />,
          document.body,
        )}
    </>
  );
};
