import { Box, SvgIcon, Tooltip } from "@mui/material";
import { IDMACategoryState } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  ChecksIcon,
  ErrorCircleIcon,
  LoaderIcon,
  PointIcon,
} from "../../common/constants/tabler-icon.constants";

interface IDMACategoryStatusIndicatorProps {
  state: IDMACategoryState;
  fontSize?: "small" | "inherit" | "large" | "medium";
}
export const DMACategoryStatusIndicator: FC<IDMACategoryStatusIndicatorProps> = ({
  state,
  fontSize = "large",
}) => {
  const { t } = useTranslation("dma_common");

  const configuration = useMemo(() => {
    switch (state) {
      case IDMACategoryState.Open:
        return {
          color: "error.main",
          Icon: PointIcon,
        };
      case IDMACategoryState.WorkInProgress:
        return {
          color: "warning.main",
          Icon: LoaderIcon,
        };
      case IDMACategoryState.Shared:
        return {
          color: "primary.main",
          Icon: CheckIcon,
        };
      case IDMACategoryState.Verified:
        return {
          color: "primary.main",
          Icon: ChecksIcon,
        };
      default:
        return {
          color: "error.main",
          Icon: ErrorCircleIcon,
        };
    }
  }, [state]);

  return (
    <Tooltip title={t(`category_state_${state}`)}>
      <Box color={configuration.color} component="span" display="flex" alignItems="center">
        <SvgIcon fontSize={fontSize}>
          <configuration.Icon />
        </SvgIcon>
      </Box>
    </Tooltip>
  );
};
