import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IBaseEmissionFactorData } from "@netcero/netcero-core-api-client";
import {
  getGetEmissionFactorQueryKey,
  getGetEmissionFactorsQueryKey,
} from "./emission-factors.queries";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { IEmissionFactorIdentity, IOrganizationIdentity } from "@netcero/netcero-common";

interface ICreateEmissionFactorData extends IOrganizationIdentity {
  payload: IBaseEmissionFactorData;
}

interface IUpdateEmissionFactorData extends IEmissionFactorIdentity {
  payload: IBaseEmissionFactorData;
}

export const useCreateEmissionFactorMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, payload }: ICreateEmissionFactorData) => {
      return CoreApiService.EmissionFactorApi.createEmissionFactor(organizationId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getGetEmissionFactorsQueryKey(variables.organizationId),
      }),
  });
};

export const useUpdateEmissionFactorMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: async ({
      organizationId,
      emissionFactorId,
      payload,
    }: IUpdateEmissionFactorData) => {
      return CoreApiService.EmissionFactorApi.updateEmissionFactor(
        organizationId,
        emissionFactorId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables) =>
      invalidateQueries({
        awaitQueryKeys: [
          getGetEmissionFactorsQueryKey(variables.organizationId),
          getGetEmissionFactorQueryKey({
            organizationId: variables.organizationId,
            emissionFactorId: variables.emissionFactorId,
          }),
        ],
      }),
  });
};

export const useDeleteEmissionFactorMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();
  return useMutation({
    mutationFn: async ({ organizationId, emissionFactorId }: IEmissionFactorIdentity) => {
      return CoreApiService.EmissionFactorApi.deleteEmissionFactor(organizationId, emissionFactorId)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      invalidateQueries({
        awaitQueryKeys: [getGetEmissionFactorsQueryKey(variables.organizationId)],
        nonAwaitQueryKeys: [getGetEmissionFactorQueryKey(variables)],
      }),
  });
};
