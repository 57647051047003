import { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ILocalDataEntryObjectInputParameterValueData } from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  DataEntryObjectValuesUtilities,
  IValuesErrorsPerKey,
} from "../../utilities/data-entry-object-values.utilities";
import { useManageDataEntryObjectValue } from "../../hooks/manage-data-entry-object-value.hook";
import { useTranslatedValuesErrors } from "../../hooks/translate-values-errors.hooks";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DataEntryObjectValueInputFieldsDirectComponent } from "../../input-components/direct/data-entry-object-value-input-fields-direct.component";
import { ESRS_VALUE_INPUT_STYLES } from "./esrs-value-input.constants";
import { IDrValueEditProps } from "./dr-value-editor.component";
import { EsrsValueEditingUtilities } from "./esrs-value-editing.utilities";
import { CommentsButtonWithQuery } from "../../../comments/components/comments-button.component";
import { AuditLogsButton } from "../../../audit-logging/components/audit-logs-button.component";
import { useDeoCommentsAndAuditLogContext } from "../../../comments-and-audit-log/context/deo-comments-and-audit-log.context";

export const DrValueEditorDirect: FC<IDrValueEditProps> = ({
  type,
  deoInputParameter,
  organizationId,
  recordingPeriod,
  rootDataEntryObjectId,
  dataEntryObjectId,
  // NC-1945: Disable distribution criteria
  // availableDistributionCriteria,
  onCreate,
  onUpdate,
  onDelete,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_common");

  const {
    selectedInputParameterId,
    setSelectedInputParameterId,
    toggleCommentsAndAuditLogSideSection,
    openCommentsAndAuditLogSideSection,
  } = useDeoCommentsAndAuditLogContext();

  const handleManageValuesSave = useCallback(
    async (data: ILocalDataEntryObjectInputParameterValueData) => {
      if (
        Object.values(data.valuesPerKey).every((v) => v?.value === undefined || v?.value === null)
      ) {
        // Delete when all values are empty (and not previously defined)
        if (!deoInputParameter.recordedValues[0]) {
          return;
        }
        return onDelete(deoInputParameter.recordedValues[0].id);
      } else if (deoInputParameter.recordedValues.length === 0) {
        // Create when new
        return onCreate(data);
      } else {
        // Update when already existing
        return onUpdate(deoInputParameter.recordedValues[0].id, data);
      }
    },
    [deoInputParameter.recordedValues, onCreate, onDelete, onUpdate],
  );

  const manageValuesData = useMemo(
    () => ({
      recordedValue:
        deoInputParameter.recordedValues[0] ??
        DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(
          deoInputParameter.inputParameter,
        ),
      inputParameter: deoInputParameter.inputParameter,
      recordingPeriod,
      // NC-1945: Disable distribution criteria
      // availableDistributionCriteria,
      subscribeToChanges: false,
      onSave: handleManageValuesSave,
    }),
    [
      deoInputParameter.recordedValues,
      deoInputParameter.inputParameter,
      recordingPeriod,
      // NC-1945: Disable distribution criteria
      // availableDistributionCriteria,
      handleManageValuesSave,
    ],
  );

  // Use Value Input Handling
  const { errors, handlers, value, hasChanges, isDirty } =
    useManageDataEntryObjectValue(manageValuesData);

  const filteredValuesErrors = useMemo(() => {
    const newErrors: IValuesErrorsPerKey = {};

    Object.entries(errors.errors).forEach(([key, error]) => {
      // Allow deletion of dr entry when no note is present (no errors)
      if (!value.note) {
        // Remove Error if it is "required"
        if (error === "required") {
          return;
        }
      }
      newErrors[key] = error;
    });

    return newErrors;
  }, [errors.errors, value.note]);
  const hasError = useMemo(
    () => Object.keys(filteredValuesErrors).length > 0,
    [filteredValuesErrors],
  );
  const translatedErrors = useTranslatedValuesErrors(filteredValuesErrors);

  const handleClickSaveButton = useCallback(async () => {
    // When filtered Errors empty but errors has value --> Delete Value
    if (!hasError && errors.hasError) {
      return onDelete(deoInputParameter.recordedValues[0].id);
    } else {
      return handlers.handleSave();
    }
  }, [hasError, errors.hasError, onDelete, deoInputParameter.recordedValues, handlers]);

  const showActions = useMemo(() => {
    return (
      EsrsValueEditingUtilities.doesInputParameterStoreValue(deoInputParameter.inputParameter) &&
      !EsrsValueEditingUtilities.isInputParameterDirectSave(deoInputParameter.inputParameter)
    );
  }, [deoInputParameter.inputParameter]);

  // Auto save auto save inputs
  useEffect(() => {
    if (
      EsrsValueEditingUtilities.isInputParameterDirectSave(deoInputParameter.inputParameter) &&
      isDirty
    ) {
      handlers.handleSave();
    }
    // This is fine since changes in handler should not trigger this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deoInputParameter.inputParameter, isDirty]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Value Input */}
      <DataEntryObjectValueInputFieldsDirectComponent
        variant={type === "disclosure-requirement" ? "esrs-dr" : "esrs-ip"}
        inputParameter={deoInputParameter.inputParameter}
        currentValue={value}
        onInputValueChanged={handlers.handleInputChanged}
        disabled={disabled}
        errors={hasChanges ? translatedErrors : {}}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriod.id}
        rootDataEntryObjectId={rootDataEntryObjectId}
        dataEntryObjectId={dataEntryObjectId}
      />
      {/* Comment Input */}
      {value.note !== undefined && (
        <Box>
          <Typography variant="body2" fontWeight="bold">
            {t("comment_label")}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={value.note}
            onChange={(evt) => handlers.handleNoteChanged(evt.currentTarget.value)}
            fullWidth
            multiline
            minRows={1}
            maxRows={5}
            InputProps={{
              sx: ESRS_VALUE_INPUT_STYLES,
            }}
            disabled={disabled}
          />
        </Box>
      )}
      {/* Actions Row */}
      {showActions && (
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex" gap={1}>
            {/* Comments */}
            <CommentsButtonWithQuery
              organizationId={organizationId}
              relations={{
                entity: "data-entry-object-input-parameter",
                dataEntryObjectId: dataEntryObjectId,
                inputParameterId: deoInputParameter.inputParameter.id ?? "",
              }}
              onClick={() => {
                setSelectedInputParameterId(deoInputParameter.inputParameter.id);
                if (selectedInputParameterId === deoInputParameter.inputParameter.id) {
                  toggleCommentsAndAuditLogSideSection();
                } else {
                  openCommentsAndAuditLogSideSection();
                }
              }}
            />
            {/* Audit Logs */}
            <AuditLogsButton organizationId={organizationId} active={false} />
          </Box>
          {/* Spacer */}
          <Box flex={1} />
          {/* Save / Cancel Buttons */}
          <Button color="inherit" onClick={handlers.handleReset} disabled={disabled || !hasChanges}>
            {t("cancel", { ns: "buttons" })}
          </Button>
          <Button
            variant="contained"
            onClick={handleClickSaveButton}
            disabled={disabled || hasError || !hasChanges}
          >
            {t("save", { ns: "buttons" })}
          </Button>
        </Box>
      )}
    </Box>
  );
};
