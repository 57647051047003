import { IGhgCategoriesPerScope } from "../../ghg-categories.public-types";
import { IGhgProtocolScope } from "../../../emission-factors";
import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";

export const GHG_VERSION_V1: IGhgCategoriesPerScope = {
  [IGhgProtocolScope.Scope1]: [
    IGhgCategoriesIdentifier._11StationaryCombustion,
    IGhgCategoriesIdentifier._12MobileCombustion,
    IGhgCategoriesIdentifier._13FugitiveEmissions,
    IGhgCategoriesIdentifier._14OtherEmissions,
  ],
  [IGhgProtocolScope.Scope2]: [
    IGhgCategoriesIdentifier._21PurchasedElectricity,
    IGhgCategoriesIdentifier._22PurchasedHeat,
    IGhgCategoriesIdentifier._23PurchasedSteam,
    IGhgCategoriesIdentifier._24PurchasedCooling,
  ],
  [IGhgProtocolScope.Scope3]: [
    IGhgCategoriesIdentifier._31PurchasedGoodsAndServices,
    IGhgCategoriesIdentifier._32CapitalGoods,
    IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2,
    IGhgCategoriesIdentifier._34UpstreamTransportationAndDistribution,
    IGhgCategoriesIdentifier._35Waste,
    IGhgCategoriesIdentifier._36BusinessTravel,
    IGhgCategoriesIdentifier._37EmployeeCommuting,
    IGhgCategoriesIdentifier._38LeasedOrRentedAssets,
    IGhgCategoriesIdentifier._39DownstreamTransportationAndDistribution,
    IGhgCategoriesIdentifier._310ProcessingOfSoldProducts,
    IGhgCategoriesIdentifier._311UseOfSoldProducts,
    IGhgCategoriesIdentifier._312EndOfLifeTreatmentOfSoldProducts,
    IGhgCategoriesIdentifier._313LeasedOrRentedAssetsDownstream,
    IGhgCategoriesIdentifier._314Franchise,
    IGhgCategoriesIdentifier._315Investments,
  ],
};
