import {
  Box,
  Button,
  FormLabel,
  InputAdornment,
  LinearProgress,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMAConfiguration,
  IDMAConfigurationData,
  IDMAConfigurationPotentialExtendSteps,
  IDMAConfigurationProbabilityOfOccurrenceSteps,
} from "@netcero/netcero-core-api-client";
import { DMA_MATERIALITY_THRESHOLD_MAX, DMA_MATERIALITY_THRESHOLD_MIN } from "@netcero/netcero-dma";
import { FC, useEffect, useMemo } from "react";
import { Control, Controller, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MaskedInput } from "../common/components/masked-input.component";
import { DMA_SLIDER_VALUES_NAMES, ORDERED_DMA_SLIDER_VALUES } from "./common/dma.constants";
import { useSetDMAConfigurationMutation } from "./mutations/dma.mutations";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { DMAProbabilityOfOccurrenceComponent } from "./common/dma-probability-of-occurrence.component";
import { ExportButtonWithDialog } from "../export/export-button-with-dialog.component";
import { requiresOrganizationRole } from "../authentication/higher-order-components/requires-organization-role.hoc";
import { ROLE_ACCESS } from "@netcero/netcero-common";

export type StepIndex = 1 | 2 | 3 | 4 | 5;

interface IInternalDMAConfigurationPotentialExtendSteps {
  potentialExtentStep1: number | null;
  potentialExtentStep2: number | null;
  potentialExtentStep3: number | null;
  potentialExtentStep4: number | null;
  potentialExtentStep5: number | null;
}

interface IInternalDMAConfigurationProbabilityOfOccurrenceSteps {
  probabilityOfOccurrenceStep1: number | null;
  probabilityOfOccurrenceStep2: number | null;
  probabilityOfOccurrenceStep3: number | null;
  probabilityOfOccurrenceStep4: number | null;
  probabilityOfOccurrenceStep5: number | null;
}

export interface IInternalDMAConfigurationData
  extends Omit<
    IDMAConfigurationData,
    | "potentialExtentSteps"
    | "probabilityOfOccurrenceStepsFinancial"
    | "probabilityOfOccurrenceStepsMaterial"
  > {
  definePotentialExtentSteps: boolean;
  potentialExtentSteps: IInternalDMAConfigurationPotentialExtendSteps;
  defineProbabilityOfOccurrenceStepsFinancial: boolean;
  probabilityOfOccurrenceStepsFinancial: IInternalDMAConfigurationProbabilityOfOccurrenceSteps;
  defineProbabilityOfOccurrenceStepsMaterial: boolean;
  probabilityOfOccurrenceStepsMaterial: IInternalDMAConfigurationProbabilityOfOccurrenceSteps;
}

function getDefaultFormValues(data: IDMAConfiguration): IInternalDMAConfigurationData {
  return {
    thresholdImpact: data.thresholdImpact,
    thresholdEffect: data.thresholdEffect,
    definePotentialExtentSteps: !!data.potentialExtentSteps,
    potentialExtentSteps: data.potentialExtentSteps ?? {
      potentialExtentStep1: null,
      potentialExtentStep2: null,
      potentialExtentStep3: null,
      potentialExtentStep4: null,
      potentialExtentStep5: null,
    },
    defineProbabilityOfOccurrenceStepsFinancial: !!data.probabilityOfOccurrenceStepsFinancial,
    probabilityOfOccurrenceStepsFinancial: data.probabilityOfOccurrenceStepsFinancial ?? {
      probabilityOfOccurrenceStep1: null,
      probabilityOfOccurrenceStep2: null,
      probabilityOfOccurrenceStep3: null,
      probabilityOfOccurrenceStep4: null,
      probabilityOfOccurrenceStep5: null,
    },
    defineProbabilityOfOccurrenceStepsMaterial: !!data.probabilityOfOccurrenceStepsMaterial,
    probabilityOfOccurrenceStepsMaterial: data.probabilityOfOccurrenceStepsMaterial ?? {
      probabilityOfOccurrenceStep1: null,
      probabilityOfOccurrenceStep2: null,
      probabilityOfOccurrenceStep3: null,
      probabilityOfOccurrenceStep4: null,
      probabilityOfOccurrenceStep5: null,
    },
  };
}

interface IDMAConfigurationProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  dmaConfiguration: IDMAConfiguration;
}

const useIsDirty = (
  fields: UseFormReturn<IInternalDMAConfigurationData>["formState"]["dirtyFields"],
  initialDefineProbabilityOfOccurrenceStepsFinancial: boolean,
  initialDefineProbabilityOfOccurrenceStepsMaterial: boolean,
  initialDefinePotentialExtentSteps: boolean,
) => {
  return useMemo(() => {
    const modified = { ...fields };
    // don't consider the field if define is not set
    // However, if the assessment was initially defined, it should be considered as changed

    if (!fields.definePotentialExtentSteps && !initialDefinePotentialExtentSteps) {
      delete modified.potentialExtentSteps;
    }

    if (
      !fields.defineProbabilityOfOccurrenceStepsMaterial &&
      !initialDefineProbabilityOfOccurrenceStepsMaterial
    ) {
      delete modified.probabilityOfOccurrenceStepsMaterial;
    }

    if (
      !fields.defineProbabilityOfOccurrenceStepsFinancial &&
      !initialDefineProbabilityOfOccurrenceStepsFinancial
    ) {
      delete modified.probabilityOfOccurrenceStepsFinancial;
    }

    return Object.values(modified).length > 0;
    // "fields" is a reference, therefore changes to it are not picked up by the memo
    // solution: observe changes to each field separately
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialDefineProbabilityOfOccurrenceStepsFinancial,
    initialDefineProbabilityOfOccurrenceStepsMaterial,
    initialDefinePotentialExtentSteps,
    fields.defineProbabilityOfOccurrenceStepsFinancial,
    fields.defineProbabilityOfOccurrenceStepsMaterial,
    fields.definePotentialExtentSteps,
    fields.probabilityOfOccurrenceStepsFinancial,
    fields.probabilityOfOccurrenceStepsMaterial,
    fields.potentialExtentSteps,
    fields.thresholdEffect,
    fields.thresholdImpact,
  ]);
};

export const DMAConfiguration: FC<IDMAConfigurationProps> = requiresOrganizationRole(
  ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION,
  ({ organizationId, recordingPeriodId, dataEntryObjectId, dmaConfiguration }) => {
    const { t } = useTranslation("dma_configuration");

    const updateConfigurationMutation = useSetDMAConfigurationMutation();

    const {
      control,
      handleSubmit,
      reset,
      watch,
      formState: { dirtyFields, defaultValues },
    } = useForm<IInternalDMAConfigurationData>({
      defaultValues: getDefaultFormValues(dmaConfiguration),
    });

    useEffect(() => {
      reset(getDefaultFormValues(dmaConfiguration));
    }, [dmaConfiguration, reset]);

    const isDirty = useIsDirty(
      dirtyFields,
      defaultValues!.defineProbabilityOfOccurrenceStepsFinancial!,
      defaultValues!.defineProbabilityOfOccurrenceStepsMaterial!,
      defaultValues!.definePotentialExtentSteps!,
    );

    const handleUpdateConfiguration = async (data: IInternalDMAConfigurationData) => {
      await updateConfigurationMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        payload: {
          thresholdEffect: +data.thresholdEffect,
          thresholdImpact: +data.thresholdImpact,
          potentialExtentSteps: data.definePotentialExtentSteps
            ? // validation will ensure that no fields are null
              (data.potentialExtentSteps as IDMAConfigurationPotentialExtendSteps)
            : undefined,
          probabilityOfOccurrenceStepsMaterial: data.defineProbabilityOfOccurrenceStepsMaterial
            ? // validation will ensure that no fields are null
              (data.probabilityOfOccurrenceStepsMaterial as IDMAConfigurationProbabilityOfOccurrenceSteps)
            : undefined,
          probabilityOfOccurrenceStepsFinancial: data.defineProbabilityOfOccurrenceStepsFinancial
            ? // validation will ensure that no fields are null
              (data.probabilityOfOccurrenceStepsFinancial as IDMAConfigurationProbabilityOfOccurrenceSteps)
            : undefined,
        },
      });
    };

    const definePotentialExtentSteps = watch("definePotentialExtentSteps");

    const disabled = updateConfigurationMutation.isPending;

    return (
      <Box display="flex" flexDirection="column" gap={2} alignItems="start">
        {updateConfigurationMutation.isPending && <LinearProgress />}
        {updateConfigurationMutation.isError && (
          <ErrorTextComponent error={updateConfigurationMutation.error!} />
        )}

        {/* Language */}
        <Typography variant="h6" component="span">
          {t(`title_input_language`)}
        </Typography>

        <TextField
          label={t(`title_input_language`)}
          value={t(`translationsPerLanguage.${dmaConfiguration.language}`, {
            ns: "language",
          })}
          sx={{ width: 260 }}
          disabled={true}
        />

        {/* Export */}
        <Typography variant="h6" component="span">
          {t("title_export")}
        </Typography>

        <Box mb={2}>
          <ExportButtonWithDialog
            organizationId={organizationId}
            recordingPeriodId={recordingPeriodId}
            dataEntryObjectId={dataEntryObjectId}
          />
        </Box>

        {/* Materiality Threshold */}
        <Typography variant="h6" component="span">
          {t("title_threshold")}
        </Typography>
        <Typography>{t("description_threshold")}</Typography>
        <Box display="flex" flexWrap="wrap" gap={2}>
          <MaterialityThresholdInput type="impact" control={control} disabled={disabled} />
          <MaterialityThresholdInput type="effect" control={control} disabled={disabled} />
        </Box>

        {/* Financial Effect Potential Extent Steps */}
        <Typography variant="h6" component="span" mt={2}>
          {t("title_potential_extent_steps")}
        </Typography>
        <Typography>{t("description_potential_extent_steps")}</Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <FormLabel>{t("label_set_potential_extent_steps")}</FormLabel>
          <Controller
            control={control}
            name="definePotentialExtentSteps"
            render={({ field }) => (
              <Switch
                checked={field.value}
                onChange={(_, checked) => field.onChange(checked)}
                disabled={disabled}
              />
            )}
          />
        </Box>

        {definePotentialExtentSteps &&
          [1, 2, 3, 4, 5].map((stepIndex) => (
            <PotentialExtentStepInput
              key={stepIndex}
              control={control}
              stepIndex={stepIndex as StepIndex}
              disabled={disabled}
            />
          ))}

        {/* Probability of Occurrence Steps Material*/}
        <DMAProbabilityOfOccurrenceComponent
          type="material"
          control={control}
          disabled={disabled}
        />

        {/* Probability of Occurrence Steps Financial */}
        <DMAProbabilityOfOccurrenceComponent
          type="financial"
          control={control}
          disabled={disabled}
        />

        {/* Save Button */}
        <Box mt={2}>
          <Tooltip title={isDirty ? "" : t("no_changes", { ns: "common" })} placement="right">
            <span>
              <Button
                variant="contained"
                onClick={handleSubmit(handleUpdateConfiguration)}
                disabled={!isDirty || disabled}
              >
                {t("save", { ns: "buttons" })}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    );
  },
);

// ### Reused Components ###

interface IPotentialExtentStepInputProps {
  control: Control<IInternalDMAConfigurationData>;
  stepIndex: StepIndex;
  disabled?: boolean;
}

const PotentialExtentStepInput: FC<IPotentialExtentStepInputProps> = ({
  control,
  stepIndex,
  disabled,
}) => {
  const { t } = useTranslation("dma_configuration");

  const correspondingSliderValue = useMemo(
    () => DMA_SLIDER_VALUES_NAMES[ORDERED_DMA_SLIDER_VALUES[stepIndex - 1]],
    [stepIndex],
  );

  return (
    <Controller
      control={control}
      name={`potentialExtentSteps.potentialExtentStep${stepIndex}`}
      rules={{
        required: t("error_potentialExtentStep_required"),
        min: {
          value: 0,
          message: t("error_potentialExtentStep_min"),
        },
        validate: (value, formData) => {
          if (value === null) {
            return t("error_potentialExtentStep_required");
          }

          if (stepIndex > 1) {
            const previousStepIndex = (stepIndex - 1) as 1 | 2 | 3 | 4;
            const previousStepValue =
              formData.potentialExtentSteps[`potentialExtentStep${previousStepIndex}`];
            if (value <= (previousStepValue ?? Number.MIN_SAFE_INTEGER)) {
              return t("error_potentialExtentStep_not_ascending");
            }
          }

          return true;
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={t(`slider_value_${correspondingSliderValue}`, {
            ns: "dma_impact_or_effect_edit_dialog_common",
          })}
          {...field}
          value={field.value?.toString() ?? ""}
          onChange={(value: unknown) => {
            const typedValue = value as string;
            if (typedValue !== "") {
              field.onChange(+typedValue);
            } else {
              field.onChange(null);
            }
          }}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: <InputAdornment position="end">&euro;</InputAdornment>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: MaskedInput as any,
            inputProps: {
              mask: Number,
              scale: 0,
              thousandsSeparator: ".",
              min: 0,
              unmask: true,
              eager: true,
              style: {
                textAlign: "right",
              },
            },
          }}
          sx={{ width: 260 }}
          disabled={disabled}
        />
      )}
    />
  );
};

interface IMaterialityThresholdInputProps {
  type: "impact" | "effect";
  control: Control<IInternalDMAConfigurationData, unknown>;
  disabled?: boolean;
}

const MaterialityThresholdInput: FC<IMaterialityThresholdInputProps> = ({
  type,
  control,
  disabled,
}) => {
  const { t } = useTranslation("dma_configuration");

  return (
    <Controller
      control={control}
      name={type === "impact" ? "thresholdImpact" : "thresholdEffect"}
      rules={{
        required: t("error_threshold_required"),
        min: {
          value: DMA_MATERIALITY_THRESHOLD_MIN,
          message: t("error_threshold_min", { min: DMA_MATERIALITY_THRESHOLD_MIN }),
        },
        max: {
          value: DMA_MATERIALITY_THRESHOLD_MAX,
          message: t("error_threshold_max", { max: DMA_MATERIALITY_THRESHOLD_MAX }),
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type="number"
          label={t(`label_threshold_${type}`)}
          inputProps={{
            min: DMA_MATERIALITY_THRESHOLD_MIN,
            max: DMA_MATERIALITY_THRESHOLD_MAX,
            step: 0.1,
          }}
          {...field}
          onChange={(evt) =>
            field.onChange(evt.target.value !== "" ? +(+evt.target.value).toFixed(1) : "")
          }
          error={!!error}
          helperText={error?.message}
          sx={{ width: 260 }}
          disabled={disabled}
        />
      )}
    />
  );
};
