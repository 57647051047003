import React, { FC, useEffect, useState } from "react";
import { Box, Button, ClickAwayListener, Paper, Popper, Typography } from "@mui/material";
import { $getSelection, $isRangeSelection } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { AiIcon } from "../../common/constants/custom-icons.constants";
import { CenteredCircularProgress } from "../../common/components/centered-circular-progress.component";
import { IApiError } from "@netcero/ai-common";
import { useTranslation } from "react-i18next";
import { useTranslateAiErrorMessage } from "../../ai/hooks/translate-ai-error.hook";
import { useSizeContext } from "../size.context";
import { ILexicalEditorVariant } from "../lexical.types";

interface IAiModalProps {
  onClose: (accept: boolean | null) => void;
  // Is used to disable the buttons while the AI is generating the text (it is NOT used for the loading spinner)
  isLoading?: boolean;
  generatedText: string | null;
  error: IApiError | null;
  // Variant is used to determine the position of the modal
  variant?: ILexicalEditorVariant;
}

export const AiSuggestionModal: FC<IAiModalProps> = ({
  onClose,
  isLoading,
  generatedText,
  error,
  variant,
}) => {
  const { t } = useTranslation("lexical_ai");

  const [editor] = useLexicalComposerContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const errorMessage = useTranslateAiErrorMessage(error);

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchorNodeKey = selection.anchor.getNode().getKey();
        const anchorNode = editor.getElementByKey(anchorNodeKey);
        setAnchorEl(anchorNode || null);
      }
    });

    return () => {
      setAnchorEl(null);
    };
  }, [editor]);

  const { size } = useSizeContext();

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement={variant === "comment" ? "top-start" : "bottom-start"}
      sx={{ zIndex: 1310 }}
    >
      <ClickAwayListener onClickAway={() => onClose(null)}>
        <Paper
          elevation={0}
          variant="elevation"
          sx={{
            padding: 2,
            maxWidth: size.width,
            boxShadow: "0px 6px 15px 6px rgba(0, 0, 0, 0.10)",
          }}
        >
          <Box display="flex" flexDirection="row" gap={1} sx={{ alignItems: "center" }}>
            <AiIcon />
            <Typography variant="subtitle1">{t("ai_suggestion_modal.title")}</Typography>
          </Box>
          <Box
            sx={{
              padding: 2,
              marginY: 1,
              maxHeight: "40vh",
              overflow: "auto",
            }}
          >
            {errorMessage ? (
              <Typography color="error">{errorMessage}</Typography>
            ) : generatedText ? (
              <Typography style={{ whiteSpace: "pre-wrap" }}>{generatedText}</Typography>
            ) : (
              <CenteredCircularProgress minHeight={100} />
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="text"
              color="secondary"
              onClick={() => onClose(null)}
              disabled={isLoading}
            >
              {t("button_discard")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClose(!!generatedText || null)}
              disabled={isLoading}
            >
              {t("button_accept")}
            </Button>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
