import { IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { Chip, Tooltip } from "@mui/material";
import { GHG_CATEGORIES_PATHS } from "../emission-factors.constants";
import { useRenderGhgCategoryName } from "../emission-factors.hooks";

interface IGhgCategoryChipProps {
  ghgCategory: IGhgCategoriesIdentifier;
}

export const GhgCategoryChip: FC<IGhgCategoryChipProps> = ({ ghgCategory }) => {
  const ghgCategoryNumber = GHG_CATEGORIES_PATHS[ghgCategory];
  const renderGhgCategoryName = useRenderGhgCategoryName();
  return (
    <Tooltip title={renderGhgCategoryName(ghgCategory)}>
      <Chip size="small" label={ghgCategoryNumber} />
    </Tooltip>
  );
};

interface IGhgCategoriesChipsProps {
  ghgCategories: IGhgCategoriesIdentifier[];
}

export const GhgCategoriesChips: FC<IGhgCategoriesChipsProps> = ({ ghgCategories }) => {
  return ghgCategories.map((ghgCategory, index) => (
    <GhgCategoryChip key={index} ghgCategory={ghgCategory} />
  ));
};
