import { CalculationErrorCodes } from "@netcero/netcero-calculation";
import {
  CountryCodeAlpha2Enum,
  generateDMACategoryTranslations,
  generateESRSTopicTranslations,
  MdrUnitGroup,
  ScopeKeys,
} from "@netcero/netcero-common";
import {
  IAuditLogEvent,
  IContentLanguage,
  IDataEntryObjectInputParameterExclusionReason,
  IDataEntryObjectInputParameterGapAnalysisAvailability,
  IDataEntryObjectInputParameterGapAnalysisDataCollection,
  IDataEntryObjectInputParameterStatus,
  IDEOIPValueDataQuality,
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum,
  IMarketBasedEmissionFactorType,
  ITargetPathGoalTemplateType,
  ITargetPathState,
} from "@netcero/netcero-core-api-client";
import countryTranslations from "i18n-iso-countries";
import germanCountryTranslation from "i18n-iso-countries/langs/de.json";
import {
  BasicSnackbarApiActionType,
  SnackbarSuccessMassageType,
} from "../modules/app-snackbar/app-snackbar.interfaces";
import { IROType } from "../modules/policies/policies.types";
import { ESRSInputParameterType } from "../modules/data-entry-object-values/esrs/esrs-input-parameter-type-indicator.component";
import { EligiblePhaseInDrsEfragIds } from "../modules/input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { LexicalBlockType } from "../modules/lexical/lexical.types";
import { IRenderEmissionFactorUnitType } from "../modules/emission-factors/emission-factors.hooks";

countryTranslations.registerLocale(germanCountryTranslation);
const CountryNamesByCode = Object.values(CountryCodeAlpha2Enum).reduce((res, code) => {
  const name = countryTranslations.getName(code, "de");
  if (name) {
    res[code] = name;
  }
  return res;
}, {} as Record<CountryCodeAlpha2Enum, string>);

const actionsTranslations: Record<SnackbarSuccessMassageType, string> = {
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_VALUE]:
    "Wert für Offenlegungspflicht aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS]:
    "Mitarbeitende Personen aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER]:
    "Verantwortliche Person aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE]:
    "Wert innerhalb Offenlegungspflicht aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS]:
    "Mitarbeitende Personen aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER]:
    "Verantwortliche Person aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_PHASE_IN_DECISION]: "Phase-In aktualisiert",
  [BasicSnackbarApiActionType.RESET_DISCLOSURE_REQUIREMENT]:
    "Status von Offenlegungspflicht zurückgesetzt",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT]:
    "Offenlegungspflicht für Überprüfung eingereicht",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT]: "Offenlegungspflicht genehmigt",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT]: "Offenlegungspflicht abgelehnt",
  [BasicSnackbarApiActionType.RESET_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Status von Wert innerhalb Offenlegungspflicht zurückgesetzt",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht für Überprüfung eingereicht",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht genehmigt",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht abgelehnt",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT]: "Offenlegungspflicht ausgeschlossen",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT]:
    "Offenlegungspflicht wieder hinzugefügt",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht ausgeschlossen",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Wert innerhalb Offenlegungspflicht wieder hinzugefügt",
  [BasicSnackbarApiActionType.EXPORT_DMA_IROS]: "Auswirkungen, Risiken und Chancen exportiert",
  [BasicSnackbarApiActionType.EXPORT_DMA_STAKEHOLDER_FEEDBACK]: "Stakeholder-Feedback exportiert",
  [BasicSnackbarApiActionType.EXPORT_USERS]: "Nutzer exportiert",
  // DR Assessment
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_ASSESSMENT]:
    "Bewertung für Offenlegungspflicht aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_DATA_COLLECTION]:
    "Datenerfassung aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_AVAILABILITY]:
    "Verfügbarkeit aktualisiert",
  // Sources
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_SOURCES]:
    "Quellen für Offenlegungspflicht aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_SOURCES]:
    "Quellen für Wert innerhalb Offenlegungspflicht aktualisiert",
  // IRO Move
  [BasicSnackbarApiActionType.MOVE_FINANCIAL_EFFECT_TO_OTHER_TOPIC]:
    "Chance / Risiko in anderes Thema verschoben",
  [BasicSnackbarApiActionType.MOVE_MATERIAL_IMPACT_TO_OTHER_TOPIC]:
    "Auswirkung in anderes Thema verschoben",
  // IRO Duplicate
  [BasicSnackbarApiActionType.DUPLICATE_MATERIAL_IMPACT]: "Auswirkung dupliziert",
  [BasicSnackbarApiActionType.DUPLICATE_FINANCIAL_EFFECT]: "Chance / Risiko dupliziert",
  // Init all ESRS Topics
  [BasicSnackbarApiActionType.INIT_ALL_ESRS_TOPICS]: "Alle ESRS-Themen angelegt",
  // Org Roles
  [BasicSnackbarApiActionType.UPDATE_ORG_ROLE]: "Rolle aktualisiert",
  [BasicSnackbarApiActionType.UPDATE_USER_DATA_ENTRY_OBJECTS_ACCESS]:
    "Zugriff auf Erfassungsobjekte aktualisiert",
  // Verification of DMA Categories
  [BasicSnackbarApiActionType.VERIFY_DMA_CATEGORY]: "Thema verifiziert",
  [BasicSnackbarApiActionType.UNVERIFY_DMA_CATEGORY]: "Verifizierung von Thema aufgehoben",
};

const inputParameterTypesTranslations: Record<ESRSInputParameterType, string> = {
  text: "Text",
  "text-area": "Textfeld",
  currency: "Währung",
  number: "Zahl",
  boolean: "Ja/Nein",
  date: "Datum",
  "date-range": "Zeitraum",
  options: "Auswahl",
  "nested-options": "Auswahl",
  table: "Tabelle",
  complex: "Mehrere Werte",
  policy: "Konzepte",
  "phase-in": "Phase-In",
  year: "Jahr",
  "value-with-unit": "Zahl mit Einheit",
  action: "Actions",
  target: "Ziele",
  "referenced-target": "Verlinkte Ziele",
};

const DRs_PHASE_IN_EXPLANATIONS: Record<EligiblePhaseInDrsEfragIds, string> = {
  [EligiblePhaseInDrsEfragIds.SBM_1]:
    "Das Unternehmen muss ESRS 2 SBM-1 ab dem Datum angeben, das im delegierten Rechtsakt der Kommission gemäß Richtlinie 2013/34/EU, Artikel 29b(1), dritter Unterabsatz, Punkt (ii), festgelegt ist.",
  [EligiblePhaseInDrsEfragIds.SBM_3]:
    "Das Unternehmen kann ESRS 2 SBM-3, Absatz 48(e), im ersten Jahr auslassen und für die ersten drei Jahre nur qualitative Angaben machen, wenn quantitative Angaben nicht praktikabel sind",
  [EligiblePhaseInDrsEfragIds.E1_6]:
    "Unternehmen mit weniger als 750 Beschäftigten können im ersten Jahr ihrer Nachhaltigkeitserklärung die Daten zu Scope-3- und den gesamten Treibhausgasemissionen weglassen.",
  [EligiblePhaseInDrsEfragIds.E1_9]:
    "Das Unternehmen kann ESRS E1-9 im ersten Jahr weglassen und für die ersten drei Jahre nur qualitative Angaben machen, wenn quantitative Angaben nicht praktikabel sind.",
  [EligiblePhaseInDrsEfragIds.E2_6]:
    "Das Unternehmen kann ESRS E2-6 im ersten Jahr weglassen. Mit Ausnahme von Absatz 40(b) kann es für die ersten drei Jahre nur qualitative Angaben machen, wenn quantitative Angaben nicht praktikabel sind.",
  [EligiblePhaseInDrsEfragIds.E3_5]:
    "Das Unternehmen kann ESRS E3-5 im ersten Jahr weglassen und für die ersten drei Jahre nur qualitative Angaben machen.",
  [EligiblePhaseInDrsEfragIds.E4_6]:
    "Das Unternehmen kann ESRS E4-6 im ersten Jahr weglassen und für die ersten drei Jahre nur qualitative Angaben machen.",
  [EligiblePhaseInDrsEfragIds.E5_6]:
    "Das Unternehmen kann ESRS E5-6 im ersten Jahr weglassen und für die ersten drei Jahre nur qualitative Angaben machen.",
  [EligiblePhaseInDrsEfragIds.S1_7]:
    "Das Unternehmen kann im ersten Jahr alle Datenpunkte dieser Offenlegungspflicht weglassen.",
  [EligiblePhaseInDrsEfragIds.S1_8]:
    "Das Unternehmen kann diese Offenlegungspflicht im ersten Jahr für seine Mitarbeiter in Nicht-EWR-Ländern weglassen.",
  [EligiblePhaseInDrsEfragIds.S1_11]:
    "Das Unternehmen kann im ersten Jahr der Erstellung seiner Nachhaltigkeitserklärung die im ESRS S1-11 vorgeschriebenen Angaben auslassen.",
  [EligiblePhaseInDrsEfragIds.S1_12]:
    "Das Unternehmen kann im ersten Jahr der Erstellung seiner Nachhaltigkeitserklärung die im ESRS S1-12 vorgeschriebenen Angaben auslassen.",
  [EligiblePhaseInDrsEfragIds.S1_13]:
    "Das Unternehmen kann im ersten Jahr der Erstellung seiner Nachhaltigkeitserklärung die im ESRS S1-13 vorgeschriebenen Angaben auslassen.",
  [EligiblePhaseInDrsEfragIds.S1_14]:
    "Das Unternehmen kann im ersten Jahr die Daten zu arbeitsbedingten Erkrankungen, Verletzungen, Unfällen, Todesfällen und Ausfalltagen weglassen.",
  [EligiblePhaseInDrsEfragIds.S1_15]:
    "Das Unternehmen kann im ersten Jahr der Erstellung seiner Nachhaltigkeitserklärung die im ESRS S1-15 vorgeschriebenen Angaben auslassen.",
};

const staticUnitTranslations: Record<
  IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum,
  string
> = {
  [IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum.KgPerYear]: "kg/Jahr",
  [IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum.Hours]: "Stunden",
};

const EMISSION_FACTOR_UNITS: Record<
  IRenderEmissionFactorUnitType,
  Record<IEmissionFactorUnit, string>
> = {
  short: {
    [IEmissionFactorUnit.FZkm]: "FZkm",
    [IEmissionFactorUnit.Pkm]: "Pkm",
    [IEmissionFactorUnit.Tkm]: "tkm",
    [IEmissionFactorUnit.T]: "t",
    [IEmissionFactorUnit.L]: "l",
    [IEmissionFactorUnit.M2]: "m²",
    [IEmissionFactorUnit.KWh]: "kWh",
    [IEmissionFactorUnit.MioEuros]: "Mio. €",
  },
  long: {
    [IEmissionFactorUnit.FZkm]: "Fahrzeugkilometer",
    [IEmissionFactorUnit.Pkm]: "Personenkilometer",
    [IEmissionFactorUnit.Tkm]: "Tonnenkilometer",
    [IEmissionFactorUnit.T]: "Tonne",
    [IEmissionFactorUnit.L]: "Liter",
    [IEmissionFactorUnit.M2]: "Quadratmeter",
    [IEmissionFactorUnit.KWh]: "Kilowattstunde",
    [IEmissionFactorUnit.MioEuros]: "Millionen Euro",
  },
};

const GHG_CATEGORIES: Record<IGhgCategoriesIdentifier, string> = {
  [IGhgCategoriesIdentifier._11StationaryCombustion]: "Stationäre Verbrennung",
  [IGhgCategoriesIdentifier._12MobileCombustion]: "Mobile Verbrennung",
  [IGhgCategoriesIdentifier._13FugitiveEmissions]: "Flüchtige Emissionen",
  [IGhgCategoriesIdentifier._14OtherEmissions]: "Sonstige Emissionen",
  [IGhgCategoriesIdentifier._21PurchasedElectricity]: "Eingekaufter Strom",
  [IGhgCategoriesIdentifier._22PurchasedHeat]: "Eingekaufte Wärme",
  [IGhgCategoriesIdentifier._23PurchasedSteam]: "Eingekaufter Dampf",
  [IGhgCategoriesIdentifier._24PurchasedCooling]: "Eingekaufte Kälte",
  [IGhgCategoriesIdentifier._31PurchasedGoodsAndServices]: "Eingekaufte Waren und Dienstleistungen",
  [IGhgCategoriesIdentifier._32CapitalGoods]: "Kapitalgüter",
  [IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2]:
    "Brennstoff- und energiebezogene Aktivitäten (nicht in Scope 1/2 enthalten)",
  [IGhgCategoriesIdentifier._34UpstreamTransportationAndDistribution]:
    "Vorgelagerter Transport und Distribution",
  [IGhgCategoriesIdentifier._35Waste]: "Abfall aus Betrieb",
  [IGhgCategoriesIdentifier._36BusinessTravel]: "Geschäftsreisen",
  [IGhgCategoriesIdentifier._37EmployeeCommuting]: "Arbeitswege der Mitarbeiter (Pendeln)",
  [IGhgCategoriesIdentifier._38LeasedOrRentedAssets]: "Geleaste Sachanlagen (vorgelagert)",
  [IGhgCategoriesIdentifier._39DownstreamTransportationAndDistribution]:
    "Nachgelagerter Transport und Distribution",
  [IGhgCategoriesIdentifier._310ProcessingOfSoldProducts]: " Verarbeitung verkaufter Produkte",
  [IGhgCategoriesIdentifier._311UseOfSoldProducts]: " Nutzung verkaufter Produkte",
  [IGhgCategoriesIdentifier._312EndOfLifeTreatmentOfSoldProducts]:
    " End-of-Life Treatment verkaufter Produkte",
  [IGhgCategoriesIdentifier._313LeasedOrRentedAssetsDownstream]:
    " Verleaste Sachanlagen (nachgelagert)",
  [IGhgCategoriesIdentifier._314Franchise]: " Franchise",
  [IGhgCategoriesIdentifier._315Investments]: " Investitionen",
};

const EMISSION_FACTOR_DATABASES: Record<IEmissionFactorDatabase, string> = {
  [IEmissionFactorDatabase.UbaAt]: "UBA AT",
  [IEmissionFactorDatabase.Defra]: "DEFRA",
  [IEmissionFactorDatabase.OrganizationSpecific]: "Individueller Eintrag",
};

const EMISSION_FACTOR_TYPES: Record<IEmissionFactorType, string> = {
  [IEmissionFactorType.ActivityBased]: "Aktivitätsbasiert",
  [IEmissionFactorType.LocationBasedEnergy]: "Standortbasiert (Energie)",
  [IEmissionFactorType.MarketBasedEnergy]: "Marktbasiert (Energie)",
  [IEmissionFactorType.SpendingBased]: "Ausgabenbasiert",
  [IEmissionFactorType.Other]: "Andere",
};

const MARKET_BASED_EMISSION_FACTOR_TYPES: Record<IMarketBasedEmissionFactorType, string> = {
  [IMarketBasedEmissionFactorType.ProductMix]: "Produktmix (vertragsbezogen)",
  [IMarketBasedEmissionFactorType.SupplierMix]: "Lieferantenmix",
  [IMarketBasedEmissionFactorType.ResidualMix]: "Residualmix",
};

const auditLoggingEventsTranslations: Record<IAuditLogEvent, string> = {
  recording_period_create: "Eine neue Erfassungsperiode wurde erstellt.",
  recording_period_update: "Eine Erfassungsperiode wurde bearbeitet.",
  recorded_esrs_topic_opt_in: "Es wurde mit der Erfassung für das Thema begonnen.",
  recorded_esrs_topic_opt_out: "Die Erfassung für dieses Thema wurde übersprungen.",
  dma_configuration_update: "Die DMA-Konfiguration wurde bearbeitet.",
  dma_category_create: "Eine DMA-Kategorie wurde erstellt.",
  dma_category_update: "Eine DMA-Kategorie wurde bearbeitet.",
  dma_category_update_information: "Die Informationen einer DMA-Kategorie wurden bearbeitet.",
  dma_category_delete: "Eine DMA-Kategorie wurde gelöscht.",
  dma_category_opt_in: "Es wurde mit der Erfassung für die Kategorie begonnen.",
  dma_category_opt_out: 'Diese Kategorie wurde als "nicht wesentlich" markiert',
  stakeholder_feedback_request_for_category: "Feedback wurde für diese Kategorie angefragt.",
  stakeholder_feedback_revoke_request_for_category:
    "Die Anfrage für Feedback wurde für diese Kategorie zurückgezogen.",
  dma_category_verify_material: "Die Auswirkungen wurden für diese Kategorie verifiziert.",
  dma_category_verify_financial: "Die Chancen/Risiken wurden für diese Kategorie verifiziert.",
  dma_category_verify: "Die Kategorie wurde verifiziert.",
  dma_category_unverify_material:
    "Die Verifizierung der Auswirkungen wurde für diese Kategorie aufgehoben.",
  dma_category_unverify_financial:
    "Die Verifizierung der Chance/Risiken wurde für diese Kategorie aufgehoben.",
  dma_category_unverify: "Die Verifizierung der Kategorie wurde aufgehoben.",
  dma_material_impact_create: "Eine neue Auswirkung wurde erstellt.",
  dma_material_impact_update: "Eine Auswirkung wurde bearbeitet.",
  dma_material_impact_update_dma_category:
    "Eine Auswirkung wurde in eine andere Kategorie verschoben.",
  dma_material_impact_delete: "Eine Auswirkung wurde gelöscht.",
  dma_material_impact_state_update: "Der Status der Auswirkung wurde geändert.",
  dma_financial_effect_create: "Eine neue Chance/ein neues Risiko wurde erstellt.",
  dma_financial_effect_update: "Eine Chance / ein Risiko wurde bearbeitet.",
  dma_financial_effect_update_dma_category:
    "Eine Chance / ein Risiko wurde in eine andere Kategorie verschoben.",
  dma_financial_effect_delete: "Eine Chance / ein Risiko wurde gelöscht.",
  dma_financial_effect_state_update: "Der Status der Chance / des Risikos wurde geupdated.",
  manual_stakeholder_feedback_create: "Ein manuelles Feedback wurde für die Kategorie angelegt.",
  manual_stakeholder_feedback_update: "Ein manuelles Feedback wurde bearbeitet.",
  manual_stakeholder_feedback_delete: "manual_stakeholder_feedback_delete",
  dma_stakeholder_submit_feedback: "Ein Stakeholder hat sein Feedback abgegeben.",
  dma_stakeholder_update_feedback: "Ein Stakeholder hat sein Feedback bearbeitet.",
  dma_stakeholder_create: "Ein neuer Stakeholder wurde erstellt.",
  dma_stakeholder_update: "Ein Stakeholder wurde bearbeitet.",
  dma_stakeholder_delete: "Ein Stakeholder wurde gelöscht.",
  organization_structure_create: "Eine neue Organisationsstruktur wurde angelegt.",
  organization_structure_update: "Eine Organisationsstruktur wurde bearbeitet.",
  data_entry_object_create: "Ein neues Erfassungsobjekt wurde erstellt.",
  data_entry_object_update: "Ein Erfassungsobjekt wurde bearbeitet.",
  root_data_entry_object_create: "Ein neues Root-Erfassungsobjekt wurde erstellt.",
  data_entry_object_input_parameter_create:
    "Ein neuer Erfassungsparameter wurde für ein Erfassungsobjekt erstellt.",
  data_entry_object_input_parameter_update:
    "Ein Erfassungsparameter eines Erfassungsobjektes wurde bearbeitet.",
  data_entry_object_input_parameter_update_sources:
    "Die Quellen eines Erfassungsparameters eines Erfassungsobjektes wurden bearbeitet.",
  data_entry_object_input_parameter_status_update:
    "Der Status eines Erfassungsparameters eines Erfassungsobjektes wurde geändert.",
  data_entry_object_input_parameter_exclude:
    "Der Erfassungsparameter eines Erfassungsobjektes wurde vom Bericht exkludiert.",
  data_entry_object_input_parameter_include:
    "Der Erfassungsparameter eines Erfassungsobjektes wurde im Bericht inkludiert.",
  data_entry_object_input_parameter_update_gap_analysis:
    "Werte der Gap-Analyse wurden für einen Erfassungsparameter eines Erfassungsobjektes angepasst.",
  data_entry_object_input_parameter_value_create:
    "Ein neuer Wert wurde für ein Erfassungsobjekt erfasst.",
  data_entry_object_input_parameter_value_update:
    "Ein Wert eines Erfassungsobjektes wurde bearbeitet.",
  data_entry_object_input_parameter_value_delete:
    "Ein Wert eines Erfassungsobjektes wurde gelöscht.",
  esrs_topic_phase_in_decision_makes_use_of_phase_in:
    "Die Phase-In Regelung wurde für ein ESRS-Thema in Anspruch genommen.",
  esrs_topic_phase_in_decision_no_longer_makes_use_of_phase_in:
    "Für ein ESRS-Thema wird keine Phase-In Regelung mehr in Anspruch genommen.",
  policy_create: "Ein neues Konzept wurde erstellt.",
  policy_update: "Ein Konzept wurde bearbeitet.",
  policy_delete: "Ein Konzept wurde gelöscht.",
  action_create: "Eine Maßnahme wurde erstellt.",
  action_update: "Eine Maßnahme wurde bearbeitet.",
  action_delete: "Eine Maßnahme wurde gelöscht.",
  target_create: "Ein Ziel wurde erstellt.",
  target_update: "Ein Ziel wurde bearbeitet.",
  target_delete: "Ein Ziel wurde gelöscht.",
  source_create: "Eine neue Quelle wurde erstellt.",
  source_update: "Eine Quelle wurde bearbeitet.",
  source_delete: "Eine Quelle wurde gelöscht.",
  source_file_create: "Eine neue Datei wurde für eine Quelle hochgeladen.",
  source_file_state_update: "Der Status einer Datei einer Quelle wurde geändert.",
  source_file_delete: "Eine Datei einer Quelle wurde gelöscht.",
  data_entry_object_dma_configuration_execute_dma:
    "Die DMA wurde für ein Erfassungsobjekt ausgeführt.",
  user_update_organization_role: "Die Rolle des Benutzers wurde bearbeitet.",
  user_update_data_entry_objects_access:
    "Der Zugriff des Benutzers auf Erfassungsobjekte wurde bearbeitet",
  emission_factor_create: "Ein neuer Emissionsfaktor wurde erstellt.",
  emission_factor_update: "Ein Emissionsfaktor wurde bearbeitet.",
  emission_factor_delete: "Ein Emissionsfaktor wurde gelöscht.",
};

const ADDRESSED_MATTERS_ENUM = {
  e1: {
    "esrs:ClimateChangeAdaptationMember": "Anpassung an den Klimawandel",
    "esrs:ClimateChangeMitigationMember": "Minderung des Klimawandels",
    "esrs:EnergyEfficiencyMember": "Energieeffizienz",
    "esrs:RenewableEnergyDeploymentMember": "Einsatz erneuerbarer Energien",
    "esrs:OtherClimateChangeSubtopicMember": "Sonstige Klimawandel-Themen",
  },
  e2: {
    "esrs:PollutionOfAirMember": "Luftverschmutzung",
    "esrs:PollutionOfWaterMember": "Wasserverschmutzung",
    "esrs:PollutionOfSoilMember": "Bodenverschmutzung",
    "esrs:PollutionOfLivingOrganismsAndFoodResourcesMember":
      "Verschmutzung von Lebewesen und Nahrungsressourcen",
    "esrs:SubstancesOfConcernMember": "Besorgniserregende Stoffe",
    "esrs:SubstancesOfVeryHighConcernMember": "Besonders besorgniserregende Stoffe",
    "esrs:MicroplasticsMember": "Mikroplastik",
    "esrs:OtherPollutionSubtopicMember": "Sonstige Umweltverschmutzungs-Themen",
  },
  e3: {
    "esrs:WaterAndMarineResourcesMember": "Wasser- und Meeresressourcen",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterConsumptionMember": "Wasserverbrauch",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterWithdrawalsMember": "Wasserentnahmen",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesMember": "Wassereinleitungen",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesInOceansMember":
      "Wassereinleitungen in Ozeane",
    "esrs:WaterAndMarineResourcesMember.esrs:ExtractionAndUseOfMarineResourcesMember":
      "Extraktion und Nutzung von Meeresressourcen",
    "esrs:OtherWaterAndMarineResourcesSubtopicMember":
      "Sonstige Wasser- und Meeresressourcen-Themen",
  },
  e4: {
    "esrs:DirectImpactDriversOfBiodiversityLossMember":
      "Direkte Treiber des Biodiversitätsverlusts",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:ClimateChangeMember": "Klimawandel",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:LandUseChangeMember":
      "Landnutzungsänderung",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:FreshWateruseChangeMember":
      "Änderung der Süßwassernutzung",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:SeauseChangeMember":
      "Änderung der Meeresnutzung",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:DirectExploitationMember":
      "Direkte Ausbeutung",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:InvasiveAlienSpeciesMember":
      "Invasive Arten",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:PollutionMember": "Verschmutzung",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:OtherDirectImpactDriversOfBiodiversityLossMember":
      "Sonstige direkte Treiber des Biodiversitätsverlusts",
    "esrs:ImpactsOnStateOfSpeciesMember": "Auswirkungen auf den Zustand der Arten",
    "esrs:ImpactsOnExtentAndConditionOfEcosystemsMember":
      "Auswirkungen auf den Umfang und den Zustand der Ökosysteme",
    "esrs:ImpactsAndDependenciesOnEcosystemServicesMember":
      "Auswirkungen und Abhängigkeiten von Ökosystemdienstleistungen",
    "esrs:OtherBiodiversityAndEcosystemsSubtopicMember":
      "Sonstige Biodiversitäts- und Ökosystem-Themen",
  },
  e5: {
    "esrs:ResourceInflowsMember": "Ressourcenzuflüsse",
    "esrs:ResourceOutflowsRelatedToProductsAndServicesMember":
      "Ressourcenabflüsse im Zusammenhang mit Produkten und Dienstleistungen",
    "esrs:WasteMember": "Abfall",
    "esrs:OtherResourceUseAndCircularEconomySubtopicMember":
      "Sonstige Ressourcennutzung und Kreislaufwirtschaft-Themen",
  },
  s1: {
    "esrs:WorkingConditionsForOwnWorkforceMember": "Arbeitsbedingungen für die eigene Belegschaft",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SecureEmploymentForOwnWorkforceMember":
      "Sichere Beschäftigung",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorkingTimeForOwnWorkforceMember":
      "Arbeitszeit",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:AdequateWagesForOwnWorkforceMember":
      "Angemessene Löhne",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SocialDialogueForOwnWorkforceMember":
      "Sozialer Dialog",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:FreedomOfAssociationForOwnWorkforceMember":
      "Vereinigungsfreiheit",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:ExistenceOfWorksCouncilsForOwnWorkforceMember":
      "Existenz von Betriebsräten",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:InformationConsultationAndParticipationRightsOfOwnWorkforceMember":
      "Informations-, Anhörungs- und Beteiligungsrechte der eigenen Belegschaft",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:CollectiveBargainingForOwnWorkforceMember":
      "Tarifverhandlungen",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorklifeBalanceForOwnWorkforceMember":
      "Vereinbarkeit von Beruf und Privatleben",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:HealthAndSafetyForOwnWorkforceMember":
      "Gesundheit und Sicherheit",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember":
      "Gleichbehandlung und Chancengleichheit für alle",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember":
      "Geschlechtergleichstellung und gleicher Lohn für gleichwertige Arbeit",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:TrainingAndSkillsDevelopmentForOwnWorkforceMember":
      "Ausbildung und Kompetenzentwicklung",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember":
      "Beschäftigung und Integration von Menschen mit Behinderungen",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember":
      "Maßnahmen gegen Gewalt und Belästigung am Arbeitsplatz",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:DiversityForOwnWorkforceMember":
      "Vielfalt",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember":
      "Sonstige arbeitsbezogene Rechte für die eigene Belegschaft",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ChildLabourForOwnWorkforceMember":
      "Kinderarbeit",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ForcedLabourForOwnWorkforceMember":
      "Zwangsarbeit",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:AdequateHousingForOwnWorkforceMember":
      "Angemessene Unterkunft",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:PrivacyForOwnWorkforceMember":
      "Privatsphäre",
    "esrs:OtherOwnWorkforceSubtopicMember": "Sonstige eigene Belegschaft-Themen",
  },
  s2: {
    "esrs:WorkingConditionsForValueChainWorkersMember":
      "Arbeitsbedingungen für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SecureEmploymentForValueChainWorkersMember":
      "Sichere Beschäftigung für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorkingTimeForValueChainWorkersMember":
      "Arbeitszeit für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:AdequateWagesForValueChainWorkersMember":
      "Angemessene Löhne für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SocialDialogueForValueChainWorkersMember":
      "Sozialer Dialog für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:FreedomOfAssociationForValueChainWorkersMember":
      "Vereinigungsfreiheit für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:CollectiveBargainingForValueChainWorkersMember":
      "Tarifverhandlungen für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorklifeBalanceForValueChainWorkersMember":
      "Vereinbarkeit von Beruf und Privatleben für Wertschöpfungskettenarbeiter",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:HealthAndSafetyForValueChainWorkersMember":
      "Gesundheit und Sicherheit für Wertschöpfungskettenarbeiter",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember":
      "Gleichbehandlung und Chancengleichheit für alle für Wertschöpfungskettenarbeiter",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember":
      "Geschlechtergleichstellung und gleicher Lohn für gleichwertige Arbeit für Wertschöpfungskettenarbeiter",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:TrainingAndSkillsDevelopmentForValueChainWorkersMember":
      "Ausbildung und Kompetenzentwicklung für Wertschöpfungskettenarbeiter",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember":
      "Beschäftigung und Integration von Menschen mit Behinderungen für Wertschöpfungskettenarbeiter",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember":
      "Maßnahmen gegen Gewalt und Belästigung am Arbeitsplatz für Wertschöpfungskettenarbeiter",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:DiversityForValueChainWorkersMember":
      "Vielfalt für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember":
      "Sonstige arbeitsbezogene Rechte für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ChildLabourForValueChainWorkersMember":
      "Kinderarbeit für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ForcedLabourForValueChainWorkersMember":
      "Zwangsarbeit für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:AdequateHousingForValueChainWorkersMember":
      "Angemessene Unterkunft für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:WaterAndSanitationForValueChainWorkersMember":
      "Wasser und Sanitärversorgung für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:PrivacyForValueChainWorkersMember":
      "Privatsphäre für Wertschöpfungskettenarbeiter",
    "esrs:OtherWorkersInValueChainSubtopicMember": "Sonstige Wertschöpfungskettenarbeiter-Themen",
  },
  s3: {
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember":
      "Wirtschaftliche, soziale und kulturelle Rechte von Gemeinschaften",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateHousingForAffectedCommunitiesMember":
      "Angemessene Unterkunft für betroffene Gemeinschaften",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateFoodMember":
      "Angemessene Ernährung",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:WaterAndSanitationForAffectedCommunitiesMember":
      "Wasser und Sanitärversorgung für betroffene Gemeinschaften",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:LandrelatedImpactsMember":
      "Landbezogene Auswirkungen",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:SecurityrelatedImpactsMember":
      "Sicherheitsbezogene Auswirkungen",
    "esrs:CommunitiesCivilAndPoliticalRightsMember":
      "Zivil- und politische Rechte von Gemeinschaften",
    "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfExpressionForAffectedCommunitiesMember":
      "Meinungsfreiheit für betroffene Gemeinschaften",
    "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfAssemblyMember":
      "Versammlungsfreiheit",
    "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:ImpactsOnHumanRightsDefendersMember":
      "Auswirkungen auf Menschenrechtsverteidiger",
    "esrs:RightsOfIndigenousPeoplesMember": "Rechte indigener Völker",
    "esrs:RightsOfIndigenousPeoplesMember.esrs:FreePriorAndInformedConsentMember":
      "Freie, vorherige und informierte Zustimmung",
    "esrs:RightsOfIndigenousPeoplesMember.esrs:SelfdeterminationMember": "Selbstbestimmung",
    "esrs:RightsOfIndigenousPeoplesMember.esrs:CulturalRightsMember": "Kulturelle Rechte",
    "esrs:OtherAffectedCommunitiesSubtopicMember": "Sonstige betroffene Gemeinschaften-Themen",
  },
  s4: {
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember":
      "Informationsbezogene Auswirkungen für Verbraucher und/oder Endnutzer",
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:PrivacyForConsumersAndOrEndUsersMember":
      "Privatsphäre für Verbraucher und/oder Endnutzer",
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:FreedomOfExpressionForConsumersAndOrEndusersMember":
      "Meinungsfreiheit für Verbraucher und/oder Endnutzer",
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:AccessToQualityInformationMember":
      "Zugang zu qualitativ hochwertigen Informationen",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember":
      "Persönliche Sicherheit von Verbrauchern und/oder Endnutzern",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:HealthAndSafetyForConsumersAndOrEndUsersMember":
      "Gesundheit und Sicherheit für Verbraucher und/oder Endnutzer",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:SecurityOfPersonMember":
      "Sicherheit der Person",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:ProtectionOfChildrenMember":
      "Schutz von Kindern",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember":
      "Soziale Inklusion von Verbrauchern und/oder Endnutzern",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:NondiscriminationMember":
      "Nichtdiskriminierung",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:AccessToProductsAndServicesMember":
      "Zugang zu Produkten und Dienstleistungen",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:ResponsibleMarketingPracticesMember":
      "Verantwortungsvolle Marketingpraktiken",
    "esrs:OtherConsumersAndOrEndusersSubtopicMember":
      "Sonstige Verbraucher und/oder Endnutzer-Themen",
  },
};

const LEXICAL_BLOCK_TRANSLATIONS: Record<LexicalBlockType, string> = {
  paragraph: "Absatz",
  h1: "Überschrift 1",
  h2: "Überschrift 2",
  h3: "Überschrift 3",
  h4: "Überschrift 4",
  h5: "Überschrift 5",
  h6: "Überschrift 6",
  bullet: "Liste",
  number: "Nummerierte Liste",
  check: "Checkliste",
  quote: "Zitat",
  code: "Code",
};

export const TRANSLATIONS_DE = {
  module_access: {
    error_module_not_accessible_to_organization: "Modul nicht für Organisation zugänglich!",
  },
  input_parameter_types: inputParameterTypesTranslations,
  static_unit_name: staticUnitTranslations,
  dr_eligible_for_phase_in_explanations: DRs_PHASE_IN_EXPLANATIONS,
  common: {
    logout_button_text: "Abmelden",
    no_changes: "Keine Änderungen",
    title_warning: "Warnung",
    countries: CountryNamesByCode,
    explanation_dialog_title: "Erklärung",
    title_audit_logging: "Aktivitätsprotokoll",
    placeholders: {
      search: "Suchen...",
    },
    error_field_required: "Dieses Feld ist erforderlich",
  },
  snack_bar_messages: {
    error_message: "Fehler: {{translatedAction}} - {{translatedError}}",
    actions: actionsTranslations,
  },
  user_picker_components: {
    selected_user_not_found: "Unbekannt",
    no_user_selected: "Kein Benutzer ausgewählt",
  },
  buttons: {
    create: "Erstellen",
    save: "Speichern",
    cancel: "Abbrechen",
    close: "Schließen",
    delete: "Löschen",
    remove: "Entfernen",
    edit: "Bearbeiten",
    confirm: "Bestätigen",
    accept: "Übernehmen",
    publish: "Veröffentlichen",
    accept_understood: "Verstanden",
    move: "Verschieben",
    show_explanation_button_tooltip: "Klicken um Erklärung anzuzeigen",
    add_comment: "Kommentar hinzufügen",
    remove_comment: "Kommentar entfernen",
    tooltip_copy_link: "Link kopieren",
    tooltip_copy_link_success: "Kopiert!",
    clear_selection: "Auswahl aufheben",
  },
  popup_menu_filter_users_section_component: {
    users_search_no_results: "Keine Ergebnisse für Suche",
  },
  side_bar_nav_items: {
    dashboard: "Dashboard",
    reporting: "Reporting",
    dma: "DWA",
    esrs: "ESRS",
    thg: "GHG",
    transformation: "Transformation",
    carbon_management: "Carbon Management",
    value_input: "Erfassung",
    target_paths: "Zielpfade",
    target_paths_reference_budgets: "Referenzbudgets",
    dr_assessments: "Offenlegungspflichten",
    configuration: "Konfiguration",
    configuration_organization: "Organisation",
    configuration_recording_periods: "Erfassungsperioden",
    configuration_stakeholder: "Stakeholder",
    configuration_distribution_criteria: "Aufteilungsschlüssel",
    configuration_sources: "Quellen",
    configuration_users: "Benutzer",
    configuration_emission_factors: "Emissionsfaktoren",
  },
  top_bar: {
    recording_period: "Erfassungsperiode",
    change_recording_period_text:
      "Sind Sie sicher, dass sie die Erfassungsperiode ändern möchten? Sie werden anschließend zur Startseite weitergeleitet.",
    data_entry_object: "Erfassungsobjekt",
  },
  organizations_redirect_page: {
    no_associated_organizations: "Ihr Benutzer ist keinen Organisationen zugeordnet.",
  },
  recording_periods_redirect_page: {
    no_recording_periods:
      "Für Ihre Organisation wurden noch keine Erfassungsperioden eingerichtet.",
    button_create_recording_period: "Erfassungsperiode erstellen",
  },
  lexical: {
    block_types: LEXICAL_BLOCK_TRANSLATIONS,
  },
  lexical_ai: {
    ai_button_menu: {
      title: "KI-gestützte Optionen",
      expand_text: "Text erweitern",
      summarize_text: "Text zusammenfassen",
    },
    ai_suggestion_modal: {
      title: "KI-gestützter Vorschlag",
    },
    button_accept: "Akzeptieren",
    button_discard: "Verwerfen",
    ai_button_disabled_tooltip: "Text auswählen, um KI-Funktionen verwenden zu können",
    ai_button_enabled_tooltip: "KI Assistent",
    error: {
      TEXT_TOO_LONG: "Der eingegebene Text ist leider zu lange. Bitte kürzen Sie den Text.",
      BAD_REQUEST: "Es gab ein Problem mit Ihrer Anfrage. Bitte überprüfen Sie Ihre Eingabe.",
      SERVER_ERROR: "Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
      UNKNOWN: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
    },
  },
  comments: {
    button: {
      label: "Kommentare",
    },
    notice_no_comments: {
      first: "Noch keine Kommentare",
      second: "Hinterlassen Sie den ersten Kommentar!",
    },
    text_editor: {
      placeholder: "Kommentar schreiben...",
    },
    tooltip_delete_button: "Kommentar löschen",
    delete_dialog: {
      title: "Kommentar löschen",
      content: "Sind Sie sicher, dass Sie den Kommentar löschen möchten?",
    },
  },
  audit_logs: {
    button: {
      label: "Aktivitäten",
    },
  },
  users: {
    anonymous_name: "Anonym",
  },
  recording_period_actions_dashboard_section: {
    title_double_materiality_assessment: "$t(dma_common:title)",
    double_materiality_assessment_abbreviation: "DWA",
    text_double_materiality_assessment:
      "Führen Sie eine doppelte Wesentlichkeitsanalyse durch, um die Auswirkungen und " +
      "Chancen und Risiken Ihrer Organisation zu bestimmen.",
    button_double_materiality_assessment: "Durchführen",
  },
  recording_period_recording_structures_cards_list: {
    structures_without_recording_model_header: "Ohne Erfassungsmodell",
    add_category_button: "Thema individuell anlegen",
    add_all_esrs_topics_button: "Alle ESRS-Themen anlegen",
  },
  init_esrs_confirm_dialog: {
    title: "Alle ESRS-Themen anlegen?",
    content: "Sind Sie sicher, dass Sie alle ESRS-Themen hinzufügen möchten?",
  },
  evaluation_list_item: {
    evaluation_name: {
      emissions: "Emissionen auswerten",
    },
  },
  add_recording_structure_to_recording_period_dialog: {
    dialog_title: 'Neues Thema für "{{organizationName}}" erfassen:',
    recording_models_empty: "Keine Erfassungsmodelle vorhanden.",
    choose_recording_model_label: "Erfassungsmodell auswählen",
    choose_recording_model_item_text: "Erfassungsmodell auswählen",
    template_groups_empty:
      'Für das Erfassungsmodell "{{recordingModelName}}" sind keine (weiteren) Themen verfügbar.',
    choose_templates_group_label: "Erfassungsstruktur auswählen",
    choose_templates_group_item_text: "Erfassungsstruktur auswählen",
    choose_template_label: "Vorlage auswählen",
    templates_group_empty:
      'Für die Lizenz Ihrer Organisation sind keine Vorlagen für die Erfassungsstruktur "{{templatesGroupName}}" verfügbar. Wenden Sie sich an <a>{{email}}</a>.',
    choose_template_item_text: "Vorlage auswählen",
    accept_template_button_text: "Vorlage auswählen",
    cancel_button_text: "Abbrechen",
  },
  organization_structure_is_draft_notice: {
    organization_structure_is_draft:
      "Sie müssen die Organisationsstruktur für die aktuelle Erfassungsperiode festlegen und veröffentlichen bevor Sie Werte erfassen können.",
    to_configuration_text: "Zur Konfiguration",
  },
  filter_bar: {
    choose_data_entry_object: "Erfassungsobjekt auswählen",
    clear_filter: "Filter zurücksetzen",
    clear: "Zurücksetzen",
    no_entries_available: "Keine Einträge verfügbar",
    no_entries_found: "Keine Einträge gefunden",
    search: "Suchen...",
    no_matching_results: "Keine übereinstimmenden Ergebnisse gefunden.",
  },
  data_entry_object_filter: {
    responsiblePerson: "Verantwortliche Person",
    contributingPeople: "Mitarbeitende",
    status: "Status",
    phaseIn: "Phase In",
    optional: "Optional",
  },
  data_entry_object_values_overview_thg_component: {
    page_title: "Werteerfassung",
    edit_data_entry_object_input_parameters: "Zu erfassende Werte anpassen",
    no_results_found: "Keine zu erfassenden Werte gefunden",
    tooltip_button_filter: "Filter",
    no_filterable_users_available: "Noch keine Personen zugewiesen.",
    redirect_button: "Zu erfassende Werte anpassen",
  },
  phase_in: {
    chip_label: "Phase-In",
  },
  data_entry_object_values_overview_esrs_component: {
    eu_law_label: "ESRS Standard",
    application_requirements_label: "Application Requirements",
    confirm_submit_incomplete_dr_title: 'Zugehörige Datenpunkte nicht "Erledigt"',
    confirm_submit_incomplete_dr_body:
      'Einige zugehörige Datenpunkte sind noch nicht als "Erledigt" markiert. Sind Sie sicher, dass Sie die Offenlegungspflicht als "In Prüfung" / "Erledigt" markieren möchten?',
    button_add_table_row: "Zeile hinzufügen",
    update_valid_confirm_state_change_dialog: {
      title: 'Bereits als "Erledigt" markiert',
      content:
        'Der Datenpunkt wurde bereits als "Erledigt" markiert. Sind Sie sicher, dass Sie den Wert aktualisieren und damit auf "In Arbeit" / "Offen" zurückstufen möchten?',
    },
    phase_in_title: "Phase-In",
    phase_in_info_box: {
      text: "Mindestangaben nach Anwendung der Bestimmungen für schrittweise eingeführte Angabepflichten gemäß ESRS 1 Anlage C",
    },
    materiality_not_material_chip_tooltip:
      "Mit Sub-Themen Verbunden, die in Ihrer Wesentlichkeitsanalyse als “nicht wesentlich” markiert wurden.",
  },
  data_entry_object_values_overview_common: {
    no_contributing_users: "Keine Mitarbeitenden hinzugefügt",
    add_contributing_user_tooltip: "Mitarbeitende hinzufügen",
    no_more_contributing_users_to_assign: "Keine weiteren Benutzer verfügbar",
    no_users_available_for_responsible_user: "Keine weiteren Benutzer verfügbar",
    add_responsible_user_tooltip: "Verantwortliche Person setzen",
    no_responsible_user_value_text: "Keine verantwortliche Person",
    comment_label: "Kommentar",
    dependency_mismatch_info:
      'Die Erfassung dieses Datenpunktes ist von der Angabe bei "{{parameterName}}" abhängig. Aktuell muss dieser Wert nicht erfasst werden, es ist jedoch bereits ein Wert hinterlegt.',
    buttons: {
      previous_section: "Vorheriger Abschnitt",
      next_section: "Nächster Abschnitt",
    },
    indicator_optional_text: "Optional",
    temporary_disabled_text:
      "Dieser Datenpunkt wird gerade in Absprache mit unseren Experten fachlich überarbeitet. Bitte melden Sie sich bei <a>{{email}}</a>, falls Sie den Datenpunkt aktuell unbedingt benötigen.",
    // Materiality Info Box
    materiality_info_box_not_material: {
      title: "Thema nicht wesentlich",
      text: "Basierend auf den Ergebnissen Ihrer Doppelten Wesentlichkeitsanalyse wurde dieses ESRS-Thema als “nicht wesentlich” eingestuft. Die Datenerfassung ist daher nicht erforderlich.",
    },
  },
  data_entry_object_values_input_table: {
    notice_empty: "Noch keine Werte erfasst",
    duplicate_error: "Mehrere Zeilen mit derselben Kombination der Felder: {{fields}}",
  },
  options_input_component: {
    name_empty_option: "Keine Auswahl",
  },
  data_entry_object_input_parameter_common: {
    status: {
      [IDataEntryObjectInputParameterStatus.Open]: {
        name: "Offen",
        description: "Es wurden noch keine Daten angegeben.",
        action_needed: "Der Benutzer muss Daten eingeben.",
      },
      [IDataEntryObjectInputParameterStatus.InProgress]: {
        name: "In Bearbeitung",
        description: "Daten wurden angegeben, aber noch nicht abgeschlossen.",
        action_needed: "Der Benutzer muss die Daten überprüfen und einreichen.",
      },
      [IDataEntryObjectInputParameterStatus.InReview]: {
        name: "In Prüfung",
        description: "Daten wurden eingereicht und warten auf Validierung.",
        action_needed: "Der Überprüfer muss die Daten überprüfen und genehmigen/ablehnen.",
      },
      [IDataEntryObjectInputParameterStatus.Done]: {
        name: "Erledigt",
        description: "Daten wurden vom Prüfer überprüft und genehmigt.",
        action_needed: "Keine, die Daten sind fertiggestellt.",
      },
    },
    transition_actions: {
      submit_with_reviewer: "Zu Überprüfung einreichen",
      submit_without_reviewer: "Ohne Überprüfung abschließen",
      reset: "Zurücksetzen",
      approve: "Genehmigen",
      reject: "Ablehnen",
      notice_only_responsible_person_can_approve_or_reject:
        "Nur die verantwortliche Person kann genehmigen oder ablehnen.",
    },
    excluded_status: "Ausgeschlossen",
    exclude_transition_name: "Vom Bericht ausschließen",
    exclusion_reasons: {
      [IDataEntryObjectInputParameterExclusionReason.NonMaterial]: "Nicht wesentlich",
      [IDataEntryObjectInputParameterExclusionReason.OtherReason]: "Anderer Grund",
      [IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn]: "Phase-in Bestimmung anwenden",
    },
    notice_exclusion_only_possible_as_responsible:
      "Nur die verantwortliche Person kann Angaben ausschließen.",
    notice_inclusion_only_possible_as_responsible:
      "Nur die verantwortliche Person kann Angaben wieder hinzufügen.",
  },
  data_entry_object_input_parameter_exclusion_dialog: {
    dialog_title_exclude: "Warum wollen Sie die Angabe ausschließen?",
    dialog_title_already_excluded: "Diese Angabe wurde ausgeschlossen",
    label_exclusion_reason: "Grund",
    label_explanation: "Kurze Begründung",
    label_explanation_optional: "Kurze Begründung (optional)",
    error_explanation_required: "Für den ausgewählten Grund ist eine Begründung erforderlich.",
    button_confirm_exclude: "Ausschließen",
    button_include: "Wieder hinzufügen",
    // Include Confirm Dialog
    include_confirm_dialog_title: "Angabe wieder hinzufügen",
    include_confirm_dialog_body:
      "Sind Sie sicher, dass Sie die Angabe wieder zum Bericht hinzufügen möchten?",
  },
  data_entry_object_input_parameters_dialog: {
    dialog_title: "Zu erfassende Werte:",
    close_dialog: "Schließen",
  },
  data_entry_object_select_group_component: {
    button_settings: "Aktionen",
    button_category_select_all: "Alle direkt untergeordneten Erfassungsobjekte auswählen",
    button_category_deselect_all: "Alle direkt untergeordneten Erfassungsobjekte abwählen",
    button_select_all: "Alle untergeordneten Erfassungsobjekte auswählen",
    button_deselect_all: "Alle untergeordneten Erfassungsobjekte abwählen",
  },
  data_entry_object_input_parameters_select_group_component: {
    button_settings: "Aktionen",
    button_category_select_all: "Alle Werte dieses Themas und all seiner Unterthemen auswählen",
    button_category_deselect_all: "Alle Werte dieses Themas und all seiner Unterthemen abwählen",
    button_select_all: "Alle Werte dieses Themas auswählen",
    button_deselect_all: "Alle Werte dieses Themas abwählen",
  },
  data_entry_object_values_group: {
    category_empty: "Es werden aktuell keine Werte oder Unternehmen erfasst.",
  },
  data_entry_object_values_group_input_parameter_component: {
    no_contributing_users: "Keine Mitarbeitenden hinzugefügt",
    add_contributing_user_tooltip: "Mitarbeitende hinzufügen",
    no_more_contributing_users_to_assign: "Keine weiteren Benutzer verfügbar",
  },
  associated_iros_tab_component: {
    no_entries: {
      materialImpacts: "Sie müssen erst Auswirkungen anlegen, bevor diese zur Auswahl stehen.",
      financialEffects:
        "Sie müssen erst Chancen & Risiken anlegen, bevor diese zur Auswahl stehen.",
    },
    no_results: {
      materialImpacts: "Für die angegebene Suche wurden keine Auswirkungen gefunden.",
      financialEffects: "Für die angegebene Suche wurden keine Chancen oder Risiken gefunden.",
    },
  },
  associated_iros_dialog: {
    title: "Auswirkungen, Chancen & Risiken verknüpfen",
    search_placeholder: "Suchen...",
    tab_titles: {
      materialImpacts: "Auswirkungen",
      financialEffects: "Chancen und Risiken",
    } as { [key in IROType]: string },
  },
  mdr_select_dialog: {
    table_header_name: "Titel",
    table_header_topics: "Themen",
  },
  mdr_common: {
    name: "Titel",
    iro_dropdown_text: "Verbundene Auswirkungen, Risiken & Chancen wählen",
    label_associated_policies: "Verbundene Konzepte wählen",
    label_associated_actions: "Verbundene Maßnahmen wählen",
    mdr_unit_groups: {
      [MdrUnitGroup.DIMENSIONLESS]: "Keine Einheit",
      [MdrUnitGroup.MASS]: "Masse",
      [MdrUnitGroup.ENERGY]: "Energie",
      [MdrUnitGroup.ENERGY_PER_MONETARY]: "Energie / $t(mdr_common:mdr_unit_per_monetary_suffix)",
      [MdrUnitGroup.GHG_EMISSIONS]: "Emissionen",
      [MdrUnitGroup.GHG_EMISSIONS_PER_MONETARY]:
        "Emissionen / $t(mdr_common:mdr_unit_per_monetary_suffix)",
      [MdrUnitGroup.VOLUME]: "Volumen",
      [MdrUnitGroup.VOLUME_PER_MONETARY]: "Volumen / $t(mdr_common:mdr_unit_per_monetary_suffix)",
      [MdrUnitGroup.AREA]: "Fläche",
      [MdrUnitGroup.MONETARY]: "Währung",
      other: "Andere",
    },
    mdr_unit_per_monetary_suffix: "Mio. €",
  },
  data_entry_object_policy_component: {
    present_policies: "Ja, Konzepte existieren",
    no_present_policies: "Nein, keine Konzepte sind umgesetzt oder geplant",
    button_choose_no_policies_for_topic: "Keine Konzepte existieren",
    notice_chosen_to_not_adopt_policies:
      "Es sind keine Konzepte zu diesem Thema geplant oder umgesetzt.",
    button_undo_no_policies_adopted: "Konzepte existieren",
    tooltip_add_policy: "Neues Konzept erstellen",
    no_policies_defined: "Noch keine Konzepte angelegt.",
    edit_policy: 'Konzept <i>"{{title}}"</i> bearbeiten',
    create_policy: "Neues Konzept erstellen",
    delete_policy_tooltip: "Konzept entfernen",
    confirm_dialog: {
      title: "Konzept entfernen",
      body: "Sind Sie sicher, dass Sie das Konzept entfernen möchten?",
      button_confirm: "Entfernen",
    },
    section_titles: {
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E1]:
        "Spezifische Angaben zu Klimawandel",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E2]:
        "Spezifische Angaben zu Umweltverschmutzung",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E3]:
        "Spezifische Angaben zu Wasser- und Meeresressourcen",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E4]:
        "Spezifische Angaben zu Biologische Vielfalt und Ökosysteme",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E5]:
        "Spezifische Angaben zu Kreislaufwirtschaft",
      esrs_s: "Allgemeine Angaben zu Sozialen Themen",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S1]:
        "Spezifische Angaben zu Arbeitskräfte des Unternehmens",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S2]:
        "Spezifische Angaben zu Arbeitskräfte in der Wertschöpfungskette",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S3]:
        "Spezifische Angaben zu Betroffene Gemeinschaften",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S4]:
        "Spezifische Angaben zu Verbraucher und Endnutzer",
    },
    labels: {
      general: {
        name: "Titel",
        descriptionOfKeyContentsOfPolicyExplanatory:
          "Beschreibung der wichtigsten Inhalte des Konzeptes",
        descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory:
          "Beschreibung des Anwendungsbereichs (oder der Ausnahmen) des Konzeptes",
        descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory:
          "Beschreibung der höchsten Ebene in der Organisation, die für die Umsetzung des Konzeptes verantwortlich ist",
        referenceToThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicy:
          "Verweis auf Standards oder Initiativen Dritter, zu deren Einhaltung sich das Unternehmen im Rahmen der Umsetzung des Konzepts verpflichtet, sofern vorhanden",
        descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory:
          "Beschreibung der Berücksichtigung der Interessen wichtiger Stakeholder bei der Festlegung des Konzeptes, sofern vorhanden",
        policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementIt:
          "Wird das Konzept den potenziell betroffenen Stakeholdern und den Stakeholdern, die bei der Umsetzung helfen müssen, zur Verfügung gestellt?",
        explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementItExplanatory:
          "Erklärung, wie das Konzept den potenziell betroffenen Stakeholdern und den Stakeholdern, die bei der Umsetzung helfen müssen, zur Verfügung gestellt wird",
      },
      e1: {
        addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
      },
      e2: {
        addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil:
          "Das Konzept behandelt die Minderung negativer Auswirkungen im Zusammenhang mit der Verschmutzung von Luft, Wasser und Boden",
        policyEitherAddressesSubstitutingOrMinimisingUseOfSubstancesOfConcernAndOrPhasingOutSubstancesOfVeryHighConcern:
          "Das Konzept behandelt die Substitution und Minimierung des Einsatzes von besorgniserregenden Stoffen und die Abschaffung von besonders besorgniserregenden Stoffen",
        policyAddressesAvoidingIncidentsAndOrEmergencySituationsAndIfWhenTheyOccurItEitherControlsOrLimitsTheirImpactOnPeopleAndEnvironment:
          "Das Konzept behandelt das Vermeiden von Vorfällen und Notfallsituationen und falls sie auftreten, die Kontrolle und Begrenzung ihrer Auswirkungen auf Menschen und Umwelt",
      },
      e3: {
        addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        policyAddressesWaterManagement: "Das Konzept behandelt das Wasserressourcenmanagement",
        policyAddressesEitherUseOrSourcingOfWaterAndOrMarineResourcesInOwnOperations:
          "Das Konzept behandelt die Nutzung und Beschaffung von Wasser und Meeresressourcen in eigenen Betrieben",
        policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater:
          "Das Konzept behandelt die Wasseraufbereitung als Schritt zu einer nachhaltigeren Wasserbeschaffung",
        policyAddressesPreventionAndOrAbatementOfWaterPollutionResultingFromActivities:
          "Das Konzept behandelt die Verhütung und Verminderung der Wasserverschmutzung durch eigene Aktivitäten",
        policyAddressesEitherProductOrServiceDesignInViewOfAddressingWaterrelatedIssuesAndOrPreservingMarineResources:
          "Das Konzept behandelt die Gestaltung von Produkten und Dienstleistungen im Hinblick auf wasserbezogene Themen und die Erhaltung der Meeresressourcen",
        policyAddressesCommitmentToReducingMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndOrAlongUpstreamAndDownstreamValueChain:
          "Das Konzept behandelt die Verpflichtung zur Verringerung des wesentlichen Wasserverbrauchs in Gebieten, die von Wasserrisiken betroffen sind",
        policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems:
          "Verhindert das Konzept eine weitere Verschlechterung und schützt und verbessert den Zustand von Gewässern und aquatischen Ökosystemen",
        policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources:
          "Das Konzept fördert eine nachhaltige Wassernutzung auf der Grundlage des langfristigen Schutzes verfügbarer Wasserressourcen",
        policyAimsAtEnhancingProtectionAndOrImprovingAquaticEnvironment:
          "Das Konzept zielt auf den verbesserten Schutz und die Verbesserung der aquatischen Umwelt ab",
        policyPromotesGoodEnvironmentalStatusOfMarineWater:
          "Das Konzept fördert einen guten Umweltzustand von Meeresgewässern",
        policyPromotesReductionOfWaterWithdrawalsAndOrWaterDischarges:
          "Das Konzept fördert die Reduzierung von Wasserentnahmen und -ableitungen",
        policyContributesEitherToGoodEcologicalAndOrChemicalQualityOfSurfaceWaterBodiesOrToGoodChemicalQualityAndOrQuantityOfGroundwaterBodies:
          "Das Konzept trägt zur guten ökologischen und chemischen Qualität der Oberflächengewässer und zur guten chemischen Qualität und Quantität der Grundwasserkörper bei",
        policyEitherMinimisesMaterialImpactsAndRisksOrImplementsMitigationMeasuresThatAimAtMaintainingValueAndFunctionalityOfPriorityServicesAndOrToIncreaseResourceEfficiencyInOwnOperations:
          "Das Konzept minimiert wesentliche Auswirkungen und Risiken und setzt sie Minderungsmaßnahmen um, die darauf abzielen, den Wert und die Funktionalität von prioritären Dienstleistungen aufrechtzuerhalten und die Ressourceneffizienz in eigenen Betrieben zu erhöhen",
        policyAvoidsImpactsOnAffectedCommunities:
          "Das Konzept vermeidet Auswirkungen auf betroffene Gemeinschaften",
      },
      e4: {
        addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndOrEcosystemsrelatedImpacts:
          "Das Konzept bezieht sich auf wesentliche Biodiversitäts- und Ökosystembezogene Auswirkungen",
        biodiversityAndEcosystemsrelatedPolicyRelatesToEitherMaterialDependenciesOrMaterialPhysicalAndOrTransitionRisksAndOpportunities:
          "Das Konzept bezieht sich auf wesentliche Abhängigkeiten und wesentliche physische und Übergangsrisiken und -chancen",
        biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain:
          "Das Konzept unterstützt die Rückverfolgbarkeit von Produkten, Komponenten und Rohstoffen mit tatsächlichen oder potenziellen Auswirkungen auf Biodiversität und Ökosysteme entlang der Wertschöpfungskette",
        biodiversityAndEcosystemsrelatedPolicyAddressesEitherProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainAndOrEnhanceConditionsForBiodiversity:
          "Das Konzept behandelt die Produktion, Beschaffung oder den Verbrauch aus Ökosystemen, die zur Erhaltung oder Verbesserung der Bedingungen für die biologische Vielfalt bewirtschaftet werden",
        biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndOrEcosystemsrelatedImpacts:
          "Das Konzept behandelt die sozialen Folgen von Biodiversitäts- und Ökosystembezogenen Auswirkungen",
        disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory:
          "Wie beschränkt das Konzept die Beschaffung von Lieferanten, die nicht nachweisen können, dass sie nicht zu erheblichen Schäden in geschützten Gebieten oder wichtigen Gebieten der biologischen Vielfalt beitragen?",
        disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory:
          "Wie bezieht sich das Konzept auf anerkannte Standards oder Drittanbieterzertifizierungen, die von Regulierungsbehörden überwacht werden?",
        disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
          "Wie behandelt das Konzept Rohstoffe, die aus Ökosystemen stammen, die zur Erhaltung oder Verbesserung der Bedingungen für die biologische Vielfalt bewirtschaftet wurden?",
        sustainableDevelopmentGoalsToWhichPolicyIsConnected:
          "Sustainable Development Goals, mit denen das Konzept in Bezug auf Biodiversität und Ökosysteme verbunden ist",
        disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory:
          "Wie ermöglicht das Konzept, negative Auswirkungen auf Biodiversität und Ökosysteme in eigenen Betrieben und in der zugehörigen Wertschöpfungskette zu vermeiden?",
        disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory:
          "Wie ermöglicht das Konzept, negative Auswirkungen auf Biodiversität und Ökosysteme in eigenen Betrieben und entlang der Wertschöpfungskette zu reduzieren und zu minimieren, die nicht vermieden werden können?",
        disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory:
          "Wie ermöglicht das Konzept, degradierte Ökosysteme wiederherzustellen oder geräumte Ökosysteme nach Exposition gegenüber Auswirkungen, die nicht vollständig vermieden und/oder minimiert werden können, wiederherzustellen?",
        thirdpartyStandardOfConductUsedInPolicyIsEitherObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesOrRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances:
          "Ist der in dem Konzept verwendete Drittanbieter-Verhaltensstandard objektiv und erreichbar, basierend auf einem wissenschaftlichen Ansatz zur Identifizierung von Problemen und realistisch bei der Bewertung, wie diese Probleme unter einer Vielzahl von praktischen Umständen angegangen werden können?",
        thirdpartyStandardOfConductUsedInPolicyIsEitherDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsAndNoGroupHoldingUndueAuthorityAndOrVetoPowerOverContent:
          "Ist der in dem Konzept verwendete Drittanbieter-Verhaltensstandard durch einen fortlaufenden Konsultationsprozess mit relevanten Stakeholdern entwickelt oder aufrechterhalten worden, mit ausgewogenem Input aller relevanten Stakeholdergruppen, ohne dass eine Gruppe übermäßige Autorität oder Vetomacht über den Inhalt hat?",
        thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime:
          "Ist der in dem Konzept verwendete Drittanbieter-Verhaltensstandard ein schrittweiser Ansatz und eine kontinuierliche Verbesserung des Standards und seiner Anwendung von besseren Managementpraktiken und erfordert die Festlegung von sinnvollen Zielen und spezifischen Meilensteinen, um den Fortschritt gegenüber den Prinzipien und Kriterien im Laufe der Zeit anzuzeigen?",
        thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008:
          "Ist der in dem Konzept verwendete Drittanbieter-Verhaltensstandard über unabhängige Zertifizierungs- oder Verifizierungsstellen überprüfbar, die definierte und strenge Bewertungsverfahren haben, die Interessenkonflikte vermeiden und den ISO-Leitlinien für Akkreditierungs- und Verifizierungsverfahren oder Artikel 52 der Verordnung (EG) Nr. 765/2008 entsprechen?",
        thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice:
          "Entspricht der in dem Konzept verwendete Drittanbieter-Verhaltensstandard dem ISEAL-Verhaltenskodex?",
      },
      e5: {
        addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        layersInWasteHierarchyThatPolicyAddresses:
          "Ebene in der Abfallhierarchie, die das Konzept behandelt",
        policyAddressesTransitioningAwayFromUseOfVirginResources:
          "Das Konzept behandelt die Abkehr von der Nutzung von Primärrohstoffen und die Zunahme von recycelten Materialien",
        policyAddressesSustainableSourcingAndOrUseOfRenewableResources:
          "Das Konzept behandelt die nachhaltige Beschaffung und Nutzung erneuerbarer Ressourcen",
        policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteReuseRepairRefurbishRemanufactureAndRepurposeOverWasteTreatmentRecycling:
          "Das Konzept behandelt die Priorisierung der Vermeidung oder Minimierung von Abfällen gegenüber der Abfallbehandlung",
      },
      s: {
        groundsForDiscriminationSpecificallyCoveredByPolicy:
          "Diskriminierungsgründe, die speziell von dem Konzept erfasst werden",
        referenceForIdentificationOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusers:
          "Aspekte des Konzepts, die die Anforderungen an die Offenlegung von Konzepten in Bezug auf die eigene Belegschaft, die Wertschöpfungskettenarbeiter, betroffene Gemeinschaften, Verbraucher und Endnutzer erfüllen",
        specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace:
          "Liegen spezifische Verpflichtungen in Bezug auf Inklusion oder positive Maßnahmen für Menschen aus Gruppen mit besonderen Risiken der Verletzlichkeit in den eigenen Arbeitskräften vor?",
        disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsAsWellAsExplanationOfHowPotentialBarriersForDisseminationAreIdentifiedAndRemovedExplanatory:
          "Offenlegung von Kommunikationswerkzeugen und -kanälen, die darauf abzielen, sicherzustellen, dass das Konzept zugänglich ist und dass verschiedene Zielgruppen ihre Auswirkungen verstehen",
        disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory:
          "Offenlegung spezifischer Konzept-Verpflichtungen in Bezug auf Inklusion oder positive Maßnahmen für Menschen aus Gruppen mit besonderen Risiken der Verletzlichkeit in den eigenen Arbeitskräften",
        s1: {
          addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        },
        s2: {
          addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        },
        s3: {
          addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        },
        s4: {
          addressedMatters: "Nachhaltigkeitsthemen, die in diesem Konzept behandelt werden",
        },
      },
    },
    enums: {
      e1: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e1,
      },
      e2: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e2,
      },
      e3: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e3,
      },
      e4: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e4,
        sustainableDevelopmentGoalsToWhichPolicyIsConnected: {
          "esrs:EndPovertyInAllItsFormsEverywhereMember":
            "1 - Armut in allen ihren Formen überall beenden",
          "esrs:EndHungerAchieveFoodSecurityAndImprovedNutritionAndPromoteSustainableAgricultureMember":
            "2 - Hunger beenden, Ernährungssicherheit und verbesserte Ernährung erreichen und nachhaltige Landwirtschaft fördern",
          "esrs:EnsureHealthyLivesAndPromoteWellbeingForAllAtAllAgesMember":
            "3 - Gesundes Leben für alle Menschen in jedem Alter gewährleisten",
          "esrs:EnsureInclusiveAndEquitableQualityEducationAndPromoteLifelongLearningOpportunitiesForAllMember":
            "4 - Inklusive und gerechte Qualitätserziehung gewährleisten und lebenslanges Lernen für alle fördern",
          "esrs:AchieveGenderEqualityAndEmpowerAllWomenAndGirlsMember":
            "5 - Geschlechtergleichstellung erreichen und alle Frauen und Mädchen stärken",
          "esrs:EnsureAvailabilityAndSustainableManagementOfWaterAndSanitationForAllMember":
            "6 - Verfügbarkeit und nachhaltige Bewirtschaftung von Wasser und Sanitärversorgung für alle gewährleisten",
          "esrs:EnsureAccessToAffordableReliableSustainableAndModernEnergyForAllMember":
            "7 - Zugang zu bezahlbarer, verlässlicher, nachhaltiger und moderner Energie für alle gewährleisten",
          "esrs:PromoteSustainedInclusiveAndSustainableEconomicGrowthFullAndProductiveEmploymentAndDecentWorkForAllMember":
            "8 - Förderung des dauerhaften, inklusiven und nachhaltigen Wirtschaftswachstums, vollständige und produktive Beschäftigung und menschenwürdige Arbeit für alle",
          "esrs:BuildResilientInfrastructurePromoteInclusiveAndSustainableIndustrializationAndFosterInnovationMember":
            "9 - Aufbau widerstandsfähiger Infrastrukturen, Förderung einer inklusiven und nachhaltigen Industrialisierung und Förderung von Innovationen",
          "esrs:ReduceInequalityWithinAndAmongCountriesMember":
            "10 - Ungleichheit in und zwischen Ländern verringern",
          "esrs:MakeCitiesAndHumanSettlementsInclusiveSafeResilientAndSustainableMember":
            "11 - Städte und menschliche Siedlungen inklusiv, sicher, widerstandsfähig und nachhaltig machen",
          "esrs:EnsureSustainableConsumptionAndProductionPatternsMember":
            "12 - Nachhaltige Konsum- und Produktionsmuster sicherstellen",
          "esrs:TakeUrgentActionToCombatClimateChangeAndItsImpactsMember":
            "13 - Dringende Maßnahmen zur Bekämpfung des Klimawandels und seiner Auswirkungen",
          "esrs:ConserveAndSustainablyUseOceansSeasAndMarineResourcesForSustainableDevelopmentMember":
            "14 - Ozeane, Meere und Meeresressourcen für nachhaltige Entwicklung erhalten und nachhaltig nutzen",
          "esrs:ProtectRestoreAndPromoteSustainableUseOfTerrestrialEcosystemsSustainablyManageForestsCombatDesertificationAndHaltAndReverseLandDegradationAndHaltBiodiversityLossMember":
            "15 - Terrestrische Ökosysteme schützen, wiederherstellen und nachhaltig nutzen, Wälder nachhaltig bewirtschaften, Wüstenbildung bekämpfen und Landdegradierung umkehren und den Biodiversitätsverlust stoppen",
          "esrs:PromotePeacefulAndInclusiveSocietiesForSustainableDevelopmentProvideAccessToJusticeForAllAndBuildEffectiveAccountableAndInclusiveInstitutionsAtAllLevelsMember":
            "16 - Förderung friedlicher und inklusiver Gesellschaften für nachhaltige Entwicklung, Zugang zur Justiz für alle und Aufbau effektiver, rechenschaftspflichtiger und inklusiver Institutionen auf allen Ebenen",
          "esrs:StrengthenMeansOfImplementationAndRevitalizeGlobalPartnershipForSustainableDevelopmentMember":
            "17 - Stärkung der Umsetzungsmittel und Belebung der globalen Partnerschaft für nachhaltige Entwicklung",
        },
      },
      e5: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e5,
        layersInWasteHierarchyThatPolicyAddresses: {
          "esrs:PreventionMember": "Vermeidung",
          "esrs:PreparationForReuseMember": "Vorbereitung zur Wiederverwendung",
          "esrs:RecyclingMember": "Recycling",
          "esrs:OtherRecoveryMember": "Sonstige Rückgewinnung",
          "esrs:DisposalMember": "Beseitigung",
        },
      },
      s: {
        groundsForDiscriminationSpecificallyCoveredByPolicy: {
          "esrs:RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Rassische und ethnische Herkunft",
          "esrs:ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Farbe",
          "esrs:SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Geschlecht",
          "esrs:SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Sexuelle Orientierung",
          "esrs:GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Geschlechtsidentität",
          "esrs:DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Behinderung",
          "esrs:AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Alter",
          "esrs:ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Religion",
          "esrs:PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Politische Meinung",
          "esrs:NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Nationale Herkunft",
          "esrs:SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Soziale Herkunft",
          "esrs:OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember":
            "Sonstige Formen der Diskriminierung, die durch EU-Verordnungen und nationales Recht abgedeckt sind",
        },
        s1: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s1,
        },
        s2: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s2,
        },
        s3: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s3,
        },
        s4: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s4,
        },
      },
    },
    input_errors: {
      general: {
        name: {
          required: "Bitte geben Sie einen Titel ein!",
        },
      },
    },
  },
  data_entry_object_action_component: {
    name: "Titel",
    present_actions: "Es gibt Maßnahmen für dieses Thema",
    no_present_actions: "Es gibt keine Maßnahmen für dieses Thema",
    button_choose_no_actions_for_topic: "Keine Maßnahmen existieren",
    notice_chosen_to_not_define_actions:
      "Es sind keine Maßnahmen zu diesem Thema geplant oder umgesetzt.",
    actions_adopted: "Maßnahmen existieren",
    tooltip_add_action: "Neue Maßnahme erstellen",
    no_actions_defined: "Noch keine Maßnahmen angelegt.",
    edit_action: 'Maßnahme <i>"{{title}}"</i> bearbeiten',
    create_action: "Neue Maßnahme erstellen",
    delete_action_tooltip: "Maßnahme entfernen",
    confirm_dialog: {
      title: "Maßnahme entfernen",
      body: "Sind Sie sicher, dass Sie die Maßnahme entfernen möchten?",
      button_confirm: "Entfernen",
      button_cancel: "Abbrechen",
    },
    section_titles: {
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E1]:
        "Spezifische Angaben zu Klimawandel",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E2]:
        "Spezifische Angaben zu Umweltverschmutzung",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E3]:
        "Spezifische Angaben zu Wasser- und Meeresressourcen",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E4]:
        "Spezifische Angaben zu Biologische Vielfalt und Ökosysteme",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E5]:
        "Spezifische Angaben zu Kreislaufwirtschaft",
      esrs_s: "Allgemeine Angaben zu Sozialen Themen",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S1]:
        "Spezifische Angaben zu Arbeitskräfte des Unternehmens",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S2]:
        "Spezifische Angaben zu Arbeitskräfte in der Wertschöpfungskette",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S3]:
        "Spezifische Angaben zu Betroffene Gemeinschaften",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S4]:
        "Spezifische Angaben zu Verbraucher und Endnutzer",
      esrs_e1_and_e4: "Übergreifende Angaben zu Klimawandel, Biologische Vielfalt und Ökosysteme",
    },
    labels: {
      general: {
        name: "Titel",
        disclosureOfKeyActionExplanatory: "Beschreibung der Maßnahme",
        statusOfKeyAction: "Status der Maßnahme",
        timeHorizonsUnderWhichKeyActionIsToBeCompleted:
          "Zeithorizont, innerhalb dessen die Maßnahme abgeschlossen sein soll",
        disclosureOfExpectedOutcomesOfKeyActionAndHowItsImplementationContributesToAchievementOfPolicyObjectivesAndTargetsExplanatory:
          "Offenlegung der erwarteten Ergebnisse der Maßnahme und wie deren Umsetzung zur Erreichung der Ziele von existierenden Konzepten beiträgt",
        descriptionOfScopeOfKeyActionCoverageInTermsOfActivitiesUpstreamAndOrDownstreamValueChainGeographiesAndAffectedStakeholderGroupsExplanatory:
          "Beschreibung des Umfangs der Maßnahme in Bezug auf Aktivitäten, Geografie, Wertschöpfungskette und gegebenenfalls betroffene Interessengruppen",
        keyActionCoverage: "Bereich der Abdeckung der Maßnahme",
        keyActionIsTakenToEitherProvideForCooperateInOrInSupportProvisionOfRemedyForThoseHarmedByActualMaterialImpacts:
          "Maßnahme wird durchgeführt, um Abhilfe für diejenigen zu schaffen, die durch tatsächliche wesentliche Auswirkungen geschädigt wurden",
        disclosureOfQuantitativeAndQualitativeInformationRegardingProgressOfActionsOrActionPlansDisclosedInPriorPeriodsExplanatory:
          "Offenlegung quantitativer und qualitativer Informationen über den Fortschritt der Maßnahmen oder Aktionspläne, die in vorherigen Berichtszeiträumen offengelegt wurden",
        descriptionOfTypeOfCurrentAndFutureFinancialAndOtherResourcesAllocatedToActionPlanExplanatory:
          "Beschreibung der Art der derzeitigen und zukünftigen finanziellen und sonstigen Ressourcen, die der Maßnahme zugewiesen sind",
        abilityToImplementActionOrActionPlanDependsOnSpecificPreconditions:
          "Fähigkeit zur Umsetzung der Maßnahme oder des Aktionsplans hängt von spezifischen Voraussetzungen ab",
        explanationOfHowCurrentFinancialResourcesRelateToMostRelevantAmountsPresentedInFinancialStatementsExplanatory:
          "Erläuterung, wie die derzeitig zugewiesenen finanziellen Mittel im Verhältnis zu den relevantesten Beträgen im Unternehmensabschluss stehen",
      },
      e1: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
        decarbonisationLeverTypes: "Art des verwendeten Dekarbonisierungshebels",
        adaptationSolutionTypes: "Art der implementierten Anpassungslösung",
        achievedGHGEmissionReductions: "Erzielte Reduktion der Treibhausgasemissionen",
        expectedGHGEmissionReductions: "Erwartete Reduktion der Treibhausgasemissionen",
        explanationOfExtentToWhichAbilityToImplementActionDependsOnAvailabilityAndAllocationOfResourcesExplanatory:
          "Erläuterung, inwieweit die Umsetzungsfähigkeit der Maßnahme von der Verfügbarkeit und Zuweisung von Ressourcen abhängt",
        explanationOfAnyPotentialDifferencesBetweenSignificantOpExAndCapExDisclosedUnderESRSE1AndKeyPerformanceIndicatorsDisclosedUnderCommissionDelegatedRegulationEU2021NO2178TextBlockExplanatory:
          "Erläuterung der Unterschiede zwischen den wesentlichen Betriebsausgaben und Kapitalausgaben, die unter ESRS E1 offengelegt wurden, und den Leistungsindikatoren der delegierten Verordnung (EU) 2021/2178 der Kommission",
      },
      e2: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
        actionPlanHasBeenImplementedAtSiteLevel: "Diese Maßnahme wird auf Standortebene umgesetzt",
        layersInMitigationHierarchyToWhichActionCanBeAllocatedPollution:
          "Ebene in der Abhilfemaßnahmenhierarchie, der die Maßnahme zur Bekämpfung der Umweltverschmutzung zugeordnet werden kann",
      },
      e3: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
        layersInMitigationHierarchyToWhichActionCanBeAllocatedWaterAndMarineResources:
          "Ebene in der Abhilfemaßnahmenhierarchie, der die Maßnahme zu Wasser- und Meeresressourcen zugeordnet werden kann",
        actionRelatesToAreasAtWaterRisk:
          "Maßnahme bezieht sich Gebiete, die von Wasserrisiken betroffen sind",
        actionRelatesToAreasOfHighwaterStress:
          "Maßnahme bezieht sich auf Gebiete mit hohem Wasserstress",
        informationAboutSpecificCollectiveActionExplanatory:
          "Informationen über spezifische kollektive Maßnahmen für Wasser- und Meeresressourcen",
      },
      e4: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
        layersInMitigationHierarchyToWhichActionCanBeAllocatedBiodiversityAndEcosystems:
          "Ebene in der Abhilfemaßnahmenhierarchie, der die Maßnahme zu Biodiversität und Ökosystemen zugeordnet werden kann",
        disclosureOfHowMitigationHierarchyHasBeenAppliedWithRegardToActionsExplanatory:
          "Offenlegung, wie die Abhilfemaßnahmenhierarchie in Bezug auf Maßnahmen zur Biodiversität und Ökosystemen angewendet wurde",
        biodiversityOffsetsWereUsedInActionPlan:
          "Kompensationsmaßnahmen wurden in der Maßnahme vorgesehen oder verwendet",
        disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory:
          "Offenlegung der Ziele der Kompensationsmaßnahmen und der verwendeten Leistungsindikatoren",
        financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets:
          "Finanzierungseffekte direkter und indirekter Kosten von Kompensationsmaßnahmen",
        descriptionOfBiodiversityOffsetsExplanatory:
          "Beschreibung der Kompensationsmaßnahmen, einschließlich des Bereichs, der Art, der Qualitätskriterien und der zu erfüllenden Standards",
        localAndIndigenousKnowledgeAndOrNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction:
          "Lokales und indigenes Wissen sowie naturbasierte Lösungen wurden in Maßnahmen zu Biodiversität und Ökosystemen integriert",
        disclosureOfListOfKeyStakeholdersInvolvedInKeyActionAndHowTheyAreInvolvedKeyStakeholdersNegativelyOrPositivelyImpactedByKeyActionAndHowTheyAreImpactedExplanatory:
          "Offenlegung der Liste der Interessensträger, die an der Maßnahme beteiligt oder betroffen sind, und wie sie beteiligt/betroffen sind",
        explanationOfNeedForAppropriateConsultationsAndNeedToRespectDecisionsOfAffectedCommunitiesExplanatory:
          "Erläuterung der Notwendigkeit angemessener Konsultationen und der Notwendigkeit, die Entscheidungen betroffener Gemeinschaften zu respektieren",
        descriptionOfWhetherKeyActionMayInduceSignificantNegativeSustainabilityImpactsExplanatory:
          "Beschreibung, ob die Maßnahme signifikante negative Nachhaltigkeitsauswirkungen auf Biodiversität und Ökosysteme haben könnte",
        keyActionOccurrenceBiodiversityAndEcosystems:
          "Vorkommen der Maßnahme in Bezug auf Biodiversität und Ökosysteme",
        keyActionPlanIsCarriedOutOnlyByUndertakingUsingItsResources:
          "Der Aktionsplan wird nur von dem Unternehmen unter Nutzung seiner Ressourcen durchgeführt, bezogen auf Biodiversität und Ökosysteme",
        keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributes:
          "Der Aktionsplan ist Teil einer breiteren Initiative, zu der das Unternehmen erheblich beiträgt, bezogen auf Biodiversität und Ökosysteme",
        disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsExplanatory:
          "Offenlegung zusätzlicher Informationen über das Projekt, seine Sponsoren und andere Teilnehmer, bezogen auf Biodiversität und Ökosysteme",
        descriptionOfHowKeyActionContributesToSystemwideChangeExplanatory:
          "Beschreibung, wie die Maßnahme zu einem systemweiten Wandel beiträgt, bezogen auf Biodiversität und Ökosysteme",
      },
      e5: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
        actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndOrWater:
          "Maßnahme deckt höhere Ressourceneffizienz bei der Nutzung von technischen und biologischen Materialien sowie Wasser ab",
        actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates:
          "Maßnahme deckt höhere Verwendungsraten von sekundären Rohstoffen und Rezyklaten ab",
        actionCoversApplicationOfCircularDesignAndOrHigherRatesOfEitherReuseRepairRefurbishingRemanufactureRepurposingOrRecycling:
          "Maßnahme deckt Anwendung kreislauffähigen Designs und höhere Raten von Haltbarkeit, Wiederverwendung, Reparatur, Aufarbeitung, Neufertigung, Zweckänderung und Recycling ab",
        actionCoversApplicationOfCircularBusinessPractices:
          "Maßnahme deckt Anwendung von kreislaufwirtschaftlichen Geschäftspraktiken ab",
        actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain:
          "Maßnahme deckt Vermeidung von Abfallentstehung in der vor- und nachgelagerten Wertschöpfungskette ab",
        actionCoversOptimisationOfWasteManagementInLineWithWasteHierarchy:
          "Maßnahme deckt Optimierung der Abfallwirtschaft gemäß der Abfallhierarchie ab",
        actionContributesToCollectiveAction:
          "Diese Maßnahme ist Teil einer kollektiven Maßnahme mit Wertschöpfungspartnern oder lokalen Netzwerken zur Verbesserung der Produktkreislauffähigkeit",
        descriptionOfContributionToCircularEconomy:
          "Beschreibung des Beitrags zur Kreislaufwirtschaft, z. B. durch intelligente Abfallsammelsysteme",
        descriptionOfOtherStakeholdersInvolved:
          "Beschreibung anderer Akteure, die an der kollektiven Maßnahme beteiligt sind (z.B Wettbewerber, Lieferanten, Händler, Kunden)",
        descriptionOfOrganisationOfProject:
          "Beschreibung der Organisation des Projekts und der Rolle der diversen Interessensträger",
      },
      s1: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
      },
      s2: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
      },
      s3: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
      },
      s4: {
        addressedMatters: "Nachhaltigkeitsthemen, die in dieser Maßnahme addressiert werden",
      },
      e1And4: {
        explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToRelevantLineItemsOrNotesInFinancialStatementsExplanatory:
          "Erläuterung der Beziehung zwischen bedeutenden Kapitalausgaben (CapEx) und Betriebsausgaben (OpEx), die erforderlich sind, um geplante oder ergriffene Maßnahmen in Bezug auf relevante Posten oder Anmerkungen in den Finanzberichten umzusetzen",
        explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToKeyPerformanceIndicatorsRequiredUnderCommissionDelegatedRegulationEU2021NO2178Explanatory:
          "Erläuterung der Beziehung zwischen bedeutenden CapEx und OpEx, die erforderlich sind, um geplante oder ergriffene Maßnahmen in Bezug auf die unter der delegierten Verordnung der Kommission EU 2021/NO 2178 erforderlichen Leistungsindikatoren umzusetzen",
        explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToCapExPlanRequiredByCommissionDelegatedRegulationEU2021NO2178Explanatory:
          "Erläuterung der Beziehung zwischen bedeutenden CapEx und OpEx, die erforderlich sind, um geplante oder ergriffene Maßnahmen in Bezug auf den durch die delegierte Verordnung der Kommission EU 2021/NO 2178 erforderlichen CapEx-Plan umzusetzen",
      },
    },
    enums: {
      general: {
        statusOfKeyAction: {
          "esrs:TakenMember": "Durchgeführt",
          "esrs:PlannedMember": "Geplant",
        },
        timeHorizonsUnderWhichKeyActionIsToBeCompleted: {
          "esrs:ShorttermMember": "Kurzfristig (1 Jahr)",
          "esrs:MediumtermMember": "Mittelfristig (2-5 Jahre)",
          "esrs:LongtermMember": "Langfristig (>5 Jahre)",
        },
        keyActionCoverage: {
          "esrs:UpstreamValueChainMember": "Upstream-Wertschöpfungskette",
          "esrs:DownstreamValueChainMember": "Downstream-Wertschöpfungskette",
          "esrs:UndertakingsActivitiesMember": "Eigene Tätigkeiten des Unternehmens",
          "esrs:GeographiesMember": "Geografien",
          "esrs:AffectedStakeholderGroupsMember": "Betroffene Interessengruppen",
        },
      },
      e1: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e1,
        decarbonisationLeverTypes: {
          "esrs:EnergyEfficiencyAndConsumptionReductionMember":
            "Energieeffizienz und Verbrauchsreduktion",
          "esrs:MaterialEfficiencyAndConsumptionReductionMember":
            "Materialeffizienz und Verbrauchsreduktion",
          "esrs:FuelSwitchingMember": "Brennstoffwechsel",
          "esrs:ElectrificationMember": "Elektrifizierung",
          "esrs:UseOfRenewableEnergyMember": "Nutzung erneuerbarer Energien",
          "esrs:PhaseOutSubstitutionOrModificationOfProductMember":
            "Ausphasen, Ersetzen oder Modifizieren von Produkten",
          "esrs:PhaseOutSubstitutionOrModificationOfProcessMember":
            "Ausphasen, Ersetzen oder Modifizieren von Prozessen",
          "esrs:SupplyChainDecarbonisationMember": "Dekarbonisierung der Lieferkette",
          "esrs:OtherDecarbonisationLeversMember": "Andere Dekarbonisierungshebel",
        },
        adaptationSolutionTypes: {
          "esrs:NaturebasedMember": "Naturbasiert",
          "esrs:EngineeringMember": "Ingenieurwesen",
          "esrs:TechnologicalMember": "Technologisch",
          "esrs:OtherThanNaturebasedEngineeringOrTechnologicalMember":
            "Anders als Naturbasiert, Ingenieurwesen oder Technologisch",
        },
      },
      e2: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e2,
        layersInMitigationHierarchyToWhichActionCanBeAllocatedPollution: {
          "esrs:PollutionAvoidancePreventionOfPollutionAtSourceMember":
            "Vermeidung von Verschmutzung, Prävention von Verschmutzung an der Quelle",
          "esrs:PollutionReductionMinimisationOfPollutionMember":
            "Reduzierung von Verschmutzung, Minimierung von Verschmutzung",
          "esrs:RestorationRegenerationAndTransformationOfEcosystemsWherePollutionHasOccurredControlOfImpactsFromRegularActivitiesAndIncidentsMember":
            "Wiederherstellung, Regeneration und Transformation von Ökosystemen, in denen Verschmutzung aufgetreten ist; Kontrolle der Auswirkungen von regulären Aktivitäten und Vorfällen",
        },
      },
      e3: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e3,
        layersInMitigationHierarchyToWhichActionCanBeAllocatedWaterAndMarineResources: {
          "esrs:AvoidanceOfUseOfWaterAndMarineResourcesMember":
            "Vermeidung der Nutzung von Wasser- und Meeresressourcen",
          "esrs:ReductionOfUseOfWaterAndMarineResourcesMember":
            "Reduzierung der Nutzung von Wasser- und Meeresressourcen",
          "esrs:ReclaimingAndReuseOfWaterMember": "Rückgewinnung und Wiederverwendung von Wasser",
          "esrs:RestorationAndRegenerationOfAquaticEcosystemsAndWaterBodiesMember":
            "Wiederherstellung und Regeneration von aquatischen Ökosystemen und Gewässern",
        },
      },
      e4: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e4,
        layersInMitigationHierarchyToWhichActionCanBeAllocatedBiodiversityAndEcosystems: {
          "esrs:AvoidanceMember": "Vermeidung",
          "esrs:AvoidanceMember.esrs:AvoidanceThroughSiteSelectionMember":
            "Vermeidung durch Standortauswahl",
          "esrs:AvoidanceMember.esrs:AvoidanceThroughProjectDesignMember":
            "Vermeidung durch Projektdesign",
          "esrs:AvoidanceMember.esrs:AvoidanceThroughSchedulingMember":
            "Vermeidung durch Zeitplanung",
          "esrs:MinimisationMember": "Minimierung",
          "esrs:RestorationAndRehabilitationMember": "Wiederherstellung und Rehabilitation",
          "esrs:CompensationOrOffsetsMember": "Kompensation oder Ausgleichsmaßnahmen",
        },
      },
      e5: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e5,
      },
      s1: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s1,
      },
      s2: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s2,
      },
      s3: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s3,
      },
      s4: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s4,
      },
    },
    input_errors: {
      general: {
        name: {
          required: "Bitte geben Sie einen Titel ein!",
        },
      },
    },
  },
  data_entry_object_target_component: {
    name: "Titel des Ziels",
    present_targets: "Es gibt Ziele für dieses Thema",
    no_present_targets: "Es gibt keine Ziele für dieses Thema",
    button_choose_no_targets_for_topic: "Es existieren keine Ziele",
    notice_chosen_to_not_define_targets:
      "Es sind keine Ziele zu diesem Thema geplant oder verabschiedet.",
    targets_adopted: "Ziele existieren",
    tooltip_add_target: "Neues Ziel erstellen",
    no_targets_defined: "Noch keine Ziele angelegt.",
    edit_target: 'Ziel <i>"{{title}}"</i> bearbeiten',
    create_target: "Neues Ziel erstellen",
    delete_target_tooltip: "Ziel entfernen",
    confirm_dialog: {
      title: "Ziel entfernen",
      body: "Sind Sie sicher, dass Sie das Ziel entfernen möchten?",
      button_confirm: "Entfernen",
      button_cancel: "Abbrechen",
    },
    section_titles: {
      esrs_e: "Allgemeine Angaben zu ökologischen Themen",
      esrs_e2_to_e5:
        "Übergreifende Angaben zu Klimawandel, Umweltverschmutzung, Wasser- und Meeresressourcen, Biologische Vielfalt und Ökosysteme, Kreislaufwirtschaft",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E1]:
        "Spezifische Angaben zu Klimawandel",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E2]:
        "Spezifische Angaben zu Umweltverschmutzung",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E3]:
        "Spezifische Angaben zu Wasser- und Meeresressourcen",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E4]:
        "Spezifische Angaben zu Biologische Vielfalt und Ökosysteme",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E5]:
        "Spezifische Angaben zu Kreislaufwirtschaft",
      esrs_s: "Allgemeine Angaben zu Sozialen Themen",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S1]:
        "Spezifische Angaben zu Arbeitskräfte des Unternehmens",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S2]:
        "Spezifische Angaben zu Arbeitskräfte in der Wertschöpfungskette",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S3]:
        "Spezifische Angaben zu Betroffene Gemeinschaften",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S4]:
        "Spezifische Angaben zu Verbraucher und Endnutzer",
    },
    labels: {
      general: {
        name: "Titel des Ziels",
        disclosureOfMeasurableOutcomeorientedAndTimeboundTargetExplanatory:
          "Beschreibung des messbaren, ergebnisorientierten und zeitgebundenen Ziels",
        identifiersOfRelatedImpactsRisksAndOpportunities:
          "Verbundene Auswirkungen, Risiken oder Chancen wählen",
        identifiersOfRelatedPolicies: "Mit Ziel verbundene Konzepte wählen",
        measurableTargetAbsoluteValue: "Messbares Ziel (absoluter Wert)",
        measurableTargetPercentage: "Messbares Ziel (Prozentsatz)",
        absoluteOrRelativeTarget: "Absolutes oder relatives Ziel",
        esrsMetricsAndMonetaryAmountsUsedForTarget: "Mit Ziel verbundene ESRS-Kennzahlen auswählen",
        descriptionOfScopeOfTargetExplanatory: "Beschreibung des Umfangs des Ziels",
        baselineValueOfMeasurableTargetAbsoluteValue:
          "Basiswert des messbaren Ziels (absoluter Wert)",
        baseYearFromWhichProgressIsMeasured: "Basisjahr, ab dem der Fortschritt gemessen wird",
        periodToWhichTargetAppliesStartYear: "Zeitraum, für den das Ziel gilt (Startjahr)",
        periodToWhichTargetAppliesEndYear: "Zeitraum, für den das Ziel gilt (Endjahr)",
        descriptionOfMethodologiesAndSignificantAssumptionsUsedToDefineTargetExplanatory:
          "Beschreibung der verwendeten Methoden und wesentlichen Annahmen zur Definition des Ziels",
        stakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatter:
          "Waren Stakeholder an der Zielsetzung beteiligt?",
        disclosureOfHowStakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatterExplanatory:
          "Offenlegung, wie Stakeholder an der Zielsetzung beteiligt wurden",
        descriptionOfAnyChangesInTargetAndCorrespondingMetricsOrUnderlyingMeasurementMethodologiesSignificantAssumptionsLimitationsSourcesAndAdoptedProcessesToCollectDataExplanatory:
          "Beschreibung von Änderungen des Ziels und der entsprechenden Metriken oder zugrunde liegenden Messmethoden, wesentlichen Annahmen, Einschränkungen, Quellen und der angewendeten Prozesse zur Datenerfassung",
        descriptionOfPerformanceAgainstDisclosedTargetExplanatory:
          "Beschreibung der aktuellen Leistung im Vergleich zu dem angegebenen Ziel, einschließlich Informationen darüber, wie das Ziel überwacht und überprüft wird",
        measurableTargetCurrentProgressAbsoluteValue:
          "Aktueller Fortschritt des messbaren Ziels (absoluter Wert)",
        measurableTargetCurrentProgressPercentage:
          "Aktueller Fortschritt des messbaren Ziels (Prozentsatz)",
        progressIsInLineWithWhatHadBeenInitiallyPlanned:
          "Entspricht der Fortschritt dem ursprünglich geplanten?",
      },
      e: {
        targetRelatedToEnvironmentalMattersIsBasedOnConclusiveScientificEvidence:
          "Basiert das Ziel auf schlüssigen wissenschaftlichen Erkenntnissen?",
        e2ToE5: {
          ecologicalThresholdsAndOrEntityspecificAllocationsWereTakenIntoConsiderationWhenSettingTarget:
            "Wurden ökologische Schwellenwerte und unternehmensspezifische Zuweisungen bei der Festlegung des Ziels berücksichtigt?",
          disclosureOfEcologicalThresholdsIdentifiedAndMethodologyUsedToIdentifyEcologicalThresholdsExplanatory:
            "Offenlegung der identifizierten ökologischen Schwellenwerte und der verwendeten Methodik zur Identifizierung ökologischer Schwellenwerte",
          ecologicalThresholdsAreEntityspecific:
            "Sind ökologische Schwellenwerte unternehmensspezifisch?",
          disclosureOfHowEcologicalEntityspecificThresholdsWereDeterminedExplanatory:
            "Offenlegung, wie unternehmensspezifische ökologische Schwellenwerte festgelegt wurden",
          disclosureOfHowResponsibilityForRespectingIdentifiedEcologicalThresholdsIsAllocatedExplanatory:
            "Offenlegung, wie die Verantwortung für die Einhaltung der identifizierten ökologischen Schwellenwerte zugeteilt wurde",
        },
        e1: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          descriptionOfHowItHasBeenEnsuredThatBaselineValueIsRepresentativeInTermsOfActivitiesCoveredAndInfluencesFromExternalFactorsExplanatory:
            "Beschreibung, wie sichergestellt wurde, dass der Basiswert repräsentativ für die abgedeckten Aktivitäten und den Einfluss externer Faktoren ist",
          descriptionOfHowNewBaselineValueAffectsNewTargetItsAchievementAndPresentationOfProgressOverTimeExplanatory:
            "Beschreibung, wie der neue Basiswert das neue Ziel, dessen Erreichung und die Darstellung des Fortschritts im Laufe der Zeit beeinflusst",
          disclosureOfPastProgressMadeInMeetingTargetBeforeCurrentBaseYearExplanatory:
            "Offenlegung des bisherigen Fortschritts bei der Erreichung des Ziels vor dem aktuellen Basisjahr",
          targetIsDerivedUsingSectoralDecarbonisationPathway:
            "Das Ziel wird unter Verwendung eines sektoralen Dekarbonisierungspfads abgeleitet",
          disclosureOfFrameworkAndMethodologyThatHasBeenUsedToDetermineGHGEmissionReductionTargetUnderlyingClimateAndPolicyScenariosForTargetHowFutureDevelopmentsHaveBeenConsideredAndHowTheseWillPotentiallyImpactGHGEmissionsAndEmissionsReductionsExplanatory:
            "Offenlegung des Rahmens und der Methodik, die zur Festlegung des Ziels zur Reduzierung der Treibhausgasemissionen verwendet wurden, sowie der zugrunde liegenden Klima- und Politikszenarien für das Ziel, wie zukünftige Entwicklungen berücksichtigt wurden und wie diese potenziell die Treibhausgasemissionen und Emissionsminderungen beeinflussen werden",
          explanationOfHowTargetIsCompatibleWithLimitingOfGlobalWarmingToOneAndHalfDegreesCelsiusInLineWithParisAgreementExplanatory:
            "Erläuterung, wie die Ziele mit der Begrenzung der globalen Erwärmung auf eineinhalb Grad Celsius im Einklang mit dem Pariser Abkommen vereinbar sind",
          ghgEmissionReductionTargetIsScienceBasedAndCompatibleWithLimitingGlobalWarmingToOneAndHalfDegreesCelsius:
            "Das Ziel zur Reduzierung der Treibhausgasemissionen basiert auf wissenschaftlichen Erkenntnissen und ist mit der Begrenzung der globalen Erwärmung auf eineinhalb Grad Celsius vereinbar",
          targetHasBeenExternallyAssured: "Das Ziel wurde extern geprüft",
          descriptionOfExpectedDecarbonisationLeversAndTheirOverallQuantitativeContributionsToAchieveGHGEmissionReductionTargetExplanatory:
            "Beschreibung der erwarteten Dekarbonisierungshebel und ihrer quantitativen Beiträge zur Erreichung des Ziels zur Reduzierung der Treibhausgasemissionen",
          newTechnologiesArePlannedToBeAdoptedToAchieveGHGEmissionReductionTarget:
            "Neue Technologien sind geplant, um das Ziel der Reduzierung der Treibhausgasemissionen zu erreichen",
          diverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLevers:
            "Eine Vielzahl von Klimaszenarien wurde berücksichtigt, um relevante umweltbezogene, gesellschaftliche, technologische, marktbezogene und politische Entwicklungen zu erkennen und Dekarbonisierungshebel zu bestimmen",
          disclosureOfHowDiverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLeversExplanatory:
            "Offenlegung, wie eine Vielzahl von Klimaszenarien berücksichtigt wurde, um relevante umweltbezogene, gesellschaftliche, technologische, marktbezogene und politische Entwicklungen zu erkennen und Dekarbonisierungshebel zu bestimmen",
          explanationOfHowConsistencyOfGHGEmissionReductionTargetWithGHGInventoryBoundariesHasBeenEnsuredExplanatory:
            "Erläuterung, wie die Konsistenz der Ziele zur Reduzierung der Treibhausgasemissionen mit den Emissionsinventar-Grenzen sichergestellt wurde",
          greenhouseGasesCoveredByEmissionReductionTarget:
            "Treibhausgas(e), für die ein Emissionsreduktionsziel gilt",
        },
        e2: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          targetRelatesToPreventionAndControlOfAirPollutantsAndRespectiveSpecificLoads:
            "Das Ziel bezieht sich auf die Verhinderung und Kontrolle von Luftschadstoffen und den jeweiligen spezifischen Belastungen",
          targetRelatesToEitherPreventionOrControlOfEmissionsToWaterAndOrToRespectiveSpecificLoads:
            "Das Ziel bezieht sich auf die Verhinderung und Kontrolle von Emissionen in Wasser und den jeweiligen spezifischen Belastungen",
          targetRelatesToEitherPreventionOrControlOfPollutionToSoilAndOrToRespectiveSpecificLoads:
            "Das Ziel bezieht sich auf die Verhinderung und Kontrolle von Bodenverschmutzung und den jeweiligen spezifischen Belastungen",
          targetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcern:
            "Das Ziel bezieht sich auf die Verhinderung und Kontrolle von besorgniserregenden und sehr besorgniserregenden Stoffen",
          disclosureOfHowTargetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcernExplanatory:
            "Offenlegung, wie sich das Ziel auf die Verhinderung und Kontrolle von besorgniserregenden und sehr besorgniserregenden Stoffen bezieht",
          targetIsMandatoryRequiredByLegislation:
            "Verschmutzungsbezogenes Ziel ist gesetzlich vorgeschrieben",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForPollutionPreventionAndControl:
            "Das verschmutzungsbezogene Ziel behebt Mängel in Bezug auf die wesentlichen Beitragskriterien zur Verhinderung und Kontrolle von Verschmutzung",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForPollutionPreventionAndControl:
            "Das verschmutzungsbezogene Ziel behebt Mängel in Bezug auf die Kriterien für die Vermeidung signifikanter Schäden bei der Verhinderung und Kontrolle von Verschmutzung",
        },
        e3: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          targetRelatesToManagementOfMaterialImpactsRisksAndOpportunitiesRelatedToAreasAtWaterRiskIncludingImprovementOfWaterQuality:
            "Das Ziel bezieht sich auf das Management materieller Auswirkungen, Risiken und Chancen in wassergefährdeten Gebieten, einschließlich der Verbesserung der Wasserqualität",
          targetRelatesToResponsibleManagementOfMarineResourcesImpactsRisksAndOpportunitiesIncludingNatureAndOrQuantityOfMarineResourcesrelatedCommoditiesUsedByUndertaking:
            "Das Ziel bezieht sich auf das verantwortungsvolle Management von Meeresressourcen, Auswirkungen, Risiken und Chancen, einschließlich der Art und Menge der vom Unternehmen genutzten marinen Ressourcen",
          targetRelatesToReductionOfWaterConsumptionIncludingInAreasAtWaterRiskAndOrInAreasOfHighWaterstress:
            "Das Ziel bezieht sich auf die Reduzierung des Wasserverbrauchs, einschließlich in wassergefährdeten Gebieten und in Gebieten mit hohem Wasserstress",
          targetRelatesToReductionOfWaterWithdrawals:
            "Das Ziel bezieht sich auf die Reduzierung von Wasserentnahmen",
          targetRelatesToReductionOfWaterDischarges:
            "Das Ziel bezieht sich auf die Reduzierung von Wassereinleitungen",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForWaterAndMarineResources:
            "Das Ziel für Wasser- und Meeresressourcen behebt Mängel in Bezug auf die wesentlichen Beitragskriterien für Wasser- und Meeresressourcen",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForWaterAndMarineResources:
            "Das Ziel für Wasser- und Meeresressourcen behebt Mängel in Bezug auf die Kriterien zur Vermeidung signifikanter Schäden für Wasser- und Meeresressourcen",
        },
        e4: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiertt",
          targetIsInformedByAndOrAlignedWithKunmingmontrealGlobalBiodiversityFramework:
            "Das Ziel wird vom Kunming-Montreal Global Biodiversity Framework beeinflusst und/oder ist damit abgestimmt",
          targetIsInformedByAndOrAlignedWithRelevantAspectsOfEuBiodiversityStrategyFor2030:
            "Das Ziel wird von relevanten Aspekten der EU-Biodiversitätsstrategie 2030 beeinflusst und/oder ist damit abgestimmt",
          targetIsInformedByAndOrAlignedWithOtherBiodiversityAndEcosystemrelatedNationalPoliciesAndLegislation:
            "Das Ziel wird von anderen nationalen Richtlinien und Gesetzen im Bereich Biodiversität und Ökosysteme beeinflusst und/oder ist damit abgestimmt",
          biodiversityOffsetsWereUsedInSettingTarget:
            "Biodiversitätsausgleichsmaßnahmen wurden bei der Festlegung des Ziels verwendet",
          layersInMitigationHierarchyToWhichTargetCanBeAllocatedBiodiversityAndEcosystems:
            "Ebene in der Minderungs-Hierarchie, der das Ziel zugeordnet werden kann (Biodiversität und Ökosysteme)",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForBiodiversity:
            "Das Ziel für Biodiversität und Ökosysteme behebt Mängel in Bezug auf die wesentlichen Beitragskriterien für Biodiversität",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForBiodiversity:
            "Das Ziel für Biodiversität und Ökosysteme behebt Mängel in Bezug auf die Kriterien zur Vermeidung signifikanter Schäden für Biodiversität",
        },
        e5: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          targetRelatesToIncreaseOfCircularProductDesign:
            "Das Ziel bezieht sich auf die Erhöhung des kreislauforientierten Produktdesigns",
          targetRelatesToIncreaseOfCircularMaterialUseRate:
            "Das Ziel bezieht sich auf die Erhöhung der Rate der kreislauforientierten Materialnutzung",
          targetRelatesToMinimisationOfPrimaryRawMaterial:
            "Das Ziel bezieht sich auf die Minimierung des Einsatzes von Primärrohstoffen",
          targetRelatesToSustainableSourcingAndOrUseOfRenewableResources:
            "Das Ziel bezieht sich auf die nachhaltige Beschaffung und Nutzung erneuerbarer Ressourcen",
          targetRelatesToWasteManagement: "Das Ziel bezieht sich auf Abfallmanagement",
          layersInWasteHierarchyToWhichTargetRelates:
            "Ebene in der Abfallhierarchie, auf die sich das Ziel bezieht",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForCircularEconomy:
            "Das Ziel für Ressourcennutzung und Kreislaufwirtschaft behebt Mängel in Bezug auf die wesentlichen Beitragskriterien für die Kreislaufwirtschaft",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForCircularEconomy:
            "Das Ziel für Ressourcennutzung und Kreislaufwirtschaft behebt Mängel in Bezug auf die Kriterien zur Vermeidung signifikanter Schäden für die Kreislaufwirtschaft",
        },
      },
      s: {
        targetRelationshipToImpactsRisksAndOpportunities:
          "Zielbeziehung zu Auswirkungen, Risiken und Chancen",

        s1: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Offenlegung der beabsichtigten Ergebnisse, die im Leben der Arbeitskräfte des Unternehmens erreicht werden sollen",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Offenlegung der Stabilität des Ziels im Laufe der Zeit in Bezug auf Definitionen und Methodologien zur Ermöglichung der Vergleichbarkeit",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Offenlegung der Standards oder Verpflichtungen, auf denen das Ziel basiert",
        },
        s2: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Offenlegung der beabsichtigten Ergebnisse, die im Leben der Arbeitnehmer in der Wertschöpfungskette erreicht werden sollen",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Offenlegung der Stabilität des Ziels im Laufe der Zeit in Bezug auf Definitionen und Methodologien zur Ermöglichung der Vergleichbarkeit",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Offenlegung der Standards oder Verpflichtungen, auf denen das Ziel basiert",
        },
        s3: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Offenlegung der beabsichtigten Ergebnisse, die im Leben der betroffenen Gemeinschaften erreicht werden sollen",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Offenlegung der Stabilität des Ziels im Laufe der Zeit in Bezug auf Definitionen und Methodologien zur Ermöglichung der Vergleichbarkeit",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Offenlegung der Standards oder Verpflichtungen, auf denen das Ziel basiert",
        },
        s4: {
          addressedMatters: "Nachhaltigkeitsthemen, die das Ziel addressiert",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Offenlegung der beabsichtigten Ergebnisse, die im Leben der Verbraucher und Endnutzer erreicht werden sollen",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Offenlegung der Stabilität des Ziels im Laufe der Zeit in Bezug auf Definitionen und Methodologien zur Ermöglichung der Vergleichbarkeit",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Offenlegung der Standards oder Verpflichtungen, auf denen das Ziel basiert, falls ",
        },
      },
    },
    enums: {
      general: {
        absoluteOrRelativeTarget: {
          "esrs:AbsoluteTargetMember": "Absolutes Ziel",
          "esrs:RelativeTargetMember": "Relatives Ziel",
        },
        esrsMetricsAndMonetaryAmountsUsedForTarget: {
          "esrs:AbsoluteValueOfLocationBasedScope2GreenhouseGasEmissionsReduction":
            "Absoluter Wert der standortbasierten Reduktion der Scope 2 Treibhausgasemissionen",
          "esrs:AbsoluteValueOfMarketBasedScope2GreenhouseGasEmissionsReduction":
            "Absoluter Wert der marktbezogenen Reduktion der Scope 2 Treibhausgasemissionen",
          "esrs:AbsoluteValueOfScope1GreenhouseGasEmissionsReduction":
            "Absoluter Wert der Reduktion der Scope 1 Treibhausgasemissionen",
          "esrs:AbsoluteValueOfScope3GreenhouseGasEmissionsReduction":
            "Absoluter Wert der Reduktion der Scope 3 Treibhausgasemissionen",
          "esrs:AbsoluteValueOfTotalGreenhouseGasEmissionsReduction":
            "Absoluter Wert der gesamten Reduktion der Treibhausgasemissionen",
          "esrs:AchievedGHGEmissionReductions": "Erzielte Reduktionen der Treibhausgasemissionen",
          "esrs:AdjustingItemsToAssetsAtMaterialPhysicalRiskInReconciliationWithFinancialStatement":
            "Anpassungsposten für Vermögenswerte mit wesentlichem physischem Risiko in der Abstimmung mit dem Finanzbericht",
          "esrs:AdjustingItemsToAssetsAtMaterialTransitionRiskInReconciliationWithFinancialStatement":
            "Anpassungsposten für Vermögenswerte mit wesentlichem Übergangsrisiko in der Abstimmung mit dem Finanzbericht",
          "esrs:AdjustingItemsToLiabilitiesAtMaterialTransitionRiskInReconciliationWithFinancialStatement":
            "Anpassungsposten für Verbindlichkeiten mit wesentlichem Übergangsrisiko in der Abstimmung mit dem Finanzbericht",
          "esrs:AdjustingItemsToNetRevenueAtMaterialPhysicalRiskInReconciliationWithFinancialStatement":
            "Anpassungsposten für Nettoerlöse mit wesentlichem physischem Risiko in der Abstimmung mit dem Finanzbericht",
          "esrs:AdjustingItemsToNetRevenueAtMaterialTransitionRiskInReconciliationWithFinancialStatement":
            "Anpassungsposten für Nettoerlöse mit wesentlichem Übergangsrisiko in der Abstimmung mit dem Finanzbericht",
          "esrs:AmountOfCarbonCreditsOutsideValueChainPlannedToBeCancelledInFuture":
            "Anzahl der Kohlenstoffzertifikate außerhalb der Wertschöpfungskette, die zukünftig annulliert werden sollen",
          "esrs:AmountOfCarbonCreditsOutsideValueChainThatAreVerifiedAgainstRecognisedQualityStandardsAndCancelled":
            "Anzahl der Kohlenstoffzertifikate außerhalb der Wertschöpfungskette, die nach anerkannten Qualitätsstandards geprüft und annulliert wurden",
          "esrs:AmountOfFinesForViolationOfAnticorruptionAndAntibriberyLaws":
            "Bußgelder für Verstöße gegen Anti-Korruptions- und Anti-Bestechungsgesetze",
          "esrs:AmountOfFinesPenaltiesAndCompensationForDamagesAsResultOfIncidentesAndComplaints":
            "Bußgelder, Strafen und Schadensersatz als Ergebnis von Vorfällen und Beschwerden",
          "esrs:AmountOfFinesPenaltiesAndCompensationForDamagesForSevereHumanRightsIncidentsConnectedToOwnWorkforce":
            "Bußgelder, Strafen und Schadensersatz für schwere Menschenrechtsverletzungen im Zusammenhang mit der eigenen Belegschaft",
          "esrs:AmountOfInternalAndExternalLobbyingExpenses":
            "Summe der internen und externen Lobbykosten",
          "esrs:AmountOfSubstancesOfConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured":
            "Menge besorgniserregender Stoffe, die während der Produktion erzeugt oder verwendet oder beschafft werden",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissions":
            "Menge besorgniserregender Stoffe, die Anlagen als Emissionen verlassen",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices":
            "Menge besorgniserregender Stoffe, die Anlagen als Emissionen, Produkte oder als Teil von Produkten oder Dienstleistungen verlassen",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsPartOfProducts":
            "Menge besorgniserregender Stoffe, die Anlagen als Teil von Produkten verlassen",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsProducts":
            "Menge besorgniserregender Stoffe, die Anlagen als Produkte verlassen",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsServices":
            "Menge besorgniserregender Stoffe, die Anlagen als Dienstleistungen verlassen",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured":
            "Menge besonders besorgniserregender Stoffe, die während der Produktion erzeugt oder verwendet oder beschafft werden",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissions":
            "Menge besonders besorgniserregender Stoffe, die Anlagen als Emissionen verlassen",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices":
            "Menge besonders besorgniserregender Stoffe, die Anlagen als Emissionen, Produkte oder als Teil von Produkten oder Dienstleistungen verlassen",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsPartOfProducts":
            "Menge besonders besorgniserregender Stoffe, die Anlagen als Teil von Produkten verlassen",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsProducts":
            "Menge besonders besorgniserregender Stoffe, die Anlagen als Produkte verlassen",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsServices":
            "Menge besonders besorgniserregender Stoffe, die Anlagen als Dienstleistungen verlassen",
          "esrs:AmountPaidForMembershipToLobbyingAssociations":
            "Beitrag für Mitgliedschaft in Lobbyverbänden",
          "esrs:AnnualRemunerationRatio": "Jährliches Vergütungsverhältnis",
          "esrs:AreaCoveredByInvasiveAlienSpecies":
            "Von invasiven gebietsfremden Arten bedeckte Fläche",
          "esrs:AreaOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting":
            "Fläche von Standorten im Eigentum, gepachtet oder verwaltet in oder nahe geschützter Gebiete oder wichtiger Biodiversitätsgebiete, die das Unternehmen negativ beeinflusst",
          "esrs:Assets": "Vermögenswerte",
          "esrs:AssetsAtAcuteMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Vermögenswerte mit akutem wesentlichem physischem Risiko vor Berücksichtigung von Anpassungsmaßnahmen an den Klimawandel",
          "esrs:AssetsAtChronicMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Vermögenswerte mit chronischem wesentlichem physischem Risiko vor Berücksichtigung von Anpassungsmaßnahmen an den Klimawandel",
          "esrs:AssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Vermögenswerte mit wesentlichem physischem Risiko vor Berücksichtigung von Anpassungsmaßnahmen an den Klimawandel",
          "esrs:AssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions":
            "Vermögenswerte mit wesentlichem Übergangsrisiko vor Berücksichtigung von Klimaschutzmaßnahmen",
          "esrs:AverageNumberOfDaysToPayInvoiceFromDateWhenContractualOrStatutoryTermOfPaymentStartsToBeCalculated":
            "Durchschnittliche Anzahl der Tage zur Rechnungszahlung ab dem Zeitpunkt, an dem die vertragliche oder gesetzliche Zahlungsfrist beginnt",
          "esrs:AverageNumberOfTrainingHoursPerEmployeeAndOrNonemployee":
            "Durchschnittliche Anzahl der Schulungsstunden pro Mitarbeiter und (oder) Nicht-Mitarbeiter",
          "esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope1GHGEmissions":
            "Biogene CO₂-Emissionen aus der Verbrennung oder dem biologischen Abbau von Biomasse, die nicht in den Scope 1 Treibhausgasemissionen enthalten sind",
          "esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope2GHGEmissions":
            "Biogene CO₂-Emissionen aus der Verbrennung oder dem biologischen Abbau von Biomasse, die nicht in den Scope 2 Treibhausgasemissionen enthalten sind",
          "esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassThatOccurInUpstreamAndDownstreamValueChainNotIncludedInScope3GHGEmissions":
            "Biogene CO₂-Emissionen aus der Verbrennung oder dem biologischen Abbau von Biomasse in der vor- und nachgelagerten Wertschöpfungskette, die nicht in den Scope 3 Treibhausgasemissionen enthalten sind",
          "esrs:BoardsGenderDiversityRatio": "Geschlechterdiversitätsverhältnis des Vorstands",
          "esrs:CapitalExpendituresCapExInConjunctionWithMajorIncidentsAndDeposits":
            "Investitionsausgaben (CapEx) im Zusammenhang mit größeren Vorfällen und Ablagerungen",
          "esrs:CarbonPriceAppliedForEachMetricTonneOfGreenhouseGasEmission":
            "Angewandter Kohlenstoffpreis pro Tonne Treibhausgasemission",
          "esrs:CarryingAmountOfAssetsAtMaterialPhysicalRisk":
            "Buchwert der Vermögenswerte mit wesentlichem physischem Risiko",
          "esrs:CarryingAmountOfAssetsAtMaterialTransitionRisk":
            "Buchwert der Vermögenswerte mit wesentlichem Übergangsrisiko",
          "esrs:CarryingAmountOfLiabilitiesAtMaterialTransitionRisk":
            "Buchwert der Verbindlichkeiten mit wesentlichem Übergangsrisiko",
          "esrs:CarryingAmountOfRealEstateAssets": "Buchwert der Immobilienvermögenswerte",
          "esrs:CarryingAmountOfRealEstateAssetsForWhichEnergyConsumptionIsBasedOnInternalEstimates":
            "Buchwert der Immobilienvermögenswerte, deren Energieverbrauch auf internen Schätzungen basiert",
          "esrs:ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamAndCoolingFromRenewableSources":
            "Verbrauch von zugekauftem oder erworbenem Strom, Wärme, Dampf und Kühlung aus erneuerbaren Quellen",
          "esrs:ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamOrCoolingFromFossilSources":
            "Verbrauch von zugekauftem oder erworbenem Strom, Wärme, Dampf oder Kühlung aus fossilen Quellen",
          "esrs:ConsumptionOfSelfgeneratedNonfuelRenewableEnergy":
            "Verbrauch von selbst erzeugter nicht-fossiler erneuerbarer Energie",
          "esrs:DurationOfClassroomTraining": "Dauer der Präsenzschulung",
          "esrs:DurationOfComputerbasedTraining": "Dauer der computergestützten Schulung",
          "esrs:DurationOfVoluntaryComputerbasedTraining":
            "Dauer der freiwilligen computergestützten Schulung",
          "esrs:EmissionsToAir": "Emissionen in die Luft",
          "esrs:EmissionsToSoil": "Emissionen in den Boden",
          "esrs:EmissionsToWater": "Emissionen ins Wasser",
          "esrs:EnergyConsumptionFromFossilSources": "Energieverbrauch aus fossilen Quellen",
          "esrs:EnergyConsumptionFromNuclearSources": "Energieverbrauch aus nuklearen Quellen",
          "esrs:EnergyConsumptionFromRenewableSources": "Energieverbrauch aus erneuerbaren Quellen",
          "esrs:EnergyConsumptionRelatedToOwnOperations":
            "Energieverbrauch im Zusammenhang mit eigenen Betriebsprozessen",
          "esrs:EnergyIntensityFromActivitiesInHighClimateImpactSectorsTotalEnergyConsumptionPerNetRevenue":
            "Energieintensität aus Aktivitäten in Sektoren mit hoher Klimabelastung (Gesamtenergieverbrauch pro Nettoumsatz)",
          "esrs:EstimatedAmountOfPotentiallyStrandedAssets":
            "Geschätzter Betrag potenziell blockierter Vermögenswerte",
          "esrs:ExpectedCostSavingsFromClimateChangeAdaptationActions":
            "Erwartete Kosteneinsparungen durch Maßnahmen zur Anpassung an den Klimawandel",
          "esrs:ExpectedCostSavingsFromClimateChangeMitigationActions":
            "Erwartete Kosteneinsparungen durch Maßnahmen zur Minderung des Klimawandels",
          "esrs:ExpectedGHGEmissionReductions": "Erwartete Reduktionen der Treibhausgasemissionen",
          "esrs:FinancialAndInKindPoliticalContributionsMade":
            "Finanzielle und sachbezogene politische Beiträge",
          "esrs:FinancialPoliticalContributionsMade": "Finanzielle politische Beiträge",
          "esrs:FinancingEffectsDirectAndIndirectCostsOfBiodiversityOffsets":
            "Finanzierungseffekte (direkte und indirekte Kosten) von Biodiversitätsausgleichen",
          "esrs:FuelConsumptionFromCoalAndCoalProducts":
            "Kraftstoffverbrauch aus Kohle und Kohleprodukten",
          "esrs:FuelConsumptionFromCrudeOilAndPetroleumProducts":
            "Kraftstoffverbrauch aus Rohöl und Erdölprodukten",
          "esrs:FuelConsumptionFromNaturalGas": "Kraftstoffverbrauch aus Erdgas",
          "esrs:FuelConsumptionFromOtherFossilSources":
            "Kraftstoffverbrauch aus anderen fossilen Quellen",
          "esrs:FuelConsumptionFromRenewableSources":
            "Kraftstoffverbrauch aus erneuerbaren Quellen",
          "esrs:GenderPayGap": "GenderPayGap",
          "esrs:GHGEmissionsAssociatedWithRemovalActivity":
            "Treibhausgasemissionen im Zusammenhang mit Entfernungsaktivitäten",
          "esrs:GHGEmissionsIntensityLocationbasedTotalGHGEmissionsPerNetRevenue":
            "Treibhausgasemissionsintensität, standortbasiert (Gesamtemissionen pro Nettoumsatz)",
          "esrs:GHGEmissionsIntensityMarketbasedTotalGHGEmissionsPerNetRevenue":
            "Treibhausgasemissionsintensität, marktbezogen (Gesamtemissionen pro Nettoumsatz)",
          "esrs:GHGRemovalsAndStorage": "Entfernung und Speicherung von Treibhausgasen",
          "esrs:GrossGreenhouseGasEmissions": "Brutto-Treibhausgasemissionen",
          "esrs:GrossLocationBasedScope2GreenhouseGasEmissions":
            "Brutto standortbasierte Scope 2 Treibhausgasemissionen",
          "esrs:GrossMarketBasedScope2GreenhouseGasEmissions":
            "Brutto marktbezogene Scope 2 Treibhausgasemissionen",
          "esrs:GrossScope1GreenhouseGasEmissions": "Brutto Scope 1 Treibhausgasemissionen",
          "esrs:GrossScope3GreenhouseGasEmissions": "Brutto Scope 3 Treibhausgasemissionen",
          "esrs:HazardousWaste": "Gefährlicher Abfall",
          "esrs:IncreaseDecreaseInNetRevenueFromLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess":
            "Zunahme (Abnahme) des Nettoumsatzes aus kohlenstoffarmen Produkten und Dienstleistungen oder Anpassungslösungen, auf die das Unternehmen Zugriff hat oder haben könnte",
          "esrs:IncreaseDecreaseInWaterStorage": "Zunahme (Abnahme) der Wasserspeicherung",
          "esrs:InkindPoliticalContributionsMade": "Sachbezogene politische Beiträge",
          "esrs:IntensityValueOfLocationBasedScope2GreenhouseGasEmissionsReduction":
            "Intensitätswert der standortbasierten Reduktion der Scope 2 Treibhausgasemissionen",
          "esrs:IntensityValueOfMarketBasedScope2GreenhouseGasEmissionsReduction":
            "Intensitätswert der marktbezogenen Reduktion der Scope 2 Treibhausgasemissionen",
          "esrs:IntensityValueOfScope1GreenhouseGasEmissionsReduction":
            "Intensitätswert der Reduktion der Scope 1 Treibhausgasemissionen",
          "esrs:IntensityValueOfScope3GreenhouseGasEmissionsReduction":
            "Intensitätswert der Reduktion der Scope 3 Treibhausgasemissionen",
          "esrs:IntensityValueOfTotalGreenhouseGasEmissionsReduction":
            "Intensitätswert der gesamten Reduktion der Treibhausgasemissionen",
          "esrs:Liabilities": "Verbindlichkeiten",
          "esrs:LiabilitiesFromMaterialTransitionRisksThatMayHaveToBeRecognisedInFinancialStatements":
            "Verbindlichkeiten aus wesentlichen Übergangsrisiken, die möglicherweise im Finanzbericht erfasst werden müssen",
          "esrs:LocationBasedGreenhouseGasEmissions": "Standortbasierte Treibhausgasemissionen",
          "esrs:MarketBasedGreenhouseGasEmissions": "Marktbezogene Treibhausgasemissionen",
          "esrs:MicroplasticsGenerated": "Erzeugtes Mikroplastik",
          "esrs:MicroplasticsGeneratedOrUsed": "Erzeugte oder verwendetes Mikroplastik",
          "esrs:MicroplasticsUsed": "Verwendetes Mikroplastik",
          "esrs:MonetisedGrossScope1And2GHGEmissions":
            "Monetarisierte Brutto Scope 1 und 2 Treibhausgasemissionen",
          "esrs:MonetisedTotalGHGEmissions": "Monetarisierte Gesamttreibhausgasemissionen",
          "esrs:NatureorientedAreaOffSite": "Naturorientiertes Gebiet außerhalb des Standorts",
          "esrs:NatureorientedAreaOnSite": "Naturorientiertes Gebiet am Standort",
          "esrs:NetRevenueAtMaterialPhysicalRisk": "Nettoumsatz mit wesentlichem physischem Risiko",
          "esrs:NetRevenueAtMaterialTransitionRisk": "Nettoumsatz mit wesentlichem Übergangsrisiko",
          "esrs:NetRevenueOtherThanUsedToCalculateGHGIntensity":
            "Nettoumsatz ohne Berücksichtigung zur Berechnung der Treibhausgasintensität",
          "esrs:NetRevenueUsedToCalculateGHGIntensity":
            "Nettoumsatz zur Berechnung der Treibhausgasintensität",
          "esrs:NonrecycledWaste": "Nicht recycelter Abfall",
          "esrs:NonrenewableEnergyProduction": "Nicht erneuerbare Energieproduktion",
          "esrs:NumberOfCasesOfRecordableWorkrelatedIllHealth":
            "Anzahl der meldepflichtigen Fälle arbeitsbedingter Gesundheitsprobleme",
          "esrs:NumberOfComplaintsFiledThroughChannelsForPeopleInOwnWorkforceToRaiseConcerns":
            "Anzahl der Beschwerden, die über Kanäle für die Belegschaft zur Meldung von Bedenken eingereicht wurden",
          "esrs:NumberOfComplaintsFiledToNationalContactPointsForOECDMultinationalEnterprises":
            "Anzahl der Beschwerden an nationale Kontaktstellen für OECD-Multinationalunternehmen",
          "esrs:NumberOfComplaintsReceivedFromConsumersAndEndusers":
            "Anzahl der Beschwerden von Verbrauchern und Endnutzern",
          "esrs:NumberOfConfirmedIncidentsInWhichOwnWorkersWereDismissedOrDisciplinedForCorruptionOrBriberyrelatedIncidents":
            "Anzahl der bestätigten Fälle, in denen eigene Mitarbeiter aufgrund von Korruptions- oder Bestechungsvorfällen entlassen oder diszipliniert wurden",
          "esrs:NumberOfConfirmedIncidentsOfCorruptionOrBribery":
            "Anzahl der bestätigten Korruptions- oder Bestechungsvorfälle",
          "esrs:NumberOfConfirmedIncidentsRelatingToContractsWithBusinessPartnersThatWereTerminatedOrNotRenewedDueToViolationsRelatedToCorruptionOrBribery":
            "Anzahl bestätigter Vorfälle im Zusammenhang mit Verträgen mit Geschäftspartnern, die aufgrund von Verstößen gegen Korruptions- oder Bestechungsregelungen gekündigt oder nicht verlängert wurden",
          "esrs:NumberOfConvictionsForViolationOfAnticorruptionAndAntibriberyLaws":
            "Anzahl der Verurteilungen wegen Verstoßes gegen Antikorruptions- und Anti-Bestechungsgesetze",
          "esrs:NumberOfDaysLostToWorkrelatedInjuriesAndFatalitiesFromWorkrelatedAccidentsWorkrelatedIllHealthAndFatalitiesFromIllHealth":
            "Anzahl der Ausfalltage aufgrund arbeitsbedingter Verletzungen, Todesfälle, Arbeitsunfälle, arbeitsbedingter Gesundheitsprobleme und Todesfälle durch Gesundheitsprobleme",
          "esrs:NumberOfEmissionAllowancesStoredFromPreviousAllowancesAtBeginningOfReportingPeriod":
            "Anzahl der am Anfang des Berichtszeitraums gespeicherten Emissionszertifikate aus früheren Zertifikaten",
          "esrs:NumberOfEmployeesHeadcountAtEndOfPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) am Ende des Berichtszeitraums",
          "esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) auf Top-Management-Ebene am Ende des Berichtszeitraums",
          "esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) auf Top-Management-Ebene im Berichtszeitraum",
          "esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldAtEndOfPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) zwischen 30 und 50 Jahren am Ende des Berichtszeitraums",
          "esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) zwischen 30 und 50 Jahren im Berichtszeitraum",
          "esrs:NumberOfEmployeesHeadCountDuringPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) im Berichtszeitraum",
          "esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalentAtEndOfPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl oder Vollzeitäquivalent) am Ende des Berichtszeitraums",
          "esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalentDuringPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl oder Vollzeitäquivalent) im Berichtszeitraum",
          "esrs:NumberOfEmployeesHeadCountOver50YearsOldAtEndOfPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) über 50 Jahre am Ende des Berichtszeitraums",
          "esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) über 50 Jahre im Berichtszeitraum",
          "esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) unter 30 Jahren am Ende des Berichtszeitraums",
          "esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod":
            "Anzahl der Mitarbeiter (Kopfzahl) unter 30 Jahren im Berichtszeitraum",
          "esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesAtEndOfPeriod":
            "Anzahl der Mitarbeiter in Ländern mit 50 oder mehr Mitarbeitern, die mindestens 10% der Gesamtzahl der Mitarbeiter am Ende des Berichtszeitraums repräsentieren",
          "esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesDuringPeriod":
            "Anzahl der Mitarbeiter in Ländern mit 50 oder mehr Mitarbeitern, die mindestens 10% der Gesamtzahl der Mitarbeiter im Berichtszeitraum repräsentieren",
          "esrs:NumberOfEmployeesReceivingAnticorruptionAndAntibriberyTrainingDuringPeriod":
            "Anzahl der Mitarbeiter, die während des Berichtszeitraums eine Antikorruptions- und Anti-Bestechungsschulung erhielten",
          "esrs:NumberOfEmployeesWhoHaveLeftUndertakingDuringPeriod":
            "Anzahl der Mitarbeiter, die das Unternehmen während des Berichtszeitraums verlassen haben",
          "esrs:NumberOfExecutiveMembers": "Anzahl der Geschäftsführungsmitglieder",
          "esrs:NumberOfFatalitiesAsResultOfWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites":
            "Anzahl der Todesfälle aufgrund arbeitsbedingter Gesundheitsprobleme von anderen Arbeitern auf den Standorten des Unternehmens",
          "esrs:NumberOfFatalitiesAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites":
            "Anzahl der Todesfälle aufgrund arbeitsbedingter Verletzungen und Gesundheitsprobleme von anderen Arbeitern auf den Standorten des Unternehmens",
          "esrs:NumberOfFatalitiesAsResultOfWorkrelatedInjuriesOfOtherWorkersWorkingOnUndertakingsSites":
            "Anzahl der Todesfälle aufgrund arbeitsbedingter Verletzungen von anderen Arbeitern auf den Standorten des Unternehmens",
          "esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedIllHealth":
            "Anzahl der Todesfälle in der eigenen Belegschaft aufgrund arbeitsbedingter Gesundheitsprobleme",
          "esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuries":
            "Anzahl der Todesfälle in der eigenen Belegschaft aufgrund arbeitsbedingter Verletzungen",
          "esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealth":
            "Anzahl der Todesfälle in der eigenen Belegschaft aufgrund arbeitsbedingter Verletzungen und Gesundheitsprobleme",
          "esrs:NumberOfIncidentsOfDiscrimination": "Anzahl der Diskriminierungsvorfälle",
          "esrs:NumberOfInvasiveAlienSpecies": "Anzahl invasiver gebietsfremder Arten",
          "esrs:NumberOfLegalProceedingsOutstandingForLatePayments":
            "Anzahl der laufenden Gerichtsverfahren aufgrund verspäteter Zahlungen",
          "esrs:NumberOfNonemployeesInOwnWorkforceAtEndOfPeriod":
            "Anzahl der Nicht-Mitarbeiter in der eigenen Belegschaft am Ende des Berichtszeitraums",
          "esrs:NumberOfNonemployeesInOwnWorkforceDuringPeriod":
            "Anzahl der Nicht-Mitarbeiter in der eigenen Belegschaft während des Berichtszeitraums",
          "esrs:NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesAtEndOfPeriod":
            "Anzahl der Nicht-Mitarbeiter in der eigenen Belegschaft – Personen, die von Unternehmen bereitgestellt werden, die hauptsächlich in Beschäftigungsaktivitäten tätig sind, am Ende des Berichtszeitraums",
          "esrs:NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesDuringPeriod":
            "Anzahl der Nicht-Mitarbeiter in der eigenen Belegschaft – Personen, die von Unternehmen bereitgestellt werden, die hauptsächlich in Beschäftigungsaktivitäten tätig sind, während des Berichtszeitraums",
          "esrs:NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleAtEndOfPeriod":
            "Anzahl der Nicht-Mitarbeiter in der eigenen Belegschaft – Selbstständige am Ende des Berichtszeitraums",
          "esrs:NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleDuringPeriod":
            "Anzahl der Nicht-Mitarbeiter in der eigenen Belegschaft – Selbstständige während des Berichtszeitraums",
          "esrs:NumberOfNonexecutiveMembers": "Anzahl der nicht geschäftsführenden Mitglieder",
          "esrs:NumberOfRecordableWorkrelatedAccidents":
            "Anzahl der meldepflichtigen arbeitsbedingten Unfälle",
          "esrs:NumberOfScope1GHGEmissionAllowancesWithinRegulatedEmissionTradingSchemes":
            "Anzahl der Scope 1 Treibhausgasemissionszertifikate im Rahmen regulierter Emissionshandelssysteme",
          "esrs:NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforce":
            "Anzahl schwerwiegender Menschenrechtsvorfälle in Verbindung mit der eigenen Belegschaft",
          "esrs:NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforceThatAreNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprises":
            "Anzahl schwerwiegender Menschenrechtsvorfälle in Verbindung mit der eigenen Belegschaft, die den UN-Leitprinzipien für Wirtschaft und Menschenrechte, der IAO-Erklärung über grundlegende Prinzipien und Rechte bei der Arbeit oder den OECD-Leitlinien für multinationale Unternehmen nicht entsprechen",
          "esrs:NumberOfSevereHumanRightsIncidentsWhereUndertakingPlayedRoleSecuringRemedyForThoseAffected":
            "Anzahl schwerwiegender Menschenrechtsvorfälle, bei denen das Unternehmen eine Rolle bei der Sicherstellung von Abhilfemaßnahmen für die Betroffenen spielte",
          "esrs:NumberOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting":
            "Anzahl der Standorte, die sich im Besitz des Unternehmens befinden, geleast oder verwaltet werden und in oder in der Nähe von Schutzgebieten oder wichtigen Biodiversitätsgebieten liegen, die das Unternehmen negativ beeinflusst",
          "esrs:OperatingExpendituresOpExInConjunctionWithMajorIncidentsAndDeposits":
            "Betriebsausgaben (OpEx) im Zusammenhang mit größeren Vorfällen und Ablagerungen",
          "esrs:PercentageOfApproximateGrossScope1GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme":
            "Prozentsatz der ungefähren Brutto Scope 1 Treibhausgasemissionen, die durch ein internes CO₂-Bepreisungsschema abgedeckt sind",
          "esrs:PercentageOfApproximateGrossScope2GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme":
            "Prozentsatz der ungefähren Brutto Scope 2 Treibhausgasemissionen, die durch ein internes CO₂-Bepreisungsschema abgedeckt sind",
          "esrs:PercentageOfApproximateGrossScope3GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme":
            "Prozentsatz der ungefähren Brutto Scope 3 Treibhausgasemissionen, die durch ein internes CO₂-Bepreisungsschema abgedeckt sind",
          "esrs:PercentageOfAssetsAtMaterialPhysicalRiskAddressedByClimateChangeAdaptationActions":
            "Prozentsatz der Vermögenswerte mit wesentlichem physischem Risiko, die durch Maßnahmen zur Anpassung an den Klimawandel angesprochen werden",
          "esrs:PercentageOfAssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Prozentsatz der Vermögenswerte mit wesentlichem physischem Risiko vor Berücksichtigung von Maßnahmen zur Anpassung an den Klimawandel",
          "esrs:PercentageOfAssetsAtMaterialTransitionRiskAddressedByClimateChangeMitigationActions":
            "Prozentsatz der Vermögenswerte mit wesentlichem Übergangsrisiko, die durch Maßnahmen zur Minderung des Klimawandels angesprochen werden",
          "esrs:PercentageOfAssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions":
            "Prozentsatz der Vermögenswerte mit wesentlichem Übergangsrisiko vor Berücksichtigung von Klimaschutzmaßnahmen",
          "esrs:PercentageOfBiologicalMaterialsUsedToManufactureProductsAndServicesThatAreSustainablySourced":
            "Prozentsatz der biologischen Materialien zur Herstellung von Produkten und Dienstleistungen, die nachhaltig bezogen werden",
          "esrs:PercentageOfCarbonCreditsCancelledForRecognisedQualityStandard":
            "Prozentsatz der Kohlenstoffzertifikate, die gemäß anerkannten Qualitätsstandards storniert wurden",
          "esrs:PercentageOfCarbonCreditsCancelledFromProjectsInEuropeanUnion":
            "Prozentsatz der Kohlenstoffzertifikate aus Projekten in der Europäischen Union, die storniert wurden",
          "esrs:PercentageOfCarbonCreditsCancelledFromReductionProjects":
            "Prozentsatz der Kohlenstoffzertifikate aus Reduktionsprojekten, die storniert wurden",
          "esrs:PercentageOfCarbonCreditsCancelledFromRemovalProjects":
            "Prozentsatz der Kohlenstoffzertifikate aus Entfernungsprojekten, die storniert wurden",
          "esrs:PercentageOfCarbonCreditsCancelledThatQualifiesAsCorrespondingAdjustment":
            "Prozentsatz der Kohlenstoffzertifikate, die als entsprechende Anpassung storniert wurden",
          "esrs:PercentageOfContractualInstrumentsScope2GHGEmissions":
            "Prozentsatz der vertraglichen Instrumente, Scope 2 Treibhausgasemissionen",
          "esrs:PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfEnergyBundledWithAttributesAboutEnergyGenerationInRelationToScope2GHGEmissions":
            "Prozentsatz der vertraglichen Instrumente für den Verkauf und Kauf von Energie, die mit Attributen zur Energieerzeugung im Zusammenhang mit Scope 2 Treibhausgasemissionen gebündelt sind",
          "esrs:PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfUnbundledEnergyAttributeClaimsInRelationToScope2GHGEmissions":
            "Prozentsatz der vertraglichen Instrumente für den Verkauf und Kauf von nicht gebündelten Energieattributsansprüchen im Zusammenhang mit Scope 2 Treibhausgasemissionen",
          "esrs:PercentageOfDataObtainedFromBestEstimatesWaterConsumption":
            "Prozentsatz der Daten aus besten Schätzungen (Wasserverbrauch)",
          "esrs:PercentageOfDataObtainedFromDirectMeasurementWaterConsumption":
            "Prozentsatz der Daten aus direkten Messungen (Wasserverbrauch)",
          "esrs:PercentageOfDataObtainedFromSamplingAndExtrapolationWaterConsumption":
            "Prozentsatz der Daten aus Stichproben und Extrapolationen (Wasserverbrauch)",
          "esrs:PercentageOfEmployeesAndOrNonemployeesPaidBelowApplicableAdequateWageBenchmark":
            "Prozentsatz der Mitarbeiter und/oder Nicht-Mitarbeiter, die unterhalb der anwendbaren angemessenen Lohnbenchmark bezahlt werden",
          "esrs:PercentageOfEmployeesAndOrNonemployeesThatParticipatedInRegularPerformanceAndCareerDevelopmentReviews":
            "Prozentsatz der Mitarbeiter und/oder Nicht-Mitarbeiter, die an regelmäßigen Leistungs- und Karriereentwicklungsgesprächen teilgenommen haben",
          "esrs:PercentageOfEmployeesAtTopManagementLevel":
            "Prozentsatz der Mitarbeiter auf Top-Management-Ebene",
          "esrs:PercentageOfEmployeesBetween30And50YearsOld":
            "Prozentsatz der Mitarbeiter im Alter zwischen 30 und 50 Jahren",
          "esrs:PercentageOfEmployeesEntitledToTakeFamilyrelatedLeaves":
            "Prozentsatz der Mitarbeiter, die Anspruch auf familienbedingte Freistellungen haben",
          "esrs:PercentageOfEmployeesOver50YearsOld": "Prozentsatz der Mitarbeiter über 50 Jahre",
          "esrs:PercentageOfEmployeesUnder30YearsOld": "Prozentsatz der Mitarbeiter unter 30 Jahre",
          "esrs:PercentageOfEmployeeTurnover": "Prozentsatz der Mitarbeiterfluktuation",
          "esrs:PercentageOfEnergyConsumptionFromNuclearSourcesInTotalEnergyConsumption":
            "Prozentsatz des Energieverbrauchs aus Kernenergie am Gesamtenergieverbrauch",
          "esrs:PercentageOfEntitledEmployeesThatTookFamilyrelatedLeave":
            "Prozentsatz der berechtigten Mitarbeiter, die familienbedingten Urlaub genommen haben",
          "esrs:PercentageOfEstimatedShareOfPotentiallyStrandedAssetsOfTotalAssetsAtMaterialTransitionRisk":
            "Prozentsatz des geschätzten Anteils potenziell strandeter Vermögenswerte an den Gesamtvermögenswerten mit wesentlichem Übergangsrisiko",
          "esrs:PercentageOfFossilSourcesInTotalEnergyConsumption":
            "Prozentsatz fossiler Quellen am Gesamtenergieverbrauch",
          "esrs:PercentageOfFunctionsatriskCoveredByAnticorruptionAndAntibriberyTrainingProgrammes":
            "Prozentsatz der gefährdeten Funktionen, die durch Antikorruptions- und Anti-Bestechungsschulungsprogramme abgedeckt sind",
          "esrs:PercentageOfGrossScope3GreenhouseGasEmissionsCalculatedUsingPrimaryDataObtainedFromSuppliersOrOtherValueChainPartners":
            "Prozentsatz der Brutto Scope 3 Treibhausgasemissionen, die unter Verwendung von Primärdaten aus Lieferanten oder anderen Wertschöpfungskettenpartnern berechnet wurden",
          "esrs:PercentageOfIndependentBoardMembers":
            "Prozentsatz der unabhängigen Vorstandsmitglieder",
          "esrs:PercentageOfLocationBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Prozentsatz der ortsbezogenen Scope 2 Treibhausgasemissionsreduktion im Vergleich zu den Emissionen des Basisjahres",
          "esrs:PercentageOfMarketbasedScope2GHGEmissionsLinkedToPurchasedElectricityBundledWithInstruments":
            "Prozentsatz der marktbasierten Scope 2 Treibhausgasemissionen, die mit gekaufter und gebündelter Elektrizität verknüpft sind",
          "esrs:PercentageOfMarketBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Prozentsatz der marktbasierten Scope 2 Treibhausgasemissionsreduktion im Vergleich zu den Emissionen des Basisjahres",
          "esrs:PercentageOfMembersOfAdministrativeManagementAndSupervisoryBodies":
            "Prozentsatz der Mitglieder von Verwaltungs-, Leitungs- und Aufsichtsgremien",
          "esrs:PercentageOfNetRevenueFromBusinessActivitiesAtMaterialPhysicalRisk":
            "Prozentsatz des Nettoumsatzes aus Geschäftstätigkeiten mit wesentlichem physischem Risiko",
          "esrs:PercentageOfNetRevenueFromBusinessActivitiesAtMaterialTransitionRisk":
            "Prozentsatz des Nettoumsatzes aus Geschäftstätigkeiten mit wesentlichem Übergangsrisiko",
          "esrs:PercentageOfNetRevenueFromCustomersOperatingInCoalrelatedActivities":
            "Prozentsatz des Nettoumsatzes von Kunden, die in kohlebezogenen Aktivitäten tätig sind",
          "esrs:PercentageOfNetRevenueFromCustomersOperatingInGasrelatedActivities":
            "Prozentsatz des Nettoumsatzes von Kunden, die in gasbezogenen Aktivitäten tätig sind",
          "esrs:PercentageOfNetRevenueFromCustomersOperatingInOilrelatedActivities":
            "Prozentsatz des Nettoumsatzes von Kunden, die in ölbezogenen Aktivitäten tätig sind",
          "esrs:PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfConcern":
            "Prozentsatz des Nettoumsatzes mit Produkten und Dienstleistungen, die besorgniserregende Stoffe enthalten",
          "esrs:PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfVeryHighConcern":
            "Prozentsatz des Nettoumsatzes mit Produkten und Dienstleistungen, die Stoffe mit sehr hohem Besorgnisgrad enthalten",
          "esrs:PercentageOfNonemployeesInOwnWorkforceWhoseWorkingConditionsAndTermsOfEmploymentAreDeterminedOrInfluencedByCollectiveBargainingAgreements":
            "Prozentsatz der Nicht-Mitarbeiter in der eigenen Belegschaft, deren Arbeitsbedingungen und Beschäftigungsbedingungen durch Tarifverträge bestimmt oder beeinflusst werden",
          "esrs:PercentageOfNonrecycledWaste": "Prozentsatz des nicht recycelten Abfalls",
          "esrs:PercentageOfOwnWorkersCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelinesAndWhichHasBeenInternallyAuditedAndOrAuditedOrCertifiedByExternalParty":
            "Prozentsatz der eigenen Arbeitnehmer, die durch ein Gesundheits- und Sicherheitsmanagementsystem abgedeckt sind, das auf gesetzlichen Anforderungen und/oder anerkannten Standards oder Richtlinien basiert und intern und/oder extern auditiert oder zertifiziert wurde",
          "esrs:PercentageOfPaymentsAlignedWithStandardPaymentTerms":
            "Prozentsatz der Zahlungen im Einklang mit den Standard-Zahlungsbedingungen",
          "esrs:PercentageOfPeopleInOwnWorkforceWhoAreCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelines":
            "Prozentsatz der Personen in der eigenen Belegschaft, die durch ein Gesundheits- und Sicherheitsmanagementsystem abgedeckt sind, das auf gesetzlichen Anforderungen und/oder anerkannten Standards oder Richtlinien basiert",
          "esrs:PercentageOfPersonsWithDisabilitiesAmongstEmployees":
            "Prozentsatz der Mitarbeiter mit Behinderungen",
          "esrs:PercentageOfRemunerationRecognisedInCurrentPeriodThatIsLinkedToClimateRelatedConsiderations":
            "Prozentsatz der im aktuellen Zeitraum anerkannten Vergütung, die an klimabezogene Überlegungen gebunden ist",
          "esrs:PercentageOfRenewableSourcesInTotalEnergyConsumption":
            "Prozentsatz der erneuerbaren Quellen am Gesamtenergieverbrauch",
          "esrs:PercentageOfScope1GreenhouseGasEmissionsFromRegulatedEmissionTradingSchemes":
            "Prozentsatz der Scope 1 Treibhausgasemissionen aus regulierten Emissionshandelssystemen",
          "esrs:PercentageOfScope1GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Prozentsatz der Scope 1 Treibhausgasemissionsreduktion im Vergleich zu den Emissionen des Basisjahres",
          "esrs:PercentageOfScope1GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Prozentsatz der Scope 1 Treibhausgasemissionsreduktion an der Gesamt-Treibhausgasemissionsreduktion",
          "esrs:PercentageOfScope2LocationbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Prozentsatz der ortsbezogenen Scope 2 Treibhausgasemissionsreduktion an der Gesamt-Treibhausgasemissionsreduktion",
          "esrs:PercentageOfScope2MarketbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Prozentsatz der marktbasierten Scope 2 Treibhausgasemissionsreduktion an der Gesamt-Treibhausgasemissionsreduktion",
          "esrs:PercentageOfScope3GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Prozentsatz der Scope 3 Treibhausgasemissionsreduktion im Vergleich zu den Emissionen des Basisjahres",
          "esrs:PercentageOfScope3GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Prozentsatz der Scope 3 Treibhausgasemissionsreduktion an der Gesamt-Treibhausgasemissionsreduktion",
          "esrs:PercentageOfSecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices":
            "Prozentsatz der zur Herstellung von Produkten und Dienstleistungen verwendeten sekundären wiederverwendeten oder recycelten Komponenten, sekundären Zwischenprodukte und sekundären Materialien",
          "esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissions":
            "Prozentsatz des Ziels in Bezug auf ortsbezogene Scope 2 Treibhausgasemissionen",
          "esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Prozentsatz des Ziels in Bezug auf ortsbezogene Scope 2 Treibhausgasemissionen (im Vergleich zu den Emissionen des Basisjahres)",
          "esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsIntensityValue":
            "Prozentsatz des Ziels in Bezug auf ortsbezogene Scope 2 Treibhausgasemissionen (Intensitätswert)",
          "esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissions":
            "Prozentsatz des Ziels in Bezug auf marktbasierten Scope 2 Treibhausgasemissionen",
          "esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Prozentsatz des Ziels in Bezug auf marktbasierten Scope 2 Treibhausgasemissionen (im Vergleich zu den Emissionen des Basisjahres)",
          "esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsIntensityValue":
            "Prozentsatz des Ziels in Bezug auf marktbasierten Scope 2 Treibhausgasemissionen (Intensitätswert)",
          "esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissions":
            "Prozentsatz des Ziels in Bezug auf Scope 1 Treibhausgasemissionen",
          "esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Prozentsatz des Ziels in Bezug auf Scope 1 Treibhausgasemissionen (im Vergleich zu den Emissionen des Basisjahres)",
          "esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsIntensityValue":
            "Prozentsatz des Ziels in Bezug auf Scope 1 Treibhausgasemissionen (Intensitätswert)",
          "esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissions":
            "Prozentsatz des Ziels in Bezug auf Scope 3 Treibhausgasemissionen",
          "esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Prozentsatz des Ziels in Bezug auf Scope 3 Treibhausgasemissionen (im Vergleich zu den Emissionen des Basisjahres)",
          "esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsIntensityValue":
            "Prozentsatz des Ziels in Bezug auf Scope 3 Treibhausgasemissionen (Intensitätswert)",
          "esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissions":
            "Prozentsatz des Ziels in Bezug auf die Gesamt-Treibhausgasemissionen",
          "esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Prozentsatz des Ziels in Bezug auf die Gesamt-Treibhausgasemissionen (im Vergleich zu den Emissionen des Basisjahres)",
          "esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsIntensityValue":
            "Prozentsatz des Ziels in Bezug auf die Gesamt-Treibhausgasemissionen (Intensitätswert)",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasAtWaterRisk":
            "Prozentsatz der Gesamtemissionen von Schadstoffen in den Boden in wassergefährdeten Gebieten",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasOfHighwaterStress":
            "Prozentsatz der Gesamtemissionen von Schadstoffen in den Boden in Gebieten mit hohem Wasserstress",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasAtWaterRisk":
            "Prozentsatz der Gesamtemissionen von Schadstoffen in das Wasser in wassergefährdeten Gebieten",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasOfHighwaterStress":
            "Prozentsatz der Gesamtemissionen von Schadstoffen in das Wasser in Gebieten mit hohem Wasserstress",
          "esrs:PercentageOfTotalEmployeesCoveredByCollectiveBargainingAgreements":
            "Prozentsatz der gesamten Mitarbeiter, die durch Tarifverträge abgedeckt sind",
          "esrs:PercentageOfTotalGreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Prozentsatz der gesamten Treibhausgasemissionsreduktion im Vergleich zu den Emissionen des Basisjahres",
          "esrs:PercentageOfVariableRemunerationDependentOnSustainabilityrelatedTargetsAndOrImpacts":
            "Prozentsatz der variablen Vergütung, die an nachhaltigkeitsbezogene Ziele und/oder Auswirkungen gebunden ist",
          "esrs:PotentialFutureLiabilitiesBasedOnExistingContractualAgreementsAssociatedWithCarbonCreditsPlannedToBeCancelledInNearFuture":
            "Potenzielle zukünftige Verbindlichkeiten basierend auf bestehenden vertraglichen Vereinbarungen im Zusammenhang mit Kohlenstoffzertifikaten, die in naher Zukunft storniert werden sollen",
          "esrs:PotentialMarketSizeOfLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess":
            "Potenzielle Marktgröße für kohlenstoffarme Produkte und Dienstleistungen oder Anpassungslösungen, auf die das Unternehmen Zugang hat oder haben könnte",
          "esrs:ProductsAndTechnicalAndBiologicalMaterialsUsed":
            "Produkte sowie technische und biologische Materialien, die verwendet werden",
          "esrs:ProvisionsForEnvironmentalProtectionAndRemediationCosts":
            "Rückstellungen für Umweltschutz- und Sanierungskosten",
          "esrs:RadioactiveWaste": "Radioaktiver Abfall",
          "esrs:RateOfRecordableWorkrelatedAccidents":
            "Rate der meldepflichtigen arbeitsbedingten Unfälle",
          "esrs:RemunerationRatioAdjustedForPurchasingPowerDifferencesBetweenCountries":
            "Vergütungsquote angepasst an Kaufkraftunterschiede zwischen Ländern",
          "esrs:RenewableEnergyProduction": "Erzeugung erneuerbarer Energien",
          "esrs:Revenue": "Umsatz",
          "esrs:RevenueFromActivitiesInHighClimateImpactSectors":
            "Umsatz aus Aktivitäten in klimarelevanten Sektoren",
          "esrs:RevenueFromActivitiesOtherThanInHighClimateImpactSectors":
            "Umsatz aus Aktivitäten in Sektoren mit geringem Klimaeinfluss",
          "esrs:RevenueFromBusinessActivitiesAtMaterialPhysicalRisk":
            "Umsatz aus Geschäftstätigkeiten mit wesentlichem physischem Risiko",
          "esrs:RevenueFromBusinessActivitiesAtMaterialTransitionRisk":
            "Umsatz aus Geschäftstätigkeiten mit wesentlichem Übergangsrisiko",
          "esrs:RevenueFromChemicalsProduction": "Umsatz aus der Chemikalienproduktion",
          "esrs:RevenueFromCoal": "Umsatz aus Kohle",
          "esrs:RevenueFromControversialWeapons": "Umsatz aus kontroversen Waffen",
          "esrs:RevenueFromCultivationAndOrProductionOfTobacco":
            "Umsatz aus dem Anbau und/oder der Herstellung von Tabak",
          "esrs:RevenueFromCustomersOperatingInCoalRelatedActivities":
            "Umsatz aus Kunden, die in kohlebezogenen Aktivitäten tätig sind",
          "esrs:RevenueFromCustomersOperatingInGasRelatedActivities":
            "Umsatz aus Kunden, die in gasbezogenen Aktivitäten tätig sind",
          "esrs:RevenueFromCustomersOperatingInOilRelatedActivities":
            "Umsatz aus Kunden, die in ölbezogenen Aktivitäten tätig sind",
          "esrs:RevenueFromFossilFuelCoalOilAndGasSector":
            "Umsatz aus dem fossilen Brennstoffsektor (Kohle, Öl und Gas)",
          "esrs:RevenueFromGas": "Umsatz aus Gas",
          "esrs:RevenueFromOil": "Umsatz aus Öl",
          "esrs:RevenueFromTaxonomyalignedEconomicActivitiesRelatedToFossilGas":
            "Umsatz aus taxonomiekonformen wirtschaftlichen Aktivitäten im Zusammenhang mit fossilem Gas",
          "esrs:Reversals": "Rückstellungen",
          "esrs:SealedArea": "Versiegelte Fläche",
          "esrs:SecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices":
            "Sekundäre wiederverwendete oder recycelte Komponenten, sekundäre Zwischenprodukte und sekundäre Materialien, die zur Herstellung von Produkten und Dienstleistungen verwendet werden",
          "esrs:SignificantCapExForCoalrelatedEconomicActivities":
            "Wesentliche Investitionsausgaben (CapEx) für kohlebezogene wirtschaftliche Aktivitäten",
          "esrs:SignificantCapExForGasrelatedEconomicActivities":
            "Wesentliche Investitionsausgaben (CapEx) für gasbezogene wirtschaftliche Aktivitäten",
          "esrs:SignificantCapExForOilrelatedEconomicActivities":
            "Wesentliche Investitionsausgaben (CapEx) für ölbezogene wirtschaftliche Aktivitäten",
          "esrs:UseOfLandArea": "Nutzung der Landfläche",
          "esrs:WasteGenerated": "Erzeugter Abfall",
          "esrs:WasteGeneratedDirectedToDisposal": "Abfall zur Entsorgung",
          "esrs:WasteGeneratedDivertedFromDisposal":
            "Abfall, der von der Entsorgung abgeleitet wurde",
          "esrs:WaterConsumption": "Wasserverbrauch",
          "esrs:WaterConsumptionInAreasAtWaterRisk":
            "Wasserverbrauch in wassergefährdeten Gebieten",
          "esrs:WaterConsumptionInAreasOfHighwaterStress":
            "Wasserverbrauch in Gebieten mit hohem Wasserstress",
          "esrs:WaterDischarges": "Wassereinleitungen",
          "esrs:WaterIntensityTotalWaterConsumptionPerNetRevenue":
            "Wasserintensität (gesamter Wasserverbrauch pro Nettoumsatz)",
          "esrs:WaterRecycledAndReused": "Wasser recycelt und wiederverwendet",
          "esrs:WaterStored": "Gespeichertes Wasser",
          "esrs:WaterWithdrawals": "Wasserentnahmen",
        },
      },
      e: {
        e1: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e1,
          greenhouseGasesCoveredByEmissionReductionTarget: {
            "esrs:PollutantCarbonDioxideCO2Member": "Kohlendioxid (CO2)",
            "esrs:PollutantMethaneCH4Member": "Methan (CH4)",
            "esrs:PollutantNitrousOxideN2OMember": "Lachgas (N2O)",
            "esrs:HydrofluorocarbonsHFCsMember": "Fluorkohlenwasserstoffe (HFKW)",
            "esrs:PerfluorocarbonsPFCsMember": "Perfluorkohlenstoffe (PFK)",
            "esrs:PollutantSulphurHexafluorideSF6Member": "Schwefelhexafluorid (SF6)",
            "esrs:NitrogenTrifluorideNF3Member": "Stickstofftrifluorid (NF3)",
            "esrs:OtherGHGCategoryMember": "Andere Treibhausgaskategorie",
          },
        },
        e2: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e2,
        },
        e3: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e3,
        },
        e4: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e4,
          layersInMitigationHierarchyToWhichTargetCanBeAllocatedBiodiversityAndEcosystems: {
            "esrs:AvoidanceMember": "Vermeidung",
            "esrs:AvoidanceMember.esrs:AvoidanceThroughSiteSelectionMember":
              "Vermeidung durch Standortwahl",
            "esrs:AvoidanceMember.esrs:AvoidanceThroughProjectDesignMember":
              "Vermeidung durch Projektgestaltung",
            "esrs:AvoidanceMember.esrs:AvoidanceThroughSchedulingMember":
              "Vermeidung durch Terminplanung",
            "esrs:MinimisationMember": "Minimierung",
            "esrs:RestorationAndRehabilitationMember": "Wiederherstellung und Rehabilitation",
            "esrs:CompensationOrOffsetsMember": "Kompensation oder Ausgleich",
          },
        },
        e5: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e5,
          layersInWasteHierarchyToWhichTargetRelates: {
            "esrs:PreventionMember": "Vermeidung",
            "esrs:PreparationForReuseMember": "Vorbereitung zur Wiederverwendung",
            "esrs:RecyclingMember": "Recycling",
            "esrs:OtherRecoveryMember": "Andere Verwertung",
            "esrs:DisposalMember": "Entsorgung",
          },
        },
      },
      s: {
        targetRelationshipToImpactsRisksAndOpportunities: {
          "esrs:ReducingNegativeImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember":
            "Reduzierung negativer Auswirkungen auf eigene Arbeitskräfte / Wertschöpfungsketten-Arbeiter / betroffene Gemeinschaften / Verbraucher und Endnutzer",
          "esrs:AdvancingPositiveImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember":
            "Förderung positiver Auswirkungen auf eigene Arbeitskräfte / Wertschöpfungsketten-Arbeiter / betroffene Gemeinschaften / Verbraucher und Endnutzer",
          "esrs:ManagingMaterialRisksAndOpportunitiesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember":
            "Management wesentlicher Risiken und Chancen im Zusammenhang mit eigenen Arbeitskräften / Wertschöpfungsketten-Arbeitern / betroffenen Gemeinschaften / Verbrauchern und Endnutzern",
        },
        s1: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s1,
        },
        s2: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s2,
        },
        s3: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s3,
        },
        s4: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s4,
        },
      },
    },
    input_errors: {
      // Field specific
      general: {
        name: {
          required: "Bitte geben Sie einen Titel ein!",
        },
      },
      // Non field specific
      start_year_after_end_year: "Das Startjahr muss größer oder gleich dem Endjahr sein!",
    },
  },
  data_entry_object_value_edit_component: {
    tooltips: {
      add_note: "Notiz hinzufügen",
      remove_note: "Notiz entfernen",
      add_date: "Datum erfassen",
      use_distribution_criterion: "Aufteilungsschlüssel verwenden",
      undo_distribution_criterion: "Aufteilungsschlüssel nicht verwenden",
      data_quality: {
        none: "Keine Angabe",
        [IDEOIPValueDataQuality.Estimated]: "Geschätzt",
        [IDEOIPValueDataQuality.Measured]: "Exakter Wert/Gemessen",
        [IDEOIPValueDataQuality.Calculated]: "Berechnet",
      },
    },
  },
  data_entry_object_value_actions_component: {
    tooltips: {
      cancel: "Abbrechen",
      reset: "Änderungen verwerfen",
      edit: "Speichern",
      create: "Neuen Eintrag speichern",
      delete: "Löschen",
    },
  },
  data_entry_object_values_edit: {
    remove_date_tooltip: "Datum entfernen",
    date_error_invalid: "Ungültiges Datum",
    date_error_before_start: "Das Datum darf nicht kleiner als {{minDate}} sein!",
    date_error_after_end: "Das Datum darf nicht größer als {{maxDate}} sein!",
    date_error_end_before_start: "Das Enddatum darf nicht vor dem Startdatum liegen!",
    value_use_distribution_criterion: "Aufteilungsschlüssel verwenden",
    value_undo_distribution_criterion: "Aufteilungsschlüssel nicht verwenden",
    no_dc_selected: "Keine Auswahl",
    label_dc: "Aufteilungsschlüssel",
    criteria_hint_component: {
      labels: {
        value_in_percent: "Hinterlegter Wert (%):",
        calculated_value: "Berechneter Wert:",
      },
      hints: {
        general:
          'Dieser Wert wird auf die untergeordnete Struktur nach dem "{{dcName}}"-Schlüssel verteilt.',
        value_in_percent:
          'Der für <bold>"{{deoName}}"</bold> hinterlegte Wert ist für diesen Schlüssel <bold>{{keyValue}}</bold>, das entspricht <bold>{{valueInPercent}}%</bold> des Gesamtbetrages von <bold>{{sum}}</bold>.',
      },
    },
  },
  data_entry_object_value_esrs_phase_in_input: {
    use_phase_in_toggle_label: "Phase-in Regelung für Unternehmen bis 750 Mitarbeiter aktivieren",
    information_box_content:
      "Die Anwendung der Schrittweisen Einführung (Phase-in) ersetzt die Angabepflichten des gewählten ESRS Themas durch ein minimales Set neuer Pflichtangaben.",
    notice_only_root_data_entry_object: "Phase In ist nur für das Haupterfassungsobjekt verfügbar",
    notice_phase_in_in_use: "Phase-In Regelung wird verwendet",
  },
  data_entry_object_parent_values_component: {
    title: "Werte von übergeordneten Erfassungsobjekten:",
    title_own_values: "Eigene Werte:",
  },
  data_entry_object_parent_value_component: {
    from: "Von",
  },
  error_dialog: {
    dialog_title: "Fehler",
    button_close: "Schließen",
  },
  data_entry_object_edit_dialog: {
    dialog_title: "Erfassungsobjekt {{dataEntryObjectName}} bearbeiten",
    dialog_title_new: "Neues Erfassungsobjekt erstellen",
    name_required: "Bitte geben Sie einen Namen ein.",
    name_input_label: "Name",
    financially_consolidated_label: "Finanziell vollkonsolidiert?",
    operational_control_label: "Operative Kontrolle?",
    part_of_value_chain_label: "Teil der Wertschöpfungskette?",
    description_input_label: "Beschreibung (optional)",
    button_delete_tooltip: "Erfassungsobjekt entfernen",
    button_change_parent: "In anderes Erfassungsobjekt verschieben",
    button_cancel: "Abbrechen",
    button_save: "Speichern",
    button_create: "Erstellen",
    object_type_select_label: "Art",
    country_select_label: "Land",
    share_held_by_parent_label: "Anteile an diesem Erfassungsobjekt",
    object_type_value_location: "Standort",
    object_type_value_department: "Abteilung",
    object_type_value_team: "Team",
    object_type_value_business_area: "Geschäftsbereich",
    object_type_value_grouping: "Gruppierung",
    object_type_value_organization: "Organisation",
  },
  configuration_dashboard_page: {
    title: "Konfiguration",
    title_recording_periods: "Erfassungsperioden",
    description_recording_periods: "Erfassungsperioden erstellen und verwalten.",
    button_recording_periods: "Erfassungsperioden",
    title_stakeholder: "Stakeholder",
    description_stakeholder:
      "Stakeholder erstellen und verwalten. Über einen gesonderten Freigabelink können externe Stakeholder Aktionen in der Plattform setzen.",
    button_stakeholder: "Stakeholder",
    title_distribution_criteria: "Aufteilungsschlüssel",
    description_distribution_criteria: "Aufteilungsschlüssel erstellen und verwalten.",
    button_distribution_criteria: "Aufteilungsschlüssel",
    title_sources: "Quellen",
    button_sources: "Quellen",
    description_sources: "Quellen erstellen und verwalten.",
    title_users: "Benutzer",
    button_users: "Benutzer",
    description_users: "Benutzer verwalten.",
    title_emission_factors: "Emissionsfaktoren",
    button_emission_factors: "Emissionsfaktoren",
    description_emission_factors: "Emissionsfaktoren verwalten.",
  },
  recording_periods_edit_list_component: {
    title: "Erfassungsperioden",
    button_new_recording_period: "Erstellen",
    recording_periods_empty: "Keine Erfassungsperioden vorhanden.",
    button_edit_organization_structure_tooltip: "Organisationsstruktur bearbeiten",
    button_edit_distribution_criteria_tooltip: "Aufteilungsschlüssel bearbeiten",
    button_edit_recording_period_tooltip: "Erfassungsperiode bearbeiten",
  },
  organization_configuration_dashboard_page: {
    title: "Organisation konfigurieren",
    title_organization_structure: "Organisationsstruktur",
    description_organization_structure: "Struktur der Organisation verwalten.",
    button_edit_organization_structure: "Organisationsstruktur bearbeiten",
  },
  organization_structure_configuration_page: {
    title: "Organisationsstruktur",
    button_dc: "Aufteilungsschlüssel",
    button_dc_tooltip: "Aufteilungsschlüssel konfigurieren",
  },
  organization_structure_configuration_component: {
    recording_period_select_label: "Erfassungsperiode auswählen",
    organization_structure_commit_draft_button_tooltip: "Entwurf veröffentlichen",
    organization_structure_is_draft_notice:
      "Die Organisationsstruktur ist aktuell noch ein Entwurf. Sie müssen die Organisationsstruktur " +
      "veröffentlichen bevor Sie Werte erfassen können.",
  },
  organization_structure_tree_editor_component: {
    delete_confirm_dialog_title: "Erfassungsobjekt entfernen",
    delete_confirm_dialog_content:
      "Sind Sie sicher, dass Sie das Erfassungsobjekt {{dataEntryObjectName}} und alle untergeordneten Erfassungsobjekte entfernen möchten?",
    add_child_tooltip: "Neues Erfassungsobjekt hinzufügen",
    add_child_mode_text: "Wählen Sie das übergeordnete Erfassungsobjekt",
    add_child_cancel_tooltip: "Abbrechen",
    error_cannot_set_deo_as_its_own_parent:
      "Erfassungsobjekt kann nicht sein eigenes übergeordnetes Erfassungsobjekt sein!",
    error_selected_deo_is_already_parent_of_deo_to_be_moved:
      "Das gewählte Erfassungsobjekt ist bereits das aktuell übergeordnete Erfassungsobjekt!",
    error_selected_new_parent_cannot_be_child_of_deo_to_be_moved:
      "Das Erfassungsobjekt kann nicht in ein ihm untergeordnetes Erfassungsobjekt verschoben werden!",
    change_parent_mode_text: "Neues übergeordnetes Erfassungsobjekt wählen",
    change_parent_cancel_tooltip: "Abbrechen",
    deo_count_text: "{{currentCount}} / {{maxCount}} Erfassungsobjekten",
    deo_limit_reached: "Maximale Anzahl an Erfassungsobjekten erreicht",
  },
  organization_structure_tree_component: {
    node_type_location: "Standort",
    node_type_department: "Abteilung",
    node_type_team: "Team",
    node_type_business_area: "Geschäftsbereich",
    node_type_grouping: "Gruppierung",
    node_type_company: "Organisation/Unternehmen",
    node_type_organization: "Organisation",
  },
  organization_structure_draft_commit_dialog: {
    title: "Organisationsstruktur final veröffentlichen",
    warning_text:
      "Nach der Veröffentlichung kann die Organisationsstruktur nicht mehr geändert oder " +
      "zurückgesetzt werden. Alle bisher vorgenommenen Anpassungen sind endgültig.",
    understand: {
      label: "Ich verstehe, dass die Organisationsstruktur nicht mehr geändert werden kann.",
      errors: {
        required: "Bitte bestätigen Sie, dass Sie die Meldung verstanden haben.",
      },
    },
  },
  recording_period_edit_dialog: {
    title_create: "Neue Erfassungsperiode erstellen",
    title_edit: "Erfassungsperiode {{name}} bearbeiten",
    default_name: "Geschäftsjahr {{year}}",
    name_label: "Name",
    name_required: "Bitte geben Sie einen Namen ein.",
    description_label: "Beschreibung (optional)",
    start_date_label: "Startdatum",
    start_date_required: "Bitte geben Sie ein Startdatum ein.",
    start_date_invalid: "Bitte geben Sie ein gültiges Startdatum ein.",
    start_date_min: "Das Startdatum muss nach dem {{minDate}} (vorherige Periode) liegen.",
    start_date_max: "Das Startdatum muss vor dem Enddatum liegen.",
    end_date_required: "Bitte geben Sie ein Enddatum ein.",
    end_date_invalid: "Bitte geben Sie ein gültiges Enddatum ein.",
    end_date_min: "Das Enddatum muss nach dem Startdatum liegen.",
    end_date_max: "Das Enddatum muss vor dem {{maxDate}} (nachfolgende Periode) liegen.",
    end_date_label: "Enddatum",
    button_create: "Erstellen",
    button_save: "Speichern",
    button_cancel: "Abbrechen",
  },
  // Evaluations
  emissions_evaluation_page: {
    title: "Emissionen auswerten",
    notice: {
      text: "Die Emissionsauswertung befindet sich momentan im Umbau und ist deshalb nicht verfügbar.",
      button: "Zurück zum Start",
    },
  },
  emissions_overview: {
    title_organization_unit: "Erfassungsobjekt:",
    notice_unit:
      "Alle Werte in der Auswertung sind in der Einheit <bold>t CO<sub>2</sub>eq</bold> angegeben.",
    title_emission_group: "Emissionsgruppe:",
    title_total_emissions: "Gesamtemissionen:",
    title_total_emissions_by_recording_group: "Nach Emissionsgruppen:",
    title_total_emissions_by_child: "Nach Erfassungsobjekt:",
    title_child_data_entry_objects: "Erfassungsobjekte:",
    title_scopes: "Verteilung Scopes:",
  },
  emissions_direct_and_upstream_component: {
    title_direct_emissions: "Direkte Emissionen",
    title_upstream_emissions: "Vorgelagerte Emissionen",
  },
  total_emissions_pie_chart_component: {
    empty: "Keine Emissionen",
    total_emissions: "Gesamt:",
  },
  total_emissions_bar_percentage_component: {
    empty: "Keine Emissionen",
  },
  emissions_by_scope_bar_charts: {
    empty: "Keine Emissionen",
    button_filter: "Filter",
    title_scope1: "Scope 1",
    title_scope2: "Scope 2",
    title_scope3: "Scope 3",
    title_total: "Gesamt",
    no_deo_with_emissions_notice:
      "Für gewählte Erfassungsobjekte und Emissionsgruppen wurden keine Emissionen erfasst.",
    deo_result_no_emissions: "(keine Emissionen)",
  },
  deo_emissions_by_category_bar_chart: {
    empty: "Keine Emissionen",
    button_filter: "Filter",
    no_deo_with_emissions_notice:
      "Für gewählte Erfassungsobjekte und Emissionsgruppen wurden keine Emissionen erfasst.",
    deo_result_no_emissions: "(keine Emissionen)",
  },
  emissions_evaluation_error_component: {
    title: "Bei der Auswertung ist ein Fehler aufgetreten!",
    subtitle_error_message: "Fehlermeldung:",
    errorMessages: {
      "formula_specific.reference.context_key_not_found":
        "Der geforderte Key wurde im Context nicht gefunden!",
    } as { [key in CalculationErrorCodes]: string },
    affected: {
      subtitle: "Formel & Input Parameter",
      inputParameter: "Input-Parameter: {{inputParameterName}} (ID: {{id}})",
      calculationFormula: "Formel: {{calculationFormulaName}} (ID: {{id}})",
      value: "Wert: {{id}}",
    },
    step: {
      subtitle: "Betroffener Schritt:",
      subtitle_value_key: "Context-Key:",
      subtitle_index: "({{count}}. Schritt innerhalb der Formel)",
      subtitle_formula: "Berechnungs-Formel:",
    },
    context: {
      subtitle: "Context:",
      headers: {
        key: "Context-Key:",
        value: "Wert",
      },
    },
  },
  // Double Materiality Assessment
  dma_common: {
    title: "Doppelte Wesentlichkeitsanalyse",
    // Materiality State
    materiality_chip_tooltip: "Wesentlichkeitsgrad / Wesentlichkeit",
    materiality_true_state: "Ja",
    materiality_false_state: "Nein",
    // IRO State
    iro_state_draft: "Entwurf",
    iro_state_shared: "Freigegeben",
    // Category State
    category_state_open: "Offen",
    category_state_workInProgress: "In Bearbeitung",
    category_state_shared: "Freigegeben",
    category_state_verified: "Abgeschlossen",
  },
  dma_configuration: {
    title_threshold: "Bewertung",
    description_threshold:
      "Dieser Wert wird verwendet um zu bestimmen, ob eine Auswirkung, Chance oder ein Risiko materiell " +
      "sind oder nicht. Ist der berechnete Wesentlichkeitsgrad gleich oder größer als dieser Wert, ist die " +
      "Auswirkung, Chance oder ein Risiko materiell.",
    label_threshold_impact: "Schwellenwert Auswirkungen",
    label_threshold_effect: "Schwellenwert Chancen/Risiken",
    error_threshold_required: "Bitte geben Sie einen Wert ein!",
    error_threshold_max: "Der Schwellenwert darf nicht größer als {{max}} sein.",
    error_threshold_min: "Der Schwellenwert darf nicht kleiner als {{min}} sein.",
    title_potential_extent_steps: "Potentielles Ausmaß",
    description_potential_extent_steps:
      "Das potentielle Ausmaß von Chancen und Risiken wird in 5 Schritten bewertet. Für diese Schritte kann ein " +
      "monetärer Wert hinterlegt werden der anschließend für Berechnung des Schweregrades in Euro verwendet wird.",
    label_set_potential_extent_steps: "Individuelle Werte hinterlegen",
    error_potentialExtentStep_not_ascending: "Die Werte müssen aufsteigend sein.",
    error_potentialExtentStep_required: "Bitte geben Sie einen Wert ein.",
    // probability of occurrence material
    title_probability_of_occurrence_steps_material: "Eintrittswahrscheinlichkeit von Auswirkungen",
    description_probability_of_occurrence_steps_material:
      "Die Eintrittswahrscheinlichkeit von Auswirkungen wird in 5 Schritten bewertet. Für diese Schritte kann ein " +
      "Wert in Prozent hinterlegt werden, der anschließend für Berechnung des Schweregrades / Wesentlichkeitsgrades verwendet wird.",
    // probability of occurrence financial
    title_probability_of_occurrence_steps_financial:
      "Eintrittswahrscheinlichkeit von Risiken und Chancen",
    description_probability_of_occurrence_steps_financial:
      "Die Eintrittswahrscheinlichkeit von Risiken und Chancen wird in 5 Schritten bewertet. Für diese Schritte kann ein " +
      "Wert in Prozent hinterlegt werden, der anschließend für Berechnung des Schweregrades / Wesentlichkeitsgrades verwendet wird.",
    // probability of occurrence common
    label_set_probability_of_occurrence_steps: "Individuelle Werte hinterlegen",
    // errors probability of occurrence
    error_probabilityOfOccurrence_step_not_ascending: "Die Werte müssen aufsteigend sein.",
    error_probabilityOfOccurrence_step_required: "Bitte geben Sie einen Wert ein.",
    error_probabilityOfOccurrence_step_min: "Der Wert muss größer oder gleich 0 sein.",
    error_probabilityOfOccurrence_step_max: "Der Wert darf nicht größer als 100 sein.",
    // Export
    title_export: "Export",
    title_input_language: "Erfassungssprache",
  },
  export_button_component: {
    export_data: "Daten exportieren",
    download_text: "Bitte wählen Sie aus, welche Daten Sie herunterladen möchten:",
    button_content_iros: "Auswirkungen, Chancen und Risiken exportieren",
    "button_content_stakeholder-feedback": "Stakeholder-Feedback exportieren",
    button_tooltip_iros:
      "Auswirkungen, Chancen und Risiken in gewählter Sprache ({{currentLanguage}}) exportieren",
    "button_tooltip_stakeholder-feedback":
      "Stakeholder-Feedback in gewählter Sprache ({{currentLanguage}}) exportieren",
    button_content_users: "Alle Nutzer exportieren",
    button_tooltip_users: "Alle Nutzer in gewählter Sprache ({{currentLanguage}}) exportieren",
    info: "Um die Nutzer im Klartext anzuzeigen, müssen Sie die Benutzer-IDs via SVERWEIS in Excel oder einem anderen Tabellenprogramm den entsprechenden Namen zuordnen.",
  },
  gap_analysis: {
    title: "Gap Analyse",
    subtitle: "Übersicht der Offenlegungspflichten",
    table: {
      header_name: "Offenlegungspflicht",
      header_responsible_person: "Verantwortlich",
      header_data_collection: "Datenerfassung",
      header_availability: "Verfügbarkeit",
      header_sources: "Quellen",
      header_status: "Status",
    },
    data_collection: {
      null: "Bitte auswählen",
      [IDataEntryObjectInputParameterGapAnalysisDataCollection.Local]: "Standorte/Abteilung",
      [IDataEntryObjectInputParameterGapAnalysisDataCollection.Central]: "Konzern (zentral)",
    },
    availability: {
      null: "Bitte auswählen",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Not]: "Nicht verfügbar",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Unclear]: "Unklar",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Partial]: "Teilweise",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Full]: "Vollständig",
    },
    add_all_esrs_topics_button: "Alle ESRS-Themen anlegen",
    missing_esrs_topics: "Um die Gap Analyse durchzuführen, müssen Sie alle ESRS-Themen anlegen.",
  },
  double_materiality_assessment_page: {
    no_data_points_assigned_text:
      "Ihnen wurden keine Datenpunkte zugewiesen und Sie verfügen nicht über die nötige Berechtigung, andere Datenpunkte anzuzeigen oder zu bearbeiten.",
    no_dma_configuration_present:
      "Es wurde noch keine DMA-Konfiguration für diesen Erfassungszeitraum erstellt!",
  },
  double_materiality_assessment_stepper_component: {
    material_impacts_checkbox_label: "Auswirkungen",
    financial_effects_checkbox_label: "Chancen und Risiken",
    filter_by_user_label: "Filtern nach Benutzer",
    previous_step_button: "Zurück",
    next_step_button: "Weiter",
    complete_dma_button: "Abschließen",
    tooltip_icon_dma_completed: "Bereits abgeschlossen",
    can_not_complete_dma:
      "Die DMA kann momentan nicht abgeschlossen werden, da zumindest ein Thema unvollständig ist. " +
      "Bei allen vollständigen Themen werden neben dem Namen doppelte Checkmarks angezeigt.",
    label_dma_dashboard: "Dashboard",
    button_filter: "Filter",
    no_filterable_users_available:
      "Noch keine Personen als verantwortlich oder zugewiesen hinterlegt.",
    selected_category_empty:
      "Das gewählte Hauptthema enthält keine Subthemen, für die Auswirkungen erfasst werden können.",
    dma_incomplete_notice_title: "Doppelte Wesentlichkeit unvollständig!",
    dma_incomplete_notice_content:
      "Die doppelte Wesentlichkeit kann erst abgeschlossen werden, wenn Sie für jedes Thema, das keine untergeordneten Themen " +
      "besitzt, zumindest eine Auswirkung und eine Chance oder ein Risiko angegeben haben oder bestätigt haben, dass Sie entsprechend " +
      "keine Auswirkungen oder Chancen und Risiken angeben möchten.\n\nBitte vervollständigen Sie die doppelte Wesentlichkeit bevor Sie fortfahren!",
    dma_incomplete_notice_button_accept: "Vervollständigen",
    dma_open_topic:
      "Dieses Thema ist noch offen, da keine Auswirkungen oder Chancen / Risiken erfasst wurden und das Thema nicht als nicht relevant markiert wurde.",
    dma_shared_topic:
      "Für dieses Thema fehlt die Validierung durch Stakeholder und der anschließende Abschluss aller Unterthemen.",
    dma_verified_topic: "Dieses Thema ist abgeschlossen.",
    title_dma_configuration: "Konfiguration",
    label_step_dma_configuration: "Konfiguration",
    button_create_category: "Thema hinzufügen",
    // Complete DMA Success message
    toast_complete_dma_success_title: "DMA erfolgreich abgeschlossen!",
    toast_complete_dma_success_text: "Starten Sie direkt mit der Datenerfassung in ESRS2.",
  },
  execute_dma_confirm_dialog: {
    title: "Doppelte Wesentlichkeitsanalyse abschließen",
    content_text:
      "Möchten Sie Ihre Wesentlichkeitsanalyse abschließen? Nach der Bestätigung leiten wir Sie zur ESRS-Datenerfassung weiter. Basierend auf Ihren Ergebnissen werden nicht-wesentliche Datenpunkte automatisch für Sie markiert. Weitere Änderungen sind zu einem späteren Zeitpunkt eingeschränkt möglich.",
    notice_long_duration: "Diese Aktion kann mehrere Minuten in Anspruch nehmen.",
    button_confirm: "Final abschließen",
  },
  double_materiality_assessment_sub_category_component: {
    dma_category_actions_component: {
      tooltip_edit: "Thema bearbeiten",
      tooltip_add_summary: "Zusammenfassung hinzufügen",
      tooltip_add_summary_disabled:
        "Zusammenfassung kann nur hinzugefügt werden, solange das Thema noch nicht abgeschlossen wurde.",
      tooltip_add_child: "Sub-Thema hinzufügen",
      tooltip_add_child_disabled:
        "Sub-Themen können nur hinzugefügt werden, solange das Thema noch nicht abgeschlossen wurde.",
      button_category_irrelevant: "Nicht relevant",
      tooltip_stakeholder_feedback: "Stakeholder-Feedback",
    },
    material_impacts_header: "Auswirkungen",
    material_impacts_empty_message: "Noch keine Auswirkungen",
    category_opted_out_notice: "Dieses Thema wurde als nicht relevant markiert.",
    label_category_opted_out_reason: "Begründung:",
    button_edit_opt_out_reason_tooltip: "Begründung bearbeiten",
    button_opt_back_into_category: "Zurücksetzen",
    create_material_impact_tooltip: "Auswirkung hinzufügen",
    financial_effects_header: "Chancen und Risiken",
    financial_effects_empty_message: "Noch keine Chancen oder Risiken",
    create_financial_effect_tooltip: "Chance oder Risiko hinzufügen",
    "downstream-value-chain_name": "Nachgelagerte Wertschöpfungskette",
    "own-organization_name": "Eigene Organisation",
    "upstream-value-chain_name": "Vorgelagerte Wertschöpfungskette",
    show_actions: "Aktionen einblenden",
    hide_actions: "Aktionen ausblenden",
    label_summary: "Zusammenfassung",
    tooltip_edit_summary_disabled:
      "Zusammenfassung kann nur bearbeitet werden, solange das Thema noch nicht abgeschlossen wurde.",
  },
  dma_financial_effect_general: {
    effect_type: {
      investment_costs: "Investitionskosten",
      operations_costs: "Operative Kosten",
      capital_costs: "Kapitalkosten",
      assets_value_change: "Wertänderung von Anlagen",
      revenue: "Umsätze",
      access_to_capital: "Kapitalzugang",
    },
  },
  multiple_chip_component: {
    only_more_items: "{{count}} gewählt",
    more_items: "+{{count}} weitere",
  },
  associated_users_dialog_inputs: {
    // Responsible
    label_responsible_user: "Verantwortliche Person",
    // Assigned Users
    label_assigned_users: "Mitarbeitende",
    no_assigned_users: "Keine Mitarbeitenden hinzugefügt",
    add_assigned_user_tooltip: "Mitarbeitende hinzufügen",
    no_more_users_to_assign: "Keine weiteren Benutzer verfügbar",
  },
  dma_impact_or_effect_edit_dialog_common: {
    // Inputs
    label_title: "Titel",
    error_title_required: "Bitte geben Sie einen Titel ein.",
    label_description: "Beschreibung und Hintergrund (optional)",
    description_explanation:
      "Erklärungen zum Sachverhalt, Beschreibung der zugrundeliegenden Daten, Annahmen und " +
      "Vorgehensweisen zur Bewertung / Berechnung, sowie Auflistung involvierter Bereiche.",
    label_assessmentExplanation: "Erläuterung der Bewertung",
    assessmentExplanation_explanation:
      "Erläuterung der Bewertung und der zugrundeliegenden Annahmen.",
    error_assessmentExplanation_required: "Bitte geben Sie eine Erläuterung an.",
    // Evaluation Section
    header_horizon_evaluation: "Ergebnis der Bewertung",
    header_total_evaluation: "Gesamtbewertung über alle drei Zeithorizonte",
    severity_label: "Schweregrad",
    severity_currency_label: "Schweregrad in Euro",
    no_equivalent_set_1: "Keine individuellen Werte in",
    no_equivalent_set_2: "Konfiguration",
    no_equivalent_set_3: "definiert.",
    materiality_label: "Wesentlich",
    materiality_degree_label: "Wesentlichkeitsgrad",
    materiality_true_state: "Ja",
    materiality_false_state: "Nein",
    edit_materiality_tooltip: "Berechnete Wesentlichkeit manuell überschreiben",
    calculated_materiality_label: "Berechnete Wesentlichkeit",
    custom_materiality_label: "Benutzerdefinierte Wesentlichkeit",
    cancel_edit_materiality_tooltip: "Abbrechen und berechnete Wesentlichkeit heranziehen",
    label_user_materiality_reason: "Begründung für benutzerdefinierte Wesentlichkeit",
    error_user_materiality_reason_required: "Bitte geben Sie eine Begründung an.",
    error_reason_for_horizons_was_not_provided:
      "Da es zu einer unterschiedlichen Bewertung der einzelnen Zeithorizonte " +
      "(kurz-, mittel- und langfristig) gekommen ist, muss eine Begründung angegeben werden.",
    inherit_form_horizon_select_label: "Bewertung des Zeithorizonts",
    inherit_from_shortTerm: "Werte von Kurzfristig übernehmen",
    inherit_from_mediumTerm: "Werte von Mittelfristig übernehmen",
    inherit_from_longTerm: "Werte von Langfristig übernehmen",
    // inherits_values_itself: "Zeithorizont übernimmt selbst Werte",
    horizon_shortTerm_name: "Kurzfristig",
    horizon_shortTerm_time_span: "1 Jahr",
    horizon_mediumTerm_name: "Mittelfristig",
    horizon_mediumTerm_time_span: "2 - 5 Jahre",
    horizon_longTerm_name: "Langfristig",
    horizon_longTerm_time_span: "> 5 Jahre",
    no_inheritance_name: "Manuell",
    in_use_by_other_horizon: "Andere Zeithorizonte übernehmen Werte von diesem Zeithorizont.",
    horizons_comment_label: "Begründung der unterschiedlichen Bewertung der Zeithorizonte",
    horizons_comment_explanation:
      "Begründen Sie, warum es zu einer unterschiedlichen Bewertung der einzelnen Zeithorizonte " +
      "(kurz-, mittel- und langfristig) kommt und erläutern Sie kurz die Abweichungen.",
    // Areas
    areas_select_label: "Betroffene Bereiche",
    error_effect_area_required: "Bitte wählen Sie zumindest einen Bereich.",
    "downstream-value-chain_name": "Nachgelagerte Wertschöpfungskette",
    "own-organization_name": "Eigene Organisation",
    "upstream-value-chain_name": "Vorgelagerte Wertschöpfungskette",
    // Slider Values
    slider_value_very_low: "Sehr niedrig",
    slider_value_low: "Eher niedrig",
    slider_value_average: "Mittel",
    slider_value_high: "Eher hoch",
    slider_value_very_high: "Sehr hoch",
    // Slider Values Extent
    slider_value_extent_very_low: "Minimal",
    slider_value_extent_low: "Niedrig",
    slider_value_extent_average: "Mittel",
    slider_value_extent_high: "Hoch",
    slider_value_extent_very_high: "Sehr hoch/absolut",
    // Slider Values Scale
    slider_value_scale_very_low: "Limitiert",
    slider_value_scale_low: "Konzentriert",
    slider_value_scale_average: "Mittel",
    slider_value_scale_high: "Weitverbreitet",
    slider_value_scale_very_high: "Global/Total",
    // Slider Values Irreversibility
    slider_value_irreversibility_very_low: "Relativ leicht/kurzfristig umkehrbar",
    slider_value_irreversibility_low: "umkehrbar mit Aufwand",
    slider_value_irreversibility_average: "Schwer/mittelfristig umkehrbar",
    slider_value_irreversibility_high: "Sehr schwer/langfristig umkehrbar",
    slider_value_irreversibility_very_high: "Nicht umkehrbar",
    // Assigned Users Explanation
    explanation_assigned_users:
      "Mitarbeitende haben Zugriff auf dieses IRO und können Daten eingeben sowie die Bewertung verändern, solange die Daten nicht freigegeben wurden.",
    confirm_dialog: {
      button_confirm: "$t(buttons:confirm)",
      button_cancel: "$t(buttons:cancel)",
    },
  },
  dma_material_impact_edit_dialog: {
    title_create: "Auswirkung erstellen",
    title_edit: '<italic>"{{title}}"</italic> bearbeiten',
    delete_tooltip: "Auswirkung entfernen",
    move_to_other_topic: "Auswirkung in anderes Thema verschieben",
    // Impact Inputs
    subtitle_type: "Art der Auswirkung",
    type_actual: "Tatsächliche Auswirkung",
    type_potential: "Potentielle Auswirkung",
    label_hasNegativeEffectOnHumanRights: "Hat potentiell negative Auswirkungen auf Menschenrechte",
    label_probability: "Eintrittswahrscheinlichkeit",
    explanation_probability: "Wie wahrscheinlich tritt die Auswirkung ein?",
    subtitle_result: "Bewertung der Auswirkung",
    result_positive: "Positiv",
    result_negative: "Negativ",
    label_irreversibility: "Unumkehrbarkeit",
    explanation_irreversibility: `Beschreiben Sie, wie schwer es ist, im Nachhinein die negative Auswirkung auszugleichen oder rückgängig zu machen.
      Ist es sehr leicht den Ursprungszustand wieder herzustellen, dann ist die Unumkehrbarkeit sehr niedrig.
      Ist es besonders schwer oder gar unmöglich, den Ursprungszustand wieder herzustellen, dann ist die Unumkehrbarkeit als sehr hoch einzustufen.`,
    label_extent: "Ausmaß",
    explanation_extent: `Bewerten Sie das Ausmaß / die Höhe der Auswirkung auf den wesentlich betroffenen Umfang.
    Ermitteln Sie dazu zuerst den Umfang der wesentlich Betroffenen und bewerten Sie dann, das Ausmaß auf diese Betroffenengruppe.
    Beispiel: Aufzugerneuerung an einem ihrer Standorte:
    Gibt es zwar einen Lift im verbundenen Nachbargebäude und nur wenige Stockwerke stufenlos verbunden, so kann das Ausmaß für zB. Rollstuhlfahrer als "eher hoch" bewertet werden.`,
    label_scope: "Umfang",
    explanation_scope: `Geben Sie den Umfang an, wie weitreichend die Auswirkung in Bezug auf die entsprechende Gesamtheit ist. Stellen Sie sich dazu folgende 3 Fragen:
      1. Wen könnte die Auswirkung grundsätzlich betreffen?
      2. Wer ist wesentlich davon betroffen?
      3. Wie hoch ist der Anteil/Umfang der wesentlich Betroffenen in Bezug auf mögliche Betroffene?\n
      Beispiel: Aufzugerneuerung an einem ihrer Standorte:
      1. Nur Mitarbeiter:innen, Kunden und Personen, die am Standort sind.
      2. Nur Menschen, die auch auf den Lift angewiesen sind
      3. Gibt es eine Treppe und der Standort wird nur von vereinzelten Menschen mit eingeschränkter Mobilität frequentiert kann der Umfang als "sehr niedrig" bewertet werden.`,
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Auswirkung unvollständig!",
    incomplete_for_shared_notice:
      "Um eine Auswirkung freigeben zu können muss eine Erläuterung der Bewertung angegeben werden.",
    // Buttons
    button_cancel: "Abbrechen",
    button_close: "Schließen",
    button_save: "Speichern",
    button_create: "Erstellen",
    // Duplicate Dialog
    duplicate: "Auswirkung duplizieren",
    duplicate_disabled_reason:
      "Bitte speichern Sie alle Änderungen bevor Sie die Auswirkung duplizieren.",
    duplicate_dialog_title: 'Auswirkung  <italic>"{{name}}"</italic>  duplizieren',
    duplicate_dialog_content: "Sind Sie sicher, dass Sie die Auswirkung duplizieren möchten?",
  },
  dma_financial_effect_common: {
    effect_category: {
      select_label: "Kategorie",
      market: "Markt",
      technology: "Technologie",
      policy_and_legal: "Politik und Recht",
      reputation: "Reputation",
      acute_physical: "Akut physisch",
      chronic_physical: "Chronisch physisch",
      resource_efficiency: "Ressourceneffizienz",
      products_services: "Produkte / Dienstleistungen",
      financial_incentives: "Finanzielle Anreize",
      others: "Sonstige",
    },
  },
  dma_financial_effect_edit_dialog: {
    title_create: "Chance oder Risiko erstellen",
    title_edit: '"<italic>{{title}}</italic>" bearbeiten',
    delete_tooltip: "Chance oder Risiko entfernen",
    move_to_other_topic: "Chance oder Risiko in anderes Thema verschieben",
    // Effect Inputs
    effect_type_select_label: "Chance oder Risiko",
    risk_name: "Risiko",
    chance_name: "Chance",
    subtitle_effect_type: "Finanzielle Wirkung auf",
    subtitle_additional_effect_types: "Weitere Arten:",
    error_effect_type_required: "Bitte wählen Sie zumindest eine Art für die Auswirkung.",
    label_potential_extent: "Potenzielles Ausmaß",
    label_probability_of_occurrence: "Eintrittswahrscheinlichkeit",
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Chance/Risiko unvollständig!",
    incomplete_for_shared_notice:
      "Folgende Punkte müssen noch erfüllt werden, damit die Chance / das Risiko freigeben werden kann:",
    incomplete_points: {
      // These don't follow naming convention because they have to match the names
      // of the associated input fields :)
      assessmentExplanation: "Es muss eine Erläuterung der Bewertung angegeben werden.",
      effectType: "Es muss zumindest eine finanzielle Auswirkung angegeben werden.",
    },
    // Buttons
    button_cancel: "Abbrechen",
    button_close: "Schließen",
    button_save: "Speichern",
    button_create: "Erstellen",
    // Duplicate Dialog
    duplicate: "Chance oder Risiko duplizieren",
    duplicate_disabled_reason:
      "Bitte speichern Sie alle Änderungen bevor Sie die Chance oder das Risiko duplizieren.",
    duplicate_dialog_title: 'Chance oder Risiko <italic>"{{name}}"</italic> duplizieren',
    duplicate_dialog_content:
      "Sind Sie sicher, dass Sie die Chance oder das Risiko duplizieren möchten?",
  },
  delete_impact_or_effect_confirm_dialog: {
    "title_material-impact": 'Auswirkung <italic>"{{name}}"</italic> löschen?',
    "title_financial-effect": 'Chance oder Risiko <italic>"{{name}}"</italic> löschen?',
    "content_text_material-impact":
      "Sind Sie sicher, dass Sie die Auswirkung {{name}} löschen möchten?",
    "content_text_financial-effect":
      "Sind Sie sicher, dass Sie die Chance oder das Risiko {{name}} löschen möchten?",
    button_cancel: "Abbrechen",
    button_delete: "Löschen",
  },
  move_iro_to_other_topic_dialog: {
    title_material_impact: 'Auswirkung <italic>"{{name}}"</italic> verschieben?',
    title_financial_effect: 'Chance oder Risiko <italic>"{{name}}"</italic> verschieben?',
    content_text_material_impact:
      "Sind Sie sicher, dass Sie die Auswirkung {{name}} verschieben möchten?",
    content_text_financial_effect:
      "Sind Sie sicher, dass Sie die Chance oder das Risiko {{name}} verschieben möchten?",
    button_move: "Verschieben",
    // Select Topic
    choose_esrs_topic_label: "ESRS-Thema auswählen",
    success_material_impact: "Die Auswirkung wurde erfolgreich verschoben.",
    topic_disabled_reason_not_recorded:
      "Das Verschieben in ein Thema, welches nicht erfasst wird, ist nicht möglich.",
    topic_disabled_reason_opt_out:
      "Das Verschieben in ein Thema, für welches festgelegt wurde, dass es keine Auswirkungen / Chancen und Risiken gibt, ist nicht möglich.",
    topic_disabled_reason_no_categories_available:
      "Das Verschieben in ein Thema, welches keine Sub-Themen besitzt, ist nicht möglich.",
    topic_disabled_reason_all_categories_verified:
      "Das Verschieben in ein Thema, in dem alle Sub-Themen abgeschlossen sind, ist nicht möglich.",
    // Select Category
    choose_category_label: "DMA Kategorie auswählen",
    success_financial_effect: "Die Chance oder das Risiko wurde erfolgreich verschoben.",
    category_disabled_reason_already_verified:
      "Das Verschieben in ein abgeschlossenes Thema ist nicht möglich, bitte ändern Sie den Status des Themas um diese Aktion durchführen zu können.",
    category_disabled_reason_same_category:
      "Die Chance oder das Risiko ist bereits diesem Thema zugeordnet.",
    category_disabled_reason_opt_out:
      "Verschieben in ein nicht relevante Kategorie ist nicht möglich.",
  },
  esrs_topic_opt_out_confirm_dialog: {
    title_opt_out: "Keine Auswirkungen für dieses ESRS-Thema erfassen?",
    title_edit: "Begründung aktualisieren",
    content_text:
      'Sind Sie sicher, dass Sie für das ESRS-Thema "{{topic}}" ({{name}}) weder ' +
      "Auswirkungen noch Chancen oder Risiken erfassen möchten? Sie können Ihre Entscheidung " +
      "jederzeit rückgängig machen.",
    opt_out_reason_label: "Begründung",
    opt_out_reason_required: "Bitte geben Sie eine Begründung an.",
    button_cancel: "Abbrechen",
    button_opt_out: "Bestätigen",
    button_edit: "Speichern",
  },
  // DMA Dashboard
  dma_dashboard: {
    title: "Doppelte Wesentlichkeitsanalyse Dashboard",
    button_back_to_dma: "Zurück zu Eingabe",
    title_materiality_matrix: "Wesentlichkeitsmatrix",
    // Breadcrumbs
    breadcrumbs_item_total: "Gesamt",
    // ### DMA Matrix
    title_overview: "Übersicht",
    // axis labels
    label_axis_financial_materiality: "Finanzieller Wesentlichkeitsgrad",
    label_axis_material_materiality: "Wesentlichkeitsgrad der Auswirkungen",
    // topics
    label_topic_environmental: "Umwelt",
    label_topic_social: "Soziales",
    label_topic_governance: "Governance",
    label_topic_other: "Sonstiges",
    // categories
    no_categories_to_display_notice: "Keine Themen zum Anzeigen",
    label_matrix_children: "Unterthemen",
    // ### Overview Table
    title_overview_table: "Übersichtstabelle",
    label_show_impacts: "Auswirkungen",
    label_show_effects: "Chancen/Risiken",
    button_expand_all: "Alle ausklappen",
    button_collapse_all: "Alle einklappen",
    // Headers
    table_header_material_impacts_group: "Auswirkungen",
    table_header_financial_effects_group: "Chancen/Risiken",
    header_name: "Name",
    header_materiality_short_term: "Kurzfristig",
    header_materiality_medium_term: "Mittelfristig",
    header_materiality_long_term: "Langfristig",
    header_materiality_max: "Maximal",
    header_max_materiality_degree: "Maximal",
    header_material: "Wesentlichkeit",
    // Content
    tooltip_opt_out: "Als nicht wesentlich markiert",
    tooltip_no_children: "Keine Unterthemen",
    materiality_value_true: "Wesentlich",
    materiality_value_false: "Nicht wesentlich",
    // Translations for TableCells
    cells: {
      value_exceeds_threshold: "Dieser Wert übersteigt den Schwellenwert von {{threshold}}.",
      any_value_exceeds_threshold: "Ein Wert übersteigt den Schwellenwert.",
    },
    // IRO List
    title_iro_overview_list: "Auswirkungen, Chancen und Risiken",
    // Filter
    iroType: "Typ",
    effectAreaType: "Betroffener Bereich",
    materiality: "Wesentlichkeit",
  },
  // Common DMA Components
  iros_overview_list_component: {
    table_header_title: "Titel",
    table_header_type: "Typ",
    table_header_affected_area: "Betroffener Bereich",
    table_header_result: "Wirkung",
    table_header_result_tooltip: "kurzfristig",
    table_header_materiality_degree: "Wesentlichkeitsgrad (max.)",
    // Notices
    notice_no_iros_at_all: "Keine Auswirkungen, Chancen oder Risiken erfasst.",
    notice_no_iros_for_filters:
      "Für die gesetzten Filter wurden keine Auswirkungen, Chancen oder Risiken gefunden.",
  },
  fill_in_helper_iros_list_component: {
    title: "Auswirkungen, Chancen und Risiken",
    filter_type: "Filter",
    type_hints: {
      "only-material":
        "Nur wesentliche Auswirkungen, Chancen und Risiken werden aufgelistet. Daten stammen aus der Doppelten Wesentlichkeitsanalyse.",
      "topic-specific":
        "Nur Auswirkungen, Chancen und Risiken des ausgewählten ESRS Themas werden gezeigt. Daten stammen aus der Doppelten Wesentlichkeitsanalyse.",
    },
  },
  // IRO Types in singular and plural
  iro_types: {
    singular: {
      impact: "Auswirkung",
      opportunity: "Chance",
      risk: "Risiko",
    },
    plural: {
      impact: "Auswirkungen",
      opportunity: "Chancen",
      risk: "Risiken",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_common: {
    header_stakeholder: "Stakeholder",
    header_organization: "Organisation",
    header_material: "Auswirkungen",
    header_financial: "Chancen/Risiken",
    title_material: "Auswirkungen",
    title_financial: "Chancen/Risiken",
    tooltip_feedback_not_requested: "Feedback nicht angefragt",
    tooltip_feedback_accepted: "Zugestimmt",
    tooltip_feedback_accepted_with_comment: "Zugestimmt mit Kommentar",
    tooltip_feedback_changes_requested: "Nicht zugestimmt, Änderungen angefragt",
    tooltip_feedback_pending: "Feedback ausstehend",
    notice_feedback_pending: "Noch kein Feedback erhalten",
    tooltip_copy_link: "Freigabelink des Stakeholders zur Feedbackanfrage kopieren",
    // Warnings
    category_open_warning:
      "Das Thema ist weder als nicht relevant markiert noch wurden Auswirkungen oder Chancen/Risiken erfasst. " +
      "Wenn Sie jetzt bereits Stakeholder-Feedback einholen, kann das Ihre Ergebnisse verfälschen und rechtliche Folgen für Sie haben.",
    financial_effects_drafts_warning:
      "Noch nicht alle Chancen/Risiken wurden freigegeben! Wenn Sie jetzt bereits " +
      "Stakeholder-Feedback einholen, kann das Ihre Ergebnisse verfälschen und rechtliche Folgen für Sie haben.",
    material_impacts_drafts_warning:
      "Noch nicht alle Auswirkungen wurden freigegeben! Wenn Sie jetzt bereits " +
      "Stakeholder-Feedback einholen, kann das Ihre Ergebnisse verfälschen und rechtliche Folgen für Sie haben.",
  },
  dma_category_stakeholder_feedback_dialog: {
    title: "Stakeholder-Feedback zum Thema {{name}}",
    header_feedback: "Stakeholder-Feedback",
    empty_notice: "Noch kein Feedback angefragt",
    button_request_feedback: "Feedback anfragen",
    header_manual_feedback: "Manuelles Feedback",
    manual_feedback_empty_notice: "Noch kein manuelles Feedback erfasst",
    button_add_manual_feedback: "Manuelles Feedback hinzufügen",
  },
  dma_category_validation_button: {
    button_verify_topic: "Thema abschließen",
    button_undo_verify_topic: "Abschluss aufheben",
    tooltip_steps_to_verify:
      "Setzen Sie alle Auswirkungen und Risiken/Chancen auf “Freigegeben” oder das Thema auf “Nicht relevant“, um das Thema abschließen zu können.",
    title_confirm_validation: "Abschluss des Themas bestätigen",
    button_confirm_validation: "Bestätigen und abschließen",
    content_confirm_validation:
      "Dieses Thema wird ohne Stakeholder-Feedback für Auswirkungen oder Risiken/Chancen abgeschlossen. " +
      "Feedback kann die Qualität Ihrer Berichterstattung verbessern.",
  },
  dma_category_stakeholder_feedback_info_dialog: {
    title: "Erklärung Stakeholder",
    content_intro:
      "Bitte berücksichtigen Sie Vertreter aus all jenen Stakeholdergruppen, die Sie für dieses " +
      "Thema für relevant halten. Eine Liste typischer Stakeholdergruppen:",
    item_employees: "Beschäftigte und andere Arbeitskräfte",
    item_suppliers: "Lieferanten",
    item_consumer: "Verbraucher",
    item_customers: "Kunden",
    item_end_users: "Endnutzer",
    item_local_communities: "Lokale Gemeinschaften",
    item_protection_needing_groups: "Schutzbedürftige Gruppen",
    item_ngos: "NGOs",
    item_authorities:
      "Behörden, einschließlich Regulierungsbehörden, Aufsichtsbehörden und Zentralbanken",
    item_investors: "Bestehende und potentielle Investoren",
    item_scientific_constitutions: "Wissenschaftliche Einrichtungen",
    item_creditors: "Kreditgeber und andere Gläubiger, einschließlich Vermögensverwalter",
    item_credit_institutions: "Kreditinstitute",
    item_insurers: "Versicherungsunternehmen",
    item_nature: "Die Natur kann als stiller Stakeholder über Umweltdaten eingebunden werden",
  },
  dma_category_stakeholder_feedback_request_dialog: {
    title: "Feedback einholen",
    no_stakeholders_notice: "Noch keine Stakeholder für Organisation angelegt.",
    tooltip_request_feedback: "Feedback anfragen",
    tooltip_revoke_feedback_request: "Anfrage zurückziehen",
    tooltip_feedback_delivered: "Feedback erhalten - zurückziehen nicht möglich",
    button_manage_stakeholders: "Stakeholder verwalten",
    no_request_due_to_verified_material:
      "Feedback für Auswirkungen kann nicht mehr angefragt werden, da diese bereits abgeschlossen sind.",
    no_request_due_to_verified_financial:
      "Feedback für Chancen und Risiken kann nicht mehr angefragt werden, da diese bereits abgeschlossen sind.",
  },
  dma_stakeholder_feedback_view: {
    prefix_last_changed: "Zuletzt geändert:",
    prefix_assessment: "Bewertung:",
    prefix_comment: "Kommentar:",
  },
  // Manual Stakeholder-Feedback
  manual_stakeholder_feedback_common: {
    type_material: "Auswirkungen",
    type_financial: "Chancen/Risiken",
    source_none: "Keine Quelle",
    source_interview: "Interview",
    source_workshop: "Workshop",
    source_survey: "Umfrage",
    source_discussion: "Diskussionsrunde",
  },
  dma_category_manual_feedback_list: {
    header_stakeholder: "Stakeholder",
    header_type: "Feedback Art",
    header_source: "Quelle",
    title_confirm_delete_dialog: "Manuelles Feedback löschen?",
    content_confirm_delete_dialog:
      "Sind Sie sicher, dass Sie das manuelle Feedback löschen möchten?",
    title_feedback: "Feedback",
    title_sources: "Quellen",
  },
  dma_category_manual_feedback_edit_dialog: {
    title_create: "Manuelles Feedback erstellen",
    title_edit: "Manuelles Feedback bearbeiten",
    label_feedback_type: "Feedback Art",
    label_feedback_source: "Quelle",
    label_stakeholders: "Stakeholder",
    label_sources: "Quellen",
    error_stakeholders_required: "Bitte geben Sie zumindest einen Stakeholder an.",
    no_stakeholders_selected_notice: "Keine Stakeholder ausgewählt",
    label_feedback: "Feedback",
    error_feedback_required: "Bitte geben Sie ein Feedback ein.",
    button_manage_stakeholders: "Stakeholder verwalten",
    no_stakeholders_available: "Keine Stakeholder verfügbar",
  },
  dma_language_picker_component: {
    title: "$t(dma_common:title)",
    subtext:
      "Bevor Sie mit der doppelten Wesentlichkeitsanalyse starten können, müssen Sie sich für eine Sprache entscheiden, in der die Erfassung stattfinden soll:",
    button: "Fortfahren",
    confirm: {
      title: "Sprache festlegen?",
      content:
        'Sind Sie sicher, dass Sie die Sprache auf "{{language}}" festlegen möchten?<br/><br/><strong>Diese kann anschließend NICHT mehr geändert werden!</strong>',
    },
  },
  // Content Translation
  language: {
    label: "Sprache",
    translationsPerLanguage: {
      [IContentLanguage.De]: "Deutsch",
      [IContentLanguage.En]: "Englisch",
    } as { [key in IContentLanguage]: string },
  },
  // Audit Logging
  audit_logging_configuration: {
    title: "Aktivitätsprotokoll",
    empty_notice: "Keine Audit Logs vorhanden.",
    header_name: "Name",
    header_organization: "Organisation",
  },
  audit_logging_edit_list_component: {
    headers: {
      timeStamp: "Timestamp",
      userName: "Username",
      stakeholderName: "Stakeholder-Name",
      action: "Aktion",
    },
    events: auditLoggingEventsTranslations,
  },
  audit_logging_comparison_view_component: {
    headers: {
      before: "Zuvor",
      after: "Danach",
    },
    no_data_available: "Es stehen keine Daten zur Verfügung.",
  },
  audit_logging_expand_component: {
    changelog: "Changelog",
    headers: {
      value_name: "Bezeichnung",
      before_value: "Vorher",
      after_value: "Nacher",
    },
  },
  // Stakeholder
  stakeholders_configuration: {
    title: "Stakeholder",
    empty_notice: "Noch keine Stakeholder für Organisation angelegt.",
    header_name: "Name",
    header_organization: "Organisation",
    button_create_stakeholder: "Stakeholder hinzufügen",
    button_create: "Erstellen",
    delete_dialog_confirm: "$t(buttons:delete)",
    delete_dialog_title: "Stakeholder löschen?",
    delete_dialog_body:
      "Sind sie sicher, dass Sie den Stakeholder löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  },
  stakeholder_edit_dialog: {
    title_create: "Stakeholder erstellen",
    title_edit: "Stakeholder bearbeiten",
    label_name: "Name",
    error_name_required: "Bitte geben Sie einen Namen ein.",
    label_organization: "Organisation (optional)",
    create_stakeholder: "Stakeholder erstellen",
  },
  // Distribution Criteria
  distribution_criteria_configuration: {
    title: "Aufteilungsschlüssel",
    empty_notice: "Noch keine Aufteilungsschlüssel für Organisation angelegt.",
    header_name: "Name",
    button_create_stakeholder: "Aufteilungsschlüssel hinzufügen",
    button_create: "Erstellen",
    delete_dialog_title: "Aufteilungsschlüssel löschen?",
    delete_dialog_body:
      "Sind sie sicher, dass Sie den Aufteilungsschlüssel löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  },
  distribution_criteria_edit_dialog: {
    title_create: "Aufteilungsschlüssel erstellen",
    title_edit: "Aufteilungsschlüssel bearbeiten",
    label_name: "Name",
    error_name_required: "Bitte geben Sie einen Namen ein.",
  },
  // Applied Distribution criteria
  applied_distribution_criteria_configuration_page: {
    title: "Aufteilungsschlüssel",
  },
  applied_distribution_criteria_configuration_component: {
    title: "Aufteilungsschlüssel",
    dropdowns: {
      recording_period_dropdown: {
        recording_period_select_label: "Erfassungszeitraum",
      },
      distribution_criterion_dropdown: {
        label: "Aufteilungsschlüssel",
      },
      organization_structure_is_draft_notice:
        "Die Organisationsstruktur ist aktuell noch ein Entwurf. Sie müssen die Organisationsstruktur " +
        "veröffentlichen, bevor Sie Aufteilungsschlüssel anwenden können.",
    },
    no_criteria_configured: {
      text: "Für die Organisation wurden noch keine Aufteilungsschlüssel konfiguriert.",
      button_text: "Zur Konfiguration",
    },
    configure_criterion: {
      text: "Dieser Aufteilungsschlüssel wurde noch nicht für den gewählten Erfassungszeitraum konfiguriert.",
      button_text: "Jetzt konfigurieren",
    },
    alert_info: {
      body: "Änderungen werden automatisch gespeichert, sobald das Eingabefeld verlassen wird!",
      info_already_applied:
        "Der Aufteilungsschlüssel wurde bereits angewandt. Das bedeutet, dass die Werte zwar noch abgeändert, aber nicht mehr komplett entfernt werden können.",
    },
  },
  applied_distribution_criteria_table_component: {
    buttons: {
      expand_all: "Alle ausklappen",
      collapse_all: "Alle einklappen",
      remove_configuration: "Konfiguration entfernen",
      // Apply
      apply_configuration: "Konfiguration anwenden",
      tooltip_not_populated:
        "Die Konfiguration kann noch nicht angewandt werden, da noch nicht alle Werte erfasst wurden.",
      tooltip_populated: "Konfiguration anwenden",
      // Remove application
      remove_application: "Anwendung entfernen",
      tooltip_remove_application: "Anwendung entfernen",
      tooltip_remove_application_disabled:
        "Die Anwendung kann nicht entfernt werden, da bereits Werte erfasst wurden, die auf ihr basieren.",
    },
    table_header: {
      data_entry_object_name: "Name",
      value: "Wert",
    },
    confirm_dialog: {
      title: "Konfiguration entfernen?",
      body: "Sind Sie sicher, dass Sie die Konfiguration entfernen möchten? Sämtliche Werte werden ebenso gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    },
  },
  applied_distribution_criteria_table_row_component: {},
  applied_distribution_criteria_table_cell_component: {
    error_required: "Da der Schlüssel angewandt wurde, muss ein Wert erfasst werden.",
  },
  // Sources
  sources: {
    sources: "Quellen",
    manage_sources: "Quellen verwalten",
    source_type: {
      data_source: "Datenquelle",
      calculation_source: "Berechnungsquelle",
      methodology_source: "Methodikquelle",
      guideline_or_template_source: "Leitfaden/Vorlage",
    },
    warnings: {
      cannot_be_linked:
        "Diese Quelle kann derzeit keinem Datenpunkt zugewiesen werden, weil sie keine Beschreibung enthält oder keine Dateien hochgeladen wurden.",
    },
  },
  linked_sources: {
    no_linked_sources: "Keine Quellen verknüpft",
    add_linked_source_tooltip: "Quelle verknüpfen",
    no_sources_available: "Keine Quellen verfügbar",
    no_sources_found: "Keine Quellen gefunden",
    labels: {
      linked_sources: "Quellen",
    },
    selected_sources_count: "{{count}} gewählt",
  },
  sources_configuration_page: {
    title: "Quellen",
  },
  sources_configuration_component: {
    empty_notice: "Es wurden noch keine Quellen für diese Organisation angelegt.",
    buttons: {
      create: "Erstellen",
    },
    confirm: {
      title: "Quelle entfernen?",
      body: "Sind Sie sicher, dass Sie diese Quelle entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden!",
    },
  },
  sources_edit_dialog: {
    title_create: "Quelle erstellen",
    title_edit: "Quelle bearbeiten",
    labels: {
      name: "Name",
      description: "Beschreibung der oder Link zur Quelle",
      source_type: "Art der Quelle",
      manage_files: "Dateien verwalten",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein",
      source_type_required: "Bitte wählen Sie eine Option",
    },
  },
  sources_list_component: {
    headers: {
      name: "Name",
      description: "Beschreibung",
      source_type: "Art der Quelle",
    },
  },
  sources_files_dialog: {
    title: "Dateien bearbeiten",
    name: "Datei",
    type: "Dateityp",
    delete_title: "Datei von Quelle {{sourceName}} entfernen?",
    delete_body:
      "Sind Sie sicher, dass Sie diese Datei entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden!",
    upload_file: "Hochladen",
    no_files_uploaded: "Es wurden noch keine Dateien hochgeladen.",
    file_constraints:
      "Dateien müssen kleiner als {{maxFileSizeTextRepr}} sein. Folgende Dateitypen sind erlaubt: {{allowedFileTypes}}",
  },
  // DMA Categories
  dma_category_delete_confirm_dialog: {
    title: 'Thema "{{name}}" löschen?',
    content_text: 'Sind Sie sicher, dass Sie das Thema "{{name}}" löschen möchten?',
    button_cancel: "Abbrechen",
    button_delete: "Löschen",
  },
  dma_category_edit_dialog: {
    title_create: "Neues Thema erstellen",
    title_edit: "Thema {{name}} bearbeiten",
    delete_tooltip: "Thema entfernen",
    cannot_delete_tooltip:
      "Ein Thema kann nur gelöscht werden, wenn es keine untergeordneten " +
      "Themen und Auswirkungen, Chancen oder Risiken besitzt und kein Feedback angefordert bzw. manuell eingetragen wurde.",
    error_name_required: "Bitte geben Sie einen Namen ein.",
    name_label: "Name",
    button_cancel: "Abbrechen",
    button_create: "Erstellen",
    button_save: "Speichern",
  },
  dma_category_opt_out_dialog: {
    title_opt_out: "Dieses Thema als nicht relevant markieren?",
    title_edit: "Begründung aktualisieren",
    content_text:
      'Sind Sie sicher, dass Sie das Thema "{{name}}" und alle untergeordneten Themen als nicht relevant markieren möchten? ' +
      'Gehen Sie in Ihrer Begründung darauf ein, warum "{{name}}" und ggf. die zugehörigen Unterthemen für Ihre Organisation nicht relevant sind. ' +
      "Sie können Ihre Entscheidung jederzeit rückgängig machen.",
    label_opt_out_reason: "Begründung",
    error_opt_out_reason_required: "Bitte geben Sie eine Begründung an.",
    button_cancel: "Abbrechen",
    button_opt_out: "Bestätigen",
    button_edit: "Speichern",
  },
  dma_category_opt_back_in_confirm_dialog: {
    title: "Thema auf relevant zurücksetzen?",
    content:
      'Sind Sie sicher, dass Sie das Thema "{{name}}" und alle untergeordneten Themen ' +
      "wieder auf relevant zurücksetzen möchten?",
    button_confirm: "Zurücksetzen",
  },
  // ESRS Topic
  dma_esrs_topic_component: {
    create_category_button: "Thema erstellen",
    create_category_button_tooltip:
      'Ein neues Thema zum übergeordneten Thema "{{esrsTopicName}}" erstellen.',
  },
  dma_esrs_topic_not_yet_recorded_component: {
    header: "Für dieses Thema wurde noch nichts erfasst.",
    start_collection: "Sie können nun entweder mit der",
    start_collection_button: "Vorlage verwenden",
    start_collection_button_tooltip:
      "Themen aus Standardvorlage für die Erfassung von Auswirkungen oder Chancen / Risiken für dieses Thema verwenden. " +
      "Alternative können Sie über die nachfolgenden Schaltfläche selbst Themen erstellen",
    skip_collection: "oder die",
    skip_collection_button: "Keine Auswirkungen",
    skip_collection_button_tooltip:
      "Keine Auswirkungen oder Chancen / Risiken für dieses Thema erfassen",
  },
  dma_esrs_topic_opted_out_component: {
    header: "Sie haben sich gegen die Erfassung für dieses Thema entschieden.",
    opt_out_reason_prefix: "Begründung:",
    start_collection_button: "Vorlage verwenden",
    start_collection_button_tooltip:
      "Themen aus Standardvorlage für die Erfassung von Auswirkungen oder Chancen / Risiken für dieses Thema verwenden. " +
      "Alternative können Sie über die nachfolgenden Schaltfläche selbst Themen erstellen",
    edit_opt_out_reason_button_tooltip: "Begründung bearbeiten",
  },
  // Tabular Data
  tabular_input_component: {
    header_dynamic_table: "Aktionen",
    header_required_tooltip: "Werte dieser Spalte müssen verpflichtend erfasst werden.",
  },
  dynamic_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Neue Zeile hinzufügen",
      button_add_row: "Hinzufügen",
      tooltip_save: "Änderungen speichern",
      button_save: "Speichern",
    },
    row: {
      tooltip_remove_row: "Zeile löschen",
      tooltip_save_row: "Zeile speichern",
      tooltip_undo_row: "Zeile zurücksetzen",
      tooltip_add_note: "Notiz hinzufügen",
      tooltip_remove_note: "Notiz entfernen",
    },
  },
  static_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Neue Zeile hinzufügen",
      button_add_row: "Hinzufügen",
      tooltip_save: "Tabelle speichern",
      tooltip_reset_table: "Tabelle zurücksetzen",
      button_save: "Speichern",
      tooltip_remove_note: "Notiz entfernen",
      tooltip_add_note: "Notiz hinzufügen",
    },
    confirm_dialog: {
      title: "Tabelle zurücksetzen?",
      body: "Sind sie sicher, dass Sie die Tabelle zurücksetzen möchten? Hierbei gehen sämtliche Daten verloren. Diese Aktion kann nicht rückgängig gemacht werden.",
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "Löschen",
    },
    row: {
      tooltip_undo_row: "Zeile zurücksetzen",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_overview_component: {
    title: 'Feedback für "{{name}}"',
    subtitle_no_rps:
      'Für die Organisation "{{organizationName}}" wurden noch keine Erfassungsperioden angelegt, weshalb Stakeholder-Feedback noch nicht möglich ist',
  },
  stakeholder_feedback_details_component: {
    select_title: "Erfassungszeitraum:",
  },
  stakeholder_feedbacks_component: {
    no_feedback_requested:
      "Für den gewählten Erfassungszeitraum wurde noch kein Feedback angefordert.",
    buttons: {
      previous: "Vorheriges Thema",
      next: "Nächstes Thema",
    },
  },
  stakeholder_feedback_category_component: {
    material_impacts_header: "Auswirkungen",
    financial_effects_header: "Chancen und Risiken",
    button_add_feedback: "Feedback abgeben",
    tooltip_add_feedback_impacts: "Feedback für Auswirkungen abgeben",
    tooltip_add_feedback_effects: "Feedback für Chancen und Risiken abgeben",
    button_edit_feedback: "Feedback bearbeiten",
    tooltip_edit_feedback_impacts: "Feedback für Auswirkungen bearbeiten",
    tooltip_edit_feedback_effects: "Feedback für Chancen und Risiken bearbeiten",
    alert_not_yet_shared: {
      title: "Thema wurde noch nicht freigegeben",
      content:
        "Das Thema wurde noch nicht freigegeben, es kann daher noch zu Änderungen der " +
        "Datenlage kommen, nachdem Sie Ihr Feedback abgegeben haben. Bitte klären Sie den " +
        "Datenstand vor Ihrer Feedbackabgabe mit Ihrer Kontaktperson ab.",
    },
    alert_category_open: {
      title: "Thema noch nicht erfasst",
      content: "Für dieses Thema wurden noch keine Auswirkungen oder Chancen/Risiken erfasst.",
    },
    alert_no_iro_material: "Zu diesem Thema wurden keine Auswirkungen identifiziert.",
    alert_no_iro_financial: "Zu diesem Thema wurden keine Chancen/Risiken identifiziert.",
    category_verified_notice_material:
      "Auswirkungen abgeschlossen - Feedback kann daher nicht mehr verändert werden.",
    category_verified_notice_financial:
      "Chancen und Risiken abgeschlossen - Feedback kann daher nicht mehr verändert werden.",
    feedback_indicator: {
      status_submitted: "Feedback abgegeben",
      "status_not-submitted": "Feedback ausständig",
      "status_parent-submitted":
        "Thema wurde im Feedback des übergeordneten Themas berücksichtigt.",
      "status_parent-not-submitted":
        "Thema ist im Feedback des übergeordneten Themas zu berücksichtigen.",
    },
  },
  stakeholder_feedback_dialog: {
    title_material: "Feedback für Auswirkungen - {{name}}",
    title_financial: "Feedback für Chancen und Risiken - {{name}}",
    label_agreed: "Ich teile die Einschätzung.",
    label_feedback: "Feedback",
    label_assessment: "Bewertung",
    label_add_divergent_assessment: "Abweichende Bewertung vorschlagen",
    explanation_material_assessment: "Wie bewerten Sie diese Auswirkung?",
    explanation_financial_assessment: "Wie bewerten Sie diese Chance / dieses Risiko?",
    // Slider values
    slider_value_very_low: "1 - Sehr niedrig",
    slider_value_low: "2 - Eher niedrig",
    slider_value_average: "3 - Mittel",
    slider_value_high: "4 - Eher hoch",
    slider_value_very_high: "5 - Sehr hoch",
    slider_info_box_text:
      "Bewerten Sie die Wesentlichkeit der Auswirkungen, Risikos oder der Chancen für das Unternehmen im Kontext des Themas “{{topicTitle}}” auf einer Skala von 1-5.",
    slider_info_box_text_material:
      "Bewerten Sie die Wesentlichkeit der Auswirkungen für das Unternehmen im Kontext des Themas “{{topicTitle}}” auf einer Skala von 1-5.",
    slider_info_box_text_financial:
      "Bewerten Sie die Wesentlichkeit der Chancen oder der Risikos für das Unternehmen im Kontext des Themas “{{topicTitle}}” auf einer Skala von 1-5.",
    // Buttons
    button_cancel: "$t(buttons:cancel)",
    button_save: "$t(buttons:save)",
    button_create: "Feedback abgeben",
    button_close: "$t(buttons:close)",
    // Validation
    error_at_least_one_form_of_feedback_required:
      "Es muss zumindest eine Form des Feedbacks (Feedback oder abweichende Bewertung) angegeben werden.",
    // Materiality details
    materiality_materiality_degree_label: "Wesentlichkeitsgrad",
    materiality_material_label: "Wesentlich?",
    material_yes: "$t(dma_common:materiality_true_state)",
    material_no: "$t(dma_common:materiality_false_state)",
    materiality_opt_out_title: "Thema als nicht wesentlich markiert:",
    materiality_tooltip_materiality_degree: "Wesentlichkeitsgrad (Maximalwert: 5)",
  },
  // Reference Budgets
  reference_budgets_page: {
    title: "Referenzbudgets",
  },
  reference_budgets_component: {
    empty_notice: "Es wurden noch keine Referenzbudgets für die Organisation angelegt.",
    buttons: {
      create: "Erstellen",
    },
    confirm: {
      title: "Referenzbudget entfernen?",
      body: "Sind Sie sicher, dass Sie das Referenzbudget entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    refresh: {
      title: "Daten für Referenzbudget aktualisieren?",
      body: "Sind Sie sicher, dass Sie die Daten für das Referenzbudget aktualisieren möchten? Die alten Daten werden hiermit überschrieben und sind anschließend nicht mehr zugänglich!",
    },
  },
  reference_budgets_edit_list_component: {
    headers: {
      name: "Name",
      values_fetched_at: "Datenstand von",
      reference_year: "Referenzjahr",
      reference_revenue: "Referenzumsatz",
      budget_per_year: "Jahresbudget",
    },
    action_tooltips: {
      edit: "Referenzbudget bearbeiten",
      delete: "Referenzbudget löschen",
      refresh: "Daten für das Referenzbudget aktualisieren",
    },
  },
  reference_budget_edit_dialog: {
    title_create: "Referenzbudget erstellen",
    title_edit: "Referenzbudget bearbeiten",
    labels: {
      name: "Name",
      recording_periods: "Erfassungsstrukturen für Referenzbudget",
      reference_revenue: "Referenzumsatz",
      reference_year: "Referenzjahr",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein.",
      recording_periods_required: "Es muss zumindest eine Erfassungsstruktur ausgewählt werden.",
    },
    notices: {
      recording_periods_have_changed:
        "Die Erfassungsstrukturen wurden angepasst - somit wird das Budget neu berechnet. Dies kann nicht rückgängig gemacht werden!",
    },
  },
  // Target Paths
  target_paths_configuration_page: {
    title: "Zielpfade",
  },
  target_paths_configuration_component: {
    empty_notice: "Es wurden noch keine Zielpfade für die Organisation angelegt.",
    notice_no_reference_budget: {
      text: "Sie müssen zumindest ein Referenzbudget erstellen, bevor Sie Zielpfade konfigurieren können.",
      button_text: "Zu den Referenzbudgets",
    },
    subheaders: {
      current_organization_path: "Festgelegter Unternehmenszielpfad",
      paths_in_progress: "Zielpfade in Arbeit",
    },
    buttons: {
      create: "Erstellen",
    },
    tooltips: {},
  },
  target_path_dialogs: {
    confirm: {
      title: "Zielpfad entfernen?",
      body: "Sind Sie sicher, dass Sie den Zielpfad entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    confirm_toggle: {
      for_state: {
        [ITargetPathState.Committed]: {
          title: "Zielpfad auf Entwurf zurückstufen?",
          body: "Sind sie sicher, dass die den Zielpfad auf Entwurf zurückstufen möchten? Diese Aktion kann jederzeit rückgängig gemacht werden.",
        },
        [ITargetPathState.Draft]: {
          title: "Zielpfad als Organisationszielpfad festlegen?",
          body_1:
            'Sind Sie sicher, dass sie "{{targetPathName}}" als Organisationszielpfad festlegen möchten?',
          body_2: 'Durch diese Aktion werden folgende Pfade auf "Entwurf" zurückgestuft:',
        },
      },
    },
  },
  target_path_progress_component: {
    header: "Fortschritt",
    labels: {
      recording_structures_for_evaluation: "Erfassungsstrukturen für die Auswertung",
    },
  },
  target_paths_edit_list_component: {
    headers: {
      name: "Name",
      reference_budget_name: "Gewähltes Referenzbudget",
      state: "Status",
    },
    action_tooltips: {
      edit: "Zielpfad bearbeiten",
      edit_disabled:
        "Der Zielpfad kann nicht mehr bearbeitet werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      delete: "Zielpfad löschen",
      delete_disabled:
        "Der Zielpfad kann nicht mehr gelöscht werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      commit: "Als Organisationszielpfad festlegen",
      commit_disabled:
        "Dieser Pfad kann nicht als Organisationspfad festgelegt werden, da keine Ziele hinterlegt wurden!",
      cease: "Auf Entwurf zurückstufen",
      clone: "Zielpfad kopieren",
    },
  },
  target_path_actions_component: {
    action_tooltips: {
      edit: "Zielpfad bearbeiten",
      edit_disabled:
        "Der Zielpfad kann nicht mehr bearbeitet werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      delete: "Zielpfad löschen",
      delete_disabled:
        "Der Zielpfad kann nicht mehr gelöscht werden, da er bereits festgelegt wurde / die Ziele bereits erreicht wurden.",
      commit: "Als Organisationszielpfad festlegen",
      commit_disabled:
        "Dieser Pfad kann nicht als Organisationspfad festgelegt werden, da keine Ziele hinterlegt wurden!",
      cease: "Auf Entwurf zurückstufen",
      clone: "Zielpfad kopieren",
    },
  },
  target_path_edit_dialog: {
    title_create: "Zielpfad erstellen",
    title_edit: "Zielpfad bearbeiten",
    labels: {
      name: "Name",
      reference_budget: "Referenzbudget für Zielpfad",
      use_template: "Template für Ziele verwenden?",
      template: "Zieltemplate",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein.",
      name_unique: "Der Name muss innerhalb der Organisation eindeutig sein.",
    },
    tooltips: {
      use_template:
        "Es werden automatisch Beispielziele erstellt, die Sie bei der Konfiguration unterstützen.",
    },
    goals: {
      [ITargetPathGoalTemplateType.ParisConform]: "Paris-konformer Standard-Zielpfad",
    },
  },
  target_path_clone_dialog: {
    title: "Zielpfad kopieren",
    labels: {
      name: "Neuer Name",
    },
    errors: {
      name_required: "Bitte geben Sie einen Namen ein.",
      name_unique: "Der Name muss innerhalb der Organisation eindeutig sein.",
    },
  },
  target_path_state_chip_component: {
    text_by_state: {
      [ITargetPathState.Draft]: "Entwurf",
      [ITargetPathState.Committed]: "Festgelegt",
      [ITargetPathState.Fulfilled]: "Erfüllt",
    },
    tooltip_text_by_state: {
      [ITargetPathState.Draft]:
        "Dieser Zielpfad befindet sich noch im Entwicklungsstadium. Änderungen sind jederzeit möglich.",
      [ITargetPathState.Committed]:
        "Dieser Zielpfad wurde als Richtung für das Unternehmen gewählt und kann deshalb nicht bearbeitet werden.",
      [ITargetPathState.Fulfilled]:
        "Die aus diesem Pfad abgeleiteten Ziele wurden bereits erfüllt. Er kann nicht mehr bearbeitet werden.",
    },
  },
  target_path_is_committed_info_component: {
    title: "Info",
    text: "Dieser Zielpfad wurde als Organisationszielpfad festgelegt. Er kann deshalb nicht bearbeitet werden.",
  },
  // Target Path Goals
  target_path_details_component: {
    title: 'Zielpfad "{{targetPathName}}"',
  },
  target_path_goals_edit_list_component: {
    title: "Ziele",
    headers: {
      target_year: "Zieljahr",
      reduction_to_reference_year: "Reduktion/Steigerung zu Referenzjahr",
      emissions_in_target_year: "Emissionen im Zieljahr (Gesamt)",
    },
    buttons: {
      create: "Hinzufügen",
    },
    confirm: {
      title: "Ziel entfernen?",
      body: "Sind Sie sicher, dass Sie das Ziel entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    },
    action_tooltips: {
      edit: "Ziel bearbeiten",
      delete: "Ziel löschen",
    },
  },
  target_path_goal_edit_dialog_component: {
    title_create: "Ziel erstellen",
    title_edit: "Ziel bearbeiten",
    labels: {
      target_year: "Zieljahr",
    },
    errors: {
      date: {
        minDate:
          "Das ausgewählte Zieljahr darf nicht vor dem Referenzjahr liegen ({{referenceYear}}).",
        targetForYearAlreadyDefined:
          "Es wurde bereits ein Ziel für das Zieljahr {{targetYear}} festgelegt.",
      },
    },
  },
  target_path_goal_edit_component: {
    toggle: {
      text_absolute: "Absolut",
      text_relative: "Relativ",
    },
    confirm_dialog: {
      title: "Erfassungsart ändern?",
      body: "Sind Sie sicher, dass Sie die Erfassungsart ändern möchten? Sämtliche bis jetzt eingegebene Werte werden gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    },
  },
  target_path_goal_value_change_input_component: {
    labels: {
      change: "Reduktion",
      change_type: "Änderungsart",
    },
  },
  absolute_target_path_goal_edit_component: {
    translations: {
      in_percent: "Reduktion in [%]",
      by_value: "Reduktion um [tCO\u2082eq]",
      to_value: "Reduktion auf [tCO\u2082eq]",
    },
    helper_text: "Referenzbudget: {{referenceBudget}}",
    helper_text_no_value: "Kein Referenzbudget vorhanden",
    scopes: {
      scope_reductionScope1: "Reduktion Scope 1",
      scope_reductionScope2: "Reduktion Scope 2",
      scope_reductionScope3: "Reduktion Scope 3",
    },
    tooltips: {
      reduction_as_percent: "Das entspricht einer {{reductionTerm}} von {{percentage}} %.",
      reduction_as_by_value: "Das entspricht einer {{reductionTerm}} von {{value}} t CO\u2082eq.",
      no_value:
        "Es kann kein Wert eingetragen werden, da kein Referenzbudget zu diesem Scope existiert.",
    },
  },
  relative_target_path_goal_edit_component: {
    translations: {
      in_percent: "Jährliches Umsatzwachstum in [%]",
      by_value: "Wachstum um [€]",
      to_value: "Erwarteter Umsatz im Zieljahr",
    },
    headings: {
      revenue: "Umsatz",
      intensity: "Intensität",
    },
    revenue: {
      textfield_label_growth: "Umsatzwachstum",
      textfield_label_absolute: "Zielumsatz",
      helper_text: "Referenzumsatz: {{referenceRevenue}}",
      tooltip_dropdown_no_revenue:
        "Es kann nur ein konkretes Umsatzziel (und keine Veränderung) eingeben werden, da kein Referenzumsatz hinterlegt wurde.",
    },
  },
  target_intensity_input_component: {
    helper_text_no_value: "Kein Referenzbudget vorhanden",
    tooltip_no_value:
      "Es kann kein Wert eingetragen werden, da kein Referenzbudget zu diesem Scope existiert.",
    tooltip_value:
      "Dies entspricht Emissionen von {{absoluteValue}} t CO\u2082eq bzw. einer {{reductionTerm}} von {{relativeReduction}} %.",
    scopes: {
      intensityScope1: "Zielintensität Scope 1",
      intensityScope2: "Zielintensität Scope 2",
      intensityScope3: "Zielintensität Scope 3",
    },
  },
  reference_intensity_component: {
    content: "Die {{textSum}} Referenzintensität beträgt: {{referenceIntensity}} tCO\u2082eq / €.",
  },
  target_path_diagram_component: {
    title: "Diagram",
    title_for_organization_path: "Unternehmenszielpfad",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    } as const,
  },
  target_path_general: {
    terms: {
      reduction: "Reduktion",
      increase: "Steigerung",
    },
  },
  target_path_progress_diagram_component: {
    title_for_organization_path: "Unternehmenszielpfad",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    },
  },
  // Input Components
  list_value_input_component: {
    empty_option: "Kein Wert",
  },
  nested_options_input_component: {
    buttons: {
      close: "Schließen",
      clear: "Auswahl zurücksetzen",
    },
    search: {
      placeholder: "Suchen...",
    },
  },
  // Input Validation
  value_validation_errors: {
    required: "Bitte geben Sie einen Wert für dieses Feld ein.",
    number: {
      base: "Bitte geben Sie eine Zahl an.",
      precision: "Der Wert darf höchstens {{limit}} Nachkommastellen haben.",
      min: "Der Wert muss größer oder gleich {{limit}} sein.",
      max: "Der Wert muss kleiner oder gleich {{limit}} sein.",
      unsafe: "Der Wert muss eine sichere Zahl sein.",
    },
    boolean: {
      base: "Bitte wählen Sie einen Wert.",
    },
    string: {
      empty: "Bitte geben Sie einen Text ein.",
    },
    array: {
      includesRequiredUnknowns: "Es muss zumindest eine Option gewählt werden.",
      sparse: "Bitte füllen Sie alle Felder aus.",
      max: "Es dürfen maximal {{limit}} Werte gewählt werden.",
    },
    date: {
      format: "Bitte geben Sie ein gültiges Datum ein.",
      min: "Das Datum muss nach dem {{limit}} liegen.",
      max: "Das Datum muss vor dem {{limit}} liegen.",
      greater: "Das Startdatum muss vor dem Enddatum liegen.",
    },
    any: {
      only: "Bitte wählen Sie eine Option.",
      not_unique: "Nicht einzigartig.",
    },
    "text-area": {
      invalid_content: "Fehlerhafter Inhalt konnte nicht verarbeitet werden.",
    },
  },
  number_input: {
    placeholder: "Zahl eingeben",
  },
  currency_input: {
    placeholder: "Betrag eingeben",
  },
  text_input: {
    placeholder: "Text eingeben",
  },
  text_area_input: {
    placeholder: {
      default: "Text eingeben",
      input_parameter: "Text eingeben",
      disclosure_requirement:
        "Geben Sie hier alle Angaben zur Offenlegungspflicht ein und " +
        "orientieren Sie sich an den untenstehenden Datenpunkten.",
    },
  },
  boolean_input: {
    value_true: "Ja",
    value_false: "Nein",
  },
  date_input_component: {
    remove_date_tooltip: "Datum entfernen",
  },
  value_with_unit_input: {
    placeholder: "Zahl eingeben",
    // per_monetary: "Millionen €",
    per_monetary: "Mio. €",
  },
  referenced_target_input: {
    placeholder_one: "Ziel auswählen",
    placeholder_other: "Ziele auswählen",
    dialog: {
      title_one: "Ziel auswählen",
      title_other: "Ziele auswählen",
      table_header_name: "Ziel",
    },
  },
  // Intercom
  intercom_reference_widget_component: {
    tooltip: "Hilfe öffnen",
  },
  // Errors
  error: {
    axios: {
      connection: "Verbindungsfehler - Bitte überprüfen Sie Ihre Internetverbindung",
    },
    generic: {
      "400": "Fehlerhafte Anfrage",
      "401": "Nicht autorisiert",
      "403": "Keine Berechtigung",
      "404": "Nicht gefunden",
      "409": "Konflikt",
      "500": "Interner Serverfehler",
      "502": "API nicht verfügbar",
    },
    general: {
      entity_not_found: "Nicht gefunden.",
      internal_server_error: "Interner Serverfehler.",
    },
    auth: {
      unauthorized:
        "Ihre Sitzung ist abgelaufen oder Ihre Zugangsdaten sind ungültig. Bitte melden Sie sich erneut an.",
    },
    input_parameter: {
      not_found: "Der Wert konnte nicht gefunden werden.",
    },
    organization: {
      not_found: "Die Organisation konnte nicht gefunden werden.",
    },
    recording_period: {
      not_found: "Die Erfassungsperiode konnte nicht gefunden werden.",
      previous_structure_is_draft:
        "Die vorherige Organisationsstruktur ist ein Entwurf. Sie muss veröffentlicht werden bevor eine neue Erfassungsperiode erstellt werden kann.",
      value_would_move_outside_scope:
        "Es wurden bereits Werte für die Erfassungsperiode außerhalb des neuen Zeitraums angegeben!",
      overlaps_with_existing_recording_period:
        "Der angegebene Zeitraum überschneidet sich mit einer bereits existierenden Erfassungsperiode!",
    },
    recording_model: {
      not_found: "Das Erfassungsmodell konnte nicht gefunden werden.",
    },
    recording_model_templates_group: {
      not_found: "Das Thema konnte nicht gefunden werden.",
    },
    input_parameter_recording_structure_template: {
      not_found: "Die Vorlage konnte nicht gefunden werden.",
      no_organization_license: "Lizenz für Organisation inkludiert Template nicht.",
    },
    input_parameter_recording_structure: {
      not_found: "Die Erfassungsstruktur konnte nicht gefunden werden.",
      input_parameter_used_more_than_once:
        "Ein Wert darf nur einmal innerhalb der Erfassungsstruktur erfasst werden!",
      input_parameter_not_found:
        "In der Erfassungsstruktur wurde zumindest ein unbekannter Wert zur Erfassung angegeben!",
      order_in_use:
        "Die angegebene Reihung der Erfassungsstruktur innerhalb des Erfassungsmodells wird bereits verwendet!",
      no_organization_license: "Lizenz für Organisation inkludiert Struktur nicht!",
    },
    organization_structure: {
      not_found: "Die Organisation Struktur konnte nicht gefunden werden.",
      not_yet_committed: "Die Organisation Struktur wurde noch nicht veröffentlicht.",
      already_committed: "Der Entwurf wurde bereits veröffentlicht.",
      duplicate_ids:
        "Unerwarteter Fehler! Die Werte eines vergangenen " +
        "Erfassungsobjekts werden an mehrere neue Erfassungsobjekte vererbt!",
      id_doesnt_belong_to_previous_structure:
        "Unerwarteter Fehler! Es wurde versucht, ein Erfassungsobjekt zu vererben, " +
        "das nicht Teil der vorherigen Organisationsstruktur war!",
    },
    input_parameter_recording_method: {
      not_found: "Verwendete Erfassungsmethode für Wert nicht gefunden.",
    },
    data_entry_object: {
      not_found: "Das Datenobjekt konnte nicht gefunden werden.",
      next_already_set:
        "Das Datenobjekt wird bereits von einem anderen Datenobjekt als Vorgänger " +
        "(erfasste Werte werden vererbt) verwendet.",
    },
    data_entry_object_input_parameter_value: {
      bad_value: "Fehlerhafter Wert!",
      value_already_recorded_for_period:
        "Wert wurde für den gegebenen Erfassungszeitraum bereits erfasst.",
      not_found: "Wert wird für das gegebene Erfassungsobjekt nicht erfasst.",
      value_outside_recording_period:
        "Das Datum für den Wert liegt außerhalb der Erfassungsperiode für die er erfasst wurde!",
    },

    dma_category: {
      not_found: "Das Thema konnte nicht gefunden werden.",
      has_children: "Aktion nicht möglich: Das Thema hat untergeordnete Themen!",
      has_associated_financial_effects:
        "Aktion nicht möglich: Das Thema hat Chancen und Risiken untergeordnet!",
      has_associated_material_impacts:
        "Aktion nicht möglich: Das Thema hat Auswirkungen untergeordnet!",
      opt_out_of_category_with_iros:
        "Opt Out nicht möglich: Das Thema hat Auswirkungen, Chancen oder Risiken untergeordnet!",
      opt_out_without_reason: "Als nicht relevant markieren ohne Begründung nicht möglich",
      opt_in_with_reason:
        "Als nicht relevant markieren rückgängig machen mit Begründung nicht möglich",
      has_opted_out: "Bereits als nicht relevant markiert",
      in_invalid_state: "Status des Themas für Abschluss ist ungültig",
      already_verified: "Thema ist bereits abgeschlossen",
    },
    dma_effect: {
      reason_was_not_provided: "Eine Begründung muss angegeben werden.",
      reason_was_provided_without_override:
        "Es darf keine Begründung ohne abweichende Wesentlichkeit angegeben werden!",
      cannot_be_moved_to_opt_out_category:
        "Verschieben in ein nicht relevantes Thema ist nicht möglich!",
      cannot_be_moved_to_opt_out_topic:
        "Verschieben in ein nicht relevantes Thema ist nicht möglich!",
    },
    dma_financial_effect: {
      not_found: "Chance/Risiko nicht gefunden",
      not_responsible_user: "Ihr Benutzer ist nicht für die Chance/Risiko verantwortlich",
      already_published:
        "Chance/Risiko ist bereits freigegeben und kann nicht mehr verändert werden",
      incomplete_for_shared:
        "Um eine Chance/Risiko freizugeben müssen Sie eine Erläuterung der Bewertung angeben.",
      referenced_in_policies:
        "Die Chance/Risiko wird von einem Konzept referenziert. Deshalb kann ihr Status nicht mehr zu 'Entwurf' geändert werden.",
      referenced_in_actions:
        "Die Chance/Risiko wird von einer Maßnahme referenziert. Deshalb kann ihr Status nicht mehr zu 'Entwurf' geändert werden.",
    },
    dma_material_impact: {
      not_found: "Auswirkung nicht gefunden.",
      not_responsible_user: "Keine verantwortliche Person für die Auswirkung angegeben.",
      already_published: "Bereits freigegeben.",
      incomplete_for_shared:
        "Um eine Chance/Risiko freizugeben müssen Sie eine Erläuterung der Bewertung angeben.",
      referenced_in_policies:
        "Die Auswirkung wird von einem Konzept referenziert. Deshalb kann ihr Status nicht mehr zu 'Entwurf' geändert werden.",
      referenced_in_actions:
        "Die Auswirkung wird von einer Maßnahme referenziert. Deshalb kann ihr Status nicht mehr zu 'Entwurf' geändert werden.",
    },
    esrs_topic: {
      not_found: "ESRS-Thema nicht gefunden",
      does_not_support_custom_categories:
        "Für das gewählte ESRS-Thema können keine benutzerdefinierten Themen erstellt werden",
    },
    recorded_esrs_topic: {
      opt_out_of_topic_with_categories:
        "Keine Auswirkungen setzen für dieses Thema nicht möglich, da bereits Sub-Themen erstellt wurden",
      does_not_exist:
        "Das angegebene ESRS-Thema oder das verwendete Erfassungsobjekt existiert nicht",
      category_is_not_child: "Das angegebene Thema ist nicht Teil des angegebenen ESRS Themas",
      opt_out_without_reason:
        "Um keine Themen für ein ESRS Thema zu wählen müssen Sie eine Begründung angeben",
      opt_in_with_reason:
        "Um keine Themen rückgängig zu machen darf keine Begründung angegeben werden",
    },
    dma_execution: {
      invalid_category: "Ungültiges Thema",
      feedback_missing: "Feedback fehlt",
    },
    data_entry_object_input_parameter: {
      attribution_reason_not_present: "",
      explanation_required: "Für den ausgewählten Grund ist eine Begründung erforderlich.",
    },
    stakeholder: {
      not_found: "Stakeholder nicht gefunden",
      has_associated_feedback:
        "Der Stakeholder konnte nicht gelöscht werden, da er Feedback hinterlegt hat.",
    },
    stakeholder_feedback: {
      not_found: "Stakeholder-Feedback nicht gefunden",
      invalid:
        "Feedback fehlerhaft - entweder muss zugestimmt werden und nur ein Kommentar " +
        "(ohne anderen Bewertungsvorschlag) oder, wenn die Bewertung nicht geteilt wurde, " +
        "zumindest ein anderer Bewertungsvorschlag oder ein Feedback Text angegeben werden.",
      already_submitted: "Feedback wurde bereits abgegeben",
    },
    manual_stakeholder_feedback: {
      not_found: "Manuelles Stakeholder-Feedback nicht gefunden",
    },
    // Distribution Criterion
    distribution_criterion: {
      not_found: "Aufteilungsschlüssel nicht gefunden",
      already_in_use:
        "Der Aufteilungsschlüssel wird bereits verwendet und kann daher nicht gelöscht werden.",
    },
    // Reference Budget
    reference_budget: {
      is_used_in_target_path:
        "Das Referenzbudget wird in einem Zielpfad verwendet und kann daher nicht gelöscht werden.",
      not_all_target_paths_in_draft_state:
        "Das Budget wird bereits von Zielpfaden referenziert, die festgelegt wurden. Das berechnete CO\u2082 Budget kann deshalb nicht mehr verändert werden.",
    },
    // Source
    source: {
      is_referenced: "Die Quelle wird bereits referenziert und kann daher nicht gelöscht werden.",
    },
    // Source Files
    source_file: {
      invalid_mime_type: "Die Datei oder eine der Dateien hat einen ungültigen Dateityp.",
    },
    // S3 Errors
    EntityTooLarge: "Die Datei oder eine der Dateien ist zu groß.",
    unknown: "Unbekannter Fehler",
    update_user_org_role: {
      forbidden: "Unzulässige Rollenzuweisung",
    },
    update_user_data_entry_objects_access: {
      user_is_not_data_entry_object_admin:
        "Der Benutzer muss die Berechtigung Objektzugriff haben!",
      missing_data_entry_object_descendants:
        "Es müssen alle untergeordneten Erfassungsobjekte ausgewählt werden.",
    },
  },
  not_found: {
    title: "Diese Seite wurde nicht gefunden",
    go_to_dashboard: "Zurück zur Startseite",
  },
  dma_categories: generateDMACategoryTranslations(IContentLanguage.De),
  esrs_topics: generateESRSTopicTranslations(IContentLanguage.De),
  users_table: {
    first_name: "Vorname",
    last_name: "Nachname",
    email: "E-Mail",
    role: "Rolle",
    no_users_found: "Keine Benutzer gefunden",
  },
  esrs_paywall: {
    title: "ESRS-Datenerfassungsmodul nicht freigeschaltet",
    content:
      "Ihr Unternehmenskonto hat das ESRS-Datenerfassungsmodul noch nicht freigeschaltet. Klicken Sie auf den untenstehenden Link, um direkt mit unserem Team Kontakt aufzunehmen. Nach dem Kauf schalten wir das Modul umgehend für Sie frei.",
    cta: "Kontakt aufnehmen",
  },
  users_page: {
    title: "Benutzer",
  },
  roles: {
    organization_admin: "Vollzugriff",
    organization_admin_description:
      "Der Benutzer kann alle Aktionen ohnen Einschränkungen durchführen.",
    object_admin: "Objektzugriff",
    object_admin_description:
      "Der Benutzer wird einem oder mehreren Datenerfassungsobjekten zugeordnet und kann für diese und alle Töchterobjekte alle Datenerfassungspunkte sehen und bearbeiten.",
    view_members: "Limitiert",
    view_members_description:
      "Der Benutzer kann nur Datenpunkte sehen und bearbeiten, denen er als Verantwortlicher oder Mitarbeiter zugeordnet ist.",
  },
  require_organization_role: {
    access_denied: "Sie haben nicht die erforderliche Berechtigung, um diese Seite anzuzeigen.",
  },
  update_deo_access_dialog: {
    title: "Erfassungsobjekte zuweisen",
    deo_picker_label: "Erfassungsobjekte auswählen",
  },
  organizations_layout: {
    organization_disabled:
      "Der Zugang dieser Organisation ist deaktiviert.<br/><br/> Bitte wenden Sie sich an <link/>, wenn Sie NetCero weiterhin nutzen möchten",
  },
  emission_factors_page: {
    title: "Emissionsfaktoren",
    create_emission_factor: "Emissionsfaktor manuell erstellen",
  },
  emission_factor_dialog: {
    create_emission_factor: "Emissionsfaktor erstellen",
    edit_emission_factor: "Emissionsfaktor bearbeiten",
    name: "Name",
    country: "Land",
    version: "Version",
    type: "Typ",
    marked_based_emission_factor_type: "Art des marktbasierten Faktors",
    sources: "Quellen",
    description: "Beschreibung (optional)",
    ghg_category: "THG-Kategorie",
    factor: "Faktor",
    unit: "Einheit",
    supplier: "Lieferant (optional)",
    delete_emission_factor: "Emissionsfaktor löschen",
    delete_emission_factor_body: "Sind Sie sicher, dass Sie den Emissionsfaktor löschen möchten?",
    add_factor_for_indirect_emissions: "Faktor für indirekten Emissionen hinzufügen",
    scope_3_factor: "Scope 3 Faktor",
  },
  emission_factors_table: {
    no_emission_factors_found: "Keine Emissionsfaktoren gefunden",
    name: "Name",
    database: "Datenbank",
    type: "Typ",
    version: "Version",
    ghg_category: "THG-Kategorie",
    factor: "Faktor",
    country: "Land",
    unit: "Einheit",
  },
  emission_factors_filters: {
    name: "Suche nach Name...",
    databases: "Datenbank",
    emissionFactorTypes: "Typ",
    ghgCategories: "THG-Kategorie",
    countries: "Land",
    units: "Einheit",
  },
  market_based_emission_factor_types: MARKET_BASED_EMISSION_FACTOR_TYPES,
  emission_factor_units: EMISSION_FACTOR_UNITS,
  ghg_categories: GHG_CATEGORIES,
  emission_factor_databases: EMISSION_FACTOR_DATABASES,
  emission_factor_types: EMISSION_FACTOR_TYPES,
};
