import { IEmissionFactor } from "@netcero/netcero-core-api-client";

export enum IGhgProtocolScope {
  Scope1 = "scope_1",
  Scope2 = "scope_2",
  Scope3 = "scope_3",
}
export type EmissionScopeFactors = { [key in IGhgProtocolScope]?: number };

export interface ILocalEmissionFactor extends Omit<IEmissionFactor, "scopeFactors"> {
  scopeFactors: EmissionScopeFactors;
}
