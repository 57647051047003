import { FC, PropsWithChildren } from "react";
import { useHasOrganizationModuleAccess } from "./has-organization-module-access.hook";
import { AppModule } from "@netcero/netcero-common";
import { ModuleNotAccessibleNotice } from "./module-not-accessible-notice.component";
import { useParams } from "react-router-dom";

type IRequireModuleAccessProps = PropsWithChildren<{
  organizationId: string;
  module: AppModule;
  /** Whether an error should be displayed - default children are just hidden */
  showError?: boolean;
}>;

export const RequireModuleAccess: FC<IRequireModuleAccessProps> = ({
  organizationId,
  module,
  showError,
  children,
}) => {
  const hasAccess = useHasOrganizationModuleAccess(organizationId, module);
  return hasAccess ? children : showError ? <ModuleNotAccessibleNotice module={module} /> : null;
};

export const RequireModuleAccessOrganizationFromRouter: FC<
  Omit<IRequireModuleAccessProps, "organizationId">
> = (props) => {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error(
      "RequireModuleAccessOrganizationFromRouter must be used within a route with an organizationId param",
    );
  }

  return <RequireModuleAccess organizationId={organizationId} {...props} />;
};
