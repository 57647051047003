import { Box, Button, CardContent, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StyledCard } from "../../modules/common/components/styled-card.component";
import { useTranslation } from "react-i18next";
import { EmissionFactorsTable } from "../../modules/emission-factors/components/emission-factors-table.component";
import { EmissionFactorsFilters } from "../../modules/emission-factors/components/emission-factors-filters.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { EmissionFactorEditDialog } from "../../modules/emission-factors/components/emission-factor-edit-dialog.component";
import { ConfirmDialog } from "../../modules/common/dialogs/variants/confirm.dialog";
import {
  useCreateEmissionFactorMutation,
  useDeleteEmissionFactorMutation,
  useUpdateEmissionFactorMutation,
} from "../../modules/emission-factors/emission-factors.mutations";
import {
  useDialogState,
  useDialogStateWithoutData,
} from "../../modules/common/dialogs/dialog-state.hook";
import { useResetMutationsOnOpen } from "../../modules/common/hooks/use-reset-mutations-on-open.hook";
import { IBaseEmissionFactorData } from "@netcero/netcero-core-api-client";
import { IOrganizationIdentity, ROLE_ACCESS } from "@netcero/netcero-common";
import { useDoesUserHaveRole } from "../../modules/authentication/hooks/does-user-have-role.hook";

export const EmissionFactorsPage: FC = () => {
  const { organizationId } = useParams();

  return organizationId ? (
    <InternalEmissionFactorsPage organizationId={organizationId} />
  ) : (
    "Organization ID is missing"
  );
};

const InternalEmissionFactorsPage: FC<IOrganizationIdentity> = ({ organizationId }) => {
  const { t } = useTranslation("emission_factors_page");
  const navigate = useNavigate();
  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/emission-factors`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/emission-factors`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/emission-factors`);
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const [searchParams, setSearchParams] = useSearchParams();

  const canCreateEditDeleteEmissionFactor = useDoesUserHaveRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_EMISSION_FACTORS,
  );

  const createEmissionFactorMutation = useCreateEmissionFactorMutation();
  const updateEmissionFactorMutation = useUpdateEmissionFactorMutation();
  const deleteEmissionFactorMutation = useDeleteEmissionFactorMutation();

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();
  useResetMutationsOnOpen(isCreateOpen, createEmissionFactorMutation);

  const {
    isOpen: isEditOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: updateEmissionFactorId,
  } = useDialogState<string>();
  useResetMutationsOnOpen(isEditOpen, updateEmissionFactorMutation);

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    data: deleteEmissionFactorId,
  } = useDialogState<string>();
  useResetMutationsOnOpen(isDeleteDialogOpen, deleteEmissionFactorMutation);

  const handleCreate = useCallback(
    async (payload: IBaseEmissionFactorData | null) => {
      if (payload !== null) {
        await createEmissionFactorMutation.mutateAsync({
          organizationId: organizationId,
          payload,
        });
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createEmissionFactorMutation, organizationId],
  );

  const handleUpdate = useCallback(
    async (payload: IBaseEmissionFactorData | null) => {
      if (payload !== null && updateEmissionFactorId) {
        await updateEmissionFactorMutation.mutateAsync({
          organizationId: organizationId,
          emissionFactorId: updateEmissionFactorId,
          payload,
        });
      }
      closeEditDialog();
    },
    [closeEditDialog, updateEmissionFactorMutation, organizationId, updateEmissionFactorId],
  );

  const handleDelete = useCallback(async () => {
    if (organizationId !== undefined && deleteEmissionFactorId) {
      await deleteEmissionFactorMutation.mutateAsync({
        organizationId: organizationId,
        emissionFactorId: deleteEmissionFactorId,
      });
    }
    closeDeleteDialog();
  }, [closeDeleteDialog, deleteEmissionFactorMutation, organizationId, deleteEmissionFactorId]);

  if (!organizationId) {
    return null;
  }

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: t("configuration", { ns: "side_bar_nav_items" }),
      to: NavItemUrlUtilities.getConfigurationPageUrl(organizationId),
    },
    { label: t("configuration_emission_factors", { ns: "side_bar_nav_items" }) },
  ];

  return (
    <>
      {/* Create Dialog */}
      <EmissionFactorEditDialog
        open={isCreateOpen}
        loading={createEmissionFactorMutation.isPending}
        error={createEmissionFactorMutation.error}
        disabled={createEmissionFactorMutation.isPending}
        onClose={handleCreate}
        organizationId={organizationId}
      />
      {/* Edit Dialog */}
      <EmissionFactorEditDialog
        open={isEditOpen}
        loading={updateEmissionFactorMutation.isPending}
        error={updateEmissionFactorMutation.error}
        disabled={updateEmissionFactorMutation.isPending}
        onClose={handleUpdate}
        organizationId={organizationId}
        emissionFactorId={updateEmissionFactorId}
      />
      {/* Delete Dialog */}
      <ConfirmDialog
        open={isDeleteDialogOpen}
        error={deleteEmissionFactorMutation.error}
        loading={deleteEmissionFactorMutation.isPending}
        disabled={deleteEmissionFactorMutation.isPending}
        onCancel={closeDeleteDialog}
        onConfirm={handleDelete}
        title={t("delete_emission_factor", { ns: "emission_factor_dialog" })}
        content={t("delete_emission_factor_body", { ns: "emission_factor_dialog" })}
      />
      {/* Content */}
      <Box>
        <AppBreadCrumbs items={breadCrumbItems} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h1" mt={1}>
            {t("title")}
          </Typography>
          {canCreateEditDeleteEmissionFactor && (
            <Button variant="contained" onClick={() => openCreateDialog()}>
              {t("create_emission_factor")}
            </Button>
          )}
        </Box>
        <Box mt={2.5}>
          <EmissionFactorsFilters searchParams={searchParams} setSearchParams={setSearchParams} />
        </Box>
        <Box mt={4}>
          <StyledCard>
            <CardContent sx={{ p: 2.5 }}>
              <EmissionFactorsTable
                organizationId={organizationId}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                onEdit={(emissionFactorId) => {
                  openEditDialog(emissionFactorId);
                }}
                onDelete={(emissionFactorId) => {
                  openDeleteDialog(emissionFactorId);
                }}
                canEditDeleteEmissionFactor={canCreateEditDeleteEmissionFactor ?? false}
              />
            </CardContent>
          </StyledCard>
        </Box>
      </Box>
    </>
  );
};
