import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import {
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import {
  CountryCodeAlpha2Enum,
  IEmissionFactorIdentity,
  ILocalEmissionFactor,
  IOrganizationIdentity,
} from "@netcero/netcero-common";
import { IPaginationQueryParams } from "../pagination/pagination.types";

interface IGetEmissionFactorsQueryParams extends IOrganizationIdentity, IPaginationQueryParams {
  title?: string;
  emissionFactorDatabases?: IEmissionFactorDatabase[];
  emissionFactorTypes?: IEmissionFactorType[];
  ghgCategories?: IGhgCategoriesIdentifier[];
  countries?: CountryCodeAlpha2Enum[];
  units?: IEmissionFactorUnit[];
}

export const GET_EMISSIONS_FACTORS_BASE_QUERY_KEY = ["get-emission-factors"];
export const getGetEmissionFactorsQueryKey = (
  organizationId: string,
  queryParams?: Omit<IGetEmissionFactorsQueryParams, "organizationId">,
) => [
  ...GET_EMISSIONS_FACTORS_BASE_QUERY_KEY,
  organizationId,
  ...(queryParams ? [queryParams] : []),
];

export const GET_EMISSION_FACTOR_QUERY_KEY = ["get-emission-factor"];
export const getGetEmissionFactorQueryKey = ({
  organizationId,
  emissionFactorId,
}: IEmissionFactorIdentity) => [...GET_EMISSION_FACTOR_QUERY_KEY, organizationId, emissionFactorId];

export function useGetEmissionFactorsQuery({
  organizationId,
  ...queryParams
}: IGetEmissionFactorsQueryParams) {
  const {
    limit,
    offset,
    title,
    emissionFactorDatabases,
    emissionFactorTypes,
    ghgCategories,
    countries,
    units,
  } = queryParams;
  return useQuery({
    queryKey: getGetEmissionFactorsQueryKey(organizationId, queryParams),
    queryFn: async () => {
      return CoreApiService.EmissionFactorsApi.getEmissionFactors(
        organizationId,
        limit,
        offset,
        title,
        emissionFactorDatabases,
        emissionFactorTypes,
        ghgCategories,
        countries,
        units,
      )
        .then((req) => req())
        .then((res) => res.data as { total: number; items: ILocalEmissionFactor[] });
    },
  });
}

export function useGetEmissionFactorQuery(identity: IEmissionFactorIdentity, enabled = true) {
  return useQuery({
    queryKey: getGetEmissionFactorQueryKey(identity),
    queryFn: async () => {
      return CoreApiService.EmissionFactorApi.getEmissionFactor(
        identity.organizationId,
        identity.emissionFactorId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled: enabled && !!identity.organizationId && !!identity.emissionFactorId,
  });
}
