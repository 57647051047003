import { FC } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { IESRSTopic, IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";
import { useObserveSizeState } from "../../../common/hooks/use-observe-size-state.hook";

interface IIROsESRSTopicsTabsProps {
  selectedTopic: IESRSTopicIdentifier | null;
  esrsTopics: IESRSTopic[];
  onSelectTopic: (topic: IESRSTopicIdentifier) => void;
}

export const IROsESRSTopicsTabs: FC<IIROsESRSTopicsTabsProps> = ({
  selectedTopic,
  esrsTopics,
  onSelectTopic,
}) => {
  const { size: tabsContainerSize, setElementRef: setTabContainerRef } = useObserveSizeState();
  return (
    <Box ref={setTabContainerRef}>
      <Tabs
        variant={tabsContainerSize.width < 1000 ? "scrollable" : "fullWidth"}
        value={selectedTopic}
      >
        {esrsTopics.map((topic) => (
          <Tab
            key={topic.identifier}
            value={topic.identifier}
            label={
              <span>
                {topic.topic.split(" ")[0]}
                <br />
                {topic.topic.split(" ")[1]}
              </span>
            }
            onClick={() => onSelectTopic(topic.identifier)}
            sx={{
              bgcolor: topic.identifier === selectedTopic ? "#00000008" : undefined,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
