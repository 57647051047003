/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { ICountryCodeAlpha2Enum } from "../models";
// @ts-ignore
import { IEmissionFactorDatabase } from "../models";
// @ts-ignore
import { IEmissionFactorType } from "../models";
// @ts-ignore
import { IEmissionFactorUnit } from "../models";
// @ts-ignore
import { IGetEmissionFactors200Response } from "../models";
// @ts-ignore
import { IGhgCategoriesIdentifier } from "../models";
/**
 * EmissionFactorsApi - axios parameter creator
 * @export
 */
export const EmissionFactorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetch paginated emission factors.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {number} limit The max number of items returned
     * @param {number} offset The number of items before to skip
     * @param {string} [title] filter by emission factor title
     * @param {Array<IEmissionFactorDatabase>} [emissionFactorDatabases] filter by emission factor databases
     * @param {Array<IEmissionFactorType>} [emissionFactorTypes] filter by emission factor types
     * @param {Array<IGhgCategoriesIdentifier>} [ghgCategories] filter by GHG categories
     * @param {Array<ICountryCodeAlpha2Enum>} [countries] filter by countries
     * @param {Array<IEmissionFactorUnit>} [emissionFactorUnits] filter by emission factor unit types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmissionFactors: async (
      organizationId: string,
      limit: number,
      offset: number,
      title?: string,
      emissionFactorDatabases?: Array<IEmissionFactorDatabase>,
      emissionFactorTypes?: Array<IEmissionFactorType>,
      ghgCategories?: Array<IGhgCategoriesIdentifier>,
      countries?: Array<ICountryCodeAlpha2Enum>,
      emissionFactorUnits?: Array<IEmissionFactorUnit>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getEmissionFactors", "organizationId", organizationId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists("getEmissionFactors", "limit", limit);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists("getEmissionFactors", "offset", offset);
      const localVarPath = `/organizations/{organizationId}/emission-factors`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (title !== undefined) {
        localVarQueryParameter["title"] = title;
      }

      if (emissionFactorDatabases) {
        localVarQueryParameter["emissionFactorDatabases"] = emissionFactorDatabases;
      }

      if (emissionFactorTypes) {
        localVarQueryParameter["emissionFactorTypes"] = emissionFactorTypes;
      }

      if (ghgCategories) {
        localVarQueryParameter["ghgCategories"] = ghgCategories;
      }

      if (countries) {
        localVarQueryParameter["countries"] = countries;
      }

      if (emissionFactorUnits) {
        localVarQueryParameter["emissionFactorUnits"] = emissionFactorUnits;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmissionFactorsApi - functional programming interface
 * @export
 */
export const EmissionFactorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmissionFactorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch paginated emission factors.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {number} limit The max number of items returned
     * @param {number} offset The number of items before to skip
     * @param {string} [title] filter by emission factor title
     * @param {Array<IEmissionFactorDatabase>} [emissionFactorDatabases] filter by emission factor databases
     * @param {Array<IEmissionFactorType>} [emissionFactorTypes] filter by emission factor types
     * @param {Array<IGhgCategoriesIdentifier>} [ghgCategories] filter by GHG categories
     * @param {Array<ICountryCodeAlpha2Enum>} [countries] filter by countries
     * @param {Array<IEmissionFactorUnit>} [emissionFactorUnits] filter by emission factor unit types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmissionFactors(
      organizationId: string,
      limit: number,
      offset: number,
      title?: string,
      emissionFactorDatabases?: Array<IEmissionFactorDatabase>,
      emissionFactorTypes?: Array<IEmissionFactorType>,
      ghgCategories?: Array<IGhgCategoriesIdentifier>,
      countries?: Array<ICountryCodeAlpha2Enum>,
      emissionFactorUnits?: Array<IEmissionFactorUnit>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetEmissionFactors200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactors(
        organizationId,
        limit,
        offset,
        title,
        emissionFactorDatabases,
        emissionFactorTypes,
        ghgCategories,
        countries,
        emissionFactorUnits,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmissionFactorsApi - factory interface
 * @export
 */
export const EmissionFactorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmissionFactorsApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch paginated emission factors.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {number} limit The max number of items returned
     * @param {number} offset The number of items before to skip
     * @param {string} [title] filter by emission factor title
     * @param {Array<IEmissionFactorDatabase>} [emissionFactorDatabases] filter by emission factor databases
     * @param {Array<IEmissionFactorType>} [emissionFactorTypes] filter by emission factor types
     * @param {Array<IGhgCategoriesIdentifier>} [ghgCategories] filter by GHG categories
     * @param {Array<ICountryCodeAlpha2Enum>} [countries] filter by countries
     * @param {Array<IEmissionFactorUnit>} [emissionFactorUnits] filter by emission factor unit types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmissionFactors(
      organizationId: string,
      limit: number,
      offset: number,
      title?: string,
      emissionFactorDatabases?: Array<IEmissionFactorDatabase>,
      emissionFactorTypes?: Array<IEmissionFactorType>,
      ghgCategories?: Array<IGhgCategoriesIdentifier>,
      countries?: Array<ICountryCodeAlpha2Enum>,
      emissionFactorUnits?: Array<IEmissionFactorUnit>,
      options?: any,
    ): AxiosPromise<IGetEmissionFactors200Response> {
      return localVarFp
        .getEmissionFactors(
          organizationId,
          limit,
          offset,
          title,
          emissionFactorDatabases,
          emissionFactorTypes,
          ghgCategories,
          countries,
          emissionFactorUnits,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmissionFactorsApi - interface
 * @export
 * @interface EmissionFactorsApi
 */
export interface EmissionFactorsApiInterface {
  /**
   *
   * @summary Fetch paginated emission factors.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {number} limit The max number of items returned
   * @param {number} offset The number of items before to skip
   * @param {string} [title] filter by emission factor title
   * @param {Array<IEmissionFactorDatabase>} [emissionFactorDatabases] filter by emission factor databases
   * @param {Array<IEmissionFactorType>} [emissionFactorTypes] filter by emission factor types
   * @param {Array<IGhgCategoriesIdentifier>} [ghgCategories] filter by GHG categories
   * @param {Array<ICountryCodeAlpha2Enum>} [countries] filter by countries
   * @param {Array<IEmissionFactorUnit>} [emissionFactorUnits] filter by emission factor unit types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorsApiInterface
   */
  getEmissionFactors(
    organizationId: string,
    limit: number,
    offset: number,
    title?: string,
    emissionFactorDatabases?: Array<IEmissionFactorDatabase>,
    emissionFactorTypes?: Array<IEmissionFactorType>,
    ghgCategories?: Array<IGhgCategoriesIdentifier>,
    countries?: Array<ICountryCodeAlpha2Enum>,
    emissionFactorUnits?: Array<IEmissionFactorUnit>,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetEmissionFactors200Response>;
}

/**
 * EmissionFactorsApi - object-oriented interface
 * @export
 * @class EmissionFactorsApi
 * @extends {BaseAPI}
 */
export class EmissionFactorsApi extends BaseAPI implements EmissionFactorsApiInterface {
  /**
   *
   * @summary Fetch paginated emission factors.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {number} limit The max number of items returned
   * @param {number} offset The number of items before to skip
   * @param {string} [title] filter by emission factor title
   * @param {Array<IEmissionFactorDatabase>} [emissionFactorDatabases] filter by emission factor databases
   * @param {Array<IEmissionFactorType>} [emissionFactorTypes] filter by emission factor types
   * @param {Array<IGhgCategoriesIdentifier>} [ghgCategories] filter by GHG categories
   * @param {Array<ICountryCodeAlpha2Enum>} [countries] filter by countries
   * @param {Array<IEmissionFactorUnit>} [emissionFactorUnits] filter by emission factor unit types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorsApi
   */
  public getEmissionFactors(
    organizationId: string,
    limit: number,
    offset: number,
    title?: string,
    emissionFactorDatabases?: Array<IEmissionFactorDatabase>,
    emissionFactorTypes?: Array<IEmissionFactorType>,
    ghgCategories?: Array<IGhgCategoriesIdentifier>,
    countries?: Array<ICountryCodeAlpha2Enum>,
    emissionFactorUnits?: Array<IEmissionFactorUnit>,
    options?: AxiosRequestConfig,
  ) {
    return EmissionFactorsApiFp(this.configuration)
      .getEmissionFactors(
        organizationId,
        limit,
        offset,
        title,
        emissionFactorDatabases,
        emissionFactorTypes,
        ghgCategories,
        countries,
        emissionFactorUnits,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
