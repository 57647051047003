import { FC, useCallback, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { ContentLanguageSelectComponent } from "../content-translation/components/content-language-select.component";
import { useCreateDMAConfigurationMutation } from "./mutations/dma.mutations";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { useDialogStateWithoutData } from "../common/dialogs/dialog-state.hook";
import { ConfirmDialogTextBody } from "../common/dialogs/variants/confirm.dialog";
import { FormatTranslation } from "../common/components/format-translation.component";
import { requiresOrganizationRole } from "../authentication/higher-order-components/requires-organization-role.hoc";
import { ROLE_ACCESS } from "@netcero/netcero-common";

interface IDMALanguagePickerComponentProps {
  organizationId: string;
  recordingPeriodId: string;
}

export const DMALanguagePickerComponent: FC<IDMALanguagePickerComponentProps> =
  // Only allow access for with correct role
  requiresOrganizationRole(
    ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CONFIGURATION,
    ({ organizationId, recordingPeriodId }) => {
      const { t } = useTranslation("dma_language_picker_component");

      const [language, setLanguage] = useState<IContentLanguage | null>(null);
      const {
        isOpen: showConfirmationDialog,
        openDialog: openConfirmationDialog,
        closeDialog: closeConfirmationDialog,
      } = useDialogStateWithoutData();

      const createDMAConfigurationMutation = useCreateDMAConfigurationMutation();

      const handleSubmit = useCallback(async () => {
        if (language === null) {
          return;
        }

        await createDMAConfigurationMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          payload: { language },
        });
      }, [createDMAConfigurationMutation, language, organizationId, recordingPeriodId]);

      const handleCloseConfirmDialog = useCallback(
        async (confirm: boolean) => {
          if (confirm) {
            await handleSubmit();
          }
          closeConfirmationDialog();
        },
        [closeConfirmationDialog, handleSubmit],
      );

      return (
        <>
          <ConfirmDialogTextBody
            open={showConfirmationDialog}
            onClose={handleCloseConfirmDialog}
            loading={createDMAConfigurationMutation.isPending}
            disabled={createDMAConfigurationMutation.isPending}
            error={createDMAConfigurationMutation.error}
            title={t("confirm.title")}
            content={
              <FormatTranslation
                t={t}
                i18nKey="confirm.content"
                tOptions={{
                  language: t(`translationsPerLanguage.${language}`, {
                    ns: "language",
                  }),
                }}
              />
            }
          />

          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            {/* Headings */}
            <Typography variant="h1">{t("title")}</Typography>
            <Typography variant="body1">{t("subtext")}</Typography>

            {/* Loader */}
            {createDMAConfigurationMutation.isPending && <LinearProgress />}

            {/* Error text*/}
            {createDMAConfigurationMutation.isError && (
              <ErrorTextComponent error={createDMAConfigurationMutation.error!} />
            )}

            <Box display="flex" alignItems="center" gap={2}>
              {/* Dropdown for language */}
              <ContentLanguageSelectComponent
                value={language}
                onValueChange={setLanguage}
                required
                minWidth={200}
              />
              <Button
                variant="contained"
                disabled={language === null}
                onClick={() => openConfirmationDialog()}
              >
                {t("button")}
              </Button>
            </Box>
          </Box>
        </>
      );
    },
  );
