export class HookFormControllerUtilities {
  public static createRequiredStringRules(errorMessage: string) {
    return {
      required: errorMessage,
      validate: (value: string) => {
        if (value.trim() === "") {
          return errorMessage;
        }

        return true;
      },
    };
  }
}
