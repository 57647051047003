import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

interface IInvalidateQueriesByKeysParams {
  /** Keys will be awaited as Promise.all */
  awaitQueryKeys?: QueryKey[];
  /** Keys will be invalidated without awaiting them */
  nonAwaitQueryKeys?: QueryKey[];
}

/**
 * Invalidate queries by keys hook
 * @returns Invalidate queries by keys method (memoized callback)
 */
export const useInvalidateQueriesByKeys = () => {
  const queryClient = useQueryClient();
  return useCallback(
    async (data: IInvalidateQueriesByKeysParams) => {
      // Invalidate await queries
      if (data.awaitQueryKeys && data.awaitQueryKeys.length > 0) {
        await Promise.all(
          data.awaitQueryKeys.map((queryKey) =>
            queryClient.invalidateQueries({
              queryKey: queryKey,
            }),
          ),
        );
      }

      if (data.nonAwaitQueryKeys && data.nonAwaitQueryKeys.length > 0) {
        data.nonAwaitQueryKeys.forEach(
          (queryKey) =>
            void queryClient.invalidateQueries({
              queryKey: queryKey,
            }),
        );
      }
    },
    [queryClient],
  );
};
