/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IBaseEmissionFactorData } from "../models";
// @ts-ignore
import { ICreateEmissionFactor404Response } from "../models";
// @ts-ignore
import { IEmissionFactor } from "../models";
/**
 * EmissionFactorApi - axios parameter creator
 * @export
 */
export const EmissionFactorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Creates a new emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Create a new emission factor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEmissionFactor: async (
      organizationId: string,
      iBaseEmissionFactorData?: IBaseEmissionFactorData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createEmissionFactor", "organizationId", organizationId);
      const localVarPath = `/organizations/{organizationId}/emission-factors`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseEmissionFactorData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes an existing emission factor.
     * @summary Deletes an existing emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEmissionFactor: async (
      organizationId: string,
      emissionFactorId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteEmissionFactor", "organizationId", organizationId);
      // verify required parameter 'emissionFactorId' is not null or undefined
      assertParamExists("deleteEmissionFactor", "emissionFactorId", emissionFactorId);
      const localVarPath = `/organizations/{organizationId}/emission-factors/{emissionFactorId}`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"emissionFactorId"}}`, encodeURIComponent(String(emissionFactorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all details for the given emission factor.
     * @summary Fetch emission factor by id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmissionFactor: async (
      organizationId: string,
      emissionFactorId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getEmissionFactor", "organizationId", organizationId);
      // verify required parameter 'emissionFactorId' is not null or undefined
      assertParamExists("getEmissionFactor", "emissionFactorId", emissionFactorId);
      const localVarPath = `/organizations/{organizationId}/emission-factors/{emissionFactorId}`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"emissionFactorId"}}`, encodeURIComponent(String(emissionFactorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates an existing emission factor.
     * @summary Updates an existing emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Update an existing emission factor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEmissionFactor: async (
      organizationId: string,
      emissionFactorId: string,
      iBaseEmissionFactorData?: IBaseEmissionFactorData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateEmissionFactor", "organizationId", organizationId);
      // verify required parameter 'emissionFactorId' is not null or undefined
      assertParamExists("updateEmissionFactor", "emissionFactorId", emissionFactorId);
      const localVarPath = `/organizations/{organizationId}/emission-factors/{emissionFactorId}`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"emissionFactorId"}}`, encodeURIComponent(String(emissionFactorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseEmissionFactorData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmissionFactorApi - functional programming interface
 * @export
 */
export const EmissionFactorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmissionFactorApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Creates a new emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Create a new emission factor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEmissionFactor(
      organizationId: string,
      iBaseEmissionFactorData?: IBaseEmissionFactorData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEmissionFactor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEmissionFactor(
        organizationId,
        iBaseEmissionFactorData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Deletes an existing emission factor.
     * @summary Deletes an existing emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEmissionFactor(
      organizationId: string,
      emissionFactorId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmissionFactor(
        organizationId,
        emissionFactorId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all details for the given emission factor.
     * @summary Fetch emission factor by id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmissionFactor(
      organizationId: string,
      emissionFactorId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEmissionFactor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmissionFactor(
        organizationId,
        emissionFactorId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates an existing emission factor.
     * @summary Updates an existing emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Update an existing emission factor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEmissionFactor(
      organizationId: string,
      emissionFactorId: string,
      iBaseEmissionFactorData?: IBaseEmissionFactorData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEmissionFactor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmissionFactor(
        organizationId,
        emissionFactorId,
        iBaseEmissionFactorData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmissionFactorApi - factory interface
 * @export
 */
export const EmissionFactorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmissionFactorApiFp(configuration);
  return {
    /**
     *
     * @summary Creates a new emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Create a new emission factor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEmissionFactor(
      organizationId: string,
      iBaseEmissionFactorData?: IBaseEmissionFactorData,
      options?: any,
    ): AxiosPromise<IEmissionFactor> {
      return localVarFp
        .createEmissionFactor(organizationId, iBaseEmissionFactorData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes an existing emission factor.
     * @summary Deletes an existing emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEmissionFactor(
      organizationId: string,
      emissionFactorId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteEmissionFactor(organizationId, emissionFactorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all details for the given emission factor.
     * @summary Fetch emission factor by id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmissionFactor(
      organizationId: string,
      emissionFactorId: string,
      options?: any,
    ): AxiosPromise<IEmissionFactor> {
      return localVarFp
        .getEmissionFactor(organizationId, emissionFactorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates an existing emission factor.
     * @summary Updates an existing emission factor.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} emissionFactorId The id of the emission factor to use.
     * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Update an existing emission factor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEmissionFactor(
      organizationId: string,
      emissionFactorId: string,
      iBaseEmissionFactorData?: IBaseEmissionFactorData,
      options?: any,
    ): AxiosPromise<IEmissionFactor> {
      return localVarFp
        .updateEmissionFactor(organizationId, emissionFactorId, iBaseEmissionFactorData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmissionFactorApi - interface
 * @export
 * @interface EmissionFactorApi
 */
export interface EmissionFactorApiInterface {
  /**
   *
   * @summary Creates a new emission factor.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Create a new emission factor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApiInterface
   */
  createEmissionFactor(
    organizationId: string,
    iBaseEmissionFactorData?: IBaseEmissionFactorData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IEmissionFactor>;

  /**
   * Deletes an existing emission factor.
   * @summary Deletes an existing emission factor.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} emissionFactorId The id of the emission factor to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApiInterface
   */
  deleteEmissionFactor(
    organizationId: string,
    emissionFactorId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Returns all details for the given emission factor.
   * @summary Fetch emission factor by id.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} emissionFactorId The id of the emission factor to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApiInterface
   */
  getEmissionFactor(
    organizationId: string,
    emissionFactorId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IEmissionFactor>;

  /**
   * Updates an existing emission factor.
   * @summary Updates an existing emission factor.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} emissionFactorId The id of the emission factor to use.
   * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Update an existing emission factor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApiInterface
   */
  updateEmissionFactor(
    organizationId: string,
    emissionFactorId: string,
    iBaseEmissionFactorData?: IBaseEmissionFactorData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IEmissionFactor>;
}

/**
 * EmissionFactorApi - object-oriented interface
 * @export
 * @class EmissionFactorApi
 * @extends {BaseAPI}
 */
export class EmissionFactorApi extends BaseAPI implements EmissionFactorApiInterface {
  /**
   *
   * @summary Creates a new emission factor.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Create a new emission factor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApi
   */
  public createEmissionFactor(
    organizationId: string,
    iBaseEmissionFactorData?: IBaseEmissionFactorData,
    options?: AxiosRequestConfig,
  ) {
    return EmissionFactorApiFp(this.configuration)
      .createEmissionFactor(organizationId, iBaseEmissionFactorData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes an existing emission factor.
   * @summary Deletes an existing emission factor.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} emissionFactorId The id of the emission factor to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApi
   */
  public deleteEmissionFactor(
    organizationId: string,
    emissionFactorId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmissionFactorApiFp(this.configuration)
      .deleteEmissionFactor(organizationId, emissionFactorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all details for the given emission factor.
   * @summary Fetch emission factor by id.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} emissionFactorId The id of the emission factor to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApi
   */
  public getEmissionFactor(
    organizationId: string,
    emissionFactorId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmissionFactorApiFp(this.configuration)
      .getEmissionFactor(organizationId, emissionFactorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates an existing emission factor.
   * @summary Updates an existing emission factor.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} emissionFactorId The id of the emission factor to use.
   * @param {IBaseEmissionFactorData} [iBaseEmissionFactorData] Update an existing emission factor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmissionFactorApi
   */
  public updateEmissionFactor(
    organizationId: string,
    emissionFactorId: string,
    iBaseEmissionFactorData?: IBaseEmissionFactorData,
    options?: AxiosRequestConfig,
  ) {
    return EmissionFactorApiFp(this.configuration)
      .updateEmissionFactor(organizationId, emissionFactorId, iBaseEmissionFactorData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
