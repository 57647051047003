import { Navigate, Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../pages/404.page";
import { ConfigurationDashboardPage } from "../pages/configuration/configuration-dashboard.page";
import { DataEntryObjectValueInputPage } from "../pages/data-entry-object/data-entry-object-value-input.page";
import { RedirectToRootDataEntryObjectForValueInputPage } from "../pages/data-entry-object/redirect-to-root-data-entry-object-for-value-input.page";
import { DataEntryObjectEmissionEvaluationPage } from "../pages/evaluation/data-entry-object-emission-evaluation.page";
import { RedirectEvaluationToRootDEOPage } from "../pages/evaluation/redirect-evaluation-to-root-deo.page";
import { OrganizationsRedirectPage } from "../pages/organizations/organizations-redirect.page";
import { RecordingPeriodDashboardPage } from "../pages/recording-periods/recording-period-dashboard.page";
import { RecordingPeriodsRedirectPage } from "../pages/recording-periods/recording-periods-redirect.page";
import { RecordingPeriodsConfigurationPage } from "../pages/configuration/recording-periods-configuration.page";
import { OrganizationStructureConfigurationPage } from "../pages/configuration/organization-structure-configuration.page";
import { DMAPage } from "../pages/double-materiality-assessment/dma.page";
import { StakeholderFeedbackPage } from "../pages/stakeholder-feedbacks/stakeholder-feedback.page";
import { FC } from "react";
import { RootLayout } from "../pages/root.layout";
import { StakeholderConfigurationPage } from "../pages/configuration/stakeholder-configuration.page";
import { DMADashboardPage } from "../pages/double-materiality-assessment/dma-dashboard.page";
import { ReferenceBudgetPage } from "../pages/target-paths/reference-budget-configuration.page";
import { TargetPathOverviewPage } from "../pages/target-paths/target-path-overview.page";
import { TargetPathDetailsPage } from "../pages/target-paths/target-path-details.page";
import { SourcesConfigurationPage } from "../pages/configuration/sources-configuration.page";
import { RecordingPeriodLayout } from "../modules/recording-periods/recording-period.layout";
import { AuditLoggingConfigurationPage } from "../pages/configuration/audit-logging-configuration.page";
import { StakeholderFeedbacksLayout } from "../pages/stakeholder-feedbacks/stakeholder-feedbacks.layout";
import { RequireModuleAccessLayout } from "../pages/common/require-module-access.layout";
import { AppModule, ROLE_ACCESS } from "@netcero/netcero-common";
import { UsersPage } from "../pages/users/users.page";
import { RedirectToRootDataEntryObjectGapAnalysisPage } from "../pages/gap-analysis/redirect-to-root-data-entry-object-gap-analysis.page";
import { GapAnalysisPage } from "../pages/gap-analysis/gap-analysis.page";
import { RequireOrganizationRoleLayout } from "../pages/common/require-organization-role.layout";
import { EmissionFactorsPage } from "../pages/emission-factors/emission-factors.page";

export const App: FC = () => {
  return (
    <Routes>
      {/* Stakeholder Feedback (unauthenticated) */}
      <Route path="/stakeholder-feedbacks" element={<StakeholderFeedbacksLayout />}>
        <Route path=":stakeholderId" element={<StakeholderFeedbackPage />} />
      </Route>
      <Route index element={<Navigate to="/organizations" replace />} />
      {/* OrganizationsLayout requires Authentication - therefore subpages do not have to use the "requiresAuthentication" HOC */}
      <Route path="/organizations" element={<RootLayout />}>
        <Route index element={<OrganizationsRedirectPage />} />
        <Route path=":organizationId">
          <Route index element={<Navigate to="recording-periods" replace />} />
          {/* Recording Periods */}
          <Route path="recording-periods">
            <Route index element={<RecordingPeriodsRedirectPage />} />
            <Route path=":recordingPeriodId" element={<RecordingPeriodLayout />}>
              <Route index element={<Navigate to="all" replace />} />
              <Route path=":recordingStructureType">
                <Route index element={<RecordingPeriodDashboardPage />} />
                {/* Input Parameter Value Input */}
                <Route path="recording-structures">
                  <Route path=":recordingStructureId">
                    <Route index element={<RedirectToRootDataEntryObjectForValueInputPage />} />
                    <Route path="data-entry-objects">
                      <Route
                        path=":dataEntryObjectId"
                        element={<DataEntryObjectValueInputPage />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
              {/* Gap Analysis */}
              <Route
                path="gap-analysis"
                element={
                  <RequireOrganizationRoleLayout
                    rolesWithAccess={ROLE_ACCESS.ALLOWED_TO_ACCESS_GAP_ANALYSIS}
                  />
                }
              >
                <Route index element={<Navigate to="data-entry-objects" replace />} />
                <Route path="data-entry-objects">
                  <Route index element={<RedirectToRootDataEntryObjectGapAnalysisPage />} />
                  <Route path=":dataEntryObjectId" element={<GapAnalysisPage />} />
                </Route>
              </Route>
              {/* Double Materiality Assessment */}
              <Route
                path="double-materiality-assessment"
                element={<RequireModuleAccessLayout module={AppModule.DMA} />}
              >
                <Route index element={<DMAPage />} />
                <Route
                  path="dashboard"
                  element={
                    <RequireOrganizationRoleLayout
                      rolesWithAccess={ROLE_ACCESS.ALLOWED_TO_ACCESS_DMA_DASHBOARD}
                    />
                  }
                >
                  <Route index element={<DMADashboardPage />} />
                </Route>
              </Route>
              {/* Evaluations */}
              <Route path="evaluations">
                <Route path="emissions">
                  <Route path="recording-structures">
                    <Route path=":recordingStructureId">
                      <Route
                        index
                        element={<RedirectEvaluationToRootDEOPage evaluationKey="emissions" />}
                      />
                      <Route path="data-entry-objects">
                        <Route
                          path=":dataEntryObjectId"
                          element={<DataEntryObjectEmissionEvaluationPage />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* Target Paths */}
          <Route path="target-paths">
            <Route index element={<TargetPathOverviewPage />} />
            <Route path=":targetPathId" element={<TargetPathDetailsPage />} />
            <Route path="reference-budgets">
              <Route index element={<ReferenceBudgetPage />} />
            </Route>
          </Route>
          {/* Configuration */}
          <Route path="configuration">
            <Route index element={<ConfigurationDashboardPage />} />
            <Route
              path="recording-periods"
              element={
                <RequireOrganizationRoleLayout
                  rolesWithAccess={ROLE_ACCESS.ALLOWED_TO_MODIFY_CONFIGURATION_RECORDING_PERIOD}
                />
              }
            >
              <Route index element={<RecordingPeriodsConfigurationPage />} />
              <Route path=":recordingPeriodId" element={<RecordingPeriodLayout />}>
                <Route path="organization-structure">
                  <Route index element={<OrganizationStructureConfigurationPage />} />
                </Route>
                {/*<Route path="distribution-criteria">*/}
                {/*  <Route index element={<AppliedDistributionCriteriaConfigurationPage />} />*/}
                {/*</Route>*/}
              </Route>
            </Route>
            <Route
              path="stakeholder"
              element={<RequireModuleAccessLayout module={AppModule.DMA} />}
            >
              <Route index element={<StakeholderConfigurationPage />} />
            </Route>
            {/*<Route
              path="distribution-criteria"
              element={
                <RequireOrganizationRoleLayout
                  rolesWithAccess={
                    ROLE_ACCESS.ALLOWED_TO_MODIFY_CONFIGURATION_DISTRIBUTION_CRITERIA
                  }
                />
              }
            >*/}
            {/*  <Route index element={<DistributionCriteriaConfigurationPage />} />*/}
            {/*</Route>*/}
            <Route path="sources">
              <Route index element={<SourcesConfigurationPage />} />
            </Route>
            <Route
              path="audit-logging"
              element={
                <RequireOrganizationRoleLayout
                  rolesWithAccess={ROLE_ACCESS.ALLOWED_TO_ACCESS_GLOBAL_AUDIT_LOG}
                />
              }
            >
              <Route index element={<AuditLoggingConfigurationPage />} />
            </Route>
            <Route path="users">
              <Route index element={<UsersPage />} />
            </Route>
            <Route
              path="emission-factors"
              element={<RequireModuleAccessLayout module={AppModule.CARBON_ACCOUNTING} />}
            >
              <Route index element={<EmissionFactorsPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
