import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import {
  useDMAConfigurationQuery,
  useDmaDataEntryObjectConfigurationQuery,
  useESRSTopicsQuery,
} from "../../modules/double-materiality-assessment/dma.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { Box } from "@mui/material";
import { ESRSTopicsStepperComponent } from "../../modules/double-materiality-assessment/esrs-topics-stepper.component";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import {
  IAllESRSTopicsResponse,
  IDataEntryObject,
  IDMAConfiguration,
  IIntercomEntity,
} from "@netcero/netcero-core-api-client";
import { DMALanguagePickerComponent } from "../../modules/double-materiality-assessment/dma-language-picker.component";
import { useInitDMAMutation } from "../../modules/double-materiality-assessment/mutations/dma.mutations";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import {
  IIntercomReferencesContextState,
  IntercomReferencesProvider,
} from "../../modules/intercom-references/intercom-references.context";
import { useIntercomReferencesForEntityQuery } from "../../modules/intercom-references/intercom-references.queries";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";
import { useDoesUserHaveRole } from "../../modules/authentication/hooks/does-user-have-role.hook";
import { ROLE_ACCESS } from "@netcero/netcero-common";
import { DmaNoDataPointsAssignedNotice } from "./dma-no-datapoints-assigned-notice.component";
import { DMAUtilities } from "../../modules/double-materiality-assessment/utilities/dma.utilities";
import { useUserContext } from "../../modules/user/user.context";

export const DMAPage: FC = () => {
  const { organizationId, recordingPeriodId } = useParams();
  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: organizationId ?? "",
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}`);
        },
      },
      navigationItems: {
        activePath: NavItemUrlUtilities.getDMAPageUrl(organizationId!, recordingPeriodId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, recordingPeriodId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: organizationId ?? "",
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}`);
        },
      },
      recordingPeriodId: recordingPeriodId,
    }),
    [organizationId, recordingPeriodId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const dmaConfigurationQuery = useDMAConfigurationQuery(organizationId!, recordingPeriodId!);

  // Intercom state for IntercomReferences context
  const intercomImpactQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Impact);
  const intercomEffectQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Effect);

  const isIntercomQueriesLoading = useMemo(
    () => intercomImpactQuery.isLoading || intercomEffectQuery.isLoading,
    [intercomImpactQuery.isLoading, intercomEffectQuery.isLoading],
  );

  const intercomState: IIntercomReferencesContextState = useMemo(() => {
    if (isIntercomQueriesLoading) {
      return {};
    }
    return {
      [IIntercomEntity.Impact]: intercomImpactQuery.data!,
      [IIntercomEntity.Effect]: intercomEffectQuery.data!,
    };
  }, [intercomEffectQuery.data, intercomImpactQuery.data, isIntercomQueriesLoading]);

  return (
    <Box display="flex" justifyContent="center" flex={1}>
      <QueryWrapper
        query={organizationStructureQuery}
        build={(organizationStructureResponse) => (
          <OrganizationStructureDraftWrapper
            organizationId={organizationId!}
            recordingPeriodId={recordingPeriodId!}
            organizationStructure={organizationStructureResponse}
            build={(rootDataEntryObject) => (
              // Another wrapper to react to result of dmaConfiguration query
              <QueryWrapper
                query={dmaConfigurationQuery}
                loadingOverride={() => (
                  <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
                )}
                build={(dmaConfigurationResponse) =>
                  dmaConfigurationResponse.type === "exists" ? (
                    <IntercomReferencesProvider state={intercomState}>
                      <DMAForDEO
                        organizationId={organizationId!}
                        recordingPeriodId={recordingPeriodId!}
                        dataEntryObject={rootDataEntryObject}
                        dmaConfiguration={dmaConfigurationResponse.configuration}
                      />
                    </IntercomReferencesProvider>
                  ) : (
                    // configuration does not yet exist --> render language picker
                    <DMALanguagePickerComponent
                      organizationId={organizationId!}
                      recordingPeriodId={recordingPeriodId!}
                    />
                  )
                }
              />
            )}
          />
        )}
      />
    </Box>
  );
};

// Internal Component

interface IDMAForRootDEOProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObject: IDataEntryObject;
  dmaConfiguration: IDMAConfiguration;
}

const DMAForDEO: FC<IDMAForRootDEOProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObject,
  dmaConfiguration,
}) => {
  const { user } = useUserContext();
  const canModifyAllIROs = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_MODIFY_ALL_IROS);
  const selectEsrsTopics = useCallback(
    (data: IAllESRSTopicsResponse) => {
      if (canModifyAllIROs) {
        return data;
      }
      return {
        ...data,
        esrsTopics: DMAUtilities.filterTopicsByUserIds(data.esrsTopics, [
          user?.userProfile.id ?? "ERROR",
        ]),
      };
    },
    [canModifyAllIROs],
  );

  const esrsTopicsQuery = useESRSTopicsQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObject.id,
    selectEsrsTopics,
  );
  const dmaDeoConfigurationQuery = useDmaDataEntryObjectConfigurationQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObject.id,
  );

  // Initial Categories setup
  const initDMAQuery = useInitDMAMutation();

  // Initially setup categories for ESRSTopics (use default template)
  useEffect(() => {
    if (esrsTopicsQuery.data) {
      const esrsTopics = esrsTopicsQuery.data.esrsTopics;

      // init if at least one topic was not recorded
      const shouldInit =
        esrsTopics.filter((esrsTopic) => esrsTopic.recordedESRSTopic === undefined).length > 0;

      if (shouldInit) {
        initDMAQuery.mutate({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: dataEntryObject.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esrsTopicsQuery.data]);
  const haveCategoriesBeenInitialized = useMemo(() => {
    if (esrsTopicsQuery.data) {
      const esrsTopics = esrsTopicsQuery.data.esrsTopics;
      return esrsTopics
        .filter((esrsTopic) => esrsTopic.currentTemplate)
        .every((esrsTopic) => esrsTopic.recordedESRSTopic !== undefined);
    }
    return true;
  }, [esrsTopicsQuery.data]);

  // Prepare for rendering page

  const isLoading = useMemo(
    () => esrsTopicsQuery.isLoading || dmaDeoConfigurationQuery.isLoading || initDMAQuery.isPending,
    [esrsTopicsQuery.isLoading, dmaDeoConfigurationQuery.isLoading, initDMAQuery.isPending],
  );

  const queriesError = useMemo(
    () => esrsTopicsQuery.error || dmaDeoConfigurationQuery.error || initDMAQuery.error,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [esrsTopicsQuery.isError, dmaDeoConfigurationQuery.isError, initDMAQuery.isError],
  );

  // Render Component (states)

  if (isLoading || !haveCategoriesBeenInitialized) {
    return <CenteredCircularProgress />;
  }

  if (queriesError) {
    return <ErrorTextComponent error={queriesError} />;
  }

  const esrsTopicsResponse = esrsTopicsQuery.data!;
  const dmaDeoConfiguration = dmaDeoConfigurationQuery.data!;

  return esrsTopicsResponse.esrsTopics.length > 0 ? (
    <ESRSTopicsStepperComponent
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
      dataEntryObject={dataEntryObject}
      dmaConfiguration={dmaConfiguration}
      dmaDeoConfiguration={dmaDeoConfiguration}
      esrsTopics={esrsTopicsResponse.esrsTopics}
    />
  ) : canModifyAllIROs ? (
    "No ESRS Topics available - this should not happen!"
  ) : (
    <DmaNoDataPointsAssignedNotice />
  );
};
