import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  IBaseDMAFinancialEffectData,
  IDataEntryObject,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IDMAConfiguration,
  IDMAFinancialEffect,
  IDMAFinancialEffectHorizonData,
  IDMAFinancialEffectType,
  IESRSTopic,
  IFinancialEffectCategoryEnum,
  IHorizonsEnum,
  IIROState,
} from "@netcero/netcero-core-api-client";
import {
  DmaConfigurationUtilities,
  FinancialEffectCalculator,
  FinancialEffectHorizonsCalculator,
} from "@netcero/netcero-dma";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormatTranslation } from "../../common/components/format-translation.component";
import {
  ArrowRightIcon,
  CopyIcon,
  DeleteIcon,
  IconSize,
} from "../../common/constants/tabler-icon.constants";
import { useUserContext } from "../../user/user.context";
import { HORIZONS_VALUES, ORDERED_HORIZONS_VALUES } from "../common/dma-horizons.constants";
import {
  IInheritFromHorizonsState,
  IInheritFromHorizonsStateItem,
} from "../common/dma-horizons.interfaces";
import {
  DEFAULT_DMA_PROBABILITY_SLIDER_VALUE,
  DEFAULT_DMA_SLIDER_VALUE,
  ORDERED_FINANCIAL_EFFECT_CATEGORIES,
  ORDERED_FINANCIAL_EFFECT_TYPES,
} from "../common/dma.constants";
import { EditDialogHorizonTabContent } from "../common/edit-dialog-horizon-tab-content.component";
import { IROStateIndicator } from "../common/iro-state-indicator.component";
import { IROStateSelect } from "../common/iro-state-select.component";
import { useIroEditIsDirtyHook } from "../common/use-iro-edit-is-dirty.hook";
import { IROAreasCheckboxesComponent } from "../common/iro-areas-checkboxes.component";
import { DMAFinancialEffectHorizonEditSection } from "./dma-financial-effect-horizon-edit-section.component";
import { useDialogState } from "../../common/dialogs/dialog-state.hook";
import { ArrayUtilities, ObjectUtilities, ROLE_ACCESS } from "@netcero/netcero-common";
import { DMABreadcrumbsComponent } from "../common/dma-breadcrumbs.component";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import {
  IInternalFinancialEffectData,
  IInternalFinancialEffectHorizonData,
} from "./internal-financial-effect-data.types";
import { IroCommonInputs } from "../common/iro-common-inputs.component";
import { EditDialogCommentsAndChangelogPaper } from "../../comments-and-audit-log/components/edit-dialog-comments-and-audit-log-paper.component";
import { useEditDialogCommentsAndAuditLogPaperState } from "../../comments-and-audit-log/hooks/edit-dialog-comments-and-audit-log-paper-state.hook";
import { EditDialogExpandCommentsAndAuditLogPaperButton } from "../../comments-and-audit-log/components/edit-dialog-expand-comments-and-audit-log-paper-button.component";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { InfoDialogText } from "../../common/dialogs/variants/info.dialog";
import { useIroEditDialogMainDmaCategory } from "../hooks/iro-edit-dialog-main-dma-category.hook";
import { DotMenu } from "../../common/components/dot-menu.component";
import { IroTypeIndicator } from "../common/iro-type-indicator.component";
import { DmaFormatUtilities } from "../utilities/dma-format.utilities";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { useCreateFinancialEffectMutation } from "../mutations/dma.mutations";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import { OnlyShowToUsersWithRole } from "../../authentication/components/only-show-to-users-with-role.component";

// All the things that have to be validated before share
// The variants have to match the names of the fields
// to allow for easy translation!
enum ValidationItemsForSharedState {
  ExplanationProvided = "assessmentExplanation",
  EffectsProvided = "effectType",
}

// TODO: expand with further errors so that typing actually makes sense
type GlobalErrors = ValidationItemsForSharedState.ExplanationProvided;
type HorizonSpecificErrors = ValidationItemsForSharedState.EffectsProvided;

interface IValidationItemsForSharedStateState {
  // errors without horizon
  globalErrors: GlobalErrors[];
  horizonSpecificErrors: {
    [key in IHorizonsEnum]?: HorizonSpecificErrors[];
  };
}

const emptyValidationForSharedState = (): IValidationItemsForSharedStateState => ({
  globalErrors: [],
  horizonSpecificErrors: {},
});

function getDefaultValuesForHorizon(
  horizon: IDMAFinancialEffectHorizonData | undefined,
  defaultInheritFromHorizon: IHorizonsEnum | null = null,
): IInternalFinancialEffectHorizonData {
  return {
    effectType: horizon?.effectType ?? [],
    additionalEffects: horizon?.additionalEffects ?? [],
    potentialExtent: horizon?.potentialExtent ?? DEFAULT_DMA_SLIDER_VALUE,
    probabilityOfOccurrence:
      horizon?.probabilityOfOccurrence ?? DEFAULT_DMA_PROBABILITY_SLIDER_VALUE,
    inheritsFromHorizon: horizon?.inheritsFromHorizon ?? defaultInheritFromHorizon,
    // Values for override of automatic materiality
    overrideMateriality: horizon?.userMaterialityReason !== undefined,
    materialityUser: horizon?.materialityUser ?? false,
    userMaterialityReason: horizon?.userMaterialityReason ?? "",
  };
}

const getDefaultValuesForInputs = (
  financialEffect?: IDMAFinancialEffect | null,
): IInternalFinancialEffectData => {
  const defaultInheritFromHorizon = !financialEffect ? IHorizonsEnum.ShortTerm : null;

  return {
    title: financialEffect?.title ?? "",
    responsibleUserId: financialEffect?.responsibleUserId ?? null,
    description: financialEffect?.description ?? "",
    assessmentExplanation: financialEffect?.assessmentExplanation ?? "",
    effectType: financialEffect?.effectType ?? IDMAFinancialEffectType.Chance,
    category: financialEffect?.category ?? IFinancialEffectCategoryEnum.Others,
    horizonsComment: financialEffect?.horizonsComment ?? "",
    areas: financialEffect?.areas ?? [],
    associatedDataEntryObjects: financialEffect?.associatedDataEntryObjects ?? [],
    sourceIds: financialEffect?.sourceIds ?? [],
    horizons: {
      shortTerm: getDefaultValuesForHorizon(financialEffect?.horizons.shortTerm),
      mediumTerm: getDefaultValuesForHorizon(
        financialEffect?.horizons.mediumTerm,
        defaultInheritFromHorizon,
      ),
      longTerm: getDefaultValuesForHorizon(
        financialEffect?.horizons.longTerm,
        defaultInheritFromHorizon,
      ),
    },
    contributingUserIds: financialEffect?.contributingUserIds ?? [],
  };
};

function convertFormHorizonToAPIPayload(
  formData: IInternalFinancialEffectHorizonData,
): IDMAFinancialEffectHorizonData {
  return {
    effectType: formData.effectType,
    additionalEffects: formData.additionalEffects,
    probabilityOfOccurrence: formData.probabilityOfOccurrence,
    potentialExtent: formData.potentialExtent,
    materialityUser: formData.overrideMateriality ? formData.materialityUser : undefined,
    userMaterialityReason: formData.overrideMateriality
      ? formData.userMaterialityReason || undefined
      : undefined,
  };
}

function createFormHorizonAPIPayload(
  horizons: IInternalFinancialEffectData["horizons"],
  horizon: IHorizonsEnum,
): IDMAFinancialEffectHorizonData {
  const inheritsFrom = horizons[horizon].inheritsFromHorizon ?? undefined;
  return {
    ...convertFormHorizonToAPIPayload(horizons[inheritsFrom ?? horizon]),
    inheritsFromHorizon: inheritsFrom,
  };
}

interface IDMAFinancialEffectEditDialog {
  open: boolean;
  organizationId: string;
  recordingPeriodId: string;
  financialEffect?: IDMAFinancialEffect | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  dmaConfiguration: IDMAConfiguration;
  dataEntryObject: IDataEntryObject;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseDMAFinancialEffectData | null) => void;
  onUpdateIROState?: (newState: IIROState) => void;
  onDelete?: () => void;
  onMoveIRO?: () => void;
  /** This can be null or undefined when the dialog is being used in readonly mode for fill in helpers in the DR screen */
  esrsTopic: IESRSTopic | null;
  readOnly?: boolean;
}

/**
 * Dialog to create or edit a financial effect
 * @param open Whether the dialog is open or not
 * @param organizationId The organization id to use for the responsible user picker
 * @param financialEffect The financial effect to edit. If null or undefined, the dialog will be in create mode
 * @param dmaCategory The DMA category the financial effect belongs to
 * @param dmaConfiguration The DMA configuration to use for the dialog
 * @param dataEntryObject The data entry object the dma is being done for
 * @param loading Whether to display a loading indicator or not
 * @param error Error to display
 * @param disabled Whether the dialog is disabled or not (all inputs and buttons will be disabled)
 * @param onClose Callback to be called when the dialog is closed. The callback will receive the data of the financial effect created or edited, or null if the dialog was cancelled
 * @param onUpdateIROState Callback to be called when the IRO state is updated
 * @param onDelete Callback to be called when the delete button is clicked. If not provided, the delete button will not be displayed
 * @param readOnly Whether the dialog is read-only or not
 */
export const DMAFinancialEffectEditDialog: FC<IDMAFinancialEffectEditDialog> = ({
  open,
  organizationId,
  financialEffect,
  dmaCategory,
  dmaConfiguration,
  dataEntryObject,
  loading,
  error,
  disabled: parentDisabled,
  onClose,
  onUpdateIROState,
  onDelete,
  onMoveIRO,
  recordingPeriodId,
  esrsTopic,
  readOnly,
}) => {
  const internalDisabled =
    readOnly ||
    parentDisabled ||
    financialEffect?.state === IIROState.Shared ||
    // Should already be disabled due to effectState (always Shared) but just to be sure
    (dmaCategory !== null && dmaCategory.financialState === IDMACategoryState.Verified);

  const iroStateReadOnly =
    dmaCategory !== null && dmaCategory.financialState === IDMACategoryState.Verified;

  const { t } = useTranslation("dma_financial_effect_edit_dialog");
  const { wrapApiPromise } = useAppSnackbar();

  // Queries
  const duplicateFinancialEffectMutation = useCreateFinancialEffectMutation();

  // Dialog States
  const [showDuplicateFinancialEffectDialog, setShowDuplicateFinancialEffectDialog] =
    useState(false);

  const {
    openDialog: showIncompleteForSharedNoticeDialog,
    isOpen: isIncompleteForSharedNoticeDialogShown,
    closeDialog: closeShowIncompleteForSharedNoticeDialog,
    data: incompleteValidationItems,
  } = useDialogState<IValidationItemsForSharedStateState>();

  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection,
    dialogProps,
    dialogElement,
  } = useEditDialogCommentsAndAuditLogPaperState(open);

  const [viewedHorizon, setViewedHorizon] = useState<IHorizonsEnum>(IHorizonsEnum.ShortTerm);

  const formMethods = useForm<IInternalFinancialEffectData>({
    defaultValues: getDefaultValuesForInputs(financialEffect),
  });
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    setError,
    trigger,
    formState: { errors },
    getValues,
  } = formMethods;

  // Reset Dialog when opened
  useEffect(() => {
    if (open) {
      reset(getDefaultValuesForInputs(financialEffect));
      setViewedHorizon(IHorizonsEnum.ShortTerm);
    }
  }, [open, financialEffect, reset]);

  // Calculate Preview Values

  const currentHorizons = useMemo(
    () => {
      const getHorizonValues = (horizon: IHorizonsEnum) => ({
        inheritsFromHorizon: watch(`horizons.${horizon}.inheritsFromHorizon`),
        potentialExtent: watch(`horizons.${horizon}.potentialExtent`),
        probabilityOfOccurrence: watch(`horizons.${horizon}.probabilityOfOccurrence`),
        materialityUser: watch(`horizons.${horizon}.overrideMateriality`)
          ? watch(`horizons.${horizon}.materialityUser`)
          : null,
      });

      return {
        shortTerm: getHorizonValues(IHorizonsEnum.ShortTerm),
        mediumTerm: getHorizonValues(IHorizonsEnum.MediumTerm),
        longTerm: getHorizonValues(IHorizonsEnum.LongTerm),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HORIZONS_VALUES.map((horizon) => [
      watch(`horizons.${horizon}.inheritsFromHorizon`),
      watch(`horizons.${horizon}.potentialExtent`),
      watch(`horizons.${horizon}.probabilityOfOccurrence`),
      watch(`horizons.${horizon}.overrideMateriality`),
      watch(`horizons.${horizon}.materialityUser`),
    ]).flat(),
  );

  // isDirty

  const isDirty = useIroEditIsDirtyHook(control, currentHorizons);

  // Severity Calculation

  const [calculatedTotalMaterialityDegree, calculatedTotalMateriality] = useMemo(() => {
    const getValuesForHorizon = (horizon: IHorizonsEnum) => {
      const horizonKeyToUse = currentHorizons[horizon].inheritsFromHorizon ?? horizon;
      const horizonToUse = currentHorizons[horizonKeyToUse];

      return {
        potentialExtent: horizonToUse.potentialExtent,
        probabilityOfOccurrence: horizonToUse.probabilityOfOccurrence,
      };
    };

    const context = DmaConfigurationUtilities.financialContextFromConfig(dmaConfiguration);

    // Calculate Materiality (either user override or automatic calculation)
    const materiality = HORIZONS_VALUES.map((horizon) => {
      if (currentHorizons[horizon].materialityUser !== null) {
        return currentHorizons[horizon].materialityUser;
      }

      const currentHorizon = currentHorizons[horizon];

      return new FinancialEffectCalculator(currentHorizon, context).calculateMateriality();
    }).some((isMaterial) => isMaterial);

    const materialityDegree = new FinancialEffectHorizonsCalculator(
      {
        shortTerm: getValuesForHorizon(IHorizonsEnum.ShortTerm),
        mediumTerm: getValuesForHorizon(IHorizonsEnum.MediumTerm),
        longTerm: getValuesForHorizon(IHorizonsEnum.LongTerm),
      },
      context,
    ).calculateMaterialityDegree();

    return [materialityDegree, materiality];
  }, [currentHorizons, dmaConfiguration]);

  const horizonsWithOwnValues = useMemo(() => {
    return HORIZONS_VALUES.filter((horizon) => !currentHorizons[horizon].inheritsFromHorizon);
  }, [currentHorizons]);

  const hasHorizonsComment = useMemo(
    () => horizonsWithOwnValues.length > 1,
    [horizonsWithOwnValues],
  );

  // Inheritance State

  const horizonsInheritanceState: IInheritFromHorizonsState = useMemo(
    () => {
      function getInheritanceState(horizon: IHorizonsEnum): IInheritFromHorizonsStateItem {
        const inheritsFromHorizon = watch(`horizons.${horizon}.inheritsFromHorizon`);
        return {
          inUse: HORIZONS_VALUES.map((horizon) =>
            watch(`horizons.${horizon}.inheritsFromHorizon`),
          ).some((inheritsFromHorizon) => inheritsFromHorizon === horizon),
          inheritsValues: !!inheritsFromHorizon,
        };
      }

      return {
        shortTerm: getInheritanceState(IHorizonsEnum.ShortTerm),
        mediumTerm: getInheritanceState(IHorizonsEnum.MediumTerm),
        longTerm: getInheritanceState(IHorizonsEnum.LongTerm),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HORIZONS_VALUES.map((horizon) => watch(`horizons.${horizon}.inheritsFromHorizon`)),
  );

  // Close Handling (Data Emission)

  const generateBaseFinancialEffectData = useCallback(
    (data: IInternalFinancialEffectData) => {
      // Setup basic result
      const result: IBaseDMAFinancialEffectData = {
        title: data.title.trim(),
        responsibleUserId: data.responsibleUserId ?? undefined,
        description: data.description?.trim() || undefined,
        assessmentExplanation: data.assessmentExplanation?.trim() || undefined,
        effectType: data.effectType,
        category: data.category,
        areas: data.areas,
        horizonsComment: hasHorizonsComment ? data.horizonsComment?.trim() || undefined : undefined,
        horizons: {
          shortTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.ShortTerm),
          mediumTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.MediumTerm),
          longTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.LongTerm),
        },
        contributingUserIds: data.contributingUserIds,
        associatedDataEntryObjects: data.associatedDataEntryObjects,
        sourceIds: data.sourceIds,
      };

      // Clear horizonsComment if all horizons have the same value (input not shown in this case)
      if (horizonsWithOwnValues.length <= 1) {
        result.horizonsComment = undefined;
      }

      return result;
    },
    [hasHorizonsComment, horizonsWithOwnValues.length],
  );

  const handleEmitData = (data: IInternalFinancialEffectData) => {
    onClose(generateBaseFinancialEffectData(data));
  };

  const handleChangeState = (newState: IIROState) => {
    // Skip if the same
    if (newState === financialEffect?.state) {
      return;
    }

    // not allowed in create mode
    if (financialEffect == null) {
      return;
    }

    // Open incomplete Notice Dialog if not complete yet
    if (newState === IIROState.Shared) {
      const validation = emptyValidationForSharedState();

      // make sure that explanation has been provided
      if (!financialEffect.assessmentExplanation) {
        validation.globalErrors.push(ValidationItemsForSharedState.ExplanationProvided);
      }

      // check every horizon to ensure that effects have been provided
      for (const [horizonKey, horizon] of Object.entries({ ...financialEffect.horizons })) {
        // neither was provided --> add error for current horizon
        if (
          horizon.effectType.length + horizon.additionalEffects.length === 0 &&
          horizon.inheritsFromHorizon === undefined
        ) {
          validation.horizonSpecificErrors[horizonKey as IHorizonsEnum] = [
            ValidationItemsForSharedState.EffectsProvided,
          ];
        }
      }

      if (
        // horizon specific errors or global errors were specified
        validation.globalErrors.length + Object.keys(validation.horizonSpecificErrors).length >
        0
      ) {
        showIncompleteForSharedNoticeDialog(validation);
        // make sure not to try updating the IRO
        return;
      }
    }

    // Trigger Update
    onUpdateIROState?.(newState);
  };

  const handleCloseIncompleteForSharedNoticeDialog = () => {
    closeShowIncompleteForSharedNoticeDialog();
    const options = incompleteValidationItems ?? emptyValidationForSharedState();

    const translateValidationItem = (validationItem: ValidationItemsForSharedState) => {
      return validationItem === ValidationItemsForSharedState.ExplanationProvided
        ? t("error_assessmentExplanation_required", {
            ns: "dma_impact_or_effect_edit_dialog_common",
          })
        : t("error_effect_type_required");
    };

    // sets all the erroneous fields
    // .forEach to access index :)
    options.globalErrors.forEach((validationItem, index) => {
      setError(
        validationItem,
        {
          message: translateValidationItem(validationItem),
        },
        // only ever focus first element
        { shouldFocus: index === 0 },
      );
    });

    // for every horizon
    for (const [horizonKey, horizonSpecificErrors] of Object.entries(
      ObjectUtilities.objectToRecord<HorizonSpecificErrors[]>(options.horizonSpecificErrors),
    )) {
      // check all horizon specific errors
      horizonSpecificErrors.forEach((validationItem, index) => {
        setError(
          `horizons.${horizonKey as IHorizonsEnum}.${validationItem}`,
          {
            message: translateValidationItem(validationItem),
          },
          // only ever focus first element and if no global errors were encountered
          { shouldFocus: index === 0 && options.globalErrors.length === 0 },
        );
      });
    }
  };

  // Render content for options dialog
  const infoDialogContent = useMemo(() => {
    const validation: IValidationItemsForSharedStateState =
      incompleteValidationItems ?? emptyValidationForSharedState();

    // add all global errors
    let options: ValidationItemsForSharedState[] = [...validation.globalErrors];

    // add horizon specific errors
    Object.values(
      ObjectUtilities.objectToRecord<HorizonSpecificErrors[]>(validation.horizonSpecificErrors),
    )
      .flat()
      .forEach((o) => options.push(o));

    options = ArrayUtilities.deduplicatePrimArray(options);

    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <FormatTranslation t={t} i18nKey="incomplete_for_shared_notice" />
        <Box>
          <ul>
            {options.map((o) => (
              <li key={o}>
                <Typography>{t(`incomplete_points.${o}`)}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    );
  }, [incompleteValidationItems, t]);

  // Duplication Logic
  const handleDuplicateFinancialEffectDialog = () => {
    setShowDuplicateFinancialEffectDialog(true);
  };

  const handleCloseDuplicateFinancialEffectDialog = useCallback(
    async (shouldDuplicate: boolean) => {
      if (shouldDuplicate) {
        const payload = {
          ...generateBaseFinancialEffectData(getValues()),
          title: "COPY - " + getValues("title"),
        };

        await wrapApiPromise(
          duplicateFinancialEffectMutation.mutateAsync({
            organizationId,
            recordingPeriodId,
            esrsTopicId: esrsTopic?.id ?? "",
            dmaCategoryId: dmaCategory?.id ?? "",
            dataEntryObjectId: dataEntryObject.id,
            payload: payload,
          }),
          { type: BasicSnackbarApiActionType.DUPLICATE_FINANCIAL_EFFECT },
        );
        // Only close edit Dialog when confirmed
        onClose(null);
      }
      setShowDuplicateFinancialEffectDialog(false);
    },
    [
      dataEntryObject.id,
      dmaCategory?.id,
      duplicateFinancialEffectMutation,
      esrsTopic?.id,
      generateBaseFinancialEffectData,
      getValues,
      onClose,
      organizationId,
      recordingPeriodId,
      wrapApiPromise,
    ],
  );

  // Render Dialog

  const { user } = useUserContext();

  const isResponsibleUser =
    financialEffect?.responsibleUserId &&
    user?.userProfile.id === financialEffect?.responsibleUserId;

  const editMode = !!financialEffect;
  const selectedEffectType = watch("effectType");

  const mainDmaCategory = useIroEditDialogMainDmaCategory(esrsTopic, dmaCategory);
  const areCommentsAvailable = esrsTopic && financialEffect && mainDmaCategory;

  const isCategoryVerified = useMemo(() => {
    return !!dmaCategory && dmaCategory.financialState === IDMACategoryState.Verified;
  }, [dmaCategory]);

  return (
    <>
      <InfoDialogText
        open={isIncompleteForSharedNoticeDialogShown}
        onClose={handleCloseIncompleteForSharedNoticeDialog}
        title={t("incomplete_for_shared_notice_dialog_title")}
        content={infoDialogContent}
      />

      {/* Duplicate Financial Effect Dialog */}
      <ConfirmDialogTextBody
        open={showDuplicateFinancialEffectDialog}
        loading={duplicateFinancialEffectMutation.isPending}
        error={duplicateFinancialEffectMutation.error}
        title={
          <FormatTranslation
            i18nKey="duplicate_dialog_title"
            t={t}
            values={{ name: financialEffect?.title }}
          />
        }
        content={t("duplicate_dialog_content")}
        onClose={handleCloseDuplicateFinancialEffectDialog}
      />

      {areCommentsAvailable && (
        <EditDialogCommentsAndChangelogPaper
          open={showCommentsAndAuditLogSideSection}
          dialogOpen={open}
          organizationId={organizationId}
          relations={{
            entity: "financial-effect",
            financialEffectId: financialEffect.id,
          }}
          linkMetadata={{
            organizationId: organizationId,
            recordingPeriodId: recordingPeriodId,
            esrsTopicId: esrsTopic.id,
            topLevelDmaCategoryId: mainDmaCategory.id,
            entity: "financial-effect",
            financialEffectId: financialEffect.id,
          }}
          dialogElement={dialogElement}
          onClose={closeCommentsAndAuditLogSideSection}
        />
      )}

      <EditDialogWrapper
        open={open}
        title={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="flex-start">
              {/* Breadcrumbs */}
              <Box flex={1} component="span">
                <DMABreadcrumbsComponent esrsTopic={esrsTopic} dmaCategory={dmaCategory} />
              </Box>

              <Box display="flex" gap={0.5} mt={-1} mr={-2}>
                {/* 3-Dot Menu */}
                <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_DMA_CATEGORIES}>
                  {editMode && !readOnly && (
                    <DotMenu
                      disabled={loading}
                      menuItems={[
                        {
                          label: t("delete_tooltip"),
                          onClick: onDelete,
                          icon: <DeleteIcon />,
                        },
                        {
                          label: t("move_to_other_topic"),
                          onClick: onMoveIRO,
                          disabled: isCategoryVerified,
                          disabledTooltip: t("topic_disabled_reason", {
                            ns: "move_iro_to_other_topic_dialog",
                          }),
                          icon: <ArrowRightIcon />,
                        },
                        {
                          label: t("duplicate"),
                          onClick: handleDuplicateFinancialEffectDialog,
                          disabled: isDirty,
                          disabledTooltip: t("duplicate_disabled_reason"),
                          icon: <CopyIcon />,
                        },
                      ]}
                    />
                  )}
                </OnlyShowToUsersWithRole>

                {/* Open Comment and Audit Log Sidebar */}
                {areCommentsAvailable && (
                  <EditDialogExpandCommentsAndAuditLogPaperButton
                    showSideSection={showCommentsAndAuditLogSideSection}
                    onToggle={toggleCommentsAndAuditLogSideSection}
                  />
                )}
              </Box>
            </Box>
            <Divider sx={{ mx: -3, mt: -2 }} />
            <Box display="flex" flexDirection="column" gap={1}>
              {/* IRO Type Chip */}
              {editMode && (
                <IroTypeIndicator
                  iroType={
                    selectedEffectType === IDMAFinancialEffectType.Chance ? "opportunity" : "risk"
                  }
                />
              )}

              {/* Title Text */}
              <Box display="flex" alignItems="flex-start" gap={2}>
                <Box flex={1} component="span">
                  {readOnly && financialEffect?.title}
                  {!readOnly && // Only show when NOT readOnly
                    (editMode ? (
                      <FormatTranslation
                        i18nKey="title_edit"
                        t={t}
                        values={{ title: financialEffect.title }}
                      />
                    ) : (
                      t("title_create")
                    ))}
                </Box>

                {/* Status Indicator */}
                {editMode && onUpdateIROState && (
                  <>
                    {/* Allow any Changes by Responsible User AND Changes down from Shared */}
                    {isResponsibleUser || financialEffect.state === IIROState.Shared ? (
                      <IROStateSelect
                        value={financialEffect.state}
                        onChange={handleChangeState}
                        disabled={isDirty || parentDisabled}
                        readOnly={iroStateReadOnly}
                      />
                    ) : (
                      <IROStateIndicator state={financialEffect.state} />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        }
        mode={editMode ? "edit" : "create"}
        loading={!!loading}
        hasChanges={isDirty}
        onCancel={() => onClose(null)}
        onSave={handleSubmit(handleEmitData)}
        error={error}
        readOnly={readOnly}
        dialogProps={{
          fullWidth: true,
          maxWidth: "md",
          ...dialogProps,
        }}
      >
        <FormProvider {...formMethods}>
          <Box display="flex" flexDirection="column" gap={2} pt={1}>
            <IroCommonInputs
              organizationId={organizationId}
              type="financial-effect"
              existingEntityId={financialEffect?.id}
              onClickComments={toggleCommentsAndAuditLogSideSection}
              disabled={internalDisabled}
              readOnly={!!readOnly}
            />

            <Box display="flex" gap={2}>
              {/* Effect Type Select */}
              <Controller
                control={control}
                name="effectType"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select_effect_type_label">
                      {t("effect_type_select_label")}
                    </InputLabel>
                    <Select
                      labelId="select_effect_type_label"
                      label={t("effect_type_select_label")}
                      value={field.value}
                      onChange={(evt) => field.onChange(evt.target.value)}
                      disabled={internalDisabled}
                    >
                      {ORDERED_FINANCIAL_EFFECT_TYPES.map((typeValue) => (
                        <MenuItem key={typeValue} value={typeValue}>
                          {t(`${typeValue}_name`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              {/* RO Category Select */}
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="category">
                      {t("effect_category.select_label", { ns: "dma_financial_effect_common" })}
                    </InputLabel>
                    <Select
                      labelId="category"
                      label={t("effect_category.select_label", {
                        ns: "dma_financial_effect_common",
                      })}
                      value={field.value}
                      onChange={(evt) => field.onChange(evt.target.value)}
                      disabled={internalDisabled}
                    >
                      {ORDERED_FINANCIAL_EFFECT_CATEGORIES.map((categoryKey) => (
                        <MenuItem key={categoryKey} value={categoryKey}>
                          {t(`effect_category.${categoryKey}`, {
                            ns: "dma_financial_effect_common",
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>

            {/* Areas Checkboxes */}
            <IROAreasCheckboxesComponent
              dataEntryObject={dataEntryObject}
              disabled={internalDisabled}
              esrsTopic={esrsTopic}
              entityType="effect"
            />

            {/* Horizons */}
            <Box mt={2}>
              <Divider sx={{ mx: -3 }} />

              {/* Horizons Tabs */}
              <Tabs
                value={viewedHorizon}
                onChange={(_, newValue) => setViewedHorizon(newValue)}
                sx={{ mx: -3 }}
                variant="fullWidth"
              >
                {ORDERED_HORIZONS_VALUES.map((horizon) => (
                  <Tab
                    key={horizon}
                    value={horizon}
                    label={
                      <EditDialogHorizonTabContent
                        horizon={horizon}
                        error={!!errors.horizons?.[horizon]}
                      />
                    }
                  />
                ))}
              </Tabs>
              <Divider sx={{ mx: -3, mb: 3 }} />

              {/*
              Horizons Sections.
              Have to always be mounted since validation otherwise won't trigger on submit
            */}
              {HORIZONS_VALUES.map((horizon) => (
                <Box
                  key={horizon}
                  height={viewedHorizon === horizon ? "auto" : 0}
                  overflow="hidden"
                  mx={-3}
                  px={3}
                >
                  <DMAFinancialEffectHorizonEditSection
                    // Fix change of inheritance value does not trigger update of inputs and severity/materiality preview
                    key={currentHorizons[horizon].inheritsFromHorizon}
                    control={control}
                    horizon={horizon}
                    inheritFromHorizonsState={horizonsInheritanceState}
                    dmaConfiguration={dmaConfiguration}
                    setValue={setValue}
                    trigger={trigger}
                    showHorizonsCommentInput={hasHorizonsComment}
                    disabled={internalDisabled}
                    organizationId={organizationId}
                    recordingPeriodId={recordingPeriodId}
                    dataEntryObjectId={dataEntryObject.id}
                    esrsTopic={esrsTopic}
                  />
                </Box>
              ))}
            </Box>

            <Divider sx={{ mx: -3 }} />

            {/* Calculated Severity */}

            <Box display="flex" alignItems="center">
              <Typography variant="h6">
                {t("header_total_evaluation", { ns: "dma_impact_or_effect_edit_dialog_common" })}
              </Typography>
              {/* Only show if esrs topic is not null, since tooltips are bound to a specific topic */}
              {esrsTopic && (
                <IntercomReferenceWidget
                  entityType="effect"
                  identifier={`dialog_subheading_total_assessment_create_help.${esrsTopic.identifier}`}
                  iconSize={IconSize.Smaller}
                />
              )}
            </Box>

            <Box display="flex" gap={2}>
              <Typography sx={{ minWidth: 150 }}>
                {t("materiality_degree_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
              </Typography>
              <Typography fontWeight="bold">
                {DmaFormatUtilities.formatMaterialityDegree(calculatedTotalMaterialityDegree)}
              </Typography>
            </Box>

            {/* Calculated Materiality */}
            <Box display="flex" gap={2}>
              <Typography sx={{ minWidth: 150 }}>
                {t("materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
              </Typography>
              <Typography fontWeight="bold">
                {calculatedTotalMateriality
                  ? t("materiality_true_state", { ns: "dma_common" })
                  : t("materiality_false_state", { ns: "dma_common" })}
              </Typography>
            </Box>
          </Box>
        </FormProvider>
      </EditDialogWrapper>
    </>
  );
};
