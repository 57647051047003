import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Tooltip, Typography } from "@mui/material";
import { OrganizationUserPicker } from "../../user/components/organization-user-picker.component";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";

export interface IAssociatedUsersDialogInputsFormData {
  responsibleUserId: string | null | undefined;
  contributingUserIds: string[];
}

interface IAssociatedUsersDialogInputsProps {
  organizationId: string;
  disabled: boolean | undefined;
  explanationAssignedUsers?: string;
  disabledResponsibleUser?: boolean;
  disabledContributingUsers?: boolean;
}

export const AssociatedUsersDialogInputs: FC<IAssociatedUsersDialogInputsProps> = ({
  organizationId,
  disabled,
  explanationAssignedUsers,
  disabledResponsibleUser,
  disabledContributingUsers,
}) => {
  const { t } = useTranslation("associated_users_dialog_inputs");

  const { control } = useFormContext<IAssociatedUsersDialogInputsFormData>();

  return (
    <Box display="flex" gap={6}>
      {/* Responsible User */}
      <Box>
        <Typography variant="subtitle1">{t("label_responsible_user")}</Typography>
        <Box pt={0.25}>
          <Controller
            control={control}
            name="responsibleUserId"
            render={({ field }) => (
              <OrganizationUserPicker
                value={field.value ?? null}
                onChange={(user) => field.onChange(user?.id ?? null)}
                organizationId={organizationId}
                disabled={disabled || disabledResponsibleUser}
              />
            )}
          />
        </Box>
      </Box>

      {/* Assigned Users */}
      <Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="subtitle1">{t("label_assigned_users")}</Typography>
          {explanationAssignedUsers && (
            <Tooltip title={explanationAssignedUsers} placement="right">
              <InfoIcon />
            </Tooltip>
          )}
        </Box>
        <Box pt={0.75}>
          <Controller
            control={control}
            name="contributingUserIds"
            render={({ field }) => (
              <OrganizationUsersComponent
                values={field.value}
                organizationId={organizationId}
                onChange={field.onChange}
                disabled={disabled || disabledContributingUsers}
                emptyMessageAddButton={t("no_more_users_to_assign")}
                emptyMessage={t("no_assigned_users")}
                tooltipAddButton={t("add_assigned_user_tooltip")}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
