import { FC, useMemo } from "react";
import { FilterBar, getFilterConfigGenerator } from "../../filters/filter-bar.component";
import { EmissionFactorsFilter } from "../../filters/filter.constants";
import { FilterType, IFilterConfig, IOption } from "../../filters/filter.type";
import { useTranslation } from "react-i18next";
import {
  IEmissionFactorDatabase,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import { CountryCodeAlpha2Enum } from "@netcero/netcero-common";
import { useRenderGhgCategoryName } from "../emission-factors.hooks";
import { ORDERED_EMISSION_FACTOR_TYPE } from "../emission-factors.constants";
import { EmissionFactorUnitNameShortWithTooltip } from "./emission-factor-unit-name.component";

interface IEmissionFactorsFiltersProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams?: URLSearchParams) => void;
}

export const EmissionFactorsFilters: FC<IEmissionFactorsFiltersProps> = ({
  searchParams,
  setSearchParams,
}) => {
  const { t } = useTranslation("emission_factors_filters");
  const renderGhgCategoryName = useRenderGhgCategoryName();

  const generateFilterConfig = useMemo(() => getFilterConfigGenerator(t), [t]);

  const databaseOptions: IOption[] = useMemo(
    () =>
      Object.values(IEmissionFactorDatabase).map((database) => ({
        name: t(database, { ns: "emission_factor_databases" }),
        value: database,
      })),
    [t],
  );

  const emissionFactorTypeOptions: IOption[] = useMemo(
    () =>
      ORDERED_EMISSION_FACTOR_TYPE.map((database) => ({
        name: t(database, { ns: "emission_factor_types" }),
        value: database,
      })),
    [t],
  );

  const ghgCategoryOptions: IOption[] = useMemo(
    () =>
      Object.values(IGhgCategoriesIdentifier).map((ghgCategory) => ({
        name: renderGhgCategoryName(ghgCategory),
        value: ghgCategory,
      })),
    [renderGhgCategoryName],
  );

  const countryOptions: IOption[] = useMemo(
    () =>
      Object.values(CountryCodeAlpha2Enum).map((countryCode) => ({
        name: t(`countries.${countryCode}`, { ns: "common" }),
        value: countryCode,
      })),
    [t],
  );

  const unitOptions: IOption[] = useMemo(
    () =>
      Object.values(IEmissionFactorUnit).map((unit) => ({
        name: t(unit, { ns: "emission_factor_units" }),
        value: unit,
      })),
    [t],
  );

  const filters: IFilterConfig[] = useMemo(
    () => [
      generateFilterConfig(EmissionFactorsFilter.name, {
        type: FilterType.SearchOpen,
      }),
      generateFilterConfig(EmissionFactorsFilter.databases, {
        type: FilterType.Select,
        options: databaseOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.emissionFactorTypes, {
        type: FilterType.Select,
        options: emissionFactorTypeOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.ghgCategories, {
        type: FilterType.SearchMenu,
        options: ghgCategoryOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.countries, {
        type: FilterType.SearchMenu,
        options: countryOptions,
        multiple: true,
      }),
      generateFilterConfig(EmissionFactorsFilter.units, {
        type: FilterType.Select,
        options: unitOptions,
        multiple: true,
        buildMenuItem: (option) => (
          <EmissionFactorUnitNameShortWithTooltip
            unit={option.value as IEmissionFactorUnit}
            tooltipProps={{ placement: "right" }}
          />
        ),
      }),
    ],
    [
      generateFilterConfig,
      databaseOptions,
      emissionFactorTypeOptions,
      ghgCategoryOptions,
      unitOptions,
      countryOptions,
    ],
  );

  return (
    <FilterBar filters={filters} searchParams={searchParams} setSearchParams={setSearchParams} />
  );
};
