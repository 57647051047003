import { Box, Button, Tooltip } from "@mui/material";
import {
  IDMACategoryWithEffectsAndChildren,
  IStakeholderFeedbackType,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckAllIcon, UndoIcon } from "../../../common/constants/tabler-icon.constants";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";
import { useESRSTopicContext } from "../../esrs-topic.context";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "../../dma.queries";
import { ConfirmDialogTextBody } from "../../../common/dialogs/variants/confirm.dialog";
import { DMACategoriesUtilities } from "@netcero/netcero-common";

interface IDMACategoryValidationButtonsProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
  disabled?: boolean;
  onVerify: (payload: IVerifyStakeholderFeedbackRequest) => void;
}

export const DMACategoryValidationButton: FC<IDMACategoryValidationButtonsProps> = ({
  dmaCategory,
  disabled,
  onVerify,
}) => {
  const { t } = useTranslation("dma_category_validation_button");

  const [confirmDialogState, setConfirmDialogState] = useState<{
    showDialog: boolean;
  }>({
    showDialog: false,
  });
  const closeConfirmDialog = useCallback(
    () => setConfirmDialogState((prev) => ({ ...prev, showDialog: false })),
    [],
  );

  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  const stakeholderFeedbacks = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const manualStakeholderFeedbacks = useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const hasSubmittedFeedbackForMaterial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.hasSubmittedFeedbackForType(
        stakeholderFeedbacks.data?.stakeholderFeedbacks ?? [],
        manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks ?? [],
        IStakeholderFeedbackType.Material,
      ),
    [
      manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks,
      stakeholderFeedbacks.data?.stakeholderFeedbacks,
    ],
  );

  const hasSubmittedFeedbackForFinancial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.hasSubmittedFeedbackForType(
        stakeholderFeedbacks.data?.stakeholderFeedbacks ?? [],
        manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks ?? [],
        IStakeholderFeedbackType.Financial,
      ),
    [
      manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks,
      stakeholderFeedbacks.data?.stakeholderFeedbacks,
    ],
  );

  const handleConfirmClose = (confirmed: boolean) => {
    if (confirmed) {
      onVerify({
        verified: true,
      });
    }
    closeConfirmDialog();
  };

  const handleValidationClick = () => {
    if (!hasSubmittedFeedbackForMaterial || !hasSubmittedFeedbackForFinancial) {
      setConfirmDialogState({
        showDialog: true,
      });
    } else {
      // Directly perform the validation if feedback has been submitted
      onVerify({
        verified: true,
      });
    }
  };

  const isVerified = DMACategoriesUtilities.canCategoryUnsetVerified(dmaCategory);
  const canSetVerified = DMACategoriesUtilities.canCategorySetVerified(dmaCategory);

  return (
    <>
      <ConfirmDialogTextBody
        open={confirmDialogState.showDialog}
        title={t("title_confirm_validation")}
        content={t("content_confirm_validation")}
        onClose={handleConfirmClose}
        confirmOverride={t("button_confirm_validation")}
      />

      <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
        {/* Complete Topic Button */}
        {isVerified ? (
          // Topic is verified, show undo button
          <span>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<UndoIcon />}
              disabled={disabled}
              onClick={() => {
                onVerify({
                  verified: false,
                });
              }}
            >
              {t("button_undo_verify_topic")}
            </Button>
          </span>
        ) : (
          // Topic is not verified, show verify button
          <Tooltip title={!canSetVerified && t("tooltip_steps_to_verify")}>
            <span>
              <Button
                variant="text"
                color="primary"
                startIcon={<CheckAllIcon />}
                disabled={!canSetVerified || disabled}
                onClick={handleValidationClick}
              >
                {t("button_verify_topic")}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};
