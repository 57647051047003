import { useCallback, useMemo, useState } from "react";
import {
  IPaginationCurrentPageHandler,
  IPaginationRowsPerPageHandler,
  IPaginationState,
  IUsePaginationStateResult,
} from "../pagination.types";

export const usePaginationState = (
  startWithPage: number = 0,
  startWithRowsPerPage: number = 10,
): IUsePaginationStateResult => {
  const [state, setState] = useState<IPaginationState>({
    currentPage: startWithPage,
    rowsPerPage: startWithRowsPerPage,
  });

  const setCurrentPage: IPaginationCurrentPageHandler = useCallback((page: number) => {
    setState((prev) => ({ ...prev, currentPage: page }));
  }, []);

  const setRowsPerPage: IPaginationRowsPerPageHandler = useCallback((rowsPerPage: number) => {
    setState({ currentPage: 0, rowsPerPage: rowsPerPage });
  }, []);

  return useMemo(
    () => ({
      state,
      setCurrentPage,
      setRowsPerPage,
    }),
    [setCurrentPage, setRowsPerPage, state],
  );
};
