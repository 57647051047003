import { Box, CircularProgress, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { OrganizationStructureConfigurationComponent } from "../../modules/organization-structures/organization-structures-configuration.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { useCurrentRecordingPeriod } from "../../modules/recording-periods/use-current-recording-period.hook";
import {
  HEADER_HEIGHT,
  INTERCOM_HEIGHT,
} from "../../modules/common/components/app-shell-wrapper.component";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";

interface IOrganizationStructureConfigurationPageProps {}

export const OrganizationStructureConfigurationPage: FC<
  IOrganizationStructureConfigurationPageProps
> = () => {
  const { t } = useTranslation("organization_structure_configuration_page");
  const navigate = useNavigate();

  const { organizationId, recordingPeriodId } = useParams();

  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getConfigurationRecordingPeriodPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: NavItemUrlUtilities.getConfigurationRecordingPeriodPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getConfigurationRecordingPeriodPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  return (
    <Box width="100%" maxWidth="100%" mx="auto">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1" mb={2}>
          {t("title")}
        </Typography>
        {/* NC-1945: Disable distribution criteria */}
        {/*<HideChildrenInProductionAndNoFullAccessComponent>*/}
        {/*  <Tooltip title={t("button_dc_tooltip")}>*/}
        {/*    <Button*/}
        {/*      startIcon={<DistributionCriteriaIcon />}*/}
        {/*      variant="contained"*/}
        {/*      onClick={() => {*/}
        {/*        navigate(*/}
        {/*          `/organizations/${organizationId}/configuration/recording-periods/${recordingPeriodId!}/distribution-criteria`,*/}
        {/*        );*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t("button_dc")}*/}
        {/*    </Button>*/}
        {/*  </Tooltip>*/}
        {/*</HideChildrenInProductionAndNoFullAccessComponent>*/}
      </Box>
      {organizationStructureQuery.isLoading ||
      recordingPeriodsQuery.isLoading ||
      currentOrganization === null ||
      currentRecordingPeriod === null ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={`calc(100vh - ${HEADER_HEIGHT + INTERCOM_HEIGHT}px)`}
        >
          <CircularProgress />
        </Box>
      ) : organizationStructureQuery.isError || recordingPeriodsQuery.isError ? (
        <ErrorTextComponent
          error={(organizationStructureQuery.error || recordingPeriodsQuery.error)!}
        />
      ) : (
        <OrganizationStructureConfigurationComponent
          organization={currentOrganization!}
          recordingPeriods={recordingPeriodsQuery.data!.recordingPeriods}
          selectedRecordingPeriod={currentRecordingPeriod!}
          organizationStructure={organizationStructureQuery.data!}
          onChangeRecordingPeriod={(recordingPeriod) => {
            navigate(
              `/organizations/${organizationId}/configuration/recording-periods/${recordingPeriod.id}/organization-structure`,
            );
          }}
        />
      )}
    </Box>
  );
};
