/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IGhgCategoriesIdentifier = {
  _11StationaryCombustion: "1_1_stationary_combustion",
  _12MobileCombustion: "1_2_mobile_combustion",
  _13FugitiveEmissions: "1_3_fugitive_emissions",
  _14OtherEmissions: "1_4_other_emissions",
  _21PurchasedElectricity: "2_1_purchased_electricity",
  _22PurchasedHeat: "2_2_purchased_heat",
  _23PurchasedSteam: "2_3_purchased_steam",
  _24PurchasedCooling: "2_4_purchased_cooling",
  _31PurchasedGoodsAndServices: "3_1_purchased_goods_and_services",
  _32CapitalGoods: "3_2_capital_goods",
  _33EnergyRelatedActivitiesNotInScope1And2: "3_3_energy_related_activities_not_in_scope_1_and_2",
  _34UpstreamTransportationAndDistribution: "3_4_upstream_transportation_and_distribution",
  _35Waste: "3_5_waste",
  _36BusinessTravel: "3_6_business_travel",
  _37EmployeeCommuting: "3_7_employee_commuting",
  _38LeasedOrRentedAssets: "3_8_leased_or_rented_assets",
  _39DownstreamTransportationAndDistribution: "3_9_downstream_transportation_and_distribution",
  _310ProcessingOfSoldProducts: "3_10_processing_of_sold_products",
  _311UseOfSoldProducts: "3_11_use_of_sold_products",
  _312EndOfLifeTreatmentOfSoldProducts: "3_12_end_of_life_treatment_of_sold_products",
  _313LeasedOrRentedAssetsDownstream: "3_13_leased_or_rented_assets_downstream",
  _314Franchise: "3_14_franchise",
  _315Investments: "3_15_investments",
} as const;

export type IGhgCategoriesIdentifier =
  (typeof IGhgCategoriesIdentifier)[keyof typeof IGhgCategoriesIdentifier];
