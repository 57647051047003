import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { AppModule, ROLE_ACCESS } from "@netcero/netcero-common";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { AuditLogIcon, IconSize } from "../../modules/common/constants/tabler-icon.constants";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";
import { RequireModuleAccessOrganizationFromRouter } from "../../modules/organization-module-access/require-module-access.component";
import { ConfigurationSection } from "./configuration-section.component";
import { OnlyShowToUsersWithRole } from "../../modules/authentication/components/only-show-to-users-with-role.component";

export const ConfigurationDashboardPage: FC = () => {
  const { t } = useTranslation("configuration_dashboard_page");
  const { t: tNavItems } = useTranslation("side_bar_nav_items");

  const { organizationId } = useParams();
  const navigate = useNavigate();

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: tNavItems("configuration"),
      to: NavItemUrlUtilities.getConfigurationPageUrl(organizationId!),
    },
  ];

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getConfigurationPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: NavItemUrlUtilities.getConfigurationPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getConfigurationPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  return (
    <Box maxWidth="100%" width="100%" mx="auto">
      <AppBreadCrumbs items={breadCrumbItems} />
      <Box display="flex" justifyContent="space-between" gap={2} mt={1}>
        <Typography variant="h1" mb={4}>
          {t("title")}
        </Typography>
        <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_ACCESS_GLOBAL_AUDIT_LOG}>
          <Link to={NavItemUrlUtilities.getAuditLoggingConfigurationPageUrl(organizationId!)}>
            <Tooltip title={t("title_audit_logging", { ns: "common" })}>
              <IconButton>
                <AuditLogIcon size={IconSize.Large} />
              </IconButton>
            </Tooltip>
          </Link>
        </OnlyShowToUsersWithRole>
      </Box>
      {/* Organization */}
      {/* <ConfigurationOverviewSectionContainer>
        <Typography variant="h2">{t("title_organization")}</Typography>
        <Box display="flex" gap={2} mt={2}>
          <Typography flex={1}>{t("description_organization")}</Typography>
          <Link to={`/organizations/${organizationId}/configuration/organization`}>
            <Button variant="contained">{t("button_organization")}</Button>
          </Link>
        </Box>
      </ConfigurationOverviewSectionContainer>

      <Divider sx={{ my: 2 }} /> */}

      <OnlyShowToUsersWithRole roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_CONFIGURATION_RECORDING_PERIOD}>
        <ConfigurationSection
          title={t("title_recording_periods")}
          description={t("description_recording_periods")}
          url={NavItemUrlUtilities.getConfigurationRecordingPeriodPageUrl(organizationId!)}
          buttonLabel={t("button_recording_periods")}
        />
        <Divider sx={{ my: 2 }} />
      </OnlyShowToUsersWithRole>

      <ConfigurationSection
        title={t("title_stakeholder")}
        description={t("description_stakeholder")}
        url={NavItemUrlUtilities.getConfigurationStakeholderPageUrl(organizationId!)}
        buttonLabel={t("button_stakeholder")}
      />
      <Divider sx={{ my: 2 }} />

      {/* NC-1945: Disable distribution criteria */}
      {/*<OnlyShowToUsersWithRole
        roles={ROLE_ACCESS.ALLOWED_TO_MODIFY_CONFIGURATION_DISTRIBUTION_CRITERIA}
      >*/}
      {/*  <HideChildrenInProductionAndNoFullAccessComponent>*/}
      {/*    <ConfigurationSection*/}
      {/*      title={t("title_distribution_criteria")}*/}
      {/*      description={t("description_distribution_criteria")}*/}
      {/*      url={NavItemUrlUtilities.getConfigurationDistributionCriteriaPageUrl(organizationId!)}*/}
      {/*      buttonLabel={t("button_distribution_criteria")}*/}
      {/*    />*/}
      {/*    <Divider sx={{ my: 2 }} />*/}
      {/*  </HideChildrenInProductionAndNoFullAccessComponent>*/}
      {/*</OnlyShowToUsersWithRole>*/}

      <ConfigurationSection
        title={t("title_sources")}
        description={t("description_sources")}
        url={NavItemUrlUtilities.getConfigurationSourcesPageUrl(organizationId!)}
        buttonLabel={t("button_sources")}
      />
      <Divider sx={{ my: 2 }} />

      <ConfigurationSection
        title={t("title_users")}
        description={t("description_users")}
        url={NavItemUrlUtilities.getConfigurationUsersPageUrl(organizationId!)}
        buttonLabel={t("button_users")}
      />
      <Divider sx={{ my: 2 }} />

      <RequireModuleAccessOrganizationFromRouter module={AppModule.CARBON_ACCOUNTING}>
        <ConfigurationSection
          title={t("title_emission_factors")}
          description={t("description_emission_factors")}
          url={NavItemUrlUtilities.getConfigurationEmissionFactorsPageUrl(organizationId!)}
          buttonLabel={t("button_emission_factors")}
        />
        <Divider sx={{ my: 2 }} />
      </RequireModuleAccessOrganizationFromRouter>
    </Box>
  );
};
