import { useQuery } from "@tanstack/react-query";
import { useKeycloakUserApiClient } from "./hooks/keycloak-user-api.hook";

export const getUserAccountQueryKey = () => ["user", "account"];
export const useUserAccountQuery = () => {
  const keycloakClient = useKeycloakUserApiClient();
  return useQuery({
    queryKey: getUserAccountQueryKey(),
    queryFn: () => keycloakClient!.readAccount(),
    enabled: !!keycloakClient,
  });
};
