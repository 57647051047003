import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Input, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { IFilterConfig } from "./filter.type";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@uidotdev/usehooks";

interface IFilterOpenSearchProps {
  filter: IFilterConfig;
  /** URL search parameters for URL-based filtering.
   * Use either searchParams OR value, not both. */
  searchParams?: URLSearchParams;
  /** Value for state-based filtering.
   * Use either value OR searchParams, not both. */
  value?: string;
  onChange: (name: string, value: string) => void;
  endIcon?: ReactNode;
}

export const FilterOpenSearch: FC<IFilterOpenSearchProps> = ({
  filter,
  searchParams,
  value,
  onChange,
  endIcon,
}) => {
  const { t } = useTranslation("filter_bar");

  const queryFilterValue = useMemo(() => {
    if (searchParams) {
      return searchParams.get(filter.name) || "";
    }
    return value || "";
  }, [searchParams, value, filter.name]);

  const [internalValue, setInternalValue] = useState(queryFilterValue);

  const debouncedInternalValue = useDebounce(internalValue, 500);

  useEffect(() => {
    if (queryFilterValue !== debouncedInternalValue) {
      setInternalValue(queryFilterValue);
    }
    // Only trigger on queryFilterValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilterValue]);

  useEffect(() => {
    if (debouncedInternalValue !== queryFilterValue) {
      onChange(filter.name, debouncedInternalValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInternalValue, filter.name, queryFilterValue]);

  return (
    <Input
      key={filter.name}
      placeholder={filter.label ?? t("search")}
      value={internalValue}
      onChange={(e) => setInternalValue(e.target.value)}
      size="small"
      endAdornment={
        <InputAdornment position="end">
          {endIcon ?? <Search sx={{ color: "rgba(0, 0, 0, 0.5)" }} />}
        </InputAdornment>
      }
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        "& input": {
          fontSize: "14px",
          padding: 0,
          marginBottom: -0.5,
        },
      }}
    />
  );
};
