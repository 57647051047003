import {
  IDMACategoryWithEffects,
  IDMAConfigurationPotentialExtendSteps,
  IDMAConfigurationProbabilityOfOccurrenceSteps,
  IDmaStatus,
  IDmaStatusCompleted,
  IDmaStatusStarted,
  IIROProbabilityPredefinedValue,
  IRecordedESRSTopic,
} from "@netcero/netcero-core-api-client";
import { DefaultDMAProbabilitySliderValues, DMASliderValues } from "../enums/slider-values.enum";

export class DMAUtilities {
  public static getPotentialExtentCurrencyEquivalentLookup(
    potentialExtentSteps: IDMAConfigurationPotentialExtendSteps,
  ) {
    return {
      [DMASliderValues.VeryLow]: potentialExtentSteps.potentialExtentStep1,
      [DMASliderValues.RatherLow]: potentialExtentSteps.potentialExtentStep2,
      [DMASliderValues.Average]: potentialExtentSteps.potentialExtentStep3,
      [DMASliderValues.RatherHigh]: potentialExtentSteps.potentialExtentStep4,
      [DMASliderValues.VeryHigh]: potentialExtentSteps.potentialExtentStep5,
    };
  }

  /**
   * Get the potential extent currency equivalent
   * @param potentialExtentSteps The potential extent steps (as set in the DMA configuration)
   * @param sliderValue The slider value to get the equivalent for
   * @returns The potential extent currency equivalent
   */
  public static getPotentialExtentCurrencyEquivalent(
    potentialExtentSteps: IDMAConfigurationPotentialExtendSteps,
    sliderValue: DMASliderValues,
  ) {
    return DMAUtilities.getPotentialExtentCurrencyEquivalentLookup(potentialExtentSteps)[
      sliderValue
    ];
  }

  /**
   * This method returns the numeric probability value for the given probability slider value.
   * If the DMA configuration exists, the probability value is taken from the configuration.
   * Otherwise, the default value is returned.
   */
  public static getProbabilitySliderValue(
    sliderValue: IIROProbabilityPredefinedValue,
    probabilityOfOccurrenceSteps: IDMAConfigurationProbabilityOfOccurrenceSteps | null = null,
  ): number {
    if (probabilityOfOccurrenceSteps !== null) {
      const lookup: { [key in IIROProbabilityPredefinedValue]: number } = {
        [IIROProbabilityPredefinedValue.VeryLow]:
          probabilityOfOccurrenceSteps.probabilityOfOccurrenceStep1,
        [IIROProbabilityPredefinedValue.Low]:
          probabilityOfOccurrenceSteps.probabilityOfOccurrenceStep2,
        [IIROProbabilityPredefinedValue.Average]:
          probabilityOfOccurrenceSteps.probabilityOfOccurrenceStep3,
        [IIROProbabilityPredefinedValue.High]:
          probabilityOfOccurrenceSteps.probabilityOfOccurrenceStep4,
        [IIROProbabilityPredefinedValue.VeryHigh]:
          probabilityOfOccurrenceSteps.probabilityOfOccurrenceStep5,
      };
      return lookup[sliderValue];
    } else {
      switch (sliderValue) {
        case IIROProbabilityPredefinedValue.VeryLow:
          return DefaultDMAProbabilitySliderValues.VeryLow;
        case IIROProbabilityPredefinedValue.Low:
          return DefaultDMAProbabilitySliderValues.RatherLow;
        case IIROProbabilityPredefinedValue.Average:
          return DefaultDMAProbabilitySliderValues.Average;
        case IIROProbabilityPredefinedValue.High:
          return DefaultDMAProbabilitySliderValues.RatherHigh;
        case IIROProbabilityPredefinedValue.VeryHigh:
          return DefaultDMAProbabilitySliderValues.VeryHigh;
      }
    }
  }

  /**
   * This method converts the specified probability to an integer from 1 to 5
   */
  public static convertProbabilityValueToInteger(value: IIROProbabilityPredefinedValue) {
    switch (value) {
      case IIROProbabilityPredefinedValue.VeryLow:
        return 1;
      case IIROProbabilityPredefinedValue.Low:
        return 2;
      case IIROProbabilityPredefinedValue.Average:
        return 3;
      case IIROProbabilityPredefinedValue.High:
        return 4;
      case IIROProbabilityPredefinedValue.VeryHigh:
        return 5;
      default:
        throw new Error(`Impossible probability value: ${value}`);
    }
  }

  /**
   * This method checks whether the specified topic is material in the DMA status.
   * @param status The DMA status
   * @param categoryIdentifier The category identifier of the topic
   * @param allowIncomplete Whether to allow incomplete statuses (if false will always return null unless dma has been completed)
   */
  public static isTopicInDmaStatusMaterial(
    status: IDmaStatus,
    categoryIdentifier: string,
    allowIncomplete: boolean,
  ) {
    return DMAUtilities.handleIncompleteStatus(status, allowIncomplete, (validatedStatus) => {
      // ESRS_2 is always material
      if (categoryIdentifier === "esrs_2") {
        return true;
      }

      // Get the materiality for the topic (return null if not found)
      return validatedStatus.materiality.materialityPerTopic[categoryIdentifier] ?? null;
    });
  }

  /**
   * This method handles the case that the DMA status is not yet completed / not started.
   * @param status The status to inspect
   * @param allowIncomplete Whether to allow incomplete statuses
   * @param callWhenNotIncomplete The function to call when the status is not incomplete
   */
  public static handleIncompleteStatus(
    status: IDmaStatus,
    allowIncomplete: boolean,
    callWhenNotIncomplete: (status: IDmaStatusStarted | IDmaStatusCompleted) => boolean | null,
  ): boolean | null {
    // Not started DMAs are not material
    if (status.status === "not-started") {
      return null;
    }
    // Handle not yet completed
    if (!allowIncomplete && status.status !== "completed") {
      return null;
    }

    return callWhenNotIncomplete(status);
  }

  /**
   * This method calculates the total materiality for the specified category.
   * To do so, it follows the following basic rules:
   * * If the category is opted out, it is never material
   * * Otherwise, the category is material if either one of the materiality flags is set
   * @param category The category to calculate the materiality for
   */
  public static calculateTotalMaterialityForCategory(category: IDMACategoryWithEffects) {
    return category.optOut
      ? // category opted out --> marked as "not relevant" --> never material
        false
      : // otherwise, the category is material if either one of the materiality flags is set
        (category.materiality.materialityMaterial ?? false) ||
          (category.materiality.materialityFinancial ?? false);
  }

  /**
   * This method calculates the total materiality for the specified topic.
   * To do so, it follows the following basic rules:
   * * If the topic is opted out, it is never material
   * * Otherwise, the materiality of the categories is relevant
   * @param topic The topic to check
   * @param isAnyCategoryMaterial Whether any category of the topic is material
   */
  public static calculateTotalMaterialityForTopic(
    topic: IRecordedESRSTopic,
    isAnyCategoryMaterial: boolean,
  ) {
    return topic.optOut ? false : isAnyCategoryMaterial;
  }
}
