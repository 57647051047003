import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IEmissionFactorUnit, IGhgCategoriesIdentifier } from "@netcero/netcero-core-api-client";
import { EMISSION_UNIT_TEXT, GHG_CATEGORIES_PATHS } from "./emission-factors.constants";

export const useRenderGhgCategoryName = () => {
  const { t } = useTranslation("ghg_categories");

  return useCallback(
    (ghgCategory: IGhgCategoriesIdentifier) => {
      return `${GHG_CATEGORIES_PATHS[ghgCategory]} ${t(ghgCategory)}`;
    },
    [t],
  );
};

export type IRenderEmissionFactorUnitType = "short" | "long";

export interface IRenderEmissionFactorUnitHookOptions {
  withPrefix?: boolean;
}

export const useRenderEmissionFactorUnit = () => {
  const { t } = useTranslation("emission_factor_units");

  return useCallback(
    (
      unit: IEmissionFactorUnit,
      type: IRenderEmissionFactorUnitType = "short",
      options?: IRenderEmissionFactorUnitHookOptions,
    ) => {
      const unitTranslation = t(`${type}.${unit}`);
      return options?.withPrefix ? `${EMISSION_UNIT_TEXT} / ${unitTranslation}` : unitTranslation;
    },
    [t],
  );
};
