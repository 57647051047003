import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  IBaseManualStakeholderFeedbackData,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IESRSTopic,
  IManualStakeholderFeedback,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStakeholdersQuery } from "../../../stakeholder/stakeholder.queries";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";
import {
  useCreateManualFeedbackMutation,
  useUpdateManualFeedbackMutation,
} from "../../mutations/dma-category-manual-feedback.mutations";
import { DMACategoryManualFeedbackEditDialog } from "../manual-feedback/dma-category-manual-feedback-edit.dialog";
import { DMACategoryManualFeedbackList } from "../manual-feedback/dma-category-manual-feedback-list.component";
import { DMACategoryFeedbackList } from "./dma-category-feedback-list.component";
import { DMACategoryFeedbackRequestDialog } from "./dma-category-feedback-request.dialog";
import { DMACategoryFeedbackWarnings } from "./dma-category-feedback-warnings.component";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "../../dma.queries";
import { CenteredCircularProgress } from "../../../common/components/centered-circular-progress.component";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import { DialogCloseButton } from "../../../common/dialogs/dialog-button.components";

interface IManualFeedbackDialogState {
  open: boolean;
  manualFeedback?: IManualStakeholderFeedback | null;
}

interface IDMACategoryFeedbackDialogProps {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsTopicId: string;
  onClose: () => void;
}

export const DMACategoryFeedbackDialog: FC<IDMACategoryFeedbackDialogProps> = ({
  open,
  dmaCategory,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopicId,
  onClose,
}) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_dialog");

  // Stakeholders

  const stakeholdersQuery = useStakeholdersQuery(organizationId);

  // Stakeholder Feedbacks

  const stakeholderFeedbacksQuery = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory?.id ?? "",
  );

  const groupedFeedbackEntries = useMemo(() => {
    return DMACategoryFeedbackUtilities.getOrderedStakeholderFeedbackEntriesArray(
      stakeholdersQuery.data?.stakeholders ?? [],
      stakeholderFeedbacksQuery.data?.stakeholderFeedbacks ?? [],
    );
  }, [stakeholdersQuery.data, stakeholderFeedbacksQuery.data]);

  // Manual Feedbacks

  const manualFeedbacksQuery = useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory?.id ?? "",
  );

  const manualFeedbacks = useMemo(
    () => manualFeedbacksQuery.data?.manualStakeholderFeedbacks ?? [],
    [manualFeedbacksQuery.data],
  );

  // Feedback Request Dialog

  const [feedbackRequestDialogOpen, setFeedbackRequestDialogOpen] = useState(false);

  const handleOpenRequestFeedbackDialog = () => {
    setFeedbackRequestDialogOpen(true);
  };

  const handleCloseRequestFeedbackDialog = () => {
    setFeedbackRequestDialogOpen(false);
  };

  // Manual Feedback Dialog

  const [editManualFeedbackDialogState, setEditManualFeedbackState] =
    useState<IManualFeedbackDialogState>({
      open: false,
      manualFeedback: null,
    });

  const handleAddManualFeedback = () => {
    createManualFeedbackMutation.reset();
    updateManualFeedbackMutation.reset();
    setEditManualFeedbackState({ open: true, manualFeedback: null });
  };

  const handleEditManualFeedback = (manualFeedback: IManualStakeholderFeedback) => {
    createManualFeedbackMutation.reset();
    updateManualFeedbackMutation.reset();
    setEditManualFeedbackState({ open: true, manualFeedback });
  };

  const createManualFeedbackMutation = useCreateManualFeedbackMutation();
  const updateManualFeedbackMutation = useUpdateManualFeedbackMutation();

  const handleCloseEditStakeholderFeedbackDialog = async (
    data: IBaseManualStakeholderFeedbackData | null,
  ) => {
    if (data) {
      if (editManualFeedbackDialogState.manualFeedback) {
        // Update existing feedback
        await updateManualFeedbackMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId: dmaCategory?.id ?? "",
          feedbackId: editManualFeedbackDialogState.manualFeedback.id,
          payload: data,
        });
      } else {
        // Add new feedback
        await createManualFeedbackMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId: dmaCategory?.id ?? "",
          payload: data,
        });
      }
    }
    setEditManualFeedbackState((state) => ({ ...state, open: false }));
  };

  // Render Component

  const disableFeedbackAction =
    dmaCategory?.financialState === IDMACategoryState.Verified &&
    dmaCategory?.materialState === IDMACategoryState.Verified;

  const renderName = useRenderDMACategoryName();

  return (
    <>
      {/* Modals */}
      <DMACategoryFeedbackRequestDialog
        open={feedbackRequestDialogOpen}
        dmaCategory={dmaCategory}
        feedback={stakeholderFeedbacksQuery.data?.stakeholderFeedbacks ?? []}
        onClose={handleCloseRequestFeedbackDialog}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        esrsTopicId={esrsTopicId}
      />

      <DMACategoryManualFeedbackEditDialog
        open={editManualFeedbackDialogState.open}
        manualFeedback={editManualFeedbackDialogState.manualFeedback}
        dmaCategory={dmaCategory}
        loading={createManualFeedbackMutation.isPending || updateManualFeedbackMutation.isPending}
        disabled={createManualFeedbackMutation.isPending || updateManualFeedbackMutation.isPending}
        error={
          createManualFeedbackMutation.isError
            ? createManualFeedbackMutation.error
            : updateManualFeedbackMutation.isError
            ? updateManualFeedbackMutation.error
            : null
        }
        organizationId={organizationId}
        onClose={handleCloseEditStakeholderFeedbackDialog}
      />

      {/* Actual Dialog */}
      <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="start">
            {t("title", { name: renderName(dmaCategory) })}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} pb={6}>
            {/* Feedback Warnings */}
            {dmaCategory && <DMACategoryFeedbackWarnings dmaCategory={dmaCategory} />}

            <Typography variant="h6" mt={6}>
              {t("header_feedback")}
            </Typography>

            {/* List Categories */}
            <DMACategoryFeedbackList
              feedbackEntries={groupedFeedbackEntries}
              recordingPeriodId={recordingPeriodId}
            />
            {/* Query Wrapper for Stakeholder Feedbacks */}
            <QueryWrapper
              query={
                /* Waiting for both queries to load before removing the loading Spinner */
                stakeholdersQuery.isLoading || stakeholderFeedbacksQuery.isLoading
                  ? null
                  : stakeholderFeedbacksQuery
              }
              loadingOverride={() => <CenteredCircularProgress />}
              build={(response) =>
                response.stakeholderFeedbacks.length <= 0 && (
                  <DialogContentText textAlign="center">{t("empty_notice")}</DialogContentText>
                )
              }
            />

            {/* Request Feedback Button */}
            <Box ml="auto">
              <Button
                variant="contained"
                onClick={handleOpenRequestFeedbackDialog}
                disabled={disableFeedbackAction || stakeholderFeedbacksQuery.isLoading}
              >
                {t("button_request_feedback")}
              </Button>
            </Box>

            {/* Manual Feedback */}
            <Typography variant="h6" mt={6}>
              {t("header_manual_feedback")}
            </Typography>

            {/* Feedback List */}
            <DMACategoryManualFeedbackList
              manualFeedbackEntries={manualFeedbacks}
              dmaCategory={dmaCategory}
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              dataEntryObjectId={dataEntryObjectId}
              esrsTopicId={esrsTopicId}
              onEdit={handleEditManualFeedback}
            />
            {/* Query Wrapper for Manual Feedbacks */}
            <QueryWrapper
              query={manualFeedbacksQuery}
              loadingOverride={() => <CenteredCircularProgress />}
              build={(response) =>
                response.manualStakeholderFeedbacks.length <= 0 && (
                  <DialogContentText textAlign="center">
                    {t("manual_feedback_empty_notice")}
                  </DialogContentText>
                )
              }
            />

            {/* Add Manual Feedback */}
            <Box ml="auto">
              <Button
                variant="contained"
                onClick={handleAddManualFeedback}
                disabled={disableFeedbackAction || manualFeedbacksQuery.isLoading}
              >
                {t("button_add_manual_feedback")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogCloseButton onClick={() => onClose()}>
            {t("close", { ns: "buttons" })}
          </DialogCloseButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
