import {
  IGhgCategoriesIdentifier,
  IInputParameterRecordingStructureGroupGHG,
} from "@netcero/netcero-core-api-client";
import { GHG_VERSIONS } from "./versions/ghg-versions";
import { IGhgCategoriesPerScope } from "./ghg-categories.public-types";
import { IGhgProtocolScope } from "../emission-factors";

export class GhgCategoriesUtilities {
  /**
   * This method retrieves the correct definition for a given GHG structure.
   * @param recordingStructure The recording structure to get the definition for.
   */
  public static getDefinitionForRecordingStructure(
    recordingStructure: IInputParameterRecordingStructureGroupGHG,
  ): IGhgCategoriesPerScope {
    return GHG_VERSIONS[recordingStructure.version];
  }

  /**
   * This method retrieves every present category identifier for a given recording structure.
   * @param recordingStructure The recording structure to get the category identifiers for.
   */
  public static getAllCategoryIdentifiersForRecordingStructure(
    recordingStructure: IInputParameterRecordingStructureGroupGHG,
  ): IGhgCategoriesIdentifier[] {
    return Object.values(
      GhgCategoriesUtilities.getDefinitionForRecordingStructure(recordingStructure),
    ).flatMap((definition) => definition);
  }

  /**
   * This function checks if a given GHG category is included in a specific scope across all GHG versions.
   * @param category - The GHG category identifier to check.
   * @param scope - The scope to check the category against.
   * @returns A boolean value indicating whether the category is present in the scope for any GHG version.
   */
  public static isCategoryInScope(
    category: IGhgCategoriesIdentifier,
    scope: IGhgProtocolScope,
  ): boolean {
    // check every GHG version to see if the category is present in the scope for that version.
    for (const ghgVersion of Object.values(GHG_VERSIONS)) {
      if (ghgVersion[scope].includes(category)) {
        return true;
      }
    }
    return false;
  }
}
