import { KEYCLOAK_REALM, KEYCLOAK_URL } from "../../../keycloak";
import { KeycloakUserApiClient } from "@netcero/keycloak-common";
import { useApiClient } from "../../common/hooks/base-api.hook";

const KEYCLOAK_CONFIG = {
  baseUrl: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
};

/**
 * Hook to get the KeycloakUserApiClient.
 * WARNING: You probably do NOT want to use the API client directly but pass it along to actions in the keycloak-common library.
 * @throws error This hook will throw an error if the token is null. (not authenticated/no user context)
 */
export const useKeycloakUserApiClient = () => {
  return useApiClient(KeycloakUserApiClient, KEYCLOAK_CONFIG);
};
