import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

export const NotAuthorizedNotice: FC = () => {
  const { t } = useTranslation("require_organization_role");

  return (
    <Typography variant="h3" textAlign="center" mt={10}>
      {t("access_denied")}
    </Typography>
  );
};
