/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Can only exists for market based emission factors
 * @export
 * @enum {string}
 */

export const IMarketBasedEmissionFactorType = {
  ProductMix: "product_mix",
  SupplierMix: "supplier_mix",
  ResidualMix: "residual_mix",
} as const;

export type IMarketBasedEmissionFactorType =
  (typeof IMarketBasedEmissionFactorType)[keyof typeof IMarketBasedEmissionFactorType];
