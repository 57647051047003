import { UndefinedOrNull } from "@netcero/netcero-common";

export class FormatUtilities {
  public static formatNumber(num: number, options?: Intl.NumberFormatOptions): string {
    return Number.isNaN(num) ? "-" : num.toLocaleString(navigator.language, options);
  }

  public static formatFixedDecimal(num: number, decimalPlaces: number): string {
    return Number.isNaN(num)
      ? "-"
      : num.toLocaleString(navigator.language, {
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        });
  }

  public static formatOptionalString(value: UndefinedOrNull<string>, defaultValue = "-"): string {
    return value ?? defaultValue;
  }

  public static formatFractionAsPercentage(
    num: number,
    fractionDigits = 2,
    minimumIntegerDigits?: number,
  ) {
    return this.formatPercentage(num * 100, fractionDigits, minimumIntegerDigits);
  }

  /**
   *
   * @param num Value as Percent (0 is 0% and 100 is 100%)
   * @param fractionDigits The maximum number of fraction digits to display. (will be rounded)
   * Example: 2 will render 0.123456 as 12.35
   * Default: 2
   * @param minimumIntegerDigits The minimum number of digits (like lpad with 0 until number of digits in front of decimal point is reached) to use for the integer part of the number.
   * Example: 2 will render the number 1 as 01
   * @param minimumFractionDigits If not provided, it will be the same as fractionDigits. If provided, it will be the minimum number of fraction digits.
   * Example: 2 will render 1 as 1.00
   * @returns
   */
  public static formatPercentage(
    num: number,
    fractionDigits = 2,
    minimumIntegerDigits?: number,
    minimumFractionDigits?: number,
  ): string {
    return Number.isNaN(num)
      ? "-"
      : num.toLocaleString(navigator.language, {
          maximumFractionDigits: fractionDigits,
          minimumIntegerDigits,
          minimumFractionDigits: minimumFractionDigits ?? fractionDigits,
        });
  }

  public static formatCurrency(num: number, currency: string = "EUR"): string {
    return num.toLocaleString(navigator.language, { style: "currency", currency });
  }

  public static formatDate(date: Date): string {
    return date.toLocaleDateString(navigator.language);
  }

  /**
   * Formats a date time string to a locale string
   * @param date The date to format
   * @param locale The locale to use for formatting. If not provided `navigator.language` is used.
   */
  public static formatDateTime(date: Date | string, locale?: string): string {
    return new Date(date).toLocaleString(locale ?? navigator.language);
  }

  /**
   * Shortens a text to a max length
   * @param text The text to limit in length
   * @param maxLength Max length (including the 3 dots)
   * @returns cut string
   * @deprecated Use LineClampText component instead
   */
  public static cutTextAfterN(text: string, maxLength: number) {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + "..." : text;
  }

  public static formatBoolean(
    materiality: boolean | null | undefined,
    textWhenTrue: string,
    textWhenFalse: string,
    textWhenEmpty = "-",
  ): string {
    return materiality !== null && materiality !== undefined
      ? materiality
        ? textWhenTrue
        : textWhenFalse
      : textWhenEmpty;
  }

  /**
   * A method to create dynamic URLs from provided path segments.
   *
   * @param segments - An array of URL segments that will be concatenated.
   * @returns - The assembled URL.
   */
  public static generateDynamicUrl(segments: string[]): string {
    return `/${segments.join("/")}`;
  }
}
