import { FC } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AssociatedUsersDialogInputs,
  IAssociatedUsersDialogInputsFormData,
} from "../../users/components/associated-users-dialog-inputs.component";
import { LinkedSourcesDialogInput } from "../../sources/components/linked-sources-dialog-input.component";
import { useDoesUserHaveRole } from "../../authentication/hooks/does-user-have-role.hook";
import { ROLE_ACCESS } from "@netcero/netcero-common";
import { useFormContext } from "react-hook-form";
import { useUserContext } from "../../user/user.context";

interface IIroDialogTopSectionInputsProps {
  organizationId: string;
  disabled: boolean | undefined;
}

export const IroDialogTopSectionInputs: FC<IIroDialogTopSectionInputsProps> = ({
  organizationId,
  disabled,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  const { watch } = useFormContext<IAssociatedUsersDialogInputsFormData>();
  const responsibleUserId = watch("responsibleUserId");
  const { user } = useUserContext();

  const canModifyResponsibleUser = useDoesUserHaveRole(ROLE_ACCESS.ALLOWED_TO_MODIFY_ALL_IROS);
  const canModifyContributors =
    canModifyResponsibleUser ||
    (responsibleUserId && user && responsibleUserId === user?.userProfile.id);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Linked Users section */}
      <AssociatedUsersDialogInputs
        organizationId={organizationId}
        disabled={disabled}
        explanationAssignedUsers={t("explanation_assigned_users")}
        disabledResponsibleUser={!canModifyResponsibleUser}
        disabledContributingUsers={!canModifyContributors}
      />
      {/* Linked Sources */}
      <LinkedSourcesDialogInput organizationId={organizationId} disabled={disabled} />
    </Box>
  );
};
