import { FC, useMemo } from "react";
import { MaterialityStatusChip } from "../../double-materiality-assessment/common/materiality-status-chip.component";
import {
  DMACategoriesUtilities,
  IDataEntryObjectInputParameterIdentity,
} from "@netcero/netcero-common";
import { useDmaStatusQuery } from "../queries/data-entry-object-dma-status.queries";
import { useTranslation } from "react-i18next";

interface IInputParameterMaterialityChipProps {
  // Beware this should be the identity of the ROOT DEO
  identity: IDataEntryObjectInputParameterIdentity;
}

export const InputParameterMaterialityChip: FC<IInputParameterMaterialityChipProps> = ({
  identity,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_esrs_component");

  // Use organizationStructure, because we always need the root deo
  const dmaStatusQuery = useDmaStatusQuery(
    identity.organizationId,
    identity.recordingPeriodId,
    identity.dataEntryObjectId,
  );

  const isMaterial = useMemo(() => {
    return dmaStatusQuery.data
      ? DMACategoriesUtilities.isInputParameterMaterial(
          identity.inputParameterId,
          dmaStatusQuery.data,
          false,
        )
      : null;
  }, [identity.inputParameterId, dmaStatusQuery.data]);

  return (
    isMaterial === false && (
      <MaterialityStatusChip
        isMateriality={isMaterial}
        tooltipText={isMaterial ? undefined : t("materiality_not_material_chip_tooltip")}
      />
    )
  );
};
