/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IDmaCategoryIdentifierEnum = {
  E1ClimateProtection: "esrs_e1_climate_protection",
  E1AdaptionToClimateChange: "esrs_e1_adaption_to_climate_change",
  E1Energy: "esrs_e1_energy",
  E2AirPollution: "esrs_e2_air_pollution",
  E2WaterPollution: "esrs_e2_water_pollution",
  E2GroundPollution: "esrs_e2_ground_pollution",
  E2PollutionOfOrganisms: "esrs_e2_pollution_of_organisms",
  E2AlarmingSubstances: "esrs_e2_alarming_substances",
  E2MicroPlastic: "esrs_e2_micro_plastic",
  E3WaterConsumption: "esrs_e3_water_consumption",
  E3WaterWithdrawal: "esrs_e3_water_withdrawal",
  E3DrainOfWater: "esrs_e3_drain_of_water",
  E3DrainOfWaterIntoOceans: "esrs_e3_drain_of_water_into_oceans",
  E3UsageOfSeaResources: "esrs_e3_usage_of_sea_resources",
  E4DirectCausesOfBiodiversityLoss: "esrs_e4_direct_causes_of_biodiversity_loss",
  E4ImpactOnConditionOfSpecies: "esrs_e4_impact_on_condition_of_species",
  E4ImpactOnConditionOfEcosystems: "esrs_e4_impact_on_condition_of_ecosystems",
  E4ImpactsAndDependenciesOnEcosystemServices:
    "esrs_e4_impacts_and_dependencies_on_ecosystem_services",
  E5ResourceInfluxes: "esrs_e5_resource_influxes",
  E5ResourceEffluxes: "esrs_e5_resource_effluxes",
  E5Waste: "esrs_e5_waste",
  S1WorkingConditions: "esrs_s1_working_conditions",
  S1EqualityOfOpportunity: "esrs_s1_equality_of_opportunity",
  S1LabourLaw: "esrs_s1_labour_law",
  S2WorkingConditions: "esrs_s2_working_conditions",
  S2EqualityOfOpportunity: "esrs_s2_equality_of_opportunity",
  S2LabourLaw: "esrs_s2_labour_law",
  S3SocialRightsOfCommunities: "esrs_s3_social_rights_of_communities",
  S3PoliticalRightsOfCommunities: "esrs_s3_political_rights_of_communities",
  S3RightsIndigenousFolk: "esrs_s3_rights_indigenous_folk",
  S4InformationRelatedImpactsForConsumers: "esrs_s4_information_related_impacts_for_consumers",
  S4SafetyOfConsumers: "esrs_s4_safety_of_consumers",
  S4SocialInclusionOfConsumers: "esrs_s4_social_inclusion_of_consumers",
  G1CorporateCulture: "esrs_g1_corporate_culture",
  G1WhistleblowerProtection: "esrs_g1_whistleblower_protection",
  G1AnimalProtection: "esrs_g1_animal_protection",
  G1PoliticalCommitment: "esrs_g1_political_commitment",
  G1ManagementOfVendorRelations: "esrs_g1_management_of_vendor_relations",
  G1Corruption: "esrs_g1_corruption",
  E4ClimateChange: "esrs_e4_climate_change",
  E4ChangesInUsage: "esrs_e4_changes_in_usage",
  E4DirectExploitation: "esrs_e4_direct_exploitation",
  E4InvasiveSpecies: "esrs_e4_invasive_species",
  E4EnvironmentalPollution: "esrs_e4_environmental_pollution",
  E4Other: "esrs_e4_other",
  E4PopulationSizeOfSpecies: "esrs_e4_population_size_of_species",
  E4GlobalExtinctionRiskOfSpecies: "esrs_e4_global_extinction_risk_of_species",
  E4LandDegradation: "esrs_e4_land_degradation",
  E4Desertification: "esrs_e4_desertification",
  E4FloorSealant: "esrs_e4_floor_sealant",
  S1SafeEmployment: "esrs_s1_safe_employment",
  S1WorkingHours: "esrs_s1_working_hours",
  S1AptPay: "esrs_s1_apt_pay",
  S1SocialDialogue: "esrs_s1_social_dialogue",
  S1FreedomOfAssociation: "esrs_s1_freedom_of_association",
  S1Bargaining: "esrs_s1_bargaining",
  S1WorkLifeBalance: "esrs_s1_work_life_balance",
  S1HealthProtection: "esrs_s1_health_protection",
  S1EqualTreatmentOfGenders: "esrs_s1_equal_treatment_of_genders",
  S1TrainingCourses: "esrs_s1_training_courses",
  S1InclusionOfPeopleWithDisabilities: "esrs_s1_inclusion_of_people_with_disabilities",
  S1ActionsAgainstViolence: "esrs_s1_actions_against_violence",
  S1Diversity: "esrs_s1_diversity",
  S1ChildLabour: "esrs_s1_child_labour",
  S1ForceLabour: "esrs_s1_force_labour",
  S1AptAccommodation: "esrs_s1_apt_accommodation",
  S1DataPrivacy: "esrs_s1_data_privacy",
  S2SafeEmployment: "esrs_s2_safe_employment",
  S2WorkingHours: "esrs_s2_working_hours",
  S2AptPay: "esrs_s2_apt_pay",
  S2SocialDialogue: "esrs_s2_social_dialogue",
  S2FreedomOfAssociation: "esrs_s2_freedom_of_association",
  S2Bargaining: "esrs_s2_bargaining",
  S2WorkLifeBalance: "esrs_s2_work_life_balance",
  S2HealthProtection: "esrs_s2_health_protection",
  S2EqualTreatmentOfGenders: "esrs_s2_equal_treatment_of_genders",
  S2TrainingCourses: "esrs_s2_training_courses",
  S2InclusionOfPeopleWithDisabilities: "esrs_s2_inclusion_of_people_with_disabilities",
  S2ActionsAgainstViolence: "esrs_s2_actions_against_violence",
  S2Diversity: "esrs_s2_diversity",
  S2ChildLabour: "esrs_s2_child_labour",
  S2ForcedLabour: "esrs_s2_forced_labour",
  S2AptAccommodation: "esrs_s2_apt_accommodation",
  S2Sanitation: "esrs_s2_sanitation",
  S2DataPrivacy: "esrs_s2_data_privacy",
  S3AptAccommodation: "esrs_s3_apt_accommodation",
  S3AptNutrition: "esrs_s3_apt_nutrition",
  S3Sanitation: "esrs_s3_sanitation",
  S3ImpactsAffectingSoil: "esrs_s3_impacts_affecting_soil",
  S3ImpactsAffectingSafety: "esrs_s3_impacts_affecting_safety",
  S3FreedomOfSpeech: "esrs_s3_freedom_of_speech",
  S3FreedomOfAssembly: "esrs_s3_freedom_of_assembly",
  S3ImpactsOnProtectorsOfHumanRights: "esrs_s3_impacts_on_protectors_of_human_rights",
  S3Approval: "esrs_s3_approval",
  S3SelfDetermination: "esrs_s3_self_determination",
  S3CulturalRights: "esrs_s3_cultural_rights",
  S4DataPrivacy: "esrs_s4_data_privacy",
  S4FreedomOfSpeech: "esrs_s4_freedom_of_speech",
  S4AccessToInformation: "esrs_s4_access_to_information",
  S4HealthProtection: "esrs_s4_health_protection",
  S4PersonalSafety: "esrs_s4_personal_safety",
  S4ChildProtection: "esrs_s4_child_protection",
  S4NonDiscrimination: "esrs_s4_non_discrimination",
  S4AccessToProducts: "esrs_s4_access_to_products",
  S4ResponsibleMarketingMethods: "esrs_s4_responsible_marketing_methods",
  G1CorruptionPrevention: "esrs_g1_corruption_prevention",
  G1CorruptionOccurrences: "esrs_g1_corruption_occurrences",
} as const;

export type IDmaCategoryIdentifierEnum =
  (typeof IDmaCategoryIdentifierEnum)[keyof typeof IDmaCategoryIdentifierEnum];
