import { OrgRole } from "./authentication.public-types";

// General Constants

export const ORG_BASE_PERMISSIONS = ["view-roles"] as const;

export const ORG_ROLES_ASCENDING = [
  OrgRole.VIEW_MEMBERS,
  OrgRole.OBJECT_ADMIN,
  OrgRole.ORGANIZATION_ADMIN,
];
export const ORG_ROLES_DESCENDING = [...ORG_ROLES_ASCENDING].reverse();

// Specific Authentication Rules

export const ROLE_ACCESS = {
  ALLOWED_TO_MODIFY_DEO_ACCESS: [OrgRole.ORGANIZATION_ADMIN, OrgRole.OBJECT_ADMIN],
  ALLOWED_TO_ACCESS_GAP_ANALYSIS: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_ACCESS_DMA_DASHBOARD: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_COMPLETE_DMA: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_ACCESS_GLOBAL_AUDIT_LOG: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_DMA_ESRS_TOPICS: [OrgRole.ORGANIZATION_ADMIN],
  /**
   * Currently used to limit actions on DMA categories (rename, validate, feedback, opt out etc.)
   * Also includes creating, updating and deleting IROs AS well as moving IROs and whether materiality chip is shown in UI
   */
  ALLOWED_TO_MODIFY_DMA_CATEGORIES: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_ALL_IROS: [OrgRole.ORGANIZATION_ADMIN],

  ALLOWED_TO_MODIFY_DMA_CONFIGURATION: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_CONFIGURATION_RECORDING_PERIOD: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_CONFIGURATION_DISTRIBUTION_CRITERIA: [OrgRole.ORGANIZATION_ADMIN],
  ALLOWED_TO_MODIFY_EMISSION_FACTORS: [OrgRole.ORGANIZATION_ADMIN, OrgRole.OBJECT_ADMIN],
};
