import { FC } from "react";
import { OrgRole } from "@netcero/netcero-common";
import { NotAuthorizedNotice } from "../components/not-authorized-notice.component";
import { useDoesUserHaveRole } from "../hooks/does-user-have-role.hook";

/**
 * Internally uses `useAuthenticatedUserOrganizationRole` to get the user's role. The organization Id is therefore read from router params.
 * @param rolesWithAccess
 * @param component
 */
export function requiresOrganizationRole<T>(rolesWithAccess: OrgRole[], component: FC<T>): FC<T> {
  return (props: T) => {
    const hasAccess = useDoesUserHaveRole(rolesWithAccess);
    return hasAccess ? component(props) : <NotAuthorizedNotice />;
  };
}
