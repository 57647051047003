import { BaseApiClient, IBaseApiClientConfiguration, IFetchOptions } from "@netcero/netcero-common";
import {
  IKeycloakUserApiClientConfiguration,
  IKeycloakUserProfile,
} from "./keycloak-user-api.types";

export class KeycloakUserApiClient extends BaseApiClient {
  // Static helpers

  private static createBaseApiConfigurationFromKeycloakConfiguration(
    configuration: IKeycloakUserApiClientConfiguration,
  ): IBaseApiClientConfiguration {
    const keycloakBaseUrl = `${configuration.baseUrl}/realms/${configuration.realm}/`;
    return {
      ...configuration,
      baseUrl: keycloakBaseUrl,
    };
  }

  protected static override async fetch(
    configuration: IKeycloakUserApiClientConfiguration,
    method: "GET" | "POST" | "PUT" | "DELETE",
    path?: string,
    options?: IFetchOptions,
  ) {
    return super.fetch(
      KeycloakUserApiClient.createBaseApiConfigurationFromKeycloakConfiguration(configuration),
      method,
      path,
      options,
    );
  }

  // Actual API Calls

  public static readAccount(configuration: IKeycloakUserApiClientConfiguration) {
    return KeycloakUserApiClient.fetch(configuration, "GET", "account").then(
      KeycloakUserApiClient.parseJson<IKeycloakUserProfile>,
    );
  }

  public static updateAccount(
    configuration: IKeycloakUserApiClientConfiguration,
    account: IKeycloakUserProfile,
  ) {
    return KeycloakUserApiClient.fetch(configuration, "POST", "account", {
      body: account,
    });
  }

  // Instance Logic

  constructor(protected override configuration: IKeycloakUserApiClientConfiguration) {
    super(KeycloakUserApiClient.createBaseApiConfigurationFromKeycloakConfiguration(configuration));
  }

  public readAccount() {
    return KeycloakUserApiClient.readAccount(this.configuration);
  }

  public updateAccount(account: IKeycloakUserProfile) {
    return KeycloakUserApiClient.updateAccount(this.configuration, account);
  }
}
