import { FC, PropsWithChildren, ReactNode } from "react";
import { OrgRole } from "@netcero/netcero-common";
import { useDoesUserHaveRole } from "../hooks/does-user-have-role.hook";

interface IOnlyShowToUsersWithRoleProps extends PropsWithChildren {
  roles: OrgRole[];
  placeholder?: ReactNode;
}

export const OnlyShowToUsersWithRole: FC<IOnlyShowToUsersWithRoleProps> = ({
  roles,
  placeholder,
  children,
}) => {
  const showToUser = useDoesUserHaveRole(roles);
  return showToUser ? children : placeholder;
};
