import {
  IDMACategoryState,
  IDMACategoryWithEffects,
  IDmaStatus,
} from "@netcero/netcero-core-api-client";
import { ESRS_DMA_CATEGORY_INPUT_PARAMETERS } from "./constants";
import { DMAUtilities } from "@netcero/netcero-dma";
import { ArrayUtilities } from "../common";

export class DMACategoriesUtilities {
  public static canCategoryBeAltered(category: { static?: unknown | null }) {
    return category.static === null || category.static === undefined;
  }

  /**
   * This method determines the materiality of the given input parameter based on it's defined links to DMA categories.
   * If no links are defined, null is returned.
   * @param inputParameterId The ID of the input parameter to check
   * @param dmaStatus The DMA status to evaluate
   * @param allowIncomplete Whether a materiality should be computed if the DMA status is not "completed"
   */
  public static isInputParameterMaterial(
    inputParameterId: string,
    dmaStatus: IDmaStatus,
    allowIncomplete: boolean,
  ): boolean | null {
    return DMAUtilities.handleIncompleteStatus(dmaStatus, allowIncomplete, (validatedStatus) => {
      const categories = ESRS_DMA_CATEGORY_INPUT_PARAMETERS[inputParameterId];

      // no materiality can be determined if no categories are linked
      if (categories === undefined) {
        return null;
      }

      const materialitiesOfCategories = categories.map(
        (c) => validatedStatus.materiality.materialityPerCategory[c] ?? null,
      );

      const materialitiesWithoutNull =
        ArrayUtilities.removeNotPresentValues(materialitiesOfCategories);

      if (materialitiesWithoutNull.length === 0) {
        // materiality could not be determined for any category
        return null;
      } else {
        // materiality could be determined for at least some categories --> only consider them; return true if at least one is true
        return materialitiesWithoutNull.some((c) => c);
      }
    });
  }

  /**
   * Determines if a category can be set as verified based on its material and financial states.
   *
   * @param category - An object containing the material and financial states of a DMA category.
   * @param category.materialState - The material state of the category.
   * @param category.financialState - The financial state of the category.
   *
   * @returns A boolean indicating whether the category can be set as verified.
   * Returns true if both material and financial states are 'Shared', false otherwise.
   */
  public static canCategorySetVerified(
    category: Pick<IDMACategoryWithEffects, "materialState" | "financialState">,
  ) {
    return (
      category.materialState === IDMACategoryState.Shared &&
      category.financialState === IDMACategoryState.Shared
    );
  }

  /**
   * Determines if a category can be unset from verified status based on its material and financial states.
   *
   * @param category - An object containing the material and financial states of a DMA category.
   * @param category.materialState - The material state of the category.
   * @param category.financialState - The financial state of the category.
   *
   * @returns A boolean indicating whether the category can be unset from verified status.
   * Returns true if both the material state and the financial state are 'Verified', false otherwise.
   */
  public static canCategoryUnsetVerified(
    category: Pick<IDMACategoryWithEffects, "materialState" | "financialState">,
  ) {
    return (
      category.materialState === IDMACategoryState.Verified &&
      category.financialState === IDMACategoryState.Verified
    );
  }
}
