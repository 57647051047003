import React, { useEffect, useMemo, useState } from "react";
import { Box, Collapse, List, ListItem, ListItemText } from "@mui/material";
import { ChevronDownIcon, ChevronUpIcon } from "../constants/tabler-icon.constants";
import { TreeNodeData, TreeUtilities } from "@netcero/netcero-common";
import { HighlightUtils } from "../utilities/highlight.utilities";
import { useDebounce } from "@uidotdev/usehooks";
import { SearchTextInput } from "../components/search-text-input.component";
import { ACTIVE_BACKGROUND_HIGHLIGHT_COLOR } from "../../../theme/theme";

export type TreePickerSingleSelectProps = {
  treeNodeData: TreeNodeData;
  selectedNodeId?: string;
  onChange?: (selectedNode: string) => void;
  selectableNodeIds?: string[];
};

export const TreePickerSingleSelect: React.FC<TreePickerSingleSelectProps> = ({
  treeNodeData,
  selectedNodeId,
  onChange,
  selectableNodeIds,
}) => {
  const allNodes = useMemo(
    () => TreeUtilities.getAllIds([treeNodeData], (node) => node.children),
    [treeNodeData],
  );
  const [expandedNodes, setExpandedNodes] = useState<string[]>(allNodes);
  const [filteredNodes, setFilteredNodes] = useState<TreeNodeData | null>(treeNodeData);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearch = useDebounce(searchText, 250);

  // Filter nodes and update expanded nodes when search changes
  useEffect(() => {
    if (debouncedSearch) {
      const matchingAncestors = TreeUtilities.getMatchingAncestorsBySearchTerm(
        treeNodeData,
        debouncedSearch,
      );
      const matchingNodesWithDescendants = TreeUtilities.getMatchingNodesWithDescendants(
        treeNodeData,
        debouncedSearch,
      );
      setExpandedNodes(matchingAncestors);
      setFilteredNodes(matchingNodesWithDescendants);
    } else {
      setExpandedNodes(allNodes);
      setFilteredNodes(treeNodeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update when search text changes
  }, [debouncedSearch]);

  const handleClickNode = (nodeId: string) => {
    if (onChange) {
      onChange(nodeId);
    }
  };

  const getIsNodeEnabled = (nodeId: string) => {
    if (!selectableNodeIds || selectableNodeIds.includes(nodeId)) {
      return true;
    }

    return false;
  };

  const handleNodeExpand = (nodeId: string) => {
    setExpandedNodes((prev) =>
      prev.includes(nodeId) ? prev.filter((id) => id !== nodeId) : [...prev, nodeId],
    );
  };

  const renderTreeNode = (node: TreeNodeData | null, depth = 0) => {
    if (!node) {
      return null;
    }

    const isNodeEnabled = getIsNodeEnabled(node.id);
    return (
      <Box
        key={node.id}
        sx={{
          borderLeft: depth !== 0 ? "1px solid" : undefined,
          borderColor: "grey.100",
          ml: depth !== 0 ? 4 : 0,
          pr: 1,
        }}
      >
        <ListItem
          sx={{
            p: 0,
            cursor: isNodeEnabled ? "pointer" : "default",
            width: "max-content",
          }}
        >
          <Box display="flex" flexDirection="row" width="100%">
            <Box
              sx={{
                borderBottom: depth !== 0 ? "1px solid" : undefined,
                borderColor: "grey.100",
                lineHeight: "0.1em",
                margin: "10px 0 20px",
                width: "10px",
              }}
            ></Box>
            <ListItemText
              primary={HighlightUtils.highlightMatch(node.label, debouncedSearch)}
              onClick={() => isNodeEnabled && handleClickNode(node.id)}
              sx={{
                py: 0.5,
                px: 1,
                mx: 0.5,
                color: isNodeEnabled ? "text.primary" : "text.disabled",
                borderRadius: 2,
                backgroundColor: (theme) =>
                  selectedNodeId === node.id ? ACTIVE_BACKGROUND_HIGHLIGHT_COLOR : "transparent",
                ": hover": {
                  backgroundColor: "grey.50",
                },
              }}
            />
          </Box>
          {node.children.length > 0 && (
            <Box
              onClick={() => handleNodeExpand(node.id)}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {expandedNodes.includes(node.id) ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Box>
          )}
        </ListItem>

        {node.children.length > 0 && expandedNodes.includes(node.id) && (
          <Collapse in={expandedNodes.includes(node.id)}>
            <List component="div" disablePadding>
              {node.children.map((childNode) => renderTreeNode(childNode, depth + 1))}
            </List>
          </Collapse>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <SearchTextInput
        search={searchText}
        onSearchChange={setSearchText}
        textFieldProps={{
          sx: { px: 2, pt: 1 },
        }}
      />
      <Box sx={{ maxHeight: 430, overflowY: "auto" }}>
        <List>{renderTreeNode(filteredNodes)}</List>
      </Box>
    </Box>
  );
};
