import {
  IInputParameterRecordingStructure,
  IInputParameterRecordingStructureBase,
  IRecordingModel,
} from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameter } from "../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import {
  ILocalInputParameterRecordingStructureESRS,
  ILocalInputParameterRecordingStructureTHG,
} from "./local-input-parameter-recording-structure.interfaces";

export class InputParameterRecordingStructuresUtilities {
  /**
   * Creates a lookup map from the given input parameters
   * @param dataEntryObjectInputParameters Input parameters to create the lookup map from
   * @returns Map with the input parameter id as key and the input parameter as value
   */
  public static getInputParameterEntryLookupMap(
    dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
  ) {
    const result = new Map<string, ILocalDataEntryObjectInputParameter>();

    dataEntryObjectInputParameters.forEach((inputParameterEntry) => {
      result.set(inputParameterEntry.inputParameter.id, inputParameterEntry);
    });

    return result;
  }

  /**
   * Extracts the order values used in the given structures and groups them by the Recording Model Id
   * @param structures The structures to extract the order values from
   * @returns A Map with the Recording Model Id to the Set of its used order values
   */
  public static extractRecordingModelsOrderValues(
    structures: IInputParameterRecordingStructureBase[],
  ) {
    const result = new Map<string | null, Set<number>>();

    for (const structure of structures) {
      const orderValues = result.get(structure.recordingModelId || null) ?? new Set<number>();
      orderValues.add(structure.recordingModelOrder);
      result.set(structure.recordingModelId || null, orderValues);
    }

    return result;
  }

  /**
   * Groups the given recording structures by their recording model id and sorts them by their order value
   * @param structures The structures to group
   */
  public static groupRecordingStructuresByRecordingModelId(
    structures: IInputParameterRecordingStructureBase[],
  ) {
    const result = new Map<string | null, IInputParameterRecordingStructureBase[]>();

    for (const structure of structures) {
      const group = result.get(structure.recordingModelId || null) ?? [];
      group.push(structure);
      result.set(structure.recordingModelId || null, group);
    }

    // Sort the groups by their order value
    for (const group of result.values()) {
      group.sort((a, b) => a.recordingModelOrder - b.recordingModelOrder);
    }

    return result;
  }

  /**
   * Get Grouped Recording Structures
   * @param recordingModels The recording models to group
   * @param recordingStructures The structures to group
   * @param hasAccessToEsrs Flag indicating if the user has access to ESRS
   * @param canCreateStructures Flag indicating if the user can create structures (only then will empty structure be added)
   */
  public static getGroupedRecordingStructures = (
    recordingModels: IRecordingModel[],
    recordingStructures: IInputParameterRecordingStructureBase[],
    hasAccessToEsrs: boolean | null,
    canCreateStructures: boolean,
  ): [IRecordingModel | null, IInputParameterRecordingStructureBase[]][] => {
    const result: [IRecordingModel | null, IInputParameterRecordingStructureBase[]][] = [
      ...InputParameterRecordingStructuresUtilities.groupRecordingStructuresByRecordingModelId(
        recordingStructures,
      ),
    ].map(([modelId, structures]) => [
      recordingModels.find((model) => model.id === modelId) ?? null,
      structures,
    ]);

    // Add ESRS model if not present
    if (
      canCreateStructures &&
      hasAccessToEsrs &&
      !result.some(([model, structures]) => model?.identifier === "esrs")
    ) {
      const esrsModel = recordingModels.find((model) => model.identifier === "esrs");
      if (esrsModel) {
        result.push([esrsModel, []]);
      }
    }

    // At least add empty array for recording structures without recording model
    if (canCreateStructures && result.length === 0) {
      result.push([null, []]);
    }

    return result;
  };

  /**
   * Check if the given recording structure is an ESRS recording structure by its identifier
   * @param recordingStructure The recording structure to check
   */
  public static isEsrsRecordingStructureByIdentifier(
    recordingStructure: IInputParameterRecordingStructureBase,
  ) {
    return recordingStructure.categoryIdentifier.startsWith("esrs");
  }

  /**
   * Check if the given recording structure is an ESRS recording structure by its identifier
   * @param recordingStructure The recording structure to check
   */
  public static isThgRecordingStructureByIdentifier(
    recordingStructure: IInputParameterRecordingStructureBase,
  ) {
    // TODO: update with actual type as soon as new types are added
    return !recordingStructure.categoryIdentifier.startsWith("esrs");
  }

  /**
   * Compare the given recording structures by their order value. `recordingModelOrder` is used for this.
   * @param a
   * @param b
   */
  public static compareRecordingStructures(
    a: IInputParameterRecordingStructureBase,
    b: IInputParameterRecordingStructureBase,
  ) {
    return a.recordingModelOrder - b.recordingModelOrder;
  }

  /**
   * Check if the given recording structure is a THG recording structure
   * @param structure
   */
  public static isTHGRecordingStructure(
    structure: IInputParameterRecordingStructure,
  ): structure is ILocalInputParameterRecordingStructureTHG {
    return structure.structure.type === "thg";
  }

  /**
   * Check if the given recording structure is an ESRS recording structure
   * @param structure
   */
  public static isESRSRecordingStructure(
    structure: IInputParameterRecordingStructure,
  ): structure is ILocalInputParameterRecordingStructureESRS {
    return structure.structure.type === "esrs";
  }
}
