import { BaseApiClient, IBaseApiClientConfiguration } from "@netcero/netcero-common";
import { IAiData } from "./ai-api.types";

export class AiApiClient extends BaseApiClient {
  constructor(protected override configuration: IBaseApiClientConfiguration) {
    super(configuration);
  }

  // API Calls
  public static fetchAiResponse(
    configuration: IBaseApiClientConfiguration,
    organizationId: string,
    data: IAiData,
  ): Promise<ReadableStream<string>> {
    return BaseApiClient.fetchTextStream(
      configuration,
      "POST",
      `/organizations/${organizationId}/ai`,
      {
        body: data,
      },
    );
  }

  public fetchAiResponseStream(organizationId: string, data: IAiData) {
    return AiApiClient.fetchAiResponse(this.configuration, organizationId, data);
  }
}
