import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext } from "react";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../interfaces/local-data-entry-object-values.interfaces";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import {
  IDataEntryObject,
  IDataEntryObjectInputParameterContext,
  IInputParameter,
  IOrganization,
} from "@netcero/netcero-core-api-client";
import { InputParameterDisplayInformationLookup } from "../hooks/conditional-display-input-parameters.hook";
import { IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { ILocalInputParameterRecordingStructureESRS } from "../../input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";

export interface IDeoEsrsInputContextData {
  expandedDisclosureRequirementIds: string[];
  setExpandedDisclosureRequirementIds: Dispatch<SetStateAction<string[]>>;
  expandedInputParameterIds: string[];
  setExpandedInputParameterIds: Dispatch<SetStateAction<string[]>>;
  organization: IOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  organizationStructure: IDataEntryObject;
  dataEntryObject: IDataEntryObject;
  recordingStructureId: string;
  recordingStructure: ILocalInputParameterRecordingStructureESRS;
  sectionId: string;
  // NC-1945: Disable distribution criteria
  // availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  conditionalDisplayInputParametersLookup: InputParameterDisplayInformationLookup;
  // Configuration Updates
  handleDisclosureRequirementContributingUserIdsChange: (
    inputParameter: ILocalDataEntryObjectInputParameter,
    newContributorsIds: string[],
  ) => void;
  handleInputParameterContributingUserIdsChange: (
    inputParameter: ILocalDataEntryObjectInputParameter,
    newContributorsIds: string[],
  ) => void;
  handleDisclosureRequirementResponsibleUsersChange: (
    inputParameter: ILocalDataEntryObjectInputParameter,
    newResponsibleUserId: string | null,
  ) => void;
  handleInputParameterResponsibleUsersChange: (
    inputParameter: ILocalDataEntryObjectInputParameter,
    newResponsibleUserId: string | null,
  ) => void;
  // Value handling
  handleCreateDrValue: (
    drInputParameter: IInputParameter,
    data: ILocalDataEntryObjectInputParameterValueData,
  ) => Promise<void>;
  handleUpdateDrValue: (
    drInputParameter: IInputParameter,
    valueId: string,
    data: ILocalDataEntryObjectInputParameterValueData,
  ) => Promise<void>;
  handleDeleteDrValue: (drInputParameter: IInputParameter, valueId: string) => Promise<void>;
  handleCreateIPValue: (
    inputParameter: IInputParameter,
    data: ILocalDataEntryObjectInputParameterValueData,
  ) => Promise<void>;
  handleUpdateIPValue: (
    inputParameter: IInputParameter,
    valueId: string,
    data: ILocalDataEntryObjectInputParameterValueData,
  ) => Promise<void>;
  handleDeleteIPValue: (inputParameter: IInputParameter, valueId: string) => Promise<void>;
  handleUpdateTableValue: (
    inputParameter: IInputParameter,
    createdRows: ILocalDataEntryObjectInputParameterValueData[],
    updatedRows: ILocalDataEntryObjectInputParameterValue[],
    deleteRows: ILocalDataEntryObjectInputParameterValue[],
  ) => Promise<void>;
  // Submit, Approve, Reject
  handleResetInputParameter: (inputParameter: IInputParameter) => Promise<void>;
  handleSubmitInputParameter: (
    inputParameter: IInputParameter,
    context: IDataEntryObjectInputParameterContext,
  ) => Promise<void>;
  handleApproveInputParameter: (inputParameter: IInputParameter) => Promise<void>;
  handleRejectInputParameter: (
    inputParameter: IInputParameter,
    context: IDataEntryObjectInputParameterContext,
  ) => Promise<void>;
  handleResetDisclosureRequirement: (inputParameter: IInputParameter) => Promise<void>;
  handleSubmitDisclosureRequirement: (
    inputParameter: IInputParameter,
    context: IDataEntryObjectInputParameterContext,
  ) => Promise<void>;
  handleApproveDisclosureRequirement: (inputParameter: IInputParameter) => Promise<void>;
  handleRejectDisclosureRequirement: (
    inputParameter: IInputParameter,
    context: IDataEntryObjectInputParameterContext,
  ) => Promise<void>;
  // Url Creation
  createDisclosureRequirementUrl: (
    disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  ) => string;
  createInputParameterUrl: (inputParameter: ILocalDataEntryObjectInputParameter) => string;
  // State
  isLoading: boolean;
}

const DeoEsrsInputContext = createContext<IDeoEsrsInputContextData>({
  expandedDisclosureRequirementIds: [],
  setExpandedDisclosureRequirementIds: () => {},
  expandedInputParameterIds: [],
  setExpandedInputParameterIds: () => {},
  organization: {} as IOrganization,
  recordingPeriod: {} as ILocalRecordingPeriod,
  organizationStructure: {} as IDataEntryObject,
  dataEntryObject: {} as IDataEntryObject,
  recordingStructureId: "",
  recordingStructure: {} as ILocalInputParameterRecordingStructureESRS,
  sectionId: "",
  // NC-1945: Disable distribution criteria
  // availableDistributionCriteria: [],
  conditionalDisplayInputParametersLookup: {},
  handleDisclosureRequirementContributingUserIdsChange: () => {},
  handleInputParameterContributingUserIdsChange: () => {},
  handleDisclosureRequirementResponsibleUsersChange: () => {},
  handleInputParameterResponsibleUsersChange: () => {},
  handleCreateDrValue: async () => {},
  handleUpdateDrValue: async () => {},
  handleDeleteDrValue: async () => {},
  handleCreateIPValue: async () => {},
  handleUpdateIPValue: async () => {},
  handleDeleteIPValue: async () => {},
  handleUpdateTableValue: async () => {},
  // Submit, Approve, Reject
  handleResetInputParameter: async () => {},
  handleSubmitInputParameter: async () => {},
  handleApproveInputParameter: async () => {},
  handleRejectInputParameter: async () => {},
  handleResetDisclosureRequirement: async () => {},
  handleSubmitDisclosureRequirement: async () => {},
  handleApproveDisclosureRequirement: async () => {},
  handleRejectDisclosureRequirement: async () => {},
  // Url Creation
  createDisclosureRequirementUrl: () => "",
  createInputParameterUrl: () => "",
  isLoading: false,
});

interface IDeoEsrsInputContextProviderProps {
  value: IDeoEsrsInputContextData;
}

export const DeoEsrsInputContextProvider: FC<
  PropsWithChildren<IDeoEsrsInputContextProviderProps>
> = ({ value, children }) => {
  return <DeoEsrsInputContext.Provider value={value}>{children}</DeoEsrsInputContext.Provider>;
};

export const useDeoEsrsInputContext = () => {
  return useContext(DeoEsrsInputContext);
};
