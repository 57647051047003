import { UUID } from "../common.public-types";

export class LookupUtilities {
  public static generateLookUp<T extends object, K extends string | number | symbol>(
    entities: T[],
    idGetter: (entity: T) => K,
    throwOnDuplicateIdentifier: boolean = true,
  ) {
    return entities.reduce((acc, curr) => {
      const identifier = idGetter(curr);

      if (acc[identifier] !== undefined && throwOnDuplicateIdentifier) {
        throw new Error(`Duplicate identifier: ${String(identifier)}`);
      }

      acc[idGetter(curr)] = curr;

      return acc;
    }, {} as Record<K, T>); // TODO: Use Partial<> to handle undefined values (not always complete)
  }

  public static generateEntityLookUp<T extends { id: UUID }>(
    entities: T[],
    throwErrorOnDuplicateIdentifier: boolean = true,
  ) {
    return LookupUtilities.generateLookUp(entities, (e) => e.id, throwErrorOnDuplicateIdentifier);
  }
}
